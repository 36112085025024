import React from 'react'
import { View, StyleSheet, Ratio, Assets, Container, Image, Text, Input, PrimaryButton, Colors, Validation, Functions, Models } from 'utils/imports.utils';

export default function ResetPassword(props: any) {
  const [state, setState] = Functions.useSetState({ password: "", confirmPassword: "", secure: true , visible : true});

  const resetPassword = async () => {
    try {
      await Validation.resetSchema.validate({ password: state.password, confirmPassword: state.confirmPassword });
      const reset: any = await Models.auth.resetPassword({ reset_password_hash: props.location.state.hash, password: state.password });
      Functions.Success("Success", "Password reset successfully");
      setState({ password: "", confirmPassword: "", secure: true });
      addPasswordToStorage(props.location.state.email)
      props.history.push('/ResetSuccess');
    } catch (err) {
      console.log(JSON.stringify(err));
      Functions.Failure(err);
    }
  }
  const addPasswordToStorage = async (email: string) =>{
    let newUser:any = [ ]
    let loggedInData: any = await localStorage.getItem('loggedInData');
    JSON.parse(loggedInData).forEach( async (data:any) => {
      if(data.email == props.route.params.email){
        data.password = state.confirmPassword
      }
      return newUser.push(data)
    })
   await localStorage.setItem('loggedInData' , JSON.stringify(newUser))
  }
  return (
    <View style={styles.loginMainContainer}>
      <View style={styles.loginScreen}>
        <View style={styles.loginContainer}>
          <View style={styles.loginImageWrapper}>
            <Image src={Assets.Lock} width={Ratio(42)} height={Ratio(42)} svg />
          </View>
          <View style={styles.loginWrapper}>
            <View style={styles.loginHead}>
              <Text size={Ratio(24)} color={Colors.primaryDarkColor}>Reset Your Password</Text>
            </View>
            <View style={styles.inputWrapper}>
              <View style={styles.paddingBottom}>
                <Input
                  name="password"
                  label="Password"
                  onChange={(password: any) => setState({ password })}
                  icon={state.secure ? Assets.EyeHide : Assets.Eye}
                  iconHeight={Ratio(20)}
                  iconWidth={Ratio(20)}
                  iconPosition="end"
                  onIconClick={() => setState({ secure: !state.secure })}
                  value={state.password}
                  type={state.secure ? 'password' : 'text'}
                />
              </View>
              <View style={styles.paddingBottom}>
                <Input
                  icon={state.visible ? Assets.EyeHide : Assets.Eye}
                  type={state.visible ? 'password' : 'text'}
                  name="confirmPassword"
                  label="Confirm Password"
                  onChange={(confirmPassword: any) => setState({ confirmPassword })}
                  // styles={styles.input}
                  value={state.confirmPassword}
                  iconHeight={Ratio(20)}
                  iconWidth={Ratio(20)}
                  iconPosition="end"
                  onIconClick={() => setState({ visible: !state.visible })}
                />
              </View>
            </View>
            <View style={styles.buttonWrapper}>
              <PrimaryButton
                width={200}
                text="Reset"
                onPress={() => resetPassword()}
                backgroundColor={Colors.primaryDarkColor}

              />
            </View>
          </View>
        </View>
      </View>
      </View>
  )
}

const styles = StyleSheet.create({
  loginMainContainer:{
    height:"100vh",
    width:"100vw",
    backgroundColor: Colors.buttonTextColor,
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
  },

  loginScreen: {
    width: "30%",
    height: "100%",
  },
  loginContainer: {
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center"
  },
  loginImageWrapper: {
    width: Ratio(100),
    height: Ratio(100),
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 28,
    backgroundColor: Colors.secondaryLightColor
  },
  loginWrapper: {
    width: "100%",
    alignItems: "center",
    paddingTop: Ratio(24)
  },
  forgetPasswordWrapper: {
    width: "80%",
    justifyContent: "center",
    paddingBottom: Ratio(6)
  },
  loginHead: {
    width: "80%",
    alignItems: "center",
    paddingBottom: Ratio(24)
  },
  inputWrapper: {
    width: "100%"
  },
  paddingBottom: {
    paddingBottom: Ratio(10)
  },
  input: {
    width: "80%"
  },
  buttonWrapper: {
    width: "80%"
  },
  forgetContainer: {
    width: "100%",
    alignItems: "flex-end"
  }
});