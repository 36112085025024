import _ from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  Text,
  Ratio,
  Colors,
  Constants,
  Acuity,
  Functions,
  Models,
  View, StyleSheet, FlatList, ActivityIndicator,TouchableOpacity
} from 'utils/imports.utils';
import {
  getAcuityDetails,
  getAcuityList,
  getAcuityUser,
  getAcuityTrainingList,
} from 'utils/redux.utils';

interface pagination {
  page: number;
  limit: number;
}

const TrainingDetails = (props: any) => {
  const acuityList = useSelector((state: any) => state.acuity.acuityList);
  const acuity = useSelector((state: any) => state.acuity.acuity);
  const acuityTraining = useSelector((state: any) => state.training.acuityTrainingList);

  const [state, setState] = Functions.useSetState({
    hasMore: false,
    page: 1,
    limit: 20,
    loading: true,
  });

  useEffect(() => {
    setState({ loading: true });
    getSchedules({
      page: state.page,
      limit: state.limit,
    });
  }, []);

  const getSchedules = async ({ page, limit }: pagination) => {
    try {
      const appoinments: any = await Models.acuity.appointmentsList();
      getAcuityList(appoinments.data);
      getAcuityTrainings();
    } catch (err) {
      console.log('err', err);
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get schedules list');
      }
    }
  };

  const getAcuityTrainings = async () => {
    try {
      const trainings: any = await Models.training.acuityTraining();
      getAcuityTrainingList(trainings.data);
      setState({ loading: false });
    } catch (err) {
      console.log('err', err);
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get acuity trainings');
      }
    }
  };

  const loadMore = () => {
    if (state.hasMore) {
      getSchedules({ page: state.page + 1, limit: state.limit });
    }
  };

  const loader = () => {
    return (
      <View>
        {state.loadMoreLoader && (
          <ActivityIndicator
            size="small"
            color={Colors.primaryDarkColor}
            style={{ marginLeft: 6 }}
          />
        )}
      </View>
    );
  };

  const addCustomer = async (acuity: any) => {
    try {
      let req: any = {
        email: acuity.email,
        phone: acuity.phone,
        username: acuity.firstName + ' ' + acuity.lastName,
        user_type: 'customer',
      };
      const customer: any = await Models.admin.createUser(req);
      getAcuityUser(customer.data);
      props.navigation.navigate('AddTraining', { add: true, appoinment: true });
    } catch (err) {
      // if (err?.data?.data) {
      //   getAcuityUser(err.data.data);
      //   setTimeout(() => {
      //     props.navigation.navigate('AddTraining', { add: true, appoinment: true });
      //   }, 2000);
      // } else {
      //   Functions.Failure(err);
      // }
    }
  };

  const handleSchedule = async (item: any) => {
    await getAcuityDetails(item);
    addCustomer(item);
  };
  const syncAcuity = async () => {
    try{
      setState({ syncLoading: true })
      await Models.acuity.syncAcuity();
      setState({ syncLoading: false })
      Functions.Success('Success', 'Acuity Appointments Synced');
      getSchedules({
        page: state.page,
        limit: state.limit,
      });
    }catch(error){
      Functions.Failure('Failed', 'Failed to Sync Acuity Appointments');
      setState({ syncLoading: false })
      console.log(error)
    }
  }
  console.log('acuityList', acuityList);
  // console.log("acuityTraining.findIndex((acuity: any) => acuity.acuity_id.toString() === item.id.toString()) === -1 ? false : true", acuityTraining.findIndex((acuity: any) => acuity.acuity_id.toString() === "674615599") === -1 ? false : true)
  // acuityTraining.filter((acuity: any) => {
  //   console.log('acuity', acuity);
  // })
  return (
    <Container screen styles={styles.containerStyle} loading={state.loading}>
      <View style={styles.mainContainer}>
        <View style={styles.headerWrapper}>
          <Text color={Colors.primaryDarkColor} size={Ratio(24)} family={Constants.regular}>
            Acuity
          </Text>
          {
              state.syncLoading ? 
              <ActivityIndicator
                size="small"
                color={Colors.primaryDarkColor}
                style={{ marginRight: 6 }}
              /> 
              :
              <TouchableOpacity style={styles.acuitySync} onPress={syncAcuity}>
                <Text color={Colors.primaryDarkColor} >Sync Acuity</Text>
              </TouchableOpacity>
            }
        </View>
        <View style={styles.acuityList}>
          <FlatList
            data={acuityList}
            showsVerticalScrollIndicator={false}
            keyExtractor={(item: any) => item.name}
            renderItem={({ item, index }) => (
              <View
                style={{ width: "49%", paddingBottom: index === acuityList.length - 1 ? Ratio(100) : Ratio(20) }}>
                <Acuity
                  name={item.firstName + ' ' + item.lastName}
                  start_date={item.datetime}
                  end_date={item.end_date ? item.end_date : false}
                  training_type={item.type}
                  scheduled={
                    acuityTraining.findIndex(
                      (acuity: any) => acuity.acuity_id.toString() === item.id.toString(),
                    ) === -1
                      ? false
                      : true
                  }
                  handleSchedule={() => handleSchedule(item)}
                />
              </View>
            )}
            onEndReached={loadMore}
            scrollEnabled={true}
            ListFooterComponent={loader()}
          />
        </View>
      </View>
    </Container>
  );
};

const styles = StyleSheet.create({
  containerStyle: {
    padding: '0px 20px',
  },
  mainContainer: {
    width: '100%',
    height: '100%',
    // backgroundColor: "red"
  },
  headerWrapper: {
    // backgroundColor: "blue",
    padding: '10px 0px',
    height: '8%',
    justifyContent: 'space-between',
    flexDirection:"row"
  },
  acuityList: {
    paddingTop: Ratio(10),
    height: '92%',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    flexDirection: "row",
  },
});

export default TrainingDetails;
