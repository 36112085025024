import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'utils/functions.utils';
import {
  Functions,
  Models,
  Input,
  Assets,
  Component,
  PlanCard,
  Validation,
} from 'utils/imports.utils';
import { getUserDetails } from 'utils/redux.utils';
import { Form, Formik } from 'formik';
import './signup.ui.scss';
import { useDebouncedCallback } from 'use-debounce';

const SignUpComponent = (props: any) => {
  // Redux
  // State
  const [state, setState] = useSetState({
    org_name: ' ',
    name: ' ',
    email: ' ',
    activity: false,
    password: ' ',
    secure: true,
    selected: false,
    id: '',
    product_list: [],
    loading: false,
    disable: false,
    user_error: false,
    selectedForOrg: false,
  });

  //Hooks
  useEffect(() => {
    getProducts();
  }, []);

  const debounced = useDebouncedCallback((org_name) => {
    setState({ org_name });
    if(org_name !== ''){
      checkIfOrgExists(org_name);
    }
  }, 500);

  const debouncedSearch = useDebouncedCallback((email) => {
    setState({ email });
    if(email !== ''){
    checkIfUserExists(email);
    }
  }, 500);

  // Network req
  const getProducts = async () => {
    try {
      const res: any = await Models.subscription.getProductList();
      await getPriceList(res.data);
    } catch (error) {
      console.log('error', JSON.stringify(error));
    }
  };

  const getPriceList = async (data?: any) => {
    try {
      const res: any = await Models.subscription.getPriceAmount();
      data.map((item: any) => {
        res.data.map((price: any) => {
          if (item.id === price.product) {
            item.price = price.unit_amount;
            item.recurring = price.recurring;
          }
        });
        return item;
      });
      setState({ product_list: data });
    } catch (error) {
      console.log('error', error);
    }
  };

  const getPaymentMethods = async () => {
    try {
      const res: any = await Models.subscription.getPaymentMethods();
    } catch (error) {
      console.log('error', error);
    }
  };
  //Logic
  const selectPlan = (id) => {
    setState({ id });
  };

  const signIn = async () => {
    setState({ activity: true });
    try {
      let req: any = {
        username: state.name,
        email: state.email.trim(),
        org_name: state.org_name,
        password: state.password,
      };
      await Validation.trialSubscription.validate(req);
      const response: any = await Models.subscription.trialSubscription(req);
      getUserDetails(response.data);
      if (response.status == 'success') {
        const role =
          response.data.user_type.charAt(0).toUpperCase() +
          response.data.user_type.slice(1);
        await localStorage.setItem('token', response.token);
        if (response?.data?.user_type) {
          await localStorage.setItem('user_type', response.data.user_type);
        }
        localStorage.setItem(
          'subscribed',
          response.data.org_details.subscribed.toString(),
        );
        await localStorage.setItem('role', response.role);
        const callToken = await localStorage.getItem('callToken');
        const pushToken = await localStorage.getItem('pushToken');
        Functions.Success('Success', `${role} Login success`);
        setState({ email: '', password: '', secure: true, loading: false });
        window.location.href=('/Dashboard');
      }
      setState({ activity: false });
    } catch (error) {
      setState({ activity: false });
      console.log('error', error);
     Functions.Failure(error);
    }
    setState({ activity: false });
  };

  const checkIfOrgExists = async (name) => {
    try {
      if(name.length > 0) {
        const res: any = await Models.org.checkOrgExist({ name });
        setState({ disable: false });
      }
    } catch (error) {
      Functions.Failure(JSON.stringify(error));
      setState({ disable: true });
    }
  };

  const checkIfUserExists = async (email) => {
    try {
      const res: any = await Models.admin.checkIfUserExist({ email });
      setState({ user_error: false });
    } catch (error) {
      Functions.Failure(JSON.stringify(error));
      setState({ user_error: true });
    }
  };

  const validate = () => {
    if (
      state.name == '' ||
      state.org_name == '' ||
      state.email == '' ||
      state.password == '' ||
      state.id == '' ||
      state.user_error ||
      state.org_error
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onPress = () => {
    let data = {
      id: state.id,
      name: state.name,
      org_name: state.org_name,
      email: state.email,
      password: state.password,
      price: state.price,
      amount: state.amount,
      plan_data: state.plan_data,
    };
    props.onButtonClick(data);
  };

  return (
    <div className="signup_cotainer">
      <div className="signup_wrapper">
        <div className="signup_form">
          <div className="signup_header_logo">
            <img src={Assets.Logo} />
          </div>
          <div className="signup_header">Create an Account</div>
          <Formik initialValues={{}} onSubmit={() => {}}>
            <Form className="login_form">
              <div className="form_control">
                <Input
                  label="Org Name"
                  onChange={(value) => {
                    debounced(value);
                  }}
                  name="org_name"
                />
              </div>
              <div className="form_control">
                <Input
                  label="Name"
                  onChange={(value) => setState({ name: value })}
                  name="name"
                />
              </div>
              <div className="form_control">
                <Input
                  label="Email"
                  onChange={(value) => {
                    debouncedSearch(value);
                  }}
                  name="email"
                />
              </div>
              <div className="form_control">
                <Input
                  label="Password"
                  onChange={(value) => setState({ password: value })}
                  name="password"
                  type={state.secure ? 'password' : 'text'}
                  onIconClick={() => setState({ secure: !state.secure })}
                  icon={state.secure ? Assets.EyeHide : Assets.Eye}
                  iconPosition="end"
                />
              </div>
              {/* <div className="plans_container">
                <div className="plans_heading">Choose Plans</div>
                <div className="plans_wrapper">
                  {!state.loading &&
                    state.product_list.map((data) => (
                      <PlanCard
                        plan={data.name}
                        amount={data.price / 100}
                        duration={`Per ${data.recurring.interval}`}
                        selected={state.id === data.id ? true : false}
                        onClick={() => {
                          setState({
                            price: data.default_price,
                            amount: data.price,
                            plan_data: data,
                          });
                          selectPlan(data.id);
                        }}
                      />
                    ))}
                </div>
              </div> */}
              <div className="checkbox_container">
                <input
                  type="checkbox"
                  className="checkbox_wrapper"
                  onChange={(data) => {
                    setState({selectedForOrg:data.target.checked})
                  }}
                />
                <div className="checkbox_text">
                  I'm running a dog training company
                </div>
              </div>
              <div className="button_control">
                <Component.Button
                  text="Continue"
                  type="submit"
                  activity={state.activity}
                  onClick={signIn}
                  className="signup_button"
                  button_classname="signup"
                  disabled={state.disable || !state.selectedForOrg}
                />
              </div>
            </Form>
          </Formik>
          <div
            className="login_text"
            onClick={() => window.location.href = "/Login"}>
            Back to login
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpComponent;
