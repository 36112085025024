import React, { Suspense } from 'react';
import './App.scss';
import Test from 'screens/test/test.screen';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Main from 'common_components/hoc/main.hoc';
import store from 'store/store';
import { Provider } from 'react-redux';
import ChatScreen from 'screens/chat/chat.screen';
import Login from 'screens/login/login.screen';
import Conversation from 'screens/chat_screen/chat.screen';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from 'screens/home/home.screen';
import TrainerHome from 'screens/trainer_home/trainer_home.screen';
import CustomerHome from 'screens/customer_home/customer_home.screen';
import DogProfile from 'screens/dog_profile/dog_profile.screen';
import Media from 'screens/media/media.screen';
import Manage from 'screens/manage/manage.screen';
import DogList from 'screens/dog_list/dog_list.screen';
import UserManagement from 'screens/user_management/user_management.screen';
import Trainings from 'screens/training/training.screen';
import AddUser from 'screens/add_user/add_user.screen';
import AddDog from 'screens/add_dog/add_dog.screen';
import AddTraining from 'screens/add_training/add_training.screen';
import Notification from 'screens/notification/notification.screen';
import Setting from 'screens/settings/settings.screen';
import EditProfile from 'screens/edit_profile/edit_profile.screen';
import CallAvailability from 'screens/call_availability/call_availability.screen';
import ChangePassword from 'screens/change_password/change_password.screen';
import TrainingDetails from 'screens/acuity/acuity.screen';
import ResetPassword from 'screens/reset_password/reset_password.screen';
import ResetSuccess from 'screens/reset_success/reset_success.screen';
import OTP from 'screens/otp/otp.screen';
import ForgetPassword from 'screens/forget_password/forget_password.screen';
import Message from 'screens/message/message.screen';
import Organization from 'screens/org_list/org_list.screen';
import AddOrganization from 'screens/add_org/add_org';
import Branch from 'screens/branch_list/branch_list';
import AddBranch from 'screens/add_branch/add_branch.screen';
import AddAdmin from 'screens/add_admin/add_admin';
import AdminList from 'screens/admin_list/admin_list';
import AlertList from 'screens/alert_list/alert_list.screen';
import Dashboard from 'screens/dashboard/dashboard.screen';
import Payment from 'screens/payment/payment.screen';
import SignIn from 'screens/sign_in/signin.screen';
import PaymentSuccess from 'screens/payment_success/payment_success.screen';
import Subscription from 'screens/subscription/subscription.screen';
import StripePayment from 'screens/payment/stripe_payment.screen';
import Services from 'screens/services/services.screen';
import AddServices from 'screens/add_services/add_services';
import checkoutPayment from 'common_components/ui/stripe_payment_container/stripePaymentContainer';

const token = localStorage.getItem('token');
const user_type = localStorage.getItem('user_type')
let subscribed = localStorage.getItem('subscribed');
const NavRoute = ({ component: Component, ...rest }) => {
  subscribed = localStorage.getItem('subscribed')
  const token = localStorage.getItem('token');
  return (
    <Route
      {...rest}
      render={(props) => {
        if (token)
          return (
            <Main {...props}>
              <Component {...props} />
            </Main>
          );
        return <Redirect to="login" />;
      }}
    />
  );
};
// const SecondaryRoute = ({ component: Component, ...rest }) => {
//   const token = localStorage.getItem('token');
//   return (
//     <Route
//       {...rest}
//       render={(props) => {
//         if (token) return <Component {...props} />;
//         return <Login {...props} />;
//       }}
//     />
//   );
// };
function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
            {subscribed == 'false' || subscribed == null ? (
              <Suspense fallback={<div />}>
              <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/login" component={Login} />
                {user_type =='org_admin'&&<NavRoute exact path="/Payment" component={StripePayment} />}
                <Route exact path="/SignIn" component={SignIn} />
                {user_type =='org_admin'&&<Route exact path="/checkoutPayment" component={checkoutPayment} />}
                <Route exact path="*" component={Subscription} />
                </Switch>
              </Suspense>
            ) : (
              <Suspense fallback={<div />}>
              <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/ResetPassword" component={ResetPassword} />
                <Route exact path="/ResetSuccess" component={ResetSuccess} />
                <Route exact path="/ForgetPassword" component={ForgetPassword} />
                <Route exact path="/OTP" component={OTP} />
                <Route exact path="/SignIn" component={SignIn} />
                <Route exact path="/PaymentSuccess" component={PaymentSuccess} />
                <Route exact path="/Subscription" component={Subscription} />
                <NavRoute exact path="/test" component={Test} />
                <NavRoute exact path="/home" component={Home} />
                <NavRoute exact path="/trainer_home" component={TrainerHome} />
                <NavRoute exact path="/customer_home" component={CustomerHome} />
                <NavRoute exact path="/DogProfile" component={DogProfile} />
                <NavRoute exact path="/Media" component={Media} />
                <NavRoute exact path="/Manage" component={Manage} />
                <NavRoute exact path="/DogList" component={DogList} />
                <NavRoute exact path="/Clients" component={UserManagement} />
                <NavRoute exact path="/Trainers" component={UserManagement} />
                <NavRoute exact path="/Trainings" component={Trainings} />
                <NavRoute exact path="/AddUser" component={AddUser} />
                <NavRoute exact path="/AddDog" component={AddDog} />
                <NavRoute exact path="/AddTraining" component={AddTraining} />
                <NavRoute exact path="/Notification" component={Notification} />
                <NavRoute exact path="/Settings" component={Setting} />
                <NavRoute exact path="/EditProfile" component={EditProfile} />
                <NavRoute exact path="/CallAvailability" component={CallAvailability} />
                <NavRoute exact path="/ChangePassword" component={ChangePassword} />
                <NavRoute exact path="/TrainingDetails" component={TrainingDetails} />
                {/* <NavRoute exact path="/schedule" component={Home} /> */}
                <NavRoute exact path="/chat" component={ChatScreen} />
                <NavRoute exact path="/Message" component={Message} />
                <Route exact path="/conversation" component={Conversation} />
                <NavRoute exact path="/AlertList" component={AlertList} />
                <NavRoute exact path="/Organization" component={Organization} />
                <NavRoute exact path="/AddOrganization" component={AddOrganization} />
                <NavRoute exact path="/Branch" component={Branch} />
                <NavRoute exact path="/AddBranch" component={AddBranch} />
                <NavRoute exact path="/AddAdmin" component={AddAdmin} />
                <NavRoute exact path="/AdminList" component={AdminList} />
                <NavRoute exact path="/Dashboard" component={Dashboard} />
                <NavRoute exact path="/Payment" component={StripePayment} />
                <NavRoute exact path="/Services" component={Services} />
                <NavRoute exact path="/AddServices" component={AddServices} />
          </Switch>
        </Suspense>
            )}
        <ToastContainer />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
