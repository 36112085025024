import React, { useEffect } from 'react';
import {
  Assets,
  Constants,
  Image,
  Models,
  NavButton,
  Ratio,
  Text,
  Colors,
  Input,
  Functions,
  PrimaryButton,
  Validation,
  Container,
  View,
  StyleSheet,
  TouchableOpacity,
  FileInput,
} from 'utils/imports.utils';
import { useSelector } from 'react-redux';
import { uploadFile } from 'utils/s3.utils';

const AddUser = (props: any) => {

  const user = useSelector((state: any) => state.user.user);
  const branch = useSelector((state: any) => state.branch.branchList);
  const organizations = useSelector((state: any) => state.org.orgList);
  const [state, setState] = Functions.useSetState({
    username: '',
    email: '',
    phone: '',
    profile: '',
    activity: false,
    isOpen: true,
    loading: true,
    edit: false,
    profileUpload: false,
    loader: false,
  });
  
  useEffect(() => {
    // props.updateRouteParams(props.location.state);
    if (props?.route?.params?.view) {
      getUserDetails(props?.route?.params?.userId);
    } else {
      setState({ loading: false });
    }
  }, [props.route.params]);

  const backAction = () => {
    props.navigation.goBack();
    return true;
  };

  const getUserDetails = async (id: string) => {
    try {
      const user: any = await Models.user.viewUser(id);
      setState({
        email: user.data.email,
        phone: user.data?.phone,
        username: user.data.username,
        loading: false,
        profile: user.data?.profile ? user.data?.profile : '',
      });
    } catch (err) {
      // if (err) {
      //   Functions.Failure(err);
      // } else {
      //   Functions.Failure('Failed to get user details');
      // }
      Functions.Failure(err)
      setState({ loading: false });
    }
  };

  const addUser = async () => {
    try {
      setState({ activity: true });
      let req: any = {
        username: state.username,
        email: state.email,
        phone: state.phone,
        profile: state.profile,
        branch: user.branch,
        org: user.org,
      };
      await Validation.newUserSchema.validate(req);
      req.user_type = props.route.params.type;
      const response: any = await Models.admin.createUser(req);
      props.navigation.navigate('AddDog', {
        clientData: { name: response?.data?.username, id: response?.data?._id },
        add: true
      });
      Functions.Success('Success', 'User added');
      setState({ username: '', email: '', phone: '', activity: false });
      // onClose();
    } catch (err) {
      Functions.Failure(err)
      console.log('err', err);
      setState({ activity: false });
    }
  };

 
  const saveUser = async () => {
    try {
      setState({ loader: true });
      let req: any = {
        username: state.username,
        email: state.email,
        phone: state.phone,
        profile: state.profile,
        branch: user.branch,
        org: user.org,
      };
      await Validation.newUserSchema.validate(req);
      req.user_type = props.route.params.type;
      const response: any = await Models.admin.createUser(req);
      if(props.route.params.type == "customer"){
        user.created_customer == true
      }else if(props.route.params.type == "trainer"){
        user.created_trainer == true
      }
      Functions.Success('Success', 'User added');
      setState({ username: '', email: '', phone: '', loader: false });
      onClose();
    } catch (err) {
      Functions.Failure(err)
      console.log('err', err);
      setState({ loader: false });
    }
  };
  const onClose = () => {
    if (state.edit) {
      setState({ edit: false });
    } else {
      props.navigation.navigate("Clients", {type: props.route.params.type , title: props.route.params.type == "customer" ? "Clients" : "Trainers"  });
    }
  };

  const editUser = async () => {
    try {
      setState({ loader: true });
      let req: any = {
        username: state.username,
        email: state.email,
        phone: state.phone,
        profile: state.profile,
        user_type: props.route.params.user_type === "admin" ? "admin" : props.route.params.user_type,
        branch: user.branch,
        org: user.org,
      };
      await Validation.newUserSchema.validate(req);
      req.id = props.route.params.userId;
      req.user_type = props.route.params.type;
      await Models.admin.editUser(req);
      Functions.Success('Success', 'User updated');
      setState({ loader: false, edit: false });
      onClose();
    } catch (err) {
      console.log('err', err);
      Functions.Failure(err)
      setState({ loader: false });
    }
  };

  const uploadProfile = async (event) => {
    try {
      const image: any =event.target.files[0];
      setState({ profileUpload: true });
      const file: any = await uploadFile(image);
      setState({ profile: file.url, profileUpload: false });
    } catch (err) {
      console.log('err', err);
      // if (err.message) {
      //   console.log(err.err.message);
      // } else if (err) {
      //   Functions.Failure(err);
      // } else {
      //   Functions.Failure('Failed to update user profile');
      // }
    }
  };

  return (
    <Container styles={styles.addUserScreen} screen loading={state.loading}>
      <View style={styles.addUserContainer}>
        <View style={styles.headerWrapper}>
          <View style={styles.textWrapper}>
            <View style={styles.imageContainer}>
              <TouchableOpacity
                style={{
                  width: Ratio(40),
                  height: Ratio(40),
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                activeOpacity={0.7}
                onPress={onClose}>
                <Image svg src={Assets.ArrowBack} width={20} height={20} />
              </TouchableOpacity>
            </View>
            <View style={styles.headTextWrapper}>
              <Text
                top={0}
                size={Ratio(24)}
                color={Colors.primaryDarkText}
                family={Constants.light}
                weight="300">
                {props?.route?.params?.add ? props?.route?.params?.buttonText : 'Edit'}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.addUserFormContainer}>
          <View style={styles.addPetIconContainer}>
            {!state.profile && !state.profileUpload ? (
              <View style={styles.profileContainer}>
                <NavButton
                  icon={Assets.SingleUserPrimary}
                  disabled
                  width="100px"
                  height="100px"
                  style={styles.navIcon}
                  iconHeight={Ratio(50)}
                  iconWidth={Ratio(50)}
                  svg
                  disableShadow
                />
              </View>
            ) : state.profile && !state.profileUpload ? (
              <View style={styles.profileImage}>
                <Image
                  src={state.profile}
                  height="100%"
                  width="100%"
                  url
                  resize="cover"
                  radius={30}
                />
              </View>
            ) : (
              <View style={styles.profileLoading}>
                <View
                  size={30}
                  borderWidth={3}
                  indeterminate
                  color={Colors.buttonTextColor}
                />
              </View>
            )}
            <TouchableOpacity
              style={styles.uploadIcon}
              activeOpacity={0.7}
              >
                <FileInput style={styles.fileInput} src={Assets.EditWhite} iconStyle={styles.addImageIcon} id="add_user" onChange={uploadProfile}/>
              {/* <Image svg src={Assets.EditWhite} width={Ratio(20)} height={Ratio(20)} /> */}
            </TouchableOpacity>
          </View>
          <View style={styles.formBody}>
            <View style={styles.bodyContainer}>
              <Input
                label="Full Name"
                name="username"
                onChange={(username: string) => setState({ username })}
                placeholder="Full name"
                value={state.username}
                />
              <Input
                label="Email"
                name="email"
                onChange={(email: string) => setState({ email })}
                placeholder="Email"
                value={state.email}
                />
              <Input
                label="Phone"
                name='phone'
                onChange={(phone: string) => setState({ phone })}
                placeholder="Phone"
                value={state.phone}
                type="number"
                />
            </View>
          </View>
          {props.route.params.buttonText == 'Add Client' ? (
            <View style={styles.addPetButtonContainer}>
            <PrimaryButton
              text="Save"
              activity={state.loader}
              width={"200px"}
              onPress={() => {
                saveUser();
              }}
              backgroundColor={Colors.primaryDarkColor}
            />
            <PrimaryButton
              text="Save & Add Dog"
              activity={state.activity}
              width={"200px"}
              onPress={() => addUser()}
              backgroundColor={Colors.primaryDarkColor}
            /> 
          </View>
            ):(
              <PrimaryButton
                text="Done"
                activity={state.loader}
                width={"200px"}
                onPress={props.route.params.buttonText == 'Add Trainer' ? saveUser : editUser}
                backgroundColor={Colors.primaryDarkColor}

              />
            )}
        </View>
      </View>
    </Container>
  );
};

const styles = StyleSheet.create({
  addUserScreen: {
    // paddingTop: Ratio(20),
    height: '100%',
  },
  addUserContainer: {
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
  },
  headerWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: 50
  },
  imageContainer: {},
  headTextWrapper: {
    paddingLeft: Ratio(10),
  },
  textWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  addUserFormContainer: {
    position: 'relative',
    width: '100%',
    height: '90%',
    marginTop: Ratio(60),
    alignItems: 'center',
    
  },
  addPetIconContainer: {
    position: 'absolute',
    width: '100%',
    zIndex: 1,
    alignItems: 'center',
    top: -80,
  },
  navIcon: {
    width: Ratio(100),
    height: Ratio(100),
    backgroundColor: Colors.secondaryLightColor,
    borderRadius: 30,
  },
  formBody: {
    width: '50%',
    // height: Ratio('50%', true),
    backgroundColor: Colors.buttonTextColor,
    borderRadius: 32,
    shadowColor: '#DDE5EE',
    shadowOffset: { width: 4, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    padding: 20,
  },
  bodyContainer: {
    paddingTop: Ratio(50),
    padding: '0px 15px',
    width: '100%',
  },
  inputWrapper: {
    width: '100%',
    zIndex: 100,
    paddingBottom: Ratio(10),
  },
  addPetButtonContainer: {
    flexDirection:"row",
    justifyContent: 'center',
    alignItems: 'center',
    // width: '200px',
    bottom: Ratio(-10, true),
    gap:"20px",
    paddingTop:"20px"
  },
  btnContent: {
    width: '80%',
  },
  profileContainer: {},
  profileImage: {
    width: Ratio(100),
    height: Ratio(100),
  },
  uploadIcon: {
    backgroundColor: Colors.primaryDarkColor,
    width: Ratio(30),
    height: Ratio(30),
    borderRadius: Ratio(20),
    justifyContent: 'center',
    alignItems: 'center',
    bottom: -15,
    position: 'absolute',
  },
  addImageIcon: {
    width: 20,
    height: 20,
  },
  profileLoading: {
    width: Ratio(100),
    height: Ratio(100),
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.primaryDarkColor,
    opacity: 0.4,
  },
});

export default AddUser;
