import React, { useEffect, useRef } from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Alert,
  Assets,
  Constants,
  Image,
  NavButton,
  Ratio,
  Text,
  Colors,
  Functions,
  Container,
  Input,
  Models,
  FlatList
} from 'utils/imports.utils';
import { useSelector } from 'react-redux';
// import { FlatList } from 'react-native-gesture-handler';

const AlertList = (props: any) => {
  const user = useSelector((state: any) => state.user.user);
  const [state, setState] = Functions.useSetState({
    listElements: [],
    name: '',
    alertId: ""
  });

  useEffect(() => {
   viewAlert();
  }, []);
  const backAction = () => {
    props.navigation.goBack();
    return true;
  };

  const onClose = () => {
    if (state.edit) {
      setState({ edit: false });
    } else {
      props.navigation.goBack();
    }
  };

  const handelDelete = (index: number) => {
    let unchangedList = state.listElements;
    unchangedList.splice(index, 1)
    setState({ listElements: unchangedList })
    editAlert()
  }

  const handelAdd = (value: any) => {
    if (state.name.length > 0) {
      let listData = state.listElements;
      listData.push(state.name);
      setState({ listElements: listData, name: '' });
    }
    editAlert();
  };
    const viewAlert = async () => {
      try {
        const response: any = await Models.alert.viewAlert();
        setState({ listElements: response.data.words, alertId: response.data._id });
      } catch (err) {
        console.log('err', err);
      }
    };

  const editAlert = async () => {
    try {
      let req = {
        alert_id: state.alertId,
        words: state.listElements,
      };
      await Models.alert.editAlert(req);
    } catch (err) {
      console.log('err', err);
    }
  };

  const getConfirmation = (index: number) => {
    Alert.alert(
      "Are you sure to delete?",
      "",
      [
        {
          text: "Yes",
          onPress: () => { handelDelete(index) }

        },
        { text: "No", onPress: () => console.log("OK Pressed") }
      ]
    );
  }


  return (
    <Container styles={styles.addUserScreen} screen loading={state.loading}>
      <View style={styles.addUserContainer}>
        <View style={styles.headerWrapper}>
          <View style={styles.textWrapper}>
            <View style={styles.imageContainer}>
              <TouchableOpacity
                style={{
                  width: Ratio(40),
                  height: Ratio(40),
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                activeOpacity={0.7}
                onPress={onClose}>
                <Image svg src={Assets.ArrowBack} width={20} height={20} />
              </TouchableOpacity>
            </View>
            <View style={styles.headTextWrapper}>
              <Text
                // top={Platform.OS === 'android' ? 5 : 0}
                size={Ratio(24)}
                color={Colors.primaryDarkText}
                family={Constants.light}
                weight="300">
                {'Alert List'}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.bodyContainer}>
          <Input
            onChange={(name: string) => setState({ name })}
            placeholder="Add"
            iconPosition='end'
            source={Assets.CricleBlack}
            iconHeight={20}
            iconWidth={20}
            onIconClick={() => { handelAdd(state.name) }}
            value={state.name}
            name="add"
          />

          <FlatList
            data={state.listElements}
            showsVerticalScrollIndicator={false}
            renderItem={({ item, index }) => (
              <View style={styles.listContainer}>
                <Text
                  size={Ratio(18)}
                  color={Colors.primaryDarkText}
                  family={Constants.light}
                  weight="300">
                  {item}
                </Text>
                <View>
                  <NavButton
                    icon={Assets.Close}
                    svg
                    iconHeight={Ratio(25)}
                    iconWidth={Ratio(25)}
                    onClick={() => { getConfirmation(index) }}
                    // onClick ={()=>{handelDelete(index)}}
                    style={{
                      width: Ratio(50),
                      height: Ratio(50),
                      backgroundColor: 'transparent',
                      borderRadius: 16,
                      transform: [{ rotate: '180deg' }],
                    }}
                  />
                </View>
              </View>
            )}
          />
        </View>
      </View>
    </Container>
  );
};

const styles = StyleSheet.create({
  addUserScreen: {
    paddingTop: Ratio(20),
    height: '100%',
  },
  addUserContainer: {
    padding: Ratio(20),
    width: '100%',
    height: '100%',
    justifyContent: 'flex-start',
  },
  headerWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '10%',
    justifyContent: 'space-between',
  },
  imageContainer: {},
  headTextWrapper: {
    paddingLeft: Ratio(10),
  },
  textWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: Colors.buttonTextColor,
    borderRadius: 24,
    paddingLeft: Ratio(16),
    paddingVertical: Ratio(2),
    marginVertical: Ratio(10),
  },
  bodyContainer: {
    height: '90%',
  },
});

export default AlertList;
