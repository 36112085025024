import React from 'react'
import { Ratio, Assets, Container, Image, Text, PrimaryButton, Colors, Validation, Functions, OtpInput, Constants, Models, View, StyleSheet, TouchableOpacity, Input } from 'utils/imports.utils';

const OTP = (props: any) => {
  const [state, setState] = Functions.useSetState({ otp: "" });

  const resendOtp = async () => {
    try {
      await Validation.forgetPassSchema.validate({ email: props.route.params.email });
      const forgetPassword: any = await Models.auth.sendOtp({ email: props.location.state.email });
      Functions.Success("Success", "OTP has been Sent to the registered email");
    } catch (err) {
      // if (err.errors && err.errors.length > 0) {
      //   Functions.Failure("Failed", err.errors[0]);
      // } else if (err) {
      //   Functions.Failure("Failed", err);
      // } else {
      //   Functions.Failure("Failed to forget password");
      // }
    }
  }

  const verifyOtp = async () => {
    try {
      await Validation.otpSchema.validate({ otp: state.otp.toString() });
      const verify: any = await Models.auth.verifyOtp({ email: props.location.state.email, otp: state.otp });
      Functions.Success("Success", verify.message);
      setState({ otp: "" })
      props.history.push("ResetPassword", { hash: verify.data.hash , email:props.location.state.email});
    } catch (err) {
      if (err) {
         console.log('err' , err);
        Functions.Failure("Failed");
      } else {
        Functions.Failure("Failed to reset password");
      }
    }
  }

  return (
    
      <View style={styles.otpVerifyScreen}>
        <View style={styles.otpWrapperWrapper}>
          <View style={styles.iconWrapper}>
            <Image src={Assets.MessageDark} svg width={36} height={36} />
          </View>
          <View style={styles.headerWrapper}>
            <Text size={Ratio(24)} color={Colors.primaryDarkColor} styles={styles.headText}>Verify Your Code</Text>
            <Text size={Ratio(14)} textAlign={"center"} color={Colors.primaryLightText} styles={styles.descriptionText}>Please enter a verification code send to your registered email id</Text>
          </View>
          <View style={styles.inputWrapper}>
            <View style={styles.inputContent}>
            <Input
               styles={{width:"100%", textAlign:"center"}} 
               onChange={(otp: number) => {
                if(otp.toString().length <= 6){
                  setState({ otp })
                }
                }}
               name="otp"
               type='number'
               value={state.otp}
              ></Input>
            </View>
            <View style={styles.buttonWrapper}>
              <PrimaryButton 
              width={200} 
              text="Verify" 
              backgroundColor={Colors.primaryDarkColor}
              onPress={() => verifyOtp()} 
              />
            </View>
            <View style={styles.textWrapper}>
              <TouchableOpacity style={styles.loginBtn} activeOpacity={0.7} onPress={() => resendOtp()}>
                <Text size={Ratio(12)} weight="600" color={Colors.primaryDarkColor} family={Constants.medium}>Resend Code</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
      
  )
}

const styles = StyleSheet.create({
  otpVerifyScreen: {
    width: "100vw",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor:Colors.buttonTextColor,
  },
  otpWrapperWrapper: {
    width: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
  iconWrapper: {
    width: Ratio(90),
    height: Ratio(90),
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.secondaryLightColor,
    borderRadius: 28,
    // paddingBottom: Ratio(20)
    marginBottom: Ratio(24)
  },
  headerWrapper: {
    width: "70%",
    alignItems: "center",
    paddingBottom: Ratio(32)
  },
  headText: {
    paddingBottom: Ratio(8)
  },
  inputWrapper: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    // height: Ratio(60)
  },
  inputContent: {
    width: "50%",
    // height: "100%",
    paddingBottom: Ratio(10),
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  },
  input: {
    width: "80%"
  },
  buttonWrapper: {
    width: "80%",
    paddingBottom: Ratio(20)
  },
  textWrapper: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  loginBtn: {
    cursor:"pointer"
  }
})

export default OTP;