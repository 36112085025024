import React, { useEffect } from 'react';
import {
  Assets,
  Constants,
  Image,
  Models,
  NavButton,
  Ratio,
  Text,
  Colors,
  Input,
  Functions,
  PrimaryButton,
  Validation,
  Container,
  View,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
  FileInput,
} from 'utils/imports.utils';
import { uploadFile } from 'utils/s3.utils';
import { useSelector } from 'react-redux';
import { getUserDetails } from 'utils/redux.utils';


const EditProfile = (props: any) => {
  const user = useSelector((state: any) => state.user.user);
  const [state, setState] = Functions.useSetState({
    username: '',
    profile: '',
    activity: false,
    isOpen: true,
    loading: true,
    edit: false,
    profileUpload: false,
  });

  useEffect(() => {
      // getUserDetails(user._id);
      setState({
        profile: user.profile,
        username: user.username,
        loading: false,
        email: user.decrypted_email,
      });
  }, []);

  const backAction = () => {
    props.navigation.goBack();
    return true;
  };

  const editUser = async () => {
    try {
      setState({ activity: true });
      let req: any = {
        username: state.username,
        profile: state.profile,
      };
      await Validation.editUserSchema.validate(req);
      req.id = user._id;
      let response :any = await Models.user.editUser(req);
      getUserDetails(response.data);
      addNameToStorage(response.data.decrypted_email)
      Functions.Success('Success', 'Your details updated');
      setState({ activity: false, edit: false });
      props.navigation.goBack();
    } catch (err) {
      // if (err.errors && err.errors.length > 0) {
      //   Functions.Failure('Failed', err.errors[0]);
      // } else if (err) {
      //   Functions.Failure(err);
      // } else {
      //   Functions.Failure('Failed to update user details');
      // }
      setState({ activity: false });
    }
  };

  const onClose = () => {
    if (state.edit) {
      setState({ edit: false });
    } else {
      props.navigation.goBack();
    }
  };

  const uploadProfile = async (event) => {
    try {
      const image: any = await event.target.files[0];
      setState({ profileUpload: true });
      const file: any = await uploadFile(image);
      setState({ profile: file.url, profileUpload: false });
    } catch (err) {
      console.log('err', err);
      if (err) {
      } else {
        Functions.Failure('Failed to update user profile');
      }
    }
  };

  const addNameToStorage = async (email: string) =>{
    let newUser:any = [ ]
    let loggedInData: any = await localStorage.getItem('loggedInData');
    JSON.parse(loggedInData).forEach( async (data:any) => {
      if(data.email == email){
        data.name = state.username
      }
      return newUser.push(data)
    })
   await localStorage.setItem('loggedInData' , JSON.stringify(newUser))
  }

  return (
    <Container styles={styles.addUserScreen} screen loading={state.loading}>
      <View style={styles.addUserContainer}>
        <View style={styles.headerWrapper}>
          <View style={styles.textWrapper}>
            <View style={styles.imageContainer}>
              <TouchableOpacity
                style={{
                  width: Ratio(40),
                  height: Ratio(40),
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                activeOpacity={0.7}
                onPress={onClose}>
                <Image svg src={Assets.ArrowBack} width={20} height={20} />
              </TouchableOpacity>
            </View>
            <View style={styles.headTextWrapper}>
              <Text
                top={0}
                size={Ratio(24)}
                color={Colors.primaryDarkText}
                family={Constants.light}
                weight="300">
                {'Profile'}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.addUserFormContainer}>
          <View style={styles.addPetIconContainer}>
            {!state.profile && !state.profileUpload ? (
              <View style={styles.profileContainer}>
                <NavButton
                  icon={Assets.SingleUserPrimary}
                  disabled
                  style={styles.navIcon}
                  iconHeight={Ratio(70)}
                  iconWidth={Ratio(70)}
                  svg
                />
              </View>
            ) : state.profile && !state.profileUpload ? (
              <View style={styles.profileImage}>
                {state.profile ? (
                  <Image
                    src={state.profile}
                    height="100%"
                    width="100%"
                    url
                    resize="cover"
                    radius={30}
                  />
                ) : (
                  <View style={styles.imageLoader}>
                    <ActivityIndicator
                      size="small"
                      color={Colors.primaryDarkColor}></ActivityIndicator>
                  </View>
                )}
              </View>
            ) : (
              <View style={styles.profileLoading}>
                {/* <Progress.Circle
                  size={30}
                  borderWidth={3}
                  indeterminate
                  color={Colors.buttonTextColor}
                /> */}
              </View>
            )}
            <TouchableOpacity style={styles.uploadIcon} activeOpacity={0.7}>
                <FileInput style={styles.fileInput} src={Assets.EditWhite} iconStyle={styles.addImageIcon} id="add_user" onChange={uploadProfile}/>
            </TouchableOpacity>
          </View>
            <View style={styles.formBody}>
              <View style={styles.bodyContainer}>
                <Input
                  name="username"
                  label="Display Name"
                  onChange={(username: string) => setState({ username })}
                  placeholder="Full name"
                  value={state.username}
                  />
              </View>
              <View style={[styles.bodyContainer, { paddingTop: 0 }]}>
                <Input
                  name="email"
                  label="Email"
                  onChange={(email: string) => setState({ email })}
                  placeholder="Email"
                  value={state.email}
                  />
              </View>
            </View>
          <View style={styles.addPetButtonContainer}>
            <PrimaryButton
              text={'Save'}
              onPress={() => editUser()}
              backgroundColor={Colors.primaryDarkColor}
              width={200}
              activeOpacity={0.7}
              activity={state.activity}
            />
          </View>
        </View>
      </View>
    </Container>
  );
};

const styles = StyleSheet.create({
  addUserScreen: {
    paddingTop: Ratio(20),
    height: '100%',
  },
  addUserContainer: {
    padding: Ratio(20),
    width: '100%',
    justifyContent: 'space-between',
  },
  headerWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '10%',
    justifyContent: 'space-between',
  },
  imageContainer: {},
  headTextWrapper: {
    paddingLeft: Ratio(10),
  },
  textWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  addUserFormContainer: {
    position: 'relative',
    width: '100%',
    alignItems: 'center',
    marginTop: Ratio(60),
  },
  addPetIconContainer: {
    position: 'absolute',
    width: '50%',
    zIndex: 1,
    alignItems: 'center',
    top: -55,
  },
  navIcon: {
    width: Ratio(100),
    height: Ratio(100),
    backgroundColor: Colors.secondaryLightColor,
    borderRadius: 30,
  },
  formBody: {
    marginTop: 30,
    width: '50%',
    // height: Ratio('35%', true),
    backgroundColor: Colors.buttonTextColor,
    borderRadius: 32,
    shadowColor: '#DDE5EE',
    shadowOffset: { width: 4, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    paddingBottom:"30px"
  },
  bodyContainer: {
    padding: '20px 15px 0px',
    width: '100%',
  },
  inputWrapper: {
    width: '100%',
    zIndex: 100,
    paddingBottom: Ratio(10),
  },
  addPetButtonContainer: {
    // position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    paddingTop: 20,
    // bottom: Ratio(20, true),
  },
  btnContent: {
    width: '80%',
  },
  profileContainer: {},
  profileImage: {
    width: Ratio(100),
    height: Ratio(100),
  },
  uploadIcon: {
    backgroundColor: Colors.primaryDarkColor,
    width: Ratio(30),
    height: Ratio(30),
    borderRadius: Ratio(20),
    justifyContent: 'center',
    alignItems: 'center',
    bottom: Ratio(-13),
    position: 'absolute',
  },
  profileLoading: {
    width: Ratio(100),
    height: Ratio(100),
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.primaryDarkColor,
    opacity: 0.4,
  },
  imageLoader: {
    width: 100,
    height: 100,
    borderRadius: 30,
    backgroundColor: Colors.secondaryLightText,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default EditProfile;
