import {
  testDispatch,
  signupDispatch,
  loginDispatch
} from '../utils/redux.utils';
import instance from '../utils/axios.utils';
import axios from 'axios';
import { Functions } from 'utils/imports.utils';

const media = {
  uploadMedia: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'media/upload_media';
      instance()
        .post(url, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },

  uploadAxios: async (data: any) => {
    try {
      const token = await localStorage.getItem("token");
      let url = 'media/upload_media';
      const config = {
        headers: {
          'Accept': "application/json",
          "Content-Type": "multipart/form-data; charset=utf-8; boundary=6ff46e0b6b5148d984f148b6542e5a5d",
          "authorization": token,
        }
      };
      instance()
        .post(url, data, config)
        .then(res => {
          console.log(res);
          
          return res;
        })
        .catch(error => {
          console.log("err", error);
          if (error.response) {
            // reject(error.response.data.message);
          } else {
            // reject(error);
          }
        });
    } catch(err) {
      console.log("err", err);
      return false;
    }
  },

  addMedia: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'media/add_media';
      instance()
        .post(url, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },
  mediaList: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'media/get_media';
      instance()
        .post(url, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },

  editMedia: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'media/edit_media';
      instance()
        .put(url, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },

  getMediaById: (id: string) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'media/get_media/'+id;
      instance()
        .get(url)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },
  deleteMedia: (id: string) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'media/delete_media/'+id;
      instance()
        .delete(url)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },

  signedUrl: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'media/get_signed_url';
      instance()
        .post(url, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },
};

export default media;
