import Models from 'imports/models.import';
import React, { useEffect } from 'react';

export default function Test() {
  useEffect(() => {
    // getSubStatus();
    // cancelSubscription()
    // getProducts();
    // getBillingHistory()
  }, []);

  // const getSubStatus = async () => {
  //   try {
  //     const res: any = await Models.subscription.getSubscriptionStatus();
  //     console.log('res', res);
  //   } catch (error) {
  //     console.log('error', error);
  //   }
  // };

  // const cancelSubscription = async () => {
  //   try {
  //     const res: any = await Models.subscription.cancelSubscription();
  //   } catch (error) {
  //     console.log('error', error);
  //   }
  // };

  // const getProducts = async () => {
  //   try {
  //     const res: any = await Models.subscription.getProductList();
  //   } catch (error) {
  //     console.log('error', error);
  //   }
  // };

  // const getBillingHistory = async () => {
  //   try {
  //     const res: any = await Models.subscription.getInvoices();
  //     console.log('res', res);
  //   } catch (error) {
  //     console.log('error', error);
  //   }
  // };
  const handleSubmit = async () => {
    // event.preventDefault();
    // const res = await stripe.createPaymentMethod({
    //   type: 'card',
    //   card: elements.getElement(CardElement),
      // billing_details: {
      //   name: 'sairamm',
      //   email: 'sairam@brownbutton.io',
      //   address: {
      //     line1: 'VV koil street',
      //     line2: 'koyambedu',
      //     city: 'chennai',
      //     country: 'IN',
      //   },
      // },
    // });
    // console.log('res', res);
    // const response: any = await Models.subscription.addSub({
    //   payment_method: res.paymentMethod.id,
    // });
    // console.log('response', response);
    // if (!res.error) {
    //   console.log('Stripe 23 | token generated!', res.paymentMethod);
    //   //send token to backend here
    // } else {
    //   console.log('error', res.error);
    //   console.log(res.error.message);
    // }
  };
  return <div></div>;
}
