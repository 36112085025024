// @ts-nocheck
import React, { useEffect, useRef } from 'react';
import {
  Assets,
  Constants,
  Image,
  Models,
  NavButton,
  Ratio,
  Text,
  Colors,
  Input,
  Functions,
  PrimaryButton,
  Validation,
  Container,
  View,
  StyleSheet,
  TouchableOpacity,
} from 'utils/imports.utils';
import { uploadFile } from 'utils/s3.utils';
import { useSelector } from 'react-redux';

const PasswordChange = (props: any) => {
  const newPasswordRef: any = useRef();
  const confirmPasswordRef: any = useRef();
  const user = useSelector((state: any) => state.user.user);
  const [state, setState] = Functions.useSetState({
    username: '',
    profile: '',
    activity: false,
    isOpen: true,
    loading: true,
    edit: false,
    confirmSecure: true,
    newSecure: true,
    currentSecure: true,
    currentPassword: '',
    password: '',
    confirmPassword: '',
    keyboardVisible: false,
    currentVisible:false,
    newVisible:false,
    confirmVisible:false,
  });

  useEffect(() => {
    setState({
      profile: user.profile,
      username: user.username,
      loading: false,
    });
  }, []);

  const backAction = () => {
    props.navigation.goBack();
    return true;
  };

  const changePassword = async () => {
    try {
      setState({ activity: true });
      let passwords: any = {
        currentPassword: state.currentPassword,
        password: state.password,
        confirmPassword: state.confirmPassword,
      };
      await Validation.changePasswordSchema.validate(passwords);
      await Validation.changePasswordConfirmSchema.validate(passwords);
      let password_details = {
        old_password: state.currentPassword,
        password: state.password,
      };
      await Models.auth.changePassword(password_details);
      Functions.Success('Success', 'Your details updated');
      setState({ activity: false, edit: false });
      props.navigation.goBack();
      addPasswordToStorage(user.email)
    } catch (err) {
      if (err.errors && err.errors.length > 0) {
        Functions.Failure( err.message);
      } else if (err) {
        if (err === 'Incorrect Credentials') {
          Functions.Failure('Incorrect Current Password');
        } else {
          Functions.Failure(err);
        }
      } else {
        Functions.Failure('Failed to update user details');
      }
      setState({ activity: false });
    }
  };

  const onClose = () => {
    if (state.edit) {
      setState({ edit: false });
    } else {
      props.navigation.goBack();
    }
  };
  const addPasswordToStorage = async (email: string) =>{
    let newUser:any = [ ]
    let loggedInData: any = await localStorage.getItem('loggedInData');
    JSON.parse(loggedInData).forEach( async (data:any) => {
      if(data.email == email){
        data.password = state.confirmPassword
      }
      return newUser.push(data)
    })
   await localStorage.setItem('loggedInData' , JSON.stringify(newUser))
  }

  return (
    <Container styles={styles.addUserScreen} screen loading={state.loading}>
      {/* <KeyboardAvoidingView behavior="height"> */}
      <View style={styles.addUserContainer}>
        <View style={styles.headerWrapper}>
          <View style={styles.textWrapper}>
            <View style={styles.imageContainer}>
              <TouchableOpacity
                style={{
                  width: Ratio(40),
                  height: Ratio(40),
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                activeOpacity={0.7}
                onPress={onClose}>
                <Image svg src={Assets.ArrowBack} width={20} height={20} />
              </TouchableOpacity>
            </View>
            <View style={styles.headTextWrapper}>
              <Text
                top={0}
                size={Ratio(24)}
                color={Colors.primaryDarkText}
                family={Constants.light}
                weight="300">
                {'Password'}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.addUserFormContainer}>
          <View style={[styles.formBody]}>
            <View style={styles.bodyContainer}>
              <Input
                name="currentPassword"
                label="Current Password"
                onChange={(password: any) => setState({ currentPassword: password })}
                source={state.currentSecure ? Assets.EyeHide : Assets.Eye}
                iconHeight={Ratio(20)}
                iconWidth={Ratio(20)}
                iconPosition="end"
                onIconClick={() => setState({ currentVisible: !state.currentVisible , currentSecure: !state.currentSecure })}
                styles={styles.input}
                value={state.currentPassword}
                type={state.currentVisible ? 'text' : 'password'}
                />
              <Input
                name="password"
                label="New Password"
                onChange={(password: any) => setState({ password: password })}
                source={state.newSecure ? Assets.EyeHide : Assets.Eye}
                iconHeight={Ratio(20)}
                iconWidth={Ratio(20)}
                iconPosition="end"
                onIconClick={() => setState({ newVisible: !state.newVisible , newSecure: !state.newSecure })}
                styles={styles.input}
                value={state.password}
                type={state.newVisible ? 'text' : 'password'}
                />
              <Input
                name="confirmPassword"
                label="Confirm Password"
                onChange={(password: any) => setState({ confirmPassword: password })}
                source={state.confirmSecure ? Assets.EyeHide : Assets.Eye}
                iconHeight={Ratio(20)}
                iconWidth={Ratio(20)}
                iconPosition="end"
                onIconClick={() => setState({ confirmVisible: !state.confirmVisible , confirmSecure: !state.confirmSecure })}
                styles={styles.input}
                value={state.confirmPassword}
                type={state.confirmVisible ? 'text' : 'password'}
                />
            </View>
          </View>
          {!state.keyboardVisible && (
            <View style={styles.addPetButtonContainer}>
              <PrimaryButton
                text={'Save'}
                onPress={() => changePassword()}
                width={200}
                backgroundColor={Colors.primaryDarkColor}
                activeOpacity={0.7}
                activity={state.activity}
              />
            </View>
          )}
        </View>
      </View>
      {/* </KeyboardAvoidingView> */}
    </Container>
  );
};

const styles = StyleSheet.create({
  addUserScreen: {
    paddingTop: Ratio(20),
    height: '100%',
  },
  addUserContainer: {
    padding: Ratio(20),
    width: '100%',
    // height: '100%',
    justifyContent: 'space-between',
  },
  headerWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '10%',
    justifyContent: 'space-between',
  },
  imageContainer: {},
  headTextWrapper: {
    paddingLeft: Ratio(10),
  },
  textWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  addUserFormContainer: {
    position: 'relative',
    width: '100%',
    // height: '90%',
    marginTop: Ratio(30),
    // backgroundColor: "red"
  },
  formBody: {
    width: '100%',
    // height: '60%',
    backgroundColor: Colors.buttonTextColor,
    borderRadius: 32,
    shadowColor: '#DDE5EE',
    shadowOffset: { width: 4, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
  bodyContainer: {
    padding: '30px 20px',
    width: '100%',
  },
  inputWrapper: {
    width: '100%',
    zIndex: 100,
    paddingBottom: Ratio(10),
  },
  addPetButtonContainer: {
    // position: 'absolute',
    height: '40%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    // bottom: Platform.OS === 'android' ? Ratio(20, true) : Ratio(20, true),
  },
  btnContent: {
    width: '80%',
  },
  profileContainer: {},
  profileImage: {
    width: Ratio(100),
    height: Ratio(100),
  },
  input: {
    width: '100%',
  },
});

export default PasswordChange;
