import { create } from 'domain';
import { useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';

const toastOptions: any = {
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  style: {
    // backgroundColor: "#F16722"
  }
}


export const Ratio = (value: any, isHeight?: boolean) => {

  let height = window.innerHeight;
  let width = window.innerWidth;
  let ratio;

  if (isHeight) {
    if (typeof value === 'string') {
      value = value.replace('%', '');
      ratio = (parseInt(value) * height) / 100;
    } else {
      ratio = value;
    }

  } else {
    if (typeof value === 'string') {
      value = value.replace('%', '');
      ratio = (parseInt(value) * width) / 100;
    } else {
      ratio = value;
    }
  }
  return ratio;
};

export const StyleSheet = {
  create: function(style) {
    return style
  }
}

export const getBaseURL = () => {
  // let baseURL = 'http://localhost:8001'
  // let baseURL = 'https://api.petloopapp.com'
    let baseURL = 'https://training.augmo.io'
  if(process.env.REACT_APP_NODE_ENV === "development"){
    // baseURL = 'https://api.petloopapp.com'
    baseURL = 'http://localhost:8001'
    // baseURL = 'http://192.168.0.102:8001'
  }else if(process.env.REACT_APP_NODE_ENV === "stage"){
    baseURL = 'https://training.augmo.io'
  }
  return baseURL
}

export const useSetState = (initialState: any) => {
  const [state, setState] = useState(initialState)

  const newSetState = (newState: any) => {
    setState((prevState: any) => ({ ...prevState, ...newState }))
  }
  return [state, newSetState]
}

export const Success = (message: any, description?: string) => {
  toast.success(`${message}`, toastOptions);
};

export const Failure = (message: any, description?: string) => {
  toast.error(`${message}`, toastOptions);
};

export const Warning = (message: any, description?: string) => {
  toast.warn(`${message} - ${description}`, toastOptions);
};

export const Info = (message: any, description?: string) => {
  toast.info(`${message} - ${description}`, toastOptions);
};

export const isCallAvailable = (callObj: any) => {
  let currentTime = moment().format('HH:mm:ss a');
  if(moment(currentTime,'HH:mm:ss').isSameOrAfter(moment(callObj.from,'HH:mm:ss')) && 
  moment(currentTime,'HH:mm:ss').isSameOrBefore(moment(callObj.to,'HH:mm:ss'))) {
    return true;
  } else {
    return false;
  }
}

export const Alert ={
  alert: (title, body, actions) => {
    if(confirm(`${title} \n${body}`)){
      actions[0].onPress();
    }
  }
}

function getURLParams() {
  var prmstr = window.location.search.substr(1);
  return prmstr != null && prmstr != "" ? transformToAssocArray(prmstr) : {};
}

function transformToAssocArray( prmstr ) {
  var params = {};
  var prmarr = prmstr.split("&");
  for ( var i = 0; i < prmarr.length; i++) {
      var tmparr = prmarr[i].split("=");
      params[tmparr[0]] = tmparr[1];
  }
  return params;
}

export const getHours = () => {
  var x = 30; //minutes interval
  var times: any = []; // time array
  var tt = 0; // start time
  var ap = ['AM', 'PM']; // AM-PM

  //loop to increment the time and push results in array
  for (var i = 0; tt < 24 * 60; i++) {
    var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    var mm = tt % 60; // getting minutes of the hour in 0-55 format
    var time = parseInt(('0' + (hh % 24)).slice(-2));
    if(time === 0){
      time = 12;
    }
    if(time > 12){
      time = time - 12;
    }
    time.toString();
    times[i] = {
      label:
        time + ':' + ('0' + mm).slice(-2) + ' ' + ((hh >= 12) ? ap[1] : ap[0]),
    };
    tt = tt + x;
  }
  return times;
};

export const getAmount =(amount:number) =>{
  return amount/100
}

const Functions = {
  useSetState,
  getBaseURL,
  Success,
  Failure,
  Warning,
  Info,
  isCallAvailable,
  Alert,
  getURLParams,
  getHours,
  getAmount
}

export default Functions