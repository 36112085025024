import React, { useRef, useImperativeHandle, forwardRef } from 'react';
import {
  Functions,
} from 'utils/imports.utils';

interface VideoProps {
  width?: any;
  height?: any;
  fullscreen?: boolean;
  showSeekBar?: boolean;
  src: any;
  url?: boolean;
  autoplay?: boolean;
  resize?: string;
  type?: string;
  onComplete?: any;
  paused?: boolean;
  repeat?: boolean;
  replay?: any;
  onReplay?:any;
  style?: any;
  controls?: boolean;
}

const VideoPlayer = forwardRef((props: VideoProps, ref: any) => {
  const {
    width,
    height,
    src,
    style,
    controls,
    showSeekBar,
    fullscreen,
    url,
    autoplay,
    resize,
    type,
    onComplete,
    paused,
    repeat,
    replay,
  } = props;

  const { useSetState } = Functions;
  const videoRef: any = useRef(null);
  const [state, setState] = useSetState({
    paused: true,
    progress: 0,
    duration: 0,
    completed: false,
    muted: false,
    fullSize: false,
    controlVisible: true,
    replay: false,

  });

  useImperativeHandle(ref, () => ({
    pauseVideo: () => {
      setState({ paused: true });
    },
    playVideo: () => {
      handlePlayVideo();
      // setState({ paused: false })
    },
  }));

  const handlePlayVideo = () => {
    if (state.progress === 0) {
      videoRef.current.seek(0);
    }
    setState({ paused: !state.paused });
    setState({ replay: false });
  };

  const secondsToTime = (time: any) => {
    return ~~(time / 60) + ':' + (time % 60 < 10 ? '0' : '') + (time % 60);
  };

  const onLoad = (meta: any) => {
    setState({
      duration: meta.duration,
    });
  };

  const onProgress = (progress: any) => {
    setState({
      progress: progress.currentTime,
    });
  };

  const onSeek = (value: any) => {
    videoRef.current.seek(value);
  };

  const mediaControl = (playerState: any) => {
    if (playerState == 'pause') {
      setState({ paused: true });
    } else {
      setState({ paused: false });
      setState({ replay: true });
    }
  };

  const onEnd = () => {
    setState({
      paused: true,
      progress: 0,
      replay: true
    });
    // console.log("end ")
  };

  const replayVideo = (replay: boolean) => {
    setState({ completed: false })
    if (replay) {
      videoRef.current.seek(0);
      props.onReplay();
    }
  };

  const showControl = () => {
    if(state.controlVisible) {
      setTimeout(() => {
        setState({ controlVisible: false })
      }, 3000)
    } else {
      setState({ controlVisible: true })
      // setTimeout(() => {
      //   setState({ controlVisible: false })
      // }, 5000)
    }
  }
  return (
    <video ref={videoRef} width={width} height={height} controls={controls} style={style}>
      <source src={src} type="video/mp4"/>
      Your browser does not support the video tag.
    </video>
  );
});

VideoPlayer.defaultProps={controls : true}

export default VideoPlayer;
