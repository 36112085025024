import React from 'react';
import moment from 'moment';
import { Ratio, Colors, Text, InfoDetail, Assets, Constants, NavButton, Image, StyleSheet, View } from 'utils/imports.utils';
import _ from 'lodash';
import { cursorTo } from 'readline';

interface petlist {
  customer?: boolean;
  start_date?: any;
  end_date?: any;
  name: string;
  category?: string;
  gender?: string;
  age?: number;
  age_type?: string;
  username?: string; 
  user_type?: string;
  training?: boolean;
  training_type?: string;
  date?: boolean;
  profile?: string;
  user_profile?: string;
  single_date?: any;
  type?:any;
}

const PetCard = (props: petlist) => {
  const {
    start_date,
    end_date,
    name,
    category,
    gender,
    age,
    age_type,
    username,
    user_type,
    training,
    date,
    training_type,
    profile,
    user_profile,
    single_date,
    type,
  } = props;
  return (
    <View style={styles.petCardScreen}>
     { single_date || start_date || end_date ?
      type == "single day" ?
      <View style={styles.dateContainer}>
      <View style={styles.dateContent}>
        <InfoDetail text={moment(single_date).format("MMM D")} icon={Assets.CalendarPrimary} style={styles.borderRadiusTop} />
      </View>
    </View> :
    <View style={styles.dateContainer}>
        <View style={styles.dateContent}>
          <InfoDetail text={`${moment(start_date).format("MMM D")} - ${moment(end_date).format("MMM D")}`} icon={Assets.CalendarPrimary} style={styles.borderRadiusTop} />
        </View>
      </View>
      : <></>
      
      }
      <View style={styles.petCardWrapper}>
          {profile?
        <View style={styles.petImageContainer}>
          <Image src={profile} width={150} height={150} style={{ borderRadius: 30, objectFit: 'cover' }} />
        </View>
        : <View style={styles.petImageContainer}>
          <Image src={Assets.DogPrimary} width={150} height={150} style={{ objectFit: 'contain'}} />
        </View>}
        <View style={styles.petDetailsContainer}>
          <View style={styles.petDetailsContent}>
            <View style={styles.nameDetails}>
              <Text color={Colors.primaryDarkText} size={18} weight="500" ellipsizeMode='tail' numberOfLines={1} styles={styles.petCardName} family={Constants.medium}>{name}</Text>
              <Text color={Colors.primaryLightText} ellipsizeMode='tail' numberOfLines={1} styles={{width:"60%"}} size={12} weight="400" family={Constants.medium} >{category}</Text>
            </View>
            {!training &&
            <View style={styles.infoDetail}>
              {gender ?
              <View>
                <InfoDetail text={gender || ""} style={styles.infoStyle} />
              </View>
              : null}
              {age ?
              <View>
                <InfoDetail text={`${age} ${age_type || "Years"}`} style={styles.infoStyle} />
              </View>
              : null}
            </View>}
            <View style={styles.userDetail}>
              <View>
                {user_profile ?
                <img src={user_profile} width={Ratio(40)} height={Ratio(40)} style={{ borderRadius: 40, objectFit: 'cover'}} />
                : <NavButton
                    style={{ backgroundColor: Colors.secondaryLightColor, width: Ratio(40), height: Ratio(40)}}
                    svg
                    icon={Assets.SingleUserPrimary}
                    iconHeight={25}
                    iconWidth={25}
                    disabled
                  />}
              </View>
              <View style={styles.userInfoDetail}>
                <Text size={12} weight="500" styles={{ width: "65%" }} ellipsizeMode="tail" numberOfLines={1} family={Constants.medium} color={Colors.primaryDarkColor}>{username}</Text>
                <Text size={12} weight="400" styles={{ paddingTop: Ratio(0)}} color={Colors.primaryLightText} family={Constants.regular}>{user_type}</Text>
              </View>
            </View>
            {training &&
            <View style={styles.trainingTypeContainer}>
              <Text size={Ratio(12)} color={Colors.primaryDarkText}>Training</Text>
              <Text styles={{ width: "55%" }} ellipsizeMode="tail" numberOfLines={1} size={Ratio(10)} color={Colors.primaryLightText}>{_.find(Constants.services, { value: training_type })?.label}</Text>
            </View>}
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  petCardScreen: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: Colors.buttonTextColor,
    borderRadius: Ratio(30),
    position: "relative",
    cursor:"pointer",
  },
  petCardWrapper: {
    width: "100%",
    paddingBottom: Ratio(20),
    paddingLeft: Ratio(20),
    paddingRight: Ratio(20),
    paddingTop: Ratio(20),
    flexDirection: "row"
  },
  petCard: {
    width: "100%",
    padding: Ratio(15),
    flexDirection: "row",
  },
  petImageContainer: {
    // width: Ratio("30%"),
    // width: Ratio(130),
    // height: Ratio(130),
    backgroundColor: Colors.secondaryLightColor,
    borderRadius: 30
  },
  petDetailsContainer: {
    justifyContent: "center",
    width: Ratio("70%"),
    alignItems: "center",
  },
  petDetailsContent: {
    width: "100%",
    paddingLeft: Ratio(20)
  },
  dateContainer: {
    flexDirection: "row",
    justifyContent: 'flex-end',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  dateContent: {
    marginRight: Ratio(20)
  },
  borderRadiusTop: {
    borderTopRightRadius: Ratio(0),
    borderTopLeftRadius: Ratio(0)
  },
  nameDetails: {
    width: "100%",
  },
  infoDetail: {
    width: "100%",
    flexDirection: 'row',
    justifyContent: "flex-start",
    paddingTop: Ratio(5),
    paddingBottom: Ratio(5)
  },
  infoStyle: {
    backgroundColor: Colors.secondaryBackground,
    maxWidth: Ratio(100)
  },
  userDetail: {
    flexDirection: "row",
    paddingTop: Ratio(5)
  },
  userInfoDetail: {
    paddingLeft: Ratio(8),
    justifyContent: "center"
  },
  trainingTypeContainer: {
    paddingTop: Ratio(5)
  },
  petCardName: {
    width: '100%',
    color: Colors.primaryDarkText,
    fontSize: 18,
    weight: '500',
    fontFamily: Constants.medium,
  },
  petCardCategory: {
    width: '60%',
    color: Colors.primaryLightText,
    fontSize: 12,
    weight: '400',
    fontFamily: Constants.medium,
  }
})

export default PetCard;