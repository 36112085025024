import React, { useEffect } from 'react';
import { getCaller, getChat, getUserList, removeUserList } from 'utils/redux.utils';
import {
  Text,
  Container,
  Assets,
  Functions,
  NavButton,
  Colors,
  Constants,
  Ratio,
  Input,
  Models,
  UserCard,
  Validation,
  View,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
LinearGradient,
  Alert,
} from 'utils/imports.utils';
import { useSelector } from 'react-redux';
import connectSocket from 'utils/socket.utils';
let socket: any;

interface pagination {
  page: number;
  limit: number;
  search: string;
}

const Client = (props: any) => {
  const userList = useSelector((state: any) => state.user.userList);
  const user = useSelector((state: any) => state.user.user);
  let modelRef = React.createRef();
  const [state, setState] = Functions.useSetState({
    searchVisible: false,
    page: 1,
    limit: 20,
    search: '',
    loadMoreLoader: false,
    loading: true,
    addUser: false,
    activity: false,
  });

  useEffect(() => {
    getDashboardDetails();
  }, [props.route.params]);

  const backAction = () => {
    removeUserList();
    // props.navigation.goBack();
    props.navigation.navigate('Manage');
    return true;
  };

  const getDashboardDetails = async () => {
    try {
      const data: any = await Models.dashboard.manageCardDetails({});
      setState({ ...data.data });
      getUsers({ page: state.page, limit: state.limit, search: state.search });
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get pet list');
      }
      setState({ loading: false });
    }
  };

  const getUsers = async ({ page, limit, search }: pagination) => {
    try {
      setState({ loadMoreLoader: true, search });
      let req = { page, limit, search, user_type: props.route.params.type };
      const user: any = await Models.admin.userList(req);
      if (page !== 1) {
        getUserList([...userList, ...user.data.docs]);
      } else {
        getUserList([...user.data.docs]);
      }
      setState({
        loading: false,
        hasMore: user.data.hasNextPage,
        page,
        limit,
        loadMoreLoader: false,
      });
    } catch (err) {
      setState({ loadMoreLoader: false });
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get user list');
      }
    }
  };

  const goBack = () => {
    // if (socket) {
    // socket.emit('leave_chat', user._id, async (data: any) => {
    //   console.log('user disconnected...');
    // });
    // }
    removeUserList();
    // props.navigation.goBack();
    props.navigation.navigate('Manage');
  };

  const getSearch = () => {
    setState({ searchVisible: !state.searchVisible });
    getUsers({ limit: state.limit, page: 1, search: '' });
  };

  const loadMore = () => {
    if (state.hasMore) {
      getUsers({ limit: state.limit, page: state.page + 1, search: state.search });
    }
  };

  const handleChatClick = async (receiver: string) => {
    try {
      let body = {
        from: user._id,
        to: receiver,
        type: 'private',
      };
      console.log('body', body);
      const conversation: any = await Models.chat.startConversation(body);
      // console.log('conversation', conversation);
      getChat(conversation.data);
      socket = connectSocket;
      // console.log('socket', socket);
      // global.WebSocket = socket;

      socket.emit('join-chat', { user: user._id });
      socket.on('user-connected', (data: any) => {
        console.log('user connected...');
      });
      props.navigation.navigate('Message');
    } catch (err) {
      console.log('err');
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get conversation');
      }
    }
  };

  const loader = () => {
    return (
      <View>
        {state.loadMoreLoader && (
          <ActivityIndicator
            size="small"
            color={Colors.primaryDarkColor}
            style={{ marginLeft: 6 }}
          />
        )}
      </View>
    );
  };
  const openAddModel = () => {
    props.navigation.navigate('AddUser', {
      buttonText: props.route.params.type === 'customer' ? 'Add Client' : 'Add Trainer',
      type: props.route.params.type,
      add: true,
    });
  };
  const viewDetails = (id: string) => {
    props.navigation.navigate('AddUser', {
      view: true,
      buttonText: 'Details',
      type: props.route.params.type,
      userId: id,
    });
  };

  const handleCall = async (id: string) => {
    const callerDetails: any = await Models.user.viewUser(id);
    getCaller(callerDetails.data);
    props.navigation.navigate('Call');
  };

  const showMessage = (name: string) => {
    Functions.Info('Sorry', `${name} is not available to take calls right now.`);
  };

  return (
    <Container
      screen
      styles={styles.clientContainer}
      // floatingButton
      loading={state.loading}
      onFloationButtonClick={openAddModel}>
      <View style={styles.clientWrapper}>
        <View style={styles.headerWrapper}>
          <View style={styles.headerContent}>
            {!state.searchVisible && (
              <View>
                <NavButton
                  icon={Assets.ArrowBack}
                  style={{ backgroundColor: Colors.background }}
                  svg
                  onClick={() => goBack()}
                  iconHeight={Ratio(25)}
                  iconWidth={Ratio(25)}
                />
              </View>
            )}
            {!state.searchVisible && (
              <View style={styles.headTextContainer}>
                <View style={{ height: '70%' }}>
                  <Text size={Ratio(24)} color={Colors.primaryDarkColor} family={Constants.regular}>
                    {props.route.params.title}
                  </Text>
                </View>
                <View style={{ height: '30%' }}>
                  <Text size={Ratio(12)} color={Colors.secondaryDarkColor}>
                    {`${
                      props.route.params.type === 'customer'
                        ? state?.clients_count
                          ? state?.clients_count
                          : 0
                        : state?.trainers_count
                        ? state?.trainers_count
                        : 0
                    } Members`}
                  </Text>
                </View>
              </View>
            )}
          </View>
          {!state.searchVisible && !state.openFilter ? (
            <View style={styles.headerContent}>
              <NavButton icon={Assets.Plus} style={styles.addButton} onClick={() => openAddModel()}/>
              <View style={styles.navButtonContainer}>
                <NavButton
                  icon={Assets.Search}
                  onClick={getSearch}
                  svg
                  iconHeight={Ratio(25)}
                  iconWidth={Ratio(25)}
                />
              </View>
            </View>
          ) : (
            <View style={styles.headerContent}>
              {state.searchVisible && (
                <View style={styles.navButtonContainerSearch}>
                  <View style={styles.searchContainer}>
                    <Input
                      onChange={(search: any) => {
                        if (!search.target) {
                          setState({ search });
                          getUsers({ limit: state.limit, page: 1, search: search });
                        }
                      }}
                      name="search"
                      value={state.search}
                      iconPosition="start"
                      source={Assets.Search}
                      iconHeight={20}
                      iconWidth={20}
                      styles={styles.input}
                      placeholder="Search"
                    />
                  </View>
                  <NavButton
                    icon={Assets.Close}
                    onClick={getSearch}
                    svg
                    iconHeight={Ratio(25)}
                    iconWidth={Ratio(25)}
                  />
                </View>
              )}
            </View>
          )}
        </View>
        {userList.length > 0 ? (
          <View style={styles.userCardContainer}>
          <View style={styles.userCard}>
            <FlatList
              data={userList}
              scrollEnabled={true}
              showsVerticalScrollIndicator={false}
              keyExtractor={(item: any) => item.name}
              ListFooterComponent={loader()}
              renderItem={({ item, index }) => (
                <View
                  activeOpacity={0.9}
                  style={[
                    styles.userDetailCard,
                    { paddingBottom: index === userList.length - 1 ? Ratio(150) : Ratio(10) },
                  ]}>
                  <UserCard
                    user_type={
                      item?.user_type.charAt(0).toUpperCase() +
                      item?.user_type.slice(1)
                    }
                    username={item.username}
                    user={item}
                    profile={item.profile ? item.profile : null}
                    onChatClick={() => handleChatClick(item._id)}
                    onCallClick={() => handleCall(item._id)}
                    callDisable={
                      !(item.call_limited
                        ? Functions.isCallAvailable(item.limited_hours)
                        : true || !item.call_allowed)
                    }
                    onDisableClick={() => showMessage(item.username)}
                    onClick={()=> viewDetails(item._id)}
                  />
                </View>
              )}
              onEndReached={() => loadMore()}
              onEndReachedThreshold={0.5}
            />
          </View>
          </View>
        ) : (
          <View style={styles.emptyContainer}>
            <Text color={Colors.secondaryLightText} size={14}>
              No users found
            </Text>
          </View>
        )}
      </View>
      <LinearGradient
        colors={['rgba(248, 249, 251, 0)', 'rgba(248, 249, 251, 0.8)', 'rgba(248, 249, 251, 1)']}
        style={styles.overlay}
      />
    </Container>
  );
};

const styles = StyleSheet.create({
  clientContainer: {
    height: '100%',
  },
  clientWrapper: {
    width: '100%',
    height: '100%',
    paddingHorizontal: Ratio(20),
  },
  headerWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '8%',
  },
  headerContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    elevation: 10,
  },
  headTextContainer: {
    justifyContent: 'center',
    height: '100%',
    paddingLeft: Ratio(10),
  },
  navButtonContainer: {
    paddingLeft: Ratio(20),
  },
  navButtonContainerSearch: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    // paddingLeft: Ratio(20),
  },
  searchContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '80%',
  },
  input: {
    width: '100%',
  },
  emptyContainer: {
    width: '100%',
    paddingTop: Ratio(20),
    justifyContent: 'center',
    alignItems: 'center',
  },
  userCard: {
    width: '100%',
    marginTop: Ratio(20),
    flexDirection:"row",
    justifyContent:"space-between",
    flexWrap:"Wrap"
  },
  userDetailCard: {
    width: '49%',
    marginBottom: Ratio(10),
  },
  overlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: Ratio(150),
    width: '100%',
  },
  addButton:{
    borderRadius:"50%",
    backgroundColor: Colors.secondaryDarkColor,
    marginRight:"20px",
  },
  // userCardContainer:{
  //   width:"100%",
  //   display:"flex",
  //   flexDirection:'row',
  //   justifyContent:'space-between',
  // }
});

export default Client;
