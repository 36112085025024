export const sampleConstant = "sampleConstant"
import { Assets } from 'utils/imports.utils';

// Color family
// export const light = '#FFFFFF';
export const Dark = '#000000';
export const fontColor = '#F9F6FF';
export const background = '#111622';
export const primaryLinearGradient = '#9D70FF';
export const secondaryLinearGradient = '#7460FF';
export const fieldColor = '#1A202E';
export const success = 'green';
export const error = 'red';
export const main = '#7962FF';

export const statusBarHeight = 50;

// font family
export const regular = 'Poppins-Regular';
export const medium = 'Poppins-Medium';
export const thin = 'Poppins-Thin';
export const light = 'Poppins-Light';
export const heavy = 'Poppins-Heavy';
export const bold = 'Poppins-Bold';
export const semiBold = 'Poppins-SemiBold';


//App url constants
// export const peerUrl = '192.168.0.105'
// export const peerUrl = 'https://192.168.0.101'
// export const peerUrl = "139.59.85.183"
// export const socketUrl = "http://192.168.29.173:8001"
// export const serverUrl = "http://192.168.29.173:8001/api/v1/"

export const peerUrl = "api.petloopapp.com"
export const socketUrl = "wss://api.petloopapp.com"
// export const serverUrl = "https://api.petloopapp.com/api/v1/"

// export const socketUrl = "wss://training.augmo.io"
export const serverUrl = "https://training.augmo.io/api/v1/"


export const pet = {
  name: "Johny",
  age: "20",
  sex: "male",
  category: "Germon pet",
  client: {
    name: "Mukunthan",
  }
}

export const petList = [
  {
    name: "Johny",
    age: "20",
    sex: "male",
    category: "Germon pet",
    client: {
      name: "Mukunthan",
    }
  },
  {
    name: "Johny",
    age: "20",
    sex: "male",
    category: "Germon pet",
    client: {
      name: "Mukunthan",
    }
  },
  {
    name: "Johny",
    age: "20",
    sex: "male",
    category: "Germon pet",
    client: {
      name: "Mukunthan",
    }
  },
  {
    name: "Johny",
    age: "20",
    sex: "male",
    category: "Germon pet",
    client: {
      name: "Mukunthan",
    }
  },
  {
    name: "Johny",
    age: "20",
    sex: "male",
    category: "Germon pet",
    client: {
      name: "Mukunthan",
    }
  },
  {
    name: "Johny",
    age: "20",
    sex: "male",
    category: "Germon pet",
    client: {
      name: "Mukunthan",
    }
  },
  {
    name: "Johny",
    age: "20",
    sex: "male",
    category: "Germon pet",
    client: {
      name: "Mukunthan",
    }
  },
  {
    name: "Johny",
    age: "20",
    sex: "male",
    category: "Germon pet",
    client: {
      name: "Mukunthan",
    }
  },
  {
    name: "Johny",
    age: "20",
    sex: "male",
    category: "Germon pet",
    client: {
      name: "Mukunthan",
    }
  }
]

export const genderList = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  }
]

interface IAge{
  label?: string;
  value?: string;
  type?: string;
}

export const Age = () => {
  let age: IAge[] = [];
  for (let i = 1; i <= 12; i++) {
    age.push({
      label: i === 1 ? `${i} Month` : `${i} Months`,
      value: i.toString(),
      type: i === 1 ? 'Month' : 'Months',
    });
  }
  for (let i = 2; i <= 50; i++) {
    let a = i/2;
    age.push({
      label: a === 1 ? `${a} Year` : `${a} Years`,
      value: a.toString(),
      type: a === 1 ? 'Year' : 'Years',
    });
  }
  return age;
};

export const breeds = [ 
  { 
    "label": "Afador", 
    "value": "Afador" 
  }, 
  { 
    "label": "Affenhuahua", 
    "value": "Affenhuahua" 
  }, 
  { 
    "label": "Affenpinscher", 
    "value": "Affenpinscher" 
  }, 
  { 
    "label": "Afghan Hound", 
    "value": "Afghan Hound" 
  }, 
  { 
    "label": "Airedale Terrier", 
    "value": "Airedale Terrier" 
  }, 
  { 
    "label": "Akbash", 
    "value": "Akbash" 
  }, 
  { 
    "label": "Akita", 
    "value": "Akita" 
  }, 
  { 
    "label": "Akita Chow", 
    "value": "Akita Chow" 
  }, 
  { 
    "label": "Akita Pit", 
    "value": "Akita Pit" 
  }, 
  { 
    "label": "Akita Shepherd", 
    "value": "Akita Shepherd" 
  }, 
  { 
    "label": "Alaskan Klee Kai", 
    "value": "Alaskan Klee Kai" 
  }, 
  { 
    "label": "Alaskan Malamute", 
    "value": "Alaskan Malamute" 
  }, 
  { 
    "label": "American Bulldog", 
    "value": "American Bulldog" 
  }, 
  { 
    "label": "American English Coonhound", 
    "value": "American English Coonhound" 
  }, 
  { 
    "label": "American Eskimo Dog", 
    "value": "American Eskimo Dog" 
  }, 
  { 
    "label": "American Foxhound", 
    "value": "American Foxhound" 
  }, 
  { 
    "label": "American Hairless Terrier", 
    "value": "American Hairless Terrier" 
  }, 
  { 
    "label": "American Leopard Hound", 
    "value": "American Leopard Hound" 
  }, 
  { 
    "label": "American Pit Bull Terrier", 
    "value": "American Pit Bull Terrier" 
  }, 
  { 
    "label": "American Pugabull", 
    "value": "American Pugabull" 
  }, 
  { 
    "label": "American Staffordshire Terrier", 
    "value": "American Staffordshire Terrier" 
  }, 
  { 
    "label": "American Water Spaniel", 
    "value": "American Water Spaniel" 
  }, 
  { 
    "label": "Anatolian Shepherd Dog", 
    "value": "Anatolian Shepherd Dog" 
  }, 
  { 
    "label": "Appenzeller Sennenhunde", 
    "value": "Appenzeller Sennenhunde" 
  }, 
  { 
    "label": "Auggie", 
    "value": "Auggie" 
  }, 
  { 
    "label": "Aussiedoodle", 
    "value": "Aussiedoodle" 
  }, 
  { 
    "label": "Aussiepom", 
    "value": "Aussiepom" 
  }, 
  { 
    "label": "Australian Cattle Dog", 
    "value": "Australian Cattle Dog" 
  }, 
  { 
    "label": "Australian Kelpie", 
    "value": "Australian Kelpie" 
  }, 
  { 
    "label": "Australian Retriever", 
    "value": "Australian Retriever" 
  }, 
  { 
    "label": "Australian Shepherd", 
    "value": "Australian Shepherd" 
  }, 
  { 
    "label": "Australian Shepherd Husky", 
    "value": "Australian Shepherd Husky" 
  }, 
  { 
    "label": "Australian Shepherd Lab Mix", 
    "value": "Australian Shepherd Lab Mix" 
  }, 
  { 
    "label": "Australian Shepherd Pit Bull Mix", 
    "value": "Australian Shepherd Pit Bull Mix" 
  }, 
  { 
    "label": "Australian Stumpy Tail Cattle Dog", 
    "value": "Australian Stumpy Tail Cattle Dog" 
  }, 
  { 
    "label": "Australian Terrier", 
    "value": "Australian Terrier" 
  }, 
  { 
    "label": "Azawakh", 
    "value": "Azawakh" 
  }, 
  { 
    "label": "Barbet", 
    "value": "Barbet" 
  }, 
  { 
    "label": "Basenji", 
    "value": "Basenji" 
  }, 
  { 
    "label": "Bassador", 
    "value": "Bassador" 
  }, 
  { 
    "label": "Basset Fauve de Bretagne", 
    "value": "Basset Fauve de Bretagne" 
  }, 
  { 
    "label": "Basset Hound", 
    "value": "Basset Hound" 
  }, 
  { 
    "label": "Basset Retriever", 
    "value": "Basset Retriever" 
  }, 
  { 
    "label": "Bavarian Mountain Scent Hound", 
    "value": "Bavarian Mountain Scent Hound" 
  }, 
  { 
    "label": "Beabull", 
    "value": "Beabull" 
  }, 
  { 
    "label": "Beagle", 
    "value": "Beagle" 
  }, 
  { 
    "label": "Beaglier", 
    "value": "Beaglier" 
  }, 
  { 
    "label": "Bearded Collie", 
    "value": "Bearded Collie" 
  }, 
  { 
    "label": "Bedlington Terrier", 
    "value": "Bedlington Terrier" 
  }, 
  { 
    "label": "Belgian Laekenois", 
    "value": "Belgian Laekenois" 
  }, 
  { 
    "label": "Belgian Malinois", 
    "value": "Belgian Malinois" 
  }, 
  { 
    "label": "Belgian Sheepdog", 
    "value": "Belgian Sheepdog" 
  }, 
  { 
    "label": "Belgian Tervuren", 
    "value": "Belgian Tervuren" 
  }, 
  { 
    "label": "Bergamasco Sheepdog", 
    "value": "Bergamasco Sheepdog" 
  }, 
  { 
    "label": "Berger Picard", 
    "value": "Berger Picard" 
  }, 
  { 
    "label": "Bernedoodle", 
    "value": "Bernedoodle" 
  }, 
  { 
    "label": "Bernese Mountain Dog", 
    "value": "Bernese Mountain Dog" 
  }, 
  { 
    "label": "Bichon Frise", 
    "value": "Bichon Frise" 
  }, 
  { 
    "label": "Biewer Terrier", 
    "value": "Biewer Terrier" 
  }, 
  { 
    "label": "Black and Tan Coonhound", 
    "value": "Black and Tan Coonhound" 
  }, 
  { 
    "label": "Black Mouth Cur", 
    "value": "Black Mouth Cur" 
  }, 
  { 
    "label": "Black Russian Terrier", 
    "value": "Black Russian Terrier" 
  }, 
  { 
    "label": "Bloodhound", 
    "value": "Bloodhound" 
  }, 
  { 
    "label": "Blue Lacy", 
    "value": "Blue Lacy" 
  }, 
  { 
    "label": "Bluetick Coonhound", 
    "value": "Bluetick Coonhound" 
  }, 
  { 
    "label": "Bocker", 
    "value": "Bocker" 
  }, 
  { 
    "label": "Boerboel", 
    "value": "Boerboel" 
  }, 
  { 
    "label": "Boglen Terrier", 
    "value": "Boglen Terrier" 
  }, 
  { 
    "label": "Bohemian Shepherd", 
    "value": "Bohemian Shepherd" 
  }, 
  { 
    "label": "Bolognese", 
    "value": "Bolognese" 
  }, 
  { 
    "label": "Borador", 
    "value": "Borador" 
  }, 
  { 
    "label": "Border Collie", 
    "value": "Border Collie" 
  }, 
  { 
    "label": "Border Sheepdog", 
    "value": "Border Sheepdog" 
  }, 
  { 
    "label": "Border Terrier", 
    "value": "Border Terrier" 
  }, 
  { 
    "label": "Bordoodle", 
    "value": "Bordoodle" 
  }, 
  { 
    "label": "Borzoi", 
    "value": "Borzoi" 
  }, 
  { 
    "label": "BoShih", 
    "value": "BoShih" 
  }, 
  { 
    "label": "Bossie", 
    "value": "Bossie" 
  }, 
  { 
    "label": "Boston Boxer", 
    "value": "Boston Boxer" 
  }, 
  { 
    "label": "Boston Terrier", 
    "value": "Boston Terrier" 
  }, 
  { 
    "label": "Boston Terrier Pekingese Mix", 
    "value": "Boston Terrier Pekingese Mix" 
  }, 
  { 
    "label": "Bouvier des Flandres", 
    "value": "Bouvier des Flandres" 
  }, 
  { 
    "label": "Boxador", 
    "value": "Boxador" 
  }, 
  { 
    "label": "Boxer", 
    "value": "Boxer" 
  }, 
  { 
    "label": "Boxerdoodle", 
    "value": "Boxerdoodle" 
  }, 
  { 
    "label": "Boxmatian", 
    "value": "Boxmatian" 
  }, 
  { 
    "label": "Boxweiler", 
    "value": "Boxweiler" 
  }, 
  { 
    "label": "Boykin Spaniel", 
    "value": "Boykin Spaniel" 
  }, 
  { 
    "label": "Bracco Italiano", 
    "value": "Bracco Italiano" 
  }, 
  { 
    "label": "Braque du Bourbonnais", 
    "value": "Braque du Bourbonnais" 
  }, 
  { 
    "label": "Briard", 
    "value": "Briard" 
  }, 
  { 
    "label": "Brittany", 
    "value": "Brittany" 
  }, 
  { 
    "label": "Broholmer", 
    "value": "Broholmer" 
  }, 
  { 
    "label": "Brussels Griffon", 
    "value": "Brussels Griffon" 
  }, 
  { 
    "label": "Bugg", 
    "value": "Bugg" 
  }, 
  { 
    "label": "Bull Arab", 
    "value": "Bull Arab" 
  }, 
  { 
    "label": "Bull-Pei", 
    "value": "Bull-Pei" 
  }, 
  { 
    "label": "Bull Terrier", 
    "value": "Bull Terrier" 
  }, 
  { 
    "label": "Bullador", 
    "value": "Bullador" 
  }, 
  { 
    "label": "Bullboxer Pit", 
    "value": "Bullboxer Pit" 
  }, 
  { 
    "label": "Bulldog", 
    "value": "Bulldog" 
  }, 
  { 
    "label": "Bullmastiff", 
    "value": "Bullmastiff" 
  }, 
  { 
    "label": "Bullmatian", 
    "value": "Bullmatian" 
  }, 
  { 
    "label": "Cairn Terrier", 
    "value": "Cairn Terrier" 
  }, 
  { 
    "label": "Canaan Dog", 
    "value": "Canaan Dog" 
  }, 
  { 
    "label": "Cane Corso", 
    "value": "Cane Corso" 
  }, 
  { 
    "label": "Cardigan Welsh Corgi", 
    "value": "Cardigan Welsh Corgi" 
  }, 
  { 
    "label": "Carolina Dog", 
    "value": "Carolina Dog" 
  }, 
  { 
    "label": "Catahoula Bulldog", 
    "value": "Catahoula Bulldog" 
  }, 
  { 
    "label": "Catahoula Leopard Dog", 
    "value": "Catahoula Leopard Dog" 
  }, 
  { 
    "label": "Caucasian Shepherd Dog", 
    "value": "Caucasian Shepherd Dog" 
  }, 
  { 
    "label": "Cav-a-Jack", 
    "value": "Cav-a-Jack" 
  }, 
  { 
    "label": "Cavachon", 
    "value": "Cavachon" 
  }, 
  { 
    "label": "Cavador", 
    "value": "Cavador" 
  }, 
  { 
    "label": "Cavalier King Charles Spaniel", 
    "value": "Cavalier King Charles Spaniel" 
  }, 
  { 
    "label": "Cavapoo", 
    "value": "Cavapoo" 
  }, 
  { 
    "label": "Central Asian Shepherd Dog", 
    "value": "Central Asian Shepherd Dog" 
  }, 
  { 
    "label": "Cesky Terrier", 
    "value": "Cesky Terrier" 
  }, 
  { 
    "label": "Chabrador", 
    "value": "Chabrador" 
  }, 
  { 
    "label": "Cheagle", 
    "value": "Cheagle" 
  }, 
  { 
    "label": "Chesapeake Bay Retriever", 
    "value": "Chesapeake Bay Retriever" 
  }, 
  { 
    "label": "Chi Chi", 
    "value": "Chi Chi" 
  }, 
  { 
    "label": "Chi-Poo", 
    "value": "Chi-Poo" 
  }, 
  { 
    "label": "Chigi", 
    "value": "Chigi" 
  }, 
  { 
    "label": "Chihuahua", 
    "value": "Chihuahua" 
  }, 
  { 
    "label": "Chilier", 
    "value": "Chilier" 
  }, 
  { 
    "label": "Chinese Crested", 
    "value": "Chinese Crested" 
  }, 
  { 
    "label": "Chinese Shar-Pei", 
    "value": "Chinese Shar-Pei" 
  }, 
  { 
    "label": "Chinook", 
    "value": "Chinook" 
  }, 
  { 
    "label": "Chion", 
    "value": "Chion" 
  }, 
  { 
    "label": "Chipin", 
    "value": "Chipin" 
  }, 
  { 
    "label": "Chiweenie", 
    "value": "Chiweenie" 
  }, 
  { 
    "label": "Chorkie", 
    "value": "Chorkie" 
  }, 
  { 
    "label": "Chow Chow", 
    "value": "Chow Chow" 
  }, 
  { 
    "label": "Chow Shepherd", 
    "value": "Chow Shepherd" 
  }, 
  { 
    "label": "Chug", 
    "value": "Chug" 
  }, 
  { 
    "label": "Chusky", 
    "value": "Chusky" 
  }, 
  { 
    "label": "Cirneco dell’Etna", 
    "value": "Cirneco dell’Etna" 
  }, 
  { 
    "label": "Clumber Spaniel", 
    "value": "Clumber Spaniel" 
  }, 
  { 
    "label": "Cockalier", 
    "value": "Cockalier" 
  }, 
  { 
    "label": "Cockapoo", 
    "value": "Cockapoo" 
  }, 
  { 
    "label": "Cocker Spaniel", 
    "value": "Cocker Spaniel" 
  }, 
  { 
    "label": "Collie", 
    "value": "Collie" 
  }, 
  { 
    "label": "Corgi Inu", 
    "value": "Corgi Inu" 
  }, 
  { 
    "label": "Corgidor", 
    "value": "Corgidor" 
  }, 
  { 
    "label": "Corman Shepherd", 
    "value": "Corman Shepherd" 
  }, 
  { 
    "label": "Coton de Tulear", 
    "value": "Coton de Tulear" 
  }, 
  { 
    "label": "Croatian Sheepdog", 
    "value": "Croatian Sheepdog" 
  }, 
  { 
    "label": "Curly-Coated Retriever", 
    "value": "Curly-Coated Retriever" 
  }, 
  { 
    "label": "Dachsador", 
    "value": "Dachsador" 
  }, 
  { 
    "label": "Dachshund", 
    "value": "Dachshund" 
  }, 
  { 
    "label": "Dalmatian", 
    "value": "Dalmatian" 
  }, 
  { 
    "label": "Dandie Dinmont Terrier", 
    "value": "Dandie Dinmont Terrier" 
  }, 
  { 
    "label": "Daniff", 
    "value": "Daniff" 
  }, 
  { 
    "label": "Danish-Swedish Farmdog", 
    "value": "Danish-Swedish Farmdog" 
  }, 
  { 
    "label": "Deutscher Wachtelhund", 
    "value": "Deutscher Wachtelhund" 
  }, 
  { 
    "label": "Doberdor", 
    "value": "Doberdor" 
  }, 
  { 
    "label": "Doberman Pinscher", 
    "value": "Doberman Pinscher" 
  }, 
  { 
    "label": "Docker", 
    "value": "Docker" 
  }, 
  { 
    "label": "Dogo Argentino", 
    "value": "Dogo Argentino" 
  }, 
  { 
    "label": "Dogue de Bordeaux", 
    "value": "Dogue de Bordeaux" 
  }, 
  { 
    "label": "Dorgi", 
    "value": "Dorgi" 
  }, 
  { 
    "label": "Dorkie", 
    "value": "Dorkie" 
  }, 
  { 
    "label": "Doxiepoo", 
    "value": "Doxiepoo" 
  }, 
  { 
    "label": "Doxle", 
    "value": "Doxle" 
  }, 
  { 
    "label": "Drentsche Patrijshond", 
    "value": "Drentsche Patrijshond" 
  }, 
  { 
    "label": "Drever", 
    "value": "Drever" 
  }, 
  { 
    "label": "Dutch Shepherd", 
    "value": "Dutch Shepherd" 
  }, 
  { 
    "label": "English Cocker Spaniel", 
    "value": "English Cocker Spaniel" 
  }, 
  { 
    "label": "English Foxhound", 
    "value": "English Foxhound" 
  }, 
  { 
    "label": "English Setter", 
    "value": "English Setter" 
  }, 
  { 
    "label": "English Springer Spaniel", 
    "value": "English Springer Spaniel" 
  }, 
  { 
    "label": "English Toy Spaniel", 
    "value": "English Toy Spaniel" 
  }, 
  { 
    "label": "Entlebucher Mountain Dog", 
    "value": "Entlebucher Mountain Dog" 
  }, 
  { 
    "label": "Estrela Mountain Dog", 
    "value": "Estrela Mountain Dog" 
  }, 
  { 
    "label": "Eurasier", 
    "value": "Eurasier" 
  }, 
  { 
    "label": "Field Spaniel", 
    "value": "Field Spaniel" 
  }, 
  { 
    "label": "Fila Brasileiro", 
    "value": "Fila Brasileiro" 
  }, 
  { 
    "label": "Finnish Lapphund", 
    "value": "Finnish Lapphund" 
  }, 
  { 
    "label": "Finnish Spitz", 
    "value": "Finnish Spitz" 
  }, 
  { 
    "label": "Flat-Coated Retriever", 
    "value": "Flat-Coated Retriever" 
  }, 
  { 
    "label": "Fox Terrier", 
    "value": "Fox Terrier" 
  }, 
  { 
    "label": "French Bulldog", 
    "value": "French Bulldog" 
  }, 
  { 
    "label": "French Bullhuahua", 
    "value": "French Bullhuahua" 
  }, 
  { 
    "label": "French Spaniel", 
    "value": "French Spaniel" 
  }, 
  { 
    "label": "Frenchton", 
    "value": "Frenchton" 
  }, 
  { 
    "label": "Frengle", 
    "value": "Frengle" 
  }, 
  { 
    "label": "German Longhaired Pointer", 
    "value": "German Longhaired Pointer" 
  }, 
  { 
    "label": "German Pinscher", 
    "value": "German Pinscher" 
  }, 
  { 
    "label": "German Shepherd Dog", 
    "value": "German Shepherd Dog" 
  }, 
  { 
    "label": "German Shepherd Pit Bull", 
    "value": "German Shepherd Pit Bull" 
  }, 
  { 
    "label": "German Shepherd Rottweiler Mix", 
    "value": "German Shepherd Rottweiler Mix" 
  }, 
  { 
    "label": "German Sheprador", 
    "value": "German Sheprador" 
  }, 
  { 
    "label": "German Shorthaired Pointer", 
    "value": "German Shorthaired Pointer" 
  }, 
  { 
    "label": "German Spitz", 
    "value": "German Spitz" 
  }, 
  { 
    "label": "German Wirehaired Pointer", 
    "value": "German Wirehaired Pointer" 
  }, 
  { 
    "label": "Giant Schnauzer", 
    "value": "Giant Schnauzer" 
  }, 
  { 
    "label": "Glen of Imaal Terrier", 
    "value": "Glen of Imaal Terrier" 
  }, 
  { 
    "label": "Goberian", 
    "value": "Goberian" 
  }, 
  { 
    "label": "Goldador", 
    "value": "Goldador" 
  }, 
  { 
    "label": "Golden Cocker Retriever", 
    "value": "Golden Cocker Retriever" 
  }, 
  { 
    "label": "Golden Mountain Dog", 
    "value": "Golden Mountain Dog" 
  }, 
  { 
    "label": "Golden Retriever", 
    "value": "Golden Retriever" 
  }, 
  { 
    "label": "Golden Retriever Corgi", 
    "value": "Golden Retriever Corgi" 
  }, 
  { 
    "label": "Golden Shepherd", 
    "value": "Golden Shepherd" 
  }, 
  { 
    "label": "Goldendoodle", 
    "value": "Goldendoodle" 
  }, 
  { 
    "label": "Gollie", 
    "value": "Gollie" 
  }, 
  { 
    "label": "Gordon Setter", 
    "value": "Gordon Setter" 
  }, 
  { 
    "label": "Great Dane", 
    "value": "Great Dane" 
  }, 
  { 
    "label": "Great Pyrenees", 
    "value": "Great Pyrenees" 
  }, 
  { 
    "label": "Greater Swiss Mountain Dog", 
    "value": "Greater Swiss Mountain Dog" 
  }, 
  { 
    "label": "Greyador", 
    "value": "Greyador" 
  }, 
  { 
    "label": "Greyhound", 
    "value": "Greyhound" 
  }, 
  { 
    "label": "Hamiltonstovare", 
    "value": "Hamiltonstovare" 
  }, 
  { 
    "label": "Hanoverian Scenthound", 
    "value": "Hanoverian Scenthound" 
  }, 
  { 
    "label": "Harrier", 
    "value": "Harrier" 
  }, 
  { 
    "label": "Havanese", 
    "value": "Havanese" 
  }, 
  { 
    "label": "Havapoo", 
    "value": "Havapoo" 
  }, 
  { 
    "label": "Hokkaido", 
    "value": "Hokkaido" 
  }, 
  { 
    "label": "Horgi", 
    "value": "Horgi" 
  }, 
  { 
    "label": "Hovawart", 
    "value": "Hovawart" 
  }, 
  { 
    "label": "Huskita", 
    "value": "Huskita" 
  }, 
  { 
    "label": "Huskydoodle", 
    "value": "Huskydoodle" 
  }, 
  { 
    "label": "Ibizan Hound", 
    "value": "Ibizan Hound" 
  }, 
  { 
    "label": "Icelandic Sheepdog", 
    "value": "Icelandic Sheepdog" 
  }, 
  { 
    "label": "Irish Red And White Setter", 
    "value": "Irish Red And White Setter" 
  }, 
  { 
    "label": "Irish Setter", 
    "value": "Irish Setter" 
  }, 
  { 
    "label": "Irish Terrier", 
    "value": "Irish Terrier" 
  }, 
  { 
    "label": "Irish Water Spaniel", 
    "value": "Irish Water Spaniel" 
  }, 
  { 
    "label": "Irish Wolfhound", 
    "value": "Irish Wolfhound" 
  }, 
  { 
    "label": "Italian Greyhound", 
    "value": "Italian Greyhound" 
  }, 
  { 
    "label": "Jack-A-Poo", 
    "value": "Jack-A-Poo" 
  }, 
  { 
    "label": "Jack Chi", 
    "value": "Jack Chi" 
  }, 
  { 
    "label": "Jack Russell Terrier", 
    "value": "Jack Russell Terrier" 
  }, 
  { 
    "label": "Jackshund", 
    "value": "Jackshund" 
  }, 
  { 
    "label": "Japanese Chin", 
    "value": "Japanese Chin" 
  }, 
  { 
    "label": "Japanese Spitz", 
    "value": "Japanese Spitz" 
  }, 
  { 
    "label": "Korean Jindo Dog", 
    "value": "Korean Jindo Dog" 
  }, 
  { 
    "label": "Kai Ken", 
    "value": "Kai Ken" 
  }, 
  { 
    "label": "Karelian Bear Dog", 
    "value": "Karelian Bear Dog" 
  }, 
  { 
    "label": "Keeshond", 
    "value": "Keeshond" 
  }, 
  { 
    "label": "Kerry Blue Terrier", 
    "value": "Kerry Blue Terrier" 
  }, 
  { 
    "label": "King Shepherd", 
    "value": "King Shepherd" 
  }, 
  { 
    "label": "Kishu Ken", 
    "value": "Kishu Ken" 
  }, 
  { 
    "label": "Komondor", 
    "value": "Komondor" 
  }, 
  { 
    "label": "Kooikerhondje", 
    "value": "Kooikerhondje" 
  }, 
  { 
    "label": "Kuvasz", 
    "value": "Kuvasz" 
  }, 
  { 
    "label": "Kyi-Leo", 
    "value": "Kyi-Leo" 
  }, 
  { 
    "label": "Lab Pointer", 
    "value": "Lab Pointer" 
  }, 
  { 
    "label": "Labernese", 
    "value": "Labernese" 
  }, 
  { 
    "label": "Labmaraner", 
    "value": "Labmaraner" 
  }, 
  { 
    "label": "Labrabull", 
    "value": "Labrabull" 
  }, 
  { 
    "label": "Labradane", 
    "value": "Labradane" 
  }, 
  { 
    "label": "Labradoodle", 
    "value": "Labradoodle" 
  }, 
  { 
    "label": "Labrador Retriever", 
    "value": "Labrador Retriever" 
  }, 
  { 
    "label": "Labrastaff", 
    "value": "Labrastaff" 
  }, 
  { 
    "label": "Labsky", 
    "value": "Labsky" 
  }, 
  { 
    "label": "Lagotto Romagnolo", 
    "value": "Lagotto Romagnolo" 
  }, 
  { 
    "label": "Lakeland Terrier", 
    "value": "Lakeland Terrier" 
  }, 
  { 
    "label": "Lancashire Heeler", 
    "value": "Lancashire Heeler" 
  }, 
  { 
    "label": "Leonberger", 
    "value": "Leonberger" 
  }, 
  { 
    "label": "Lhasa Apso", 
    "value": "Lhasa Apso" 
  }, 
  { 
    "label": "Lhasapoo", 
    "value": "Lhasapoo" 
  }, 
  { 
    "label": "Lowchen", 
    "value": "Lowchen" 
  }, 
  { 
    "label": "Maltese", 
    "value": "Maltese" 
  }, 
  { 
    "label": "Maltese Shih Tzu", 
    "value": "Maltese Shih Tzu" 
  }, 
  { 
    "label": "Maltipoo", 
    "value": "Maltipoo" 
  }, 
  { 
    "label": "Manchester Terrier", 
    "value": "Manchester Terrier" 
  }, 
  { 
    "label": "Maremma Sheepdog", 
    "value": "Maremma Sheepdog" 
  }, 
  { 
    "label": "Mastador", 
    "value": "Mastador" 
  }, 
  { 
    "label": "Mastiff", 
    "value": "Mastiff" 
  }, 
  { 
    "label": "Miniature Pinscher", 
    "value": "Miniature Pinscher" 
  }, 
  { 
    "label": "Miniature Schnauzer", 
    "value": "Miniature Schnauzer" 
  }, 
  { 
    "label": "Morkie", 
    "value": "Morkie" 
  }, 
  { 
    "label": "Mountain Cur", 
    "value": "Mountain Cur" 
  }, 
  { 
    "label": "Mountain Feist", 
    "value": "Mountain Feist" 
  }, 
  { 
    "label": "Mudi", 
    "value": "Mudi" 
  }, 
  { 
    "label": "Mutt (Mixed)", 
    "value": "Mutt (Mixed)" 
  }, 
  { 
    "label": "Neapolitan Mastiff", 
    "value": "Neapolitan Mastiff" 
  }, 
  { 
    "label": "Newfoundland", 
    "value": "Newfoundland" 
  }, 
  { 
    "label": "Norfolk Terrier", 
    "value": "Norfolk Terrier" 
  }, 
  { 
    "label": "Northern Inuit Dog", 
    "value": "Northern Inuit Dog" 
  }, 
  { 
    "label": "Norwegian Buhund", 
    "value": "Norwegian Buhund" 
  }, 
  { 
    "label": "Norwegian Elkhound", 
    "value": "Norwegian Elkhound" 
  }, 
  { 
    "label": "Norwegian Lundehund", 
    "value": "Norwegian Lundehund" 
  }, 
  { 
    "label": "Norwich Terrier", 
    "value": "Norwich Terrier" 
  }, 
  { 
    "label": "Nova Scotia Duck Tolling Retriever", 
    "value": "Nova Scotia Duck Tolling Retriever" 
  }, 
  { 
    "label": "Old English Sheepdog", 
    "value": "Old English Sheepdog" 
  }, 
  { 
    "label": "Otterhound", 
    "value": "Otterhound" 
  }, 
  { 
    "label": "Papillon", 
    "value": "Papillon" 
  }, 
  { 
    "label": "Papipoo", 
    "value": "Papipoo" 
  }, 
  { 
    "label": "Patterdale Terrier", 
    "value": "Patterdale Terrier" 
  }, 
  { 
    "label": "Peekapoo", 
    "value": "Peekapoo" 
  }, 
  { 
    "label": "Pekingese", 
    "value": "Pekingese" 
  }, 
  { 
    "label": "Pembroke Welsh Corgi", 
    "value": "Pembroke Welsh Corgi" 
  }, 
  { 
    "label": "Petit Basset Griffon Vendéen", 
    "value": "Petit Basset Griffon Vendéen" 
  }, 
  { 
    "label": "Pharaoh Hound", 
    "value": "Pharaoh Hound" 
  }, 
  { 
    "label": "Pitsky", 
    "value": "Pitsky" 
  }, 
  { 
    "label": "Plott", 
    "value": "Plott" 
  }, 
  { 
    "label": "Pocket Beagle", 
    "value": "Pocket Beagle" 
  }, 
  { 
    "label": "Pointer", 
    "value": "Pointer" 
  }, 
  { 
    "label": "Polish Lowland Sheepdog", 
    "value": "Polish Lowland Sheepdog" 
  }, 
  { 
    "label": "Pomapoo", 
    "value": "Pomapoo" 
  }, 
  { 
    "label": "Pomchi", 
    "value": "Pomchi" 
  }, 
  { 
    "label": "Pomeagle", 
    "value": "Pomeagle" 
  }, 
  { 
    "label": "Pomeranian", 
    "value": "Pomeranian" 
  }, 
  { 
    "label": "Pomsky", 
    "value": "Pomsky" 
  }, 
  { 
    "label": "Poochon", 
    "value": "Poochon" 
  }, 
  { 
    "label": "Poodle", 
    "value": "Poodle" 
  }, 
  { 
    "label": "Portuguese Podengo Pequeno", 
    "value": "Portuguese Podengo Pequeno" 
  }, 
  { 
    "label": "Portuguese Pointer", 
    "value": "Portuguese Pointer" 
  }, 
  { 
    "label": "Portuguese Sheepdog", 
    "value": "Portuguese Sheepdog" 
  }, 
  { 
    "label": "Portuguese Water Dog", 
    "value": "Portuguese Water Dog" 
  }, 
  { 
    "label": "Pudelpointer", 
    "value": "Pudelpointer" 
  }, 
  { 
    "label": "Pug", 
    "value": "Pug" 
  }, 
  { 
    "label": "Pugalier", 
    "value": "Pugalier" 
  }, 
  { 
    "label": "Puggle", 
    "value": "Puggle" 
  }, 
  { 
    "label": "Puginese", 
    "value": "Puginese" 
  }, 
  { 
    "label": "Puli", 
    "value": "Puli" 
  }, 
  { 
    "label": "Pyredoodle", 
    "value": "Pyredoodle" 
  }, 
  { 
    "label": "Pyrenean Mastiff", 
    "value": "Pyrenean Mastiff" 
  }, 
  { 
    "label": "Pyrenean Shepherd", 
    "value": "Pyrenean Shepherd" 
  }, 
  { 
    "label": "Rat Terrier", 
    "value": "Rat Terrier" 
  }, 
  { 
    "label": "Redbone Coonhound", 
    "value": "Redbone Coonhound" 
  }, 
  { 
    "label": "Rhodesian Ridgeback", 
    "value": "Rhodesian Ridgeback" 
  }, 
  { 
    "label": "Rottador", 
    "value": "Rottador" 
  }, 
  { 
    "label": "Rottle", 
    "value": "Rottle" 
  }, 
  { 
    "label": "Rottweiler", 
    "value": "Rottweiler" 
  }, 
  { 
    "label": "Saint Berdoodle", 
    "value": "Saint Berdoodle" 
  }, 
  { 
    "label": "Saint Bernard", 
    "value": "Saint Bernard" 
  }, 
  { 
    "label": "Saluki", 
    "value": "Saluki" 
  }, 
  { 
    "label": "Samoyed", 
    "value": "Samoyed" 
  }, 
  { 
    "label": "Samusky", 
    "value": "Samusky" 
  }, 
  { 
    "label": "Schipperke", 
    "value": "Schipperke" 
  }, 
  { 
    "label": "Schnoodle", 
    "value": "Schnoodle" 
  }, 
  { 
    "label": "Scottish Deerhound", 
    "value": "Scottish Deerhound" 
  }, 
  { 
    "label": "Scottish Terrier", 
    "value": "Scottish Terrier" 
  }, 
  { 
    "label": "Sealyham Terrier", 
    "value": "Sealyham Terrier" 
  }, 
  { 
    "label": "Sheepadoodle", 
    "value": "Sheepadoodle" 
  }, 
  { 
    "label": "Shepsky", 
    "value": "Shepsky" 
  }, 
  { 
    "label": "Shetland Sheepdog", 
    "value": "Shetland Sheepdog" 
  }, 
  { 
    "label": "Shiba Inu", 
    "value": "Shiba Inu" 
  }, 
  { 
    "label": "Shichon", 
    "value": "Shichon" 
  }, 
  { 
    "label": "Shih-Poo", 
    "value": "Shih-Poo" 
  }, 
  { 
    "label": "Shih Tzu", 
    "value": "Shih Tzu" 
  }, 
  { 
    "label": "Shikoku", 
    "value": "Shikoku" 
  }, 
  { 
    "label": "Shiloh Shepherd", 
    "value": "Shiloh Shepherd" 
  }, 
  { 
    "label": "Shiranian", 
    "value": "Shiranian" 
  }, 
  { 
    "label": "Shollie", 
    "value": "Shollie" 
  }, 
  { 
    "label": "Shorkie", 
    "value": "Shorkie" 
  }, 
  { 
    "label": "Siberian Husky", 
    "value": "Siberian Husky" 
  }, 
  { 
    "label": "Silken Windhound", 
    "value": "Silken Windhound" 
  }, 
  { 
    "label": "Silky Terrier", 
    "value": "Silky Terrier" 
  }, 
  { 
    "label": "Skye Terrier", 
    "value": "Skye Terrier" 
  }, 
  { 
    "label": "Sloughi", 
    "value": "Sloughi" 
  }, 
  { 
    "label": "Small Munsterlander Pointer", 
    "value": "Small Munsterlander Pointer" 
  }, 
  { 
    "label": "Soft Coated Wheaten Terrier", 
    "value": "Soft Coated Wheaten Terrier" 
  }, 
  { 
    "label": "Spanish Mastiff", 
    "value": "Spanish Mastiff" 
  }, 
  { 
    "label": "Spinone Italiano", 
    "value": "Spinone Italiano" 
  }, 
  { 
    "label": "Springador", 
    "value": "Springador" 
  }, 
  { 
    "label": "Stabyhoun", 
    "value": "Stabyhoun" 
  }, 
  { 
    "label": "Staffordshire Bull Terrier", 
    "value": "Staffordshire Bull Terrier" 
  }, 
  { 
    "label": "Staffy Bull Bullmastiff", 
    "value": "Staffy Bull Bullmastiff" 
  }, 
  { 
    "label": "Standard Schnauzer", 
    "value": "Standard Schnauzer" 
  }, 
  { 
    "label": "Sussex Spaniel", 
    "value": "Sussex Spaniel" 
  }, 
  { 
    "label": "Swedish Lapphund", 
    "value": "Swedish Lapphund" 
  }, 
  { 
    "label": "Swedish Vallhund", 
    "value": "Swedish Vallhund" 
  }, 
  { 
    "label": "Taiwan Dog", 
    "value": "Taiwan Dog" 
  }, 
  { 
    "label": "Terripoo", 
    "value": "Terripoo" 
  }, 
  { 
    "label": "Texas Heeler", 
    "value": "Texas Heeler" 
  }, 
  { 
    "label": "Thai Ridgeback", 
    "value": "Thai Ridgeback" 
  }, 
  { 
    "label": "Tibetan Mastiff", 
    "value": "Tibetan Mastiff" 
  }, 
  { 
    "label": "Tibetan Spaniel", 
    "value": "Tibetan Spaniel" 
  }, 
  { 
    "label": "Tibetan Terrier", 
    "value": "Tibetan Terrier" 
  }, 
  { 
    "label": "Toy Fox Terrier", 
    "value": "Toy Fox Terrier" 
  }, 
  { 
    "label": "Transylvanian Hound", 
    "value": "Transylvanian Hound" 
  }, 
  { 
    "label": "Treeing Tennessee Brindle", 
    "value": "Treeing Tennessee Brindle" 
  }, 
  { 
    "label": "Treeing Walker Coonhound", 
    "value": "Treeing Walker Coonhound" 
  }, 
  { 
    "label": "Valley Bulldog", 
    "value": "Valley Bulldog" 
  }, 
  { 
    "label": "Vizsla", 
    "value": "Vizsla" 
  }, 
  { 
    "label": "Weimaraner", 
    "value": "Weimaraner" 
  }, 
  { 
    "label": "Welsh Springer Spaniel", 
    "value": "Welsh Springer Spaniel" 
  }, 
  { 
    "label": "Welsh Terrier", 
    "value": "Welsh Terrier" 
  }, 
  { 
    "label": "West Highland White Terrier", 
    "value": "West Highland White Terrier" 
  }, 
  { 
    "label": "Westiepoo", 
    "value": "Westiepoo" 
  }, 
  { 
    "label": "Whippet", 
    "value": "Whippet" 
  }, 
  { 
    "label": "Whoodle", 
    "value": "Whoodle" 
  }, 
  { 
    "label": "Wirehaired Pointing Griffon", 
    "value": "Wirehaired Pointing Griffon" 
  }, 
  { 
    "label": "Xoloitzcuintli", 
    "value": "Xoloitzcuintli" 
  }, 
  { 
    "label": "Yakutian Laika", 
    "value": "Yakutian Laika" 
  }, 
  { 
    "label": "Yorkipoo", 
    "value": "Yorkipoo" 
  }, 
  { 
    "label": "Yorkshire Terrier", 
    "value": "Yorkshire Terrier" 
  } 
] ;

// export const services = [
//   {
//     label: 'Aggressive Control',
//     value: 'Aggressive Control',
//   },
//   {
//     label: '1 Week Board and Train',
//     value: '1_week_board_and_train',
//   },
//   {
//     label: '2 Week Board and Train',
//     value: '2_week_board_and_train',
//   },
//   {
//     label: 'Reactive Board and Train',
//     value: 'reactive_board_and_train',
//   },
//   {
//     label: 'Basic Obedience Package 4 lessons',
//     value: 'basic_obedience_package_4_lessons',
//   },
//   {
//     label: 'Basic and Advanced Obedience Package 8 lessons',
//     value: 'basic_and_advanced_obedience_package_8_lessons',
//   },
//   {
//     label: 'Dog Aggression Lessons 8 lessons',
//     value: 'dog_aggression_lessons_8_lessons',
//   },
//   {
//     label: 'In-Home Basic Obedience Package 4 lessons',
//     value: 'in_home_basic_obedience_package_4_lessons',
//   },
//   {
//     label: 'In-Home Basic and Advanced Obedience Package 8 lessons',
//     value: 'in_home_basic_and_advanced_obedience_package_8_lessons',
//   },
//   {
//     label: 'In-Home Dog Aggression Lessons 8 lessons',
//     value: 'in_home_dog_aggression_lessons_8_lessons',
//   },
//   {
//     label: 'Puppy Consult 1 hour',
//     value: 'puppy_consult_1_hour',
//   },
//   {
//     label: 'Puppy Training Four lessons',
//     value: 'puppy_training_four_lessons',
//   },
//   {
//     label: 'Scent Work 4 lessons',
//     value: 'scent_work_4_lessons',
//   },
// ];

export const services = [
  {
    label: 'Dog Aggression Package',
    value: 'Dog Aggression Package',
  },
  {
    label: 'Puppy Consult',
    value: 'Puppy Consult',
  },
  {
    label: '1-Week Board&Train',
    value: '1-Week Board&Train',
  },
  {
    label: 'Basic Obedience Package',
    value: 'Basic Obedience Package',
  },
  {
    label: 'Basic + Advanced Lessons',
    value: 'Basic + Advanced Lessons',
  },
  {
    label: '2-Week Board & Train',
    value: '2-Week Board & Train',
  },
  {
    label: 'Reactivity Board and Train',
    value: 'Reactivity Board and Train',
  },
  {
    label: 'Advanced Lessons',
    value: 'Advanced Lessons',
  },
  {
    label: 'Puppy Training - 4 Lessons',
    value: 'Puppy Training - 4 Lessons',
  },
  {
    label: 'IN HOME Advanced Lessons',
    value: 'IN HOME Advanced Lessons',
  },
  {
    label: 'IN HOME Dog Aggression Lessons',
    value: 'IN HOME Dog Aggression Lessons',
  },
  {
    label: 'Scent Work',
    value: 'Scent Work',
  }
];

export const mediaList = [
  {
    url: Assets.BG,
    name: "Test"
  },
  {
    url: Assets.BG,
    name: "Test"
  },
  {
    url: Assets.BG,
    name: "Test"
  },
  {
    url: Assets.BG,
    name: "Test"
  },
  {
    url: Assets.BG,
    name: "Test"
  },
  {
    url: Assets.BG,
    name: "Test"
  },
  {
    url: Assets.BG,
    name: "Test"
  },
]

export const status = [
  {
    value: "not_started",
    title: "Yet To Start",
    color: "#F0F5FF",
    textColor: "#FFFFFF"
  },
  {
    value: "started",
    title: "In Progress",
    color: "#F16722",
    textColor: "#FFFFFF"
  },
  {
    value: "completed",
    title: "Completed",
    textColor: "#FFFFFF",
    color: "#1F8E3E"
  },
]