import { testDispatch, signupDispatch, loginDispatch } from '../utils/redux.utils';
import instance from '../utils/axios.utils';

const Alert = {
  editAlert: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'alert/edit_alert';
      instance()
        .put(url, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data.message);
          } else if (error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },

  viewAlert: () => {
    let promise = new Promise((resolve, reject) => {
      let url = 'alert/view_alert';
      instance()
        .get(url)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if (error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },
};
export default Alert;
