import React, { useEffect } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { Component, Models, PlanCard } from 'utils/imports.utils';
import '../../common_components/ui/payment_form/payment_form.ui.scss';
import Functions, { getAmount, useSetState } from 'utils/functions.utils';
import { getUserDetails } from 'utils/redux.utils';
import { useSelector } from 'react-redux';

const SubscriptionForm = (props) => {
  useEffect(() => {
    getProducts();
    getUser();
    getUserList();
  }, []);
  const stripe: any = useStripe();
  const elements: any = useElements();
  const user = useSelector((store: any) => store.user.user);

  const [state, setState] = useSetState({
    user: user,
    price: 0,
    amount: 0,
    product_list: [],
    plan_data: [],
    id: '',
    totalUserCount: 0,
  });

  const getUser = async () => {
    try {
      const user: any = await Models.auth.getUserByToken();
      localStorage.setItem(
        'subscribed',
        user.data.org_details.subscribed.toString(),
      );
      getUserDetails(user.data);
      setState({ loading: false, user });
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get user details');
      }
    }
  };

  const getUserList = async () => {
    try {
      let req = {
        page: 1,
        limit: 1000,
      };
      const res: any = await Models.admin.userList(req);
      setState({ totalUserCount: res.data.totalDocs });
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleSubmit = async (event) => {
    try {
      setState({ activity: true });
      event.preventDefault();
      const res = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: user.username,
          email: user.decrypted_email,
        },
      });
      let payment = {
        payment_method: res.paymentMethod,
        price: state.price,
        amount: state.amount,
      };
      let body = {
        user: {
          email: user.decrypted_email,
          user_id: user._id,
          username: user.username,
        },
        payment,
      };
      if (res?.error?.message) {
        Functions.Failure(res.error.message);
      } else {
        const response: any = await Models.subscription.addSub(body);
        if (response.data) {
          if (response.data.subscription.status === 'trialing') {
            await handelPaymentSuccess();
          } else if (
            response.data.subscription?.latest_invoice?.payment_intent
              ?.status === 'requires_action'
          ) {
            const { paymentIntent, error } = await stripe.confirmCardPayment(
              response.data.subscription.latest_invoice.payment_intent
                .client_secret,
            );
            if (error) {
              Functions.Failure('Payment failed');
            }
            if (paymentIntent && paymentIntent?.status === 'succeeded') {
              await handelPaymentSuccess();
              // await addSubscription({price:props.data.price, customer_id:response.data.subscription.customer,user:response.data.user});
            }
          } else if (
            response.data.subscription.latest_invoice.payment_intent.status ===
            'requires_payment_method'
          ) {
            Functions.Failure('Payment failed');
          } else if (
            response.data.subscription.latest_invoice.payment_intent.status ===
            'succeeded'
          ) {
            await handelPaymentSuccess();
          } else {
            Functions.Failure('Payment failed');
          }
        }
      }

      setState({ activity: false });
    } catch (error) {
      setState({ activity: false });
      Functions.Failure(error);
      console.log('error', error);
    }
  };

  const handelPaymentSuccess = () => {
    localStorage.setItem('subscribed', 'true');
    window.location.href = '/Dashboard';
  };

  const CARD_OPTIONS = {
    style: {
      base: {
        iconColor: 'black',
        backgroundColor: 'transparent',
        margin: 20,
        color: 'black',
        fontWeight: 400,
        fontFamily: 'Poppins',
        fontSize: '16px',
        borderRadius: '30px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#fce883',
        },
        '::placeholder': {
          color: '#8e8e93',
        },
      },

      invalid: {
        iconColor: 'red',
      },
    },
    showIcon: true,
  };

  const getProducts = async () => {
    try {
      const res: any = await Models.subscription.getProductList();
      await getPriceList(res.data);
    } catch (error) {
      console.log('error', JSON.stringify(error));
    }
  };

  const getPriceList = async (data?: any) => {
    try {
      const res: any = await Models.subscription.getPriceAmount();
      data.map((item: any) => {
        res.data.map((price: any) => {
          if (item.id === price.product) {
            item.price = price.unit_amount;
            item.recurring = price.recurring;
          }
        });
        return item;
      });
      setState({ product_list: data });
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <div className="payment_form_screen">
      <div className="payment_form_container">
        <div className="payment_form_wrapper">
          <div className="plans_container">
            <div className="plans_heading">Choose Plans</div>
            <div className="plans_wrapper">
              {!state.loading &&
                state.product_list.map((data) => (
                  <PlanCard
                    plan={data.name}
                    amount={data.price / 100}
                    duration={`Per ${data.recurring.interval}`}
                    selected={state.id === data.id ? true : false}
                    onClick={() => {
                      setState({
                        price: data.default_price,
                        amount: data.price,
                        plan_data: data,
                        id: data.id,
                      });
                    }}
                  />
                ))}
            </div>
          </div>
          <div className="payment_header">
            <div className="payment_header_text">Payment</div>
          </div>

          <div className="card_number_container">
            <div className="card_number_title">Card Number</div>
            <div className="card_number_wrapper">
              <CardNumberElement options={CARD_OPTIONS} />
            </div>
          </div>
          <div className="expiry_cvv_container">
            <div className="expiry_container">
              <div className="expiry_title">Expiration Date</div>
              <div className="expiry_wrapper">
                <CardExpiryElement options={CARD_OPTIONS} />
              </div>
            </div>
            <div className="cvv_container">
              <div className="cvv_title">CVV</div>
              <div className="cvv_wrapper">
                <CardCvcElement options={CARD_OPTIONS} />
              </div>
            </div>
          </div>
          <div className="pay_button_container">
            <Component.Button
              text={'Submit'}
              type="submit"
              onClick={handleSubmit}
              disabled={false}
              activity={state.activity}
            />
          </div>
        </div>
      </div>
      <div className="order_summary_container">
        <div className="order_summary_wrapper">
          <div className="order_summary_header">
            <div className="order_summary_header_text">Order Summary</div>
          </div>
          <div className="plan_type_container">
            <div className="plan_type_wrapper">
              <div className="plan_type">{state.plan_data.name}</div>
              <div className="plan_value">${state.amount / 100}</div>
            </div>
            <div className="plan_type_bottom_text">
              Plan for {state.totalUserCount} User
            </div>
          </div>
          <div className="total_container">
            <div className="total_wrapper">
              <div className="total_text">Total</div>
              <div className="total_bottom_text">Including taxes</div>
            </div>
            <div className="total_value">
              ${(state.amount / 100) * state.totalUserCount}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SubscriptionForm;
