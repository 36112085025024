import Functions, { Alert } from './functions.utils';
import Assets from 'imports/assets.import';
import Component from 'imports/components.import';
import * as Constants from './constant.utils';
import Models from 'imports/models.import';
import * as Validation from 'utils/validation.utils';
import Colors from './theme.utils';

import { Ratio, StyleSheet } from './functions.utils';

import NavButton from 'common_components/ui/nav_button/nav_button.ui';
import InfoDetail from 'components/info_detail/info_detail.component';
import HomeCard from 'components/home_card/home_card.component';
import PetCard from 'components/pet_card/pet_card.component';
import UserCard from 'components/user_card/user_card.component';
import MediaView from 'components/media_view/media_view.component';
import CustomerPetCard from 'components/customer_pet/customer_pet.component';
import ManageCard from 'components/manage_card/manage_card.component';
import FilterModal from 'components/filter_modal/filter_modal.comp';
import Notify from 'components/notification/notification.component';
import SettingsCard from 'components/settings_card/settings_card.component';
import Acuity from 'components/acuity_card/acuity_card.component';
import Conversation from 'components/conversation/conversation.component';
import MessageView from 'components/message/message.component';

import ValidationError from 'common_components/ui/error/error.ui';
import Image from 'common_components/ui/image/image.ui';
import View from 'common_components/ui/view/view.ui';
import FlatList from 'common_components/ui/flat_list/flat_list.ui';
import Text from 'common_components/ui/text/text.ui';
import Container from 'common_components/ui/container/container.ui';
import Modal from 'common_components/ui/modal/modal.ui';
import Input from 'common_components/ui/input/input.ui';
import PrimaryButton from 'common_components/ui/primary_button/primary_button.ui';
import VideoPlayer from 'common_components/ui/video_player/video_player.ui';
import ActivityIndicator from 'common_components/ui/activity_indicator/activity_indicator.ui';
import FileInput from 'common_components/ui/file_input/file_input.ui'
import Select from 'common_components/ui/select/select.ui';
import OtpInput from 'common_components/ui/otp_input/otp_input.ui';
import Loading from 'common_components/ui/loading/loading.ui';

import useDebounceEffect from 'common_components/hooks/use_debounce_effect';
import user from 'models/auth.model';
import EditPop from 'components/edit_modal/edit_modal.comp'
import PaymentForm from 'common_components/ui/payment_form/payment_form.ui';
import SignUpComponent from 'common_components/ui/signup_form/signup.ui';
import PlanCard from 'components/plan_card/plan_card.component';
import TaskCard from 'common_components/ui/task/task.ui';
import ServiceCard from 'components/service_card/service_card.component';

// import Swiper from 'common_components/ui/swiper/swiper.ui'


// import Interface from 'interfaces/common.interface'

let role = localStorage.getItem('role');
let user_type = localStorage.getItem('user_type');

const Tabs = [
  {
    title: "Dashboard",
    route: "Dashboard",
    users: ["super_admin"],
    activeIcon: Assets.dashboard_active,
    inActiveIcon: Assets.dashboard_inactive,
  },
  {
    title: "Home",
    route: user_type == "trainer" ? "trainer_home" : user_type == "admin" ? "home" : "customer_home" ,
    users: ["admin", "trainer" , "customer"],
    activeIcon: Assets.Home,
    inActiveIcon: Assets.HomeLight,
  },
  {
    title: "Schedule",
    route: "TrainingDetails",
    users: ["admin"],
    activeIcon: Assets.CalendarWhite,
    inActiveIcon: Assets.CalendarBlack,
  },
  {
    title: "Chat",
    route: "chat",
    users: [ "customer", "trainer" , "admin"],
    activeIcon: Assets.MessageWhite,
    inActiveIcon: Assets.MessageUnClick,
  },
  {
    title: "Manage",
    route: "manage",
    users: ["admin", "super_admin" , "org_admin" , "trainer"],
    activeIcon: Assets.CricleWhite,
    inActiveIcon: Assets.CricleBlack,
  },
  {
    title: "Settings",
    route: "Settings",
    users: ["admin","org_admin", "customer", "trainer" , "super_admin"],
    activeIcon: Assets.Tune,
    inActiveIcon: Assets.TuneBlue,
  },
]

export {
  Functions,
  Assets,
  Component,
  Constants,
  Models,
  ValidationError,
  Validation,
  Tabs,
  Colors,
  NavButton,
  Ratio,
  Image,
  View,
  View as TouchableOpacity,
  FlatList,
  View as ScrollView,
  View as LinearGradient,
  Text,
  StyleSheet,
  Container,
  ActivityIndicator,
  Alert,
  Modal,
  PrimaryButton,
  Input,
  FileInput,
  Select,
  Loading,
  // Swiper,

  //Components
  HomeCard,
  PetCard,
  InfoDetail,
  CustomerPetCard,
  VideoPlayer,
  UserCard,
  MediaView,
  ManageCard,
  FilterModal,
  Notify,
  SettingsCard,
  Acuity,
  OtpInput,
  Conversation,
  MessageView,
  //Hooks
  useDebounceEffect,
  EditPop,
  PaymentForm,
  SignUpComponent,
  PlanCard,
  TaskCard,
  ServiceCard,
}

// export const Functions = Function
// export const Assets = Asset
// export const Component = CommonComponent
// export const Models = Model
// export const ValidationError = Validation;
// export const Validator = Validators;
// export const Tabs = tabs;
// export const Colors = Color;
// export const NavButton = Navbutton;

// export default imports