import { testDispatch } from '../utils/redux.utils';
import instance from "../utils/axios.utils"

const test = {
  testRequest: (id: string) => {
    let promise = new Promise((resolve, reject)=>{
      let url = "user/public_profile_details"
      let body = {
        id: id
      }
      instance().post(url, body).then(res=>{
        testDispatch(res.data.data)
        resolve(res.data)
        console.log(res.data)
      }).catch(error=>{
        if (error.response) {
          console.log(error.response);
          reject(error.response.data.message);
        } else if(error.message) {
          console.log(error.message);
          reject(error.message);
        } else {
          console.log(error);
          reject(error);
        }
      })
    })
    return promise
  }

}

export default test