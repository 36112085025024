import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { View, StyleSheet, FlatList, TouchableOpacity, ActivityIndicator, Text, Colors, Assets, Container, Constants, NavButton, Ratio, Functions, Models, Notify } from 'utils/imports.utils';
import { getChat, getNotificationList } from 'utils/redux.utils';

interface pagination {
  limit: number;
  page: number;
}

const Notification = (props: any) => {

  const notification = useSelector((state: any) => state.notification.notificationList);
  console.log("notification", notification)
  const [state, setState] = Functions.useSetState({
    loading: true,
    limit: 20,
    page: 1,
    loadMoreLoader: false,
    hasMore: false
  })

  useEffect(() => {
    notificationList({
      limit: state.limit,
      page: state.page
    })
    updateManyNotifications();
  }, [])

  const backAction = () => {
    props.navigation.goBack();
    return true;
  }

  const updateManyNotifications = async () => {
    await Models.notification.updateManyNotification()
  }

  const notificationList = async ({ limit, page }: pagination) => {
    try {
      console.log("notification fetchedddd")
      const notificationData: any = await Models.notification.nofificationList({ limit, page });
      const data = [...notification, ...notificationData.data.docs];
      getNotificationList(data)
      setState({ loading: false, loadMoreLoader: false, hasMore: notificationData.data.hasNextPage, page });
    } catch(err) {
      console.log('err', err);
    }
  }

  const loader = () => {
    return (
      <View>
        {state.loadMoreLoader &&
          <ActivityIndicator size="small" color={Colors.primaryDarkColor} style={{ marginLeft: 6 }} />}
      </View>
    )
  }
  const loadMore = () => {
    if (state.hasMore) {
      setState({ loadMoreLoader: true });
      notificationList({ limit: state.limit, page: state.page + 1 })
    }
  };

  const updateNotification = async (item: any) => {
    try {
      if(item.redirect_to){
        if(item.redirect_to === "Message"){
          let response:any = await Models.chat.conversation(item.data);
          getChat(response.data);
        }
        props.navigation.navigate(item.redirect_to);
      }
    } catch(err) {
      console.log('err', err);
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure("Failed to update notification");
      }
    }
  }

  return (
    <Container screen loading={state.loading} styles={{ height: "100%" }}>
      <View style={styles.notificationContainer}>
        <View style={styles.headerWrapper}>
          <NavButton
            icon={Assets.ArrowBack}
            style={{
              backgroundColor: "transparent",
              justifyContent: "flex-start",
              flexDirection: "row"
            }}
            svg
            iconHeight={Ratio(25)}
            iconWidth={Ratio(25)}
            onClick={() => props.navigation.goBack()}
          />
          <View style={styles.marginLeft}>
            <Text
              size={Ratio(24)}
              family={Constants.light}
              color={Colors.primaryDarkColor}>
                Notification
            </Text>
          </View>
        </View>
        {notification.length > 0 ?
          <View style={styles.notificationList}>
            <FlatList
              data={notification}
              showsVerticalScrollIndicator={false}
              keyExtractor={(item: any) => item.name}
              renderItem={({ item, index }) => (
                <View style={{ width: '49%', marginBottom: Ratio(15)}}>
                  <Notify
                    username={item.title}
                    profile={item.from?.profile}
                    last_message={{ text: item.body }}
                    last_update={item.created_at}
                    notification
                    seen={item.seen}
                    onClick={() => updateNotification(item)}
                    // unreadCount={10}
                  />
                </View>
              )}
              onEndReached={loadMore}
              scrollEnabled={true}
              ListFooterComponent={loader()}
              initialScrollIndex={-1}
            />
          </View>
        : <View style={styles.emptyContainer}>
          <Text color={Colors.secondaryLightText} size={14}>There are no Notifications to display</Text>
        </View>}
      </View>
    </Container>
  );
};

const styles = StyleSheet.create({
  notificationContainer: {
    width: '100%',
    height: '100%',
    // backgroundColor: 'green',
  },
  headerWrapper: {
    flexDirection: "row",
    alignItems: "center",
    height: "10%",
    padding: '0px 20px'
  },
  marginLeft: {
    marginLeft: Ratio(5)
  },
  notificationList: {
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    flexDirection: "row",
    // height: "90%",
    // backgroundColor: "red",
    padding: '0px 20px',
    marginTop: 20
  },
  emptyContainer: {
    width: "100%",
    paddingTop: Ratio(20),
    justifyContent: "center",
    alignItems: "center"
  },
});

export default Notification;
