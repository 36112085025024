import {
  testDispatch,
  signupDispatch,
  loginDispatch
} from '../utils/redux.utils';
import instance from '../utils/axios.utils';

const pet = {
  createPet: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'pet/add_pet';
      instance()
        .post(url, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },

  getPetById: (id: string) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'pet/get_pet/'+id;
      instance()
        .get(url)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },
  petList: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'pet/pet_list';
      instance()
        .post(url, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },

  editPet: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'pet/edit_pet';
      instance()
        .put(url, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },

  deletePet: (id: string) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'pet/delete_pet/'+id;
      instance()
        .post(url)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },
};

export default pet;
