import test from 'models/test.model';
import auth from 'models/auth.model';
import dashboard from 'models/dashboard.model';
import pet from 'models/pet.model';
import admin from 'models/admin.model';
import user from 'models/user.model';
import training from 'models/training.model';
import media from 'models/media.model';
import chat from 'models/chat.model';
import notification from 'models/notification.model';
import acuity from 'models/acuity.model';
import call from 'models/call.model';
import org from 'models/org.model'
import branch from 'models/branch.model'
import alert from 'models/alert.model';
import subscription from 'models/subscription.model';
import services from 'models/services.model';

export const Models = {
  test,
  auth,
  dashboard,
  pet,
  admin,
  user,
  training,
  media,
  chat,
  notification,
  acuity,
  call,
  org,
  branch,
  alert,
  subscription,
  services,
}

export default Models