import { CALL_LIST } from 'utils/types.utils';
import { storeAction } from 'interfaces/common.interface';

const initialState = {
  call_list: [],
};

const CallReducer = (state = initialState, action: storeAction) => {
  switch (action.type) {
    case CALL_LIST:
      return { ...state, call_list: action.payload };
    default:
      return state;
  }
};

export default CallReducer;
