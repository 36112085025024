import { MEDIA_LIST, REMOVE_MEDIA } from 'utils/types.utils';
import { storeAction } from 'interfaces/common.interface';

const initialState = {
  mediaList: []
};

const AuthReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case MEDIA_LIST:
      let newMedia: any = [...action.payload];
      state.mediaList = newMedia;
      return state;
    case REMOVE_MEDIA:
      state.mediaList = [];
      return state;
    default:
      return state;
  }
};

export default AuthReducer;
