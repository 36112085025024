import React from 'react';
import { Assets } from 'utils/imports.utils';
import './button.ui.scss';

interface IButton {
  text: string;
  onClick?: any;
  className?: string;
  type?: any;
  width?: any;
  height?: any;
  activity?: boolean;
  button_classname?: string;
  disabled?: boolean;
}

const Button = (props: IButton) => {
  const { text, onClick, className, type, width, height, activity , button_classname, disabled} = props;
  return (
    <div className={`button_component ${props.button_classname}`} style={{ width: width, height: height }}>
      {activity ? (
        <div className="activity_container">
          <img src={Assets.LightSpinner} alt="activity_button_spinner" className="spinner_img"/>
        </div>
      ) : (
        <button
          className={`${disabled && "button_component_disabled"} button p4 ${props.className}`}
          onClick={onClick}
          disabled={disabled ? disabled: false}
          type={type || 'button'}>
          {text}
        </button>
      )}
    </div>
  );
};

export default Button;
