import { ACUITY_DETAIL, ACUITY_LIST } from 'utils/types.utils';
import { storeAction } from 'interfaces/common.interface';

const initialState = {
  acuityList: [],
  acuity: {}
};

const AcuityReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ACUITY_DETAIL:
      let acuity: any = {...action.payload};
      state.acuity = acuity;
      return state;
    case ACUITY_LIST:
      let acuityList: any = [...action.payload];
      state.acuityList = acuityList;
      return state;
    default:
      return state;
  }
};

export default AcuityReducer;
