import React, { useEffect } from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  FlatList,
  Text,
  Container,
  Ratio,
  Colors,
  Functions,
  Image,
  Assets,
  Input,
} from 'utils/imports.utils';
import _, { get } from 'lodash';

interface selectProps {
  data: any[];
  onSelect: any;
  selected?: any;
  placeHolder?: String;
  label?: string;
  labelValue: any;
  keyValue: any;
  keyType?: any;
  search?: boolean;
  onSearch?: any;
  disabled?: boolean;
  optionDisable?: boolean;
  optionClick?: any;
  optionText?: any;
  onOpenSelect?: any;
  emptyMessage?: any;
  labelStyle?: any;
}

const Select = (props: selectProps) => {
  const [state, setState] = Functions.useSetState({ open: false });
  const {
    data,
    onSelect,
    selected,
    placeHolder,
    label,
    labelValue,
    keyValue,
    keyType,
    search,
    onSearch,
    disabled,
    optionText,
    optionDisable,
    optionClick,
    onOpenSelect,
    emptyMessage,
    labelStyle
  } = props;

  const openSelect = () => {
    if (search) {
      onOpenSelect(state.open ? false : true);
    }
    setState({ open: !state.open });
  };

  const getHeight = (data: any) => {
    let height = data.length * 50;

    if (height > 200) {
      return (height = 200);
    } else {
      return height;
    }
  };

  return (
    <View styles={styles.selectContainer}>
      <View style={[styles.labelContainer, labelStyle]}>
        {label && (
          <Text
            size={Ratio(14)}
            color={state.open ? Colors.primaryDarkColor : Colors.primaryLightText}>
            {label}
          </Text>
        )}
        {optionText && (
          <TouchableOpacity
            disabled={optionDisable}
            style={{ paddingLeft: Ratio(5), width: '30%' }}
            onPress={optionClick}>
            <Text color={Colors.secondaryDarkColor} size={Ratio(14)}>
              {optionText || 'Add Client'}
            </Text>
          </TouchableOpacity>
        )}
      </View>
      <View style={[styles.selectWrapper, { maxHeight: state.open ? 250 : 50, minHeight: 50 }]}>
        <TouchableOpacity
          activeOpacity={0.7}
          style={styles.select}
          disabled={disabled}
          onPress={() => openSelect()}>
          {state.open && search ? (
            <View style={styles.inputContainer}>
              <Input
                name="search"
                placeholder="Search"
                iconPosition="end"
                source={Assets.ArrowUp}
                iconHeight={Ratio(20)}
                iconWidth={Ratio(20)}
                onIconClick={openSelect}
                onChange={onSearch}
              />
            </View>
          ) : (
            <View style={styles.selectContent}>
              <View style={styles.placeHolderWrapper}>
                <Text
                  size={Ratio(16)}
                  weight="400"
                  ellipsizeMode="tail"
                  numberOfLines={1}
                  color={
                    _.find(data, { [keyValue]: selected })
                      ? Colors.primaryDarkColor
                      : Colors.primaryLightText
                  }>
                  {selected && selected.length > 0 && _.find(data, { [keyValue]: selected })
                    ? _.find(data, { [keyValue]: selected })[labelValue]
                    : placeHolder
                    ? placeHolder
                    : 'Select'}
                </Text>
              </View>
              <View style={styles.iconContainer}>
                <Image
                  src={state.open ? Assets.ArrowUp : Assets.ArrowDown}
                  svg
                  height={Ratio(20)}
                  width={Ratio(20)}
                />
              </View>
            </View>
          )}
        </TouchableOpacity>
        {state.open && (
          <View>
            {data.length > 0 ? (
              <View style={[styles.selectList, { height: getHeight(data) }]}>
                <FlatList
                  data={data}
                  style={[styles.flatList]}
                  nestedScrollEnabled
                  showsVerticalScrollIndicator={false}
                  renderItem={({ item, index }) => (
                    <TouchableOpacity
                      style={[
                        styles.itemContent,
                        index === data.length - 1 && { borderBottomWidth: 0 },
                      ]}
                      activeOpacity={0.7}
                      onPress={() => {
                        openSelect();
                        onSelect(item[keyValue], item[keyType]);
                      }}>
                      <View style={{ width: '100%' }}>
                        <Text
                          size={Ratio(16)}
                          color={Colors.primaryDarkText}
                          styles={{ width: '100%'}}>
                          {item[labelValue]}
                        </Text>
                      </View>
                    </TouchableOpacity>
                  )}
                  keyExtractor={item => item.id}
                />
              </View>
            ) : (
              <View style={[styles.selectList, { padding: Ratio(15) }]}>
                <Text color={Colors.primaryLightText}>{emptyMessage}</Text>
              </View>
            )}
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  selectContainer: {
    width: '100%',
  },
  labelContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: Ratio(15),
    paddingBottom: Ratio(5),
    width: '100%',
  },
  selectWrapper: {
    marginVertical: Ratio(8),
    backgroundColor: Colors.inputBackground,
    borderRadius: 10,
    justifyContent: 'flex-start',
    borderWidth: Ratio(1),
    borderColor: 'transparent',
    width: '100%',
  },
  select: {
    flexDirection: 'column',
    justifyContent: 'center',
    // paddingTop: 12,
    alignItems: 'center',
    width: '100%',
    height: 50,
    // elevation: (Platform.OS === 'android') ? 0 : 50
  },
  iconContainer: {
    width: '8%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemContent: {
    justifyContent: 'center',
    width: '100%',
    height: 50,
    padding: "0px 18px",
    borderBottomColor: Colors.background,
    borderBottomWidth: Ratio(1),
  },
  selectList: {
    width: '100%',
    // position: "absolute",
    // top: Ratio(55),
    // left: 0,
    backgroundColor: Colors.inputBackground,
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 2,
    // },
    // shadowOpacity: 0.20,
    // shadowRadius: 6.27,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    borderWidth: Ratio(1),
    borderColor: Colors.outline,
    overflow: 'scroll',
  },
  flatList: {
    flexDirection: 'column',
    // maxHeight: Ratio(200),
    width: '100%',
    height: 500,
  },
  shadowProp: {
    shadowColor: '#171717',
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
  selectedWrapper: {
    borderWidth: Ratio(1),
    backgroundColor: Colors.background,
    borderColor: Colors.outline,
  },
  selectContent: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: "0px 18px",
  },
  placeHolderWrapper: {
    width: '92%',
  },
  inputContainer: {
    width: '100%',
    position: 'relative',
  },
  flatListContent: {
    backgroundColor: 'blue',
    // marginTop: Ratio(30)
  },
});

export default Select;
