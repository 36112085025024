import React, { useEffect  } from 'react';
import { useSelector } from 'react-redux';
import { Alert, Ratio, useSetState } from 'utils/functions.utils';
import {
  Assets,
  Colors,
  Constants,
  Container,
  Functions,
  Image,
  Input,
  Models,
  NavButton,
  PrimaryButton,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';

interface paginationProps {
  page: number;
  limit: number;
  search: string;
}

const AddServices = (props: any) => {
  // Redux
  const testState = useSelector((state: any) => state.test);
  const user = useSelector((state: any) => state.user.user);

  // State
  const [state, setState] = useSetState({
    price: '',
    duration: '',
    name: '',
    loading: false,
    activity: false,
    serviceId: '',
    confirmation: false,
  });

  //Hooks
  useEffect(() => {
    if (props.route.params.view) {
      console.log('qwerty');
      getService(props.route.params.serviceId);
    }
  }, []);

  // Network req

  const addService = async () => {
    try {
      setState({ activity: true });
      let req = {
        name: state.name,
        price: state.price,
        duration: state.duration,
      };
      const res: any = await Models.services.createServices(req);
      setState({ activity: false });
      props.navigation.goBack();
      console.log('req', req);
    } catch (error) {
      console.log('error', error);
      Functions.Failure('Failed add service');
      setState({ activity: false });
    }
  };

  const getService = async (_id: any) => {
    try {
      const res: any = await Models.services.viewServices(_id);
      setState({
        name: res.data.name,
        price: res.data.price,
        duration: res.data.duration,
        serviceId: res.data._id,
      });
      console.log('res', res);
    } catch (error) {
      console.log('error', error);
    }
  };

  const editService = async (_id: any) => {
    try {
      setState({ activity: true });
      let req = {
        services_id: state.serviceId,
        price: state.price,
        duration: state.duration,
        name: state.name,
      };
      const res = await Models.services.editServices(req);
      setState({ activity: false });
    } catch (error) {
      console.log('error', error);
      Functions.Failure('Failed to edit service');
      setState({ activity: false });
    }
  };

  const deleteService = async () => {
    try {
      const res = await Models.services.deleteServices(state.serviceId);
      props.navigation.goBack();
    } catch (error) {
      console.log('error', error);
      props.navigation.goBack();
    }
  };

  const getConfirmation = () => {
    Alert.alert('Want to delete Service?', '', [
      {
        text: 'Yes',
        onPress: () => deleteService(),
      },
      { text: 'No', onPress: () => console.log('OK Pressed') },
    ]);
  };

  //Logic
  const testLogic = () => {};

  return (
    <Container screen loading={state.loading}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={styles.addServiceContainer}>
          <View style={styles.headerWrapper}>
            <View style={styles.textWrapper}>
              <View>
                <TouchableOpacity
                  style={{
                    width: Ratio(40),
                    height: Ratio(40),
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  activeOpacity={0.7}
                  onPress={() => {
                    props.navigation.goBack();
                  }}>
                  <Image svg src={Assets.ArrowBack} width={20} height={20} />
                </TouchableOpacity>
              </View>
              <View style={styles.headTextWrapper}>
                <Text
                  top={5}
                  size={24}
                  color={Colors.primaryDarkText}
                  family={Constants.light}
                  weight="300">
                  {props.route.params.view ? 'Edit Service' : 'Add Service'}
                </Text>
              </View>
            </View>
            <View>
              {props.route.params.view && (
                <NavButton
                  icon={Assets.Delete}
                  style={{ backgroundColor: Colors.secondaryBackground }}
                  svg
                  onClick={() => getConfirmation()}
                  iconHeight={Ratio(25)}
                  iconWidth={Ratio(25)}
                />
              )}
            </View>
          </View>
          <View style={styles.addServiceFormContainer}>
            <View style={styles.addServiceIconContainer}>
              <View style={styles.profileImage}>
                <Image
                  src={Assets.services}
                  height="100%"
                  width="100%"
                  svg
                  resize="cover"
                  radius={30}
                />
              </View>
            </View>
            <View style={styles.formBody}>
              <View style={styles.bodyContainer}>
                <Input
                  label="Service Name *"
                  name="service name"
                  value={state.name}
                  onChange={(name: string) => setState({ name })}
                  placeholder="Service name"
                />
                <Input
                  label="Price"
                  name="price"
                  onChange={(price: string) => setState({ price })}
                  placeholder="Price"
                  type={'number'}
                  value={state.price}
                />
                <Input
                  label="Duration"
                  name="duration"
                  onChange={(duration: string) => setState({ duration })}
                  type={'number'}
                  placeholder="10 days"
                  value={state.duration}
                />
              </View>
            </View>
            <View style={styles.addServiceButtonContainer}>
              <PrimaryButton
                text="Save"
                onPress={props.route.params.view ? editService : addService}
                width={'200px'}
                activeOpacity={0.7}
                backgroundColor={Colors.primaryDarkColor}
                activity={state.activity}
              />
            </View>
          </View>
        </View>
      </ScrollView>
    </Container>
  );
};

const styles = StyleSheet.create({
  addServiceContainer: {
    padding: Ratio(20),
    marginBottom: Ratio(50),
    width: '100%',
  },
  headerWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  headTextWrapper: {
    paddingLeft: Ratio(10),
  },
  textWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  addServiceFormContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    marginTop: Ratio(60),
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
  addServiceIconContainer: {
    position: 'absolute',
    width: '100%',
    zIndex: 1,
    alignItems: 'center',
    top: -55,
  },
  profileImage: {
    width: Ratio(100),
    height: Ratio(100),
  },
  formBody: {
    width: '60%',
    backgroundColor: Colors.buttonTextColor,
    borderRadius: 32,
    shadowColor: '#DDE5EE',
    shadowOffset: { width: 4, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    paddingBottom: 20,
  },
  bodyContainer: {
    padding: '50px 20px 20px 20px',
    width: '100%',
  },
  addServiceButtonContainer: {
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
});

export default AddServices;
