import {
  testDispatch,
  signupDispatch,
  loginDispatch
} from '../utils/redux.utils';
import instance from '../utils/axios.utils';

const dashboard = {
  manageCardDetails: (body: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'dashboard/manage_details';
      instance()
        .post(url, body)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },

  trainingDetails: () => {
    let promise = new Promise((resolve, reject) => {
      let url = 'dashboard/trainings_details';
      instance()
        .post(url)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },
  adminDashboard: (body:any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'dashboard/admin_dashboard';
      instance()
        .post(url, body)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },
};

export default dashboard;
