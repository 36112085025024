import React from 'react'
import { Image, StyleSheet, View } from 'utils/imports.utils';

function FileInput(props) {
  const { id, className, src, onChange, iconStyle, style } = props
  return (
    <View style={{...styles.fileInputContainer, ...style}}>
      <label style={styles.fileInputLabel} htmlFor={id}>
          <Image src={src} style={iconStyle} alt="upload" />
      </label>
      <input type="file" style={styles.input} id={id} className={className} accept="image/x-png,image/gif,image/jpeg,video,.pdf,.doc,.docs,.dox/*" onChange={onChange} />
    </View>
  )
}

const styles = StyleSheet.create({
  fileInputContainer:{
    justifyContent: 'center',
    alignItems: 'center'
  },
  fileInputLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  input: {
    display: 'none'
  }
});

export default FileInput;