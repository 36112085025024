import React from 'react';
import moment from 'moment';
import {
  StyleSheet,
  View,
  Colors,
  Constants,
  Container,
  Image,
  Ratio,
  Text,
  Assets,
  NavButton,
} from 'utils/imports.utils';

interface conversationProps {
  profile?: string;
  username: string;
  user_type: string;
  user_id?: string;
  user: userProps;
  last_message?: any;
  last_update?: string;
  onClick?: any;
  url?: any;
  unreadCount?: number;
  message_type?: string;
  notification?: boolean;
  seen?: boolean;
  members: Array<memberProps>;
}

interface userProps  {
  call_allowed?: boolean;
  call_limited?: boolean;
  limited_hours?: object;
  phone?: string;
  profile?: string;
  user_type: string;
  username: string;
  _id: string;
}

interface memberProps {
  user?: userProps;
  created_at?: string;
}

const Conversation = (props: conversationProps) => {
  const {
    profile,
    last_message,
    last_update,
    user_id,
    user,
    user_type,
    username,
    url,
    unreadCount,
    message_type,
    notification,
    seen,
    members
  } = props;


  const isUserChat = () => {
    let memberExist = false;
    memberExist = members.some((member: memberProps) => member?.user?._id === user._id);
    return memberExist;
  }

  const constructName = (members: Array<memberProps>) => {
    let name = members[0]?.user?.username
    if(members[1]){
      name += ` & ${members[1]?.user?.username}`
    }
    return name;
  }
  const getUserType = (user_type: string) => {
    if (user_type === 'trainer') {
      return Assets.WistleWhite;
    } else if (user_type === 'customer') {
      return Assets.petFeet;
    } else if (user_type === 'admin') {
      return Assets.UserWhite;
    } else if (user_type === 'groups') {
      return Assets.GroupUserWhite;
    } else {
      return Assets.UserWhite;
    }
  };
  return (
    <Container styles={styles.conversationContainer}>
      <View style={styles.profileContainer}>
        {profile ? (
          <View style={{ position: 'relative' }}>
            <Image
              src={profile}
              url
              width={Ratio(66)}
              resize="cover"
              radius={20}
              height={Ratio(66)}
            />
            {user_type && (
              <View style={styles.userDifferent}>
                {/* <Image src={user_type === 'trainer' ? Assets.WhistlePrimary : Assets.petFeet} svg width={Ratio(25)} height={Ratio(25)} /> */}
                <NavButton
                  icon={getUserType(user_type)}
                  svg
                  disabled
                  iconHeight={Ratio(10)}
                  iconWidth={Ratio(10)}
                  style={{
                    width: Ratio(15),
                    height: Ratio(15),
                    backgroundColor: Colors.secondaryDarkColor,
                    borderRadius: 20,
                  }}
                />
              </View>
            )}
          </View>
        ) : (
          <View style={{ position: 'relative' }}>
            <NavButton
              icon={Assets.SingleUserPrimary}
              svg
              disabled
              iconHeight={Ratio(40)}
              iconWidth={Ratio(40)}
              style={{
                width: Ratio(66),
                height: Ratio(66),
                backgroundColor: Colors.secondaryLightColor,
                borderRadius: 20,
              }}
            />
            {user_type && (
              <View style={styles.userDifferent}>
                {/* <Image src={user_type === 'trainer' ? Assets.WhistlePrimary : Assets.petFeet} svg width={Ratio(25)} height={Ratio(25)} /> */}
                <NavButton
                  icon={getUserType(user_type)}
                  svg
                  disabled
                  iconHeight={Ratio(10)}
                  iconWidth={Ratio(10)}
                  style={{
                    width: Ratio(15),
                    height: Ratio(15),
                    backgroundColor: Colors.secondaryDarkColor,
                    borderRadius: 20,
                  }}
                />
              </View>
            )}
          </View>
        )}
      </View>
      <View style={styles.chatContent}>
        <View style={styles.nameDetails}>
          <Text
            color={Colors.primaryDarkColor}
            styles={{ paddingBottom: unreadCount && Ratio(3) ,textOverflow: 'ellipsis',
            whiteSpace: 'noWrap',
            overflow:"hidden"}}
            size={Ratio(16)}
            family={Constants.medium}
            weight="600">
            {
              user.user_type === "admin" && !isUserChat() ? constructName(members)
              : username}
          </Text>
          {last_message &&
          (message_type === 'image' || message_type === 'video' || message_type === 'docs') ? (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Image
                width={Ratio(12)}
                height={Ratio(12)}
                svg
                src={
                  message_type === 'image'
                    ? Assets.PhotoPlaceholder
                    : message_type === 'video'
                    ? Assets.VideoPlaceholder
                    : Assets.DocPlaceholder
                }
              />
              <Text
                color={Colors.primaryLightText}
                styles={{ width: '90%', paddingLeft: Ratio(5) }}
                ellipsizeMode="tail"
                numberOfLines={1}
                size={Ratio(12)}
                // left={Ratio(5)}
                family={Constants.regular}
                weight="400">
                {message_type === 'image'
                  ? 'Photo'
                  : message_type === 'video'
                  ? 'Video'
                  : 'Document'}
              </Text>
            </View>
          ) : (
            <Text
              color={Colors.primaryLightText}
              styles={{ width: '100%', paddingLeft: Ratio(5) ,textOverflow: 'ellipsis',
                whiteSpace: 'noWrap',
                overflow:"hidden"}}
              ellipsizeMode="tail"
              numberOfLines={1}
              size={Ratio(12)}
              family={Constants.regular}
              weight="400">
              {last_message?.text}
            </Text>
          )}
        </View>
        <View style={styles.timeDetails}>
          <Text
            styles={{ paddingBottom: !unreadCount ? Ratio(15) : Ratio(5) }}
            color={Colors.primaryLightText}
            size={Ratio(10)}>
            {moment(last_update).format('hh:mm a')}
          </Text>
          {unreadCount && unreadCount !== 0 ? (
            <Text
            styles={{ width: unreadCount > 9 ? "auto" : Ratio(10), height: Ratio(15) }}
              color={Colors.buttonTextColor}
              containerStyle={styles.countStyle}
              textAlign="center"
              size={Ratio(10)}>
              {unreadCount}
            </Text>
          ) : null}
        </View>
      </View>
    </Container>
  );
};

const styles = StyleSheet.create({
  conversationContainer: {
    // width: '49%',
    padding: Ratio(10),
    backgroundColor: Colors.buttonTextColor,
    borderRadius: 28,
    flexDirection: 'row',
    marginTop: 0,
    margin: 10,
    cursor: 'pointer',
  },
  profileContainer: {
    width: Ratio(66),
    height: Ratio(66),
  },
  chatContent: {
    width: '78%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: Ratio(10),
    flexWrap: 'wrap',
  },
  nameDetails: {
    justifyContent: 'center',
    width: '75%',
    textOverflow: 'ellipsis',
    ellipsisMode:"tail",
    whiteSpace: 'noWrap',
  },
  timeDetails: {
    width: '20%',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  countStyle: {
    backgroundColor: Colors.secondaryDarkColor,
    padding: "5px",
    borderRadius: 4,
  },
  userDifferent: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  unseenStyle: {
    backgroundColor: Colors.outline,
  },
});

export default Conversation;
