import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  Text,
  Functions,
  Models,
  Ratio,
  Constants,
  Colors,
  Assets,
  Image,
  InfoDetail,
  UserCard,
  PrimaryButton,
  MediaView,
  View,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  FlatList,
  Alert,
  LinearGradient,
  VideoPlayer,
  NavButton,
  EditPop,
} from 'utils/imports.utils';
import {
  getPetDetails,
  updateMediaList,
  getTrainingDetails,
  getChat,
  getTrainingList,
  getCaller,
} from 'utils/redux.utils';
import moment from 'moment';
import _ from 'lodash';
import connectSocket from 'utils/socket.utils';
let socket: any;

interface paginationProps {
  page: number;
  limit: number;
  search: string;
  dogId: string;
  type?: string;
}

const DogProfile = (props: any) => {
  const sliderRef: any = useRef(null);
  const user = useSelector((state: any) => state.user.user);
  const allMediaList = useSelector((state: any) => state.media.mediaList);
  const media = useSelector((state: any) => state.media.mediaList);
  const training = useSelector((state: any) => state.training.training);
  // console.log('training', training);
  const [state, setState] = Functions.useSetState({
    loading: true,
    edit: false,
    visible: false,
    loadMoreLoader: false,
    selectedMedia: { url: "" },
    progress: 0,
    upload: false,
    mediaIndex: 0,
    mediaView:false,
    editOption:false,
  });

  useEffect(() => {
    setState({ loading: true });
    mediaList();
  }, []);

  const backAction = () => {
    goBack();
    return true;
  };

  const trainingDetails = async (id: string) => {
    try {
      const dog: any = await Models.training.getTrainingById(id);
      let trainingData = {
        ...dog.data,
        customerView: training.customerView ? training.customerView : false,
        trainerView: training.trainerView ? training.trainerView : false,
        adminView: training.adminView ? training.adminView : false,
      };
      getTrainingDetails(trainingData);
      setState({ loading: false, edit: false });
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get dog details');
      }
      setState({ loading: false, edit: false });
    }
  };

  const mediaList = async () => {
    try {
      const media: any = await Models.media.mediaList({
        page: 1,
        limit: 20,
        search: '',
        pet: training.pet._id,
        // type: 'image',
      });
      updateMediaList(media.data.docs);
      setState({ loading: false });
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get media list');
      }
      setState({ loading: false });
    }
  };

  const goBack = () => {
    if (socket) {
      // socket.emit('leave_chat', user._id, async(data: any) => {
      //   console.log("user disconnected...");
      // });
    }
    props.navigation.goBack();
    updateMediaList([]);
  };

  const gotoMedia = () => {
    updateMediaList([]);
    props.navigation.navigate('Media');
  };

  const updateStatus = async (status: string) => {
    try {
      setState({ edit: true });
      const body = {
        status: status,
        id: training._id,
      };
      const edit: any = await Models.training.editTraining(body);
      Functions.Success('Success', `Training ${status}`);
      trainingDetails(training._id);
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to update training status');
      }
      setState({ loading: false, edit: false });
    }
  };

  const handleChatClick = async (receiver: string) => {
    try {
      let body = {
        from: user._id,
        to: receiver,
        type: 'private',
      };
      console.log('body', body);
      const conversation: any = await Models.chat.startConversation(body);
      // console.log('conversation', conversation);
      getChat(conversation.data);
      socket = connectSocket;
      // console.log('socket', socket);
      // global.WebSocket = socket;

      socket.emit('join-chat', { user: user._id });
      socket.on('user-connected', (data: any) => {
        console.log('user connected...');
      });
      props.navigation.navigate('Message');
    } catch (err) {
      console.log('err');
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get conversation');
      }
    }
  };

  const handleCall = async (id: string) => {
    const callerDetails: any = await Models.user.viewUser(id);
    getCaller(callerDetails.data);
    props.navigation.navigate('Call');
  };

  const showMessage = (name: string) => {
    Functions.Info('Sorry', `${name} is not available to take calls right now.`);
  }

  const showMedia = (media: any, index: number) => {
    try {
      setState({ mediaView: true, selectedMedia: [media], mediaIndex: index});
      // console.log('sliderRef', sliderRef);
      // sliderRef?.current.slideTo(index);
    } catch (err) {
      console.log('err', err);
    }
  };

  const closeModel = () => {
    setState({ mediaView: false, selectedMedia: {} });
  };

  const getMediaList = async ({ page, limit, search, dogId, type }: paginationProps) => {
    // try {
    //   let body = {
    //     page,
    //     limit,
    //     search,
    //     pet: dogId,
    //     type,
    //   };
    //   const media: any = await Models.media.mediaList(body);
    //   if (page !== 1) {
    //     updateMediaList([...allMediaList, ...media.data.docs]);
    //   } else {
    //     updateMediaList(media.data.docs);
    //   }
    //   setState({ loading: false, hasMore: media.data.hasNextPage, loadMoreLoader: false });
    // } catch (err: any) {
    //   if (err) {
    //     Functions.Failure(err);
    //   } else {
    //     Functions.Failure('Failed to get media list');
    //   }
    //   setState({ loading: false });
    // }
  };

  const deleteMedia = async (id: any) => {
    try {
      setState({ loading: true });
      await Models.media.deleteMedia(id);
      await getMediaList({
        dogId: training.pet._id,
        page: state.page,
        limit: state.limit,
        search: state.search,
        type: state.type,
      });
      setState({ mediaView: false, loading: false });
    } catch (err) {
      console.log('Error', err);
    }
  };

  

  const getConfirmation = (id: any) => {
    Alert.alert('Delete Media?', 'Are you sure want to delete media', [
      {
        text: 'Yes',
        onPress: async () => deleteMedia(id),
      },
      { text: 'No' },
    ]);
  };
  const seeChatTranscript = async () => {
    try {
      let body = {
        from: training.trainer?._id,
        to: training.customer?._id,
        type: 'private',
      };
      const conversation: any = await Models.chat.startConversation(body);
      getChat(conversation.data);
      socket = connectSocket;
      // console.log('socket', socket);
      // global.WebSocket = socket;

      socket.emit('join-chat', { user: user._id });
      socket.on('user-connected', (data: any) => {
        console.log('user connected...');
      });
      props.navigation.navigate('Message');
    } catch (err) {
      console.log('err');
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get conversation');
      }
    }
  }
  const onEditClient = async () => {
    props.navigation.navigate('AddUser', {
      view: true,
      buttonText: 'Details',
      type: training.customer.user_type,
      userId: training.customer._id,
    });
  }
  const onEditDog = async () => {
    props.navigation.navigate('AddDog', { view: true, id: training.pet._id , from:"dog_profile" });
  }
  const onEditTraining = async () =>{
    props.navigation.navigate('AddTraining', { view: true, id: training._id , from:"dog_profile"});
  }

  return (
    <Container screen styles={styles.dogProfileScreen} loading={state.loading}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={styles.dogDetailsContainer}>
          <View style={styles.headerWrapper}>
            <View style={styles.headerBack}>
            <View>
              <TouchableOpacity
                style={{
                  width: Ratio(40),
                  height: Ratio(40),
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                activeOpacity={0.7}
                onPress={goBack}>
                <Image svg src={Assets.ArrowBack} width={20} height={20} />
              </TouchableOpacity>
            </View>
            <View style={styles.headTextWrapper}>
              <Text
                top={0}
                size={Ratio(24)}
                color={Colors.primaryDarkText}
                family={Constants.light}
                weight="300">
                {'Profile'}
              </Text>
            </View>
            </View>
            <View style={styles.headerAdd}>
                <NavButton icon={Assets.EditWhite} background={Colors.primaryDarkColor} onClick={()=>{setState({editOption: !state.editOption})}} />
            </View>
          </View>
          <View style={styles.dogProfileContent}>
            <View style={styles.imageContainer}>
              {training?.pet?.profile ? (
                <View style={[styles.dogProfileImage]}>
                  <Image
                    src={training?.pet?.profile}
                    url
                    height="100%"
                    width="100%"
                    resize="cover"
                    radius={60}
                  />
                </View>
              ) : (
                <View
                  style={[
                    styles.dogProfileImage,
                    { backgroundColor: Colors.secondaryLightColor, borderRadius: 60 },
                  ]}>
                  <Image src={Assets.DogPrimary} svg height="100%" width="100%" resize="cover" />
                </View>
              )}
            </View>
            <View style={styles.nameDetailsContent}>
              <Text color={Colors.primaryDarkText} size={Ratio(20)} family={Constants.semiBold}>
                {training?.pet?.name}
              </Text>
              <Text color={Colors.primaryLightText} size={Ratio(12)} family={Constants.regular}>
                {training?.pet?.category}
              </Text>
            </View>
            <View style={styles.dogDetails}>
              {training?.pet?.gender ? (
                <View>
                  <InfoDetail
                    text={training.pet.gender}
                    textStyle={styles.infoStyle}
                    style={{ backgroundColor: Colors.secondaryBackground }}
                  />
                </View>
              ) : null}
              {training?.pet?.age ? (
                <View>
                  <InfoDetail
                    text={`${training.pet.age} ${training.pet.age_type || "Years"}`}
                    style={{ backgroundColor: Colors.secondaryBackground }}
                  />
                </View>
              ) : null}
              <View>
                {training.training_type == "single day" ?
                <InfoDetail
                icon={Assets.CalendarPrimary}
                text={`${moment(training.date).format('MMM DD')}`}
                style={{ backgroundColor: 'transparent' }}
              />
                : training.training_type == "multiple days" ?
                <InfoDetail
                icon={Assets.CalendarPrimary}
                text={`${moment(training.dates[0]).format('MMM DD')} - ${moment(
                  training.dates[training.dates.length - 1],
                ).format('MMM DD')}`}
                style={{ backgroundColor: 'transparent' }}
              />
              :
              <InfoDetail
                icon={Assets.CalendarPrimary}
                text={`${moment(training.start_date).format('MMM DD')} - ${moment(
                  training.end_date,
                ).format('MMM DD')}`}
                style={{ backgroundColor: 'transparent' }}
              />  
              }

              {/* <InfoDetail
                  icon={Assets.CalendarPrimary}
                  text={`${moment(training.start_date).format('MMM DD')}   ${moment(
                    training.end_date,
                  ).format('MMM-DD')}`}
                  style={{ backgroundColor: 'transparent' }}
                /> */}
              </View>
            </View>
            <View style={[styles.userContainer]}>
              {(training.adminView || training.trainerView) && (
                <View style={styles.customerDetails}>
                  <UserCard
                    username={training?.customer?.username}
                    user_type={training?.customer?.user_type}
                    user={training.customer}
                    imageWidth={Ratio(55)}
                    imageHeight={Ratio(55)}
                    imageRadius={15}
                    iconStyle={{ width: Ratio(55), height: Ratio(55), borderRadius: 15 }}
                    iconWidth={Ratio(35)}
                    iconHeight={Ratio(35)}
                    callDisable={
                      !(training?.customer?.call_limited
                        ? Functions.isCallAvailable(training?.customer?.limited_hours)
                        : true || !training?.customer?.call_allowed)
                    }
                    onDisableClick={() => showMessage(training?.customer?.username)}
                    profile={training?.customer?.profile ? training?.customer?.profile : null}
                    onChatClick={() => handleChatClick(training?.customer._id)}
                    onCallClick={() => handleCall(training?.customer?._id)}
                  />
                </View>
              )}
              {training?.trainer && (training.adminView || training.customerView) && (
                <View style={styles.customerDetails}>
                  <UserCard
                    username={training?.trainer?.username}
                    user_type={training?.trainer?.user_type}
                    user={training.trainer}
                    imageWidth={Ratio(55)}
                    imageHeight={Ratio(55)}
                    imageRadius={15}
                    iconStyle={{ width: Ratio(55), height: Ratio(55), borderRadius: 15 }}
                    iconWidth={Ratio(35)}
                    callDisable={
                      !(training?.trainer?.call_limited
                        ? Functions.isCallAvailable(training?.trainer?.limited_hours)
                        : true || !training?.trainer?.call_allowed)
                    }
                    onDisableClick={() => showMessage(training?.trainer?.username)}
                    iconHeight={Ratio(35)}
                    profile={training?.trainer?.profile ? training?.trainer?.profile : null}
                    onChatClick={() => handleChatClick(training?.trainer._id)}
                    onCallClick={() => handleCall(training?.trainer?._id)}
                  />
                </View>
              )}
              {training.adminView &&
                <TouchableOpacity style={{ justifyContent: 'center', alignItems: 'center', marginBottom: 20}} onPress={seeChatTranscript}>
                  <Text color={Colors.secondaryDarkText} styles={{cursor:"pointer"}}>See Chat Transcript</Text>
                </TouchableOpacity>
              }
              <View style={styles.trainingDetails}>
                <Text size={Ratio(16)} family={Constants.medium} color={Colors.primaryDarkText}>
                  Training
                </Text>
                <View style={styles.processContent}>
                  <View style={{ width: '70%' }}>
                    <Text
                      styles={{ width: '90%' }}
                      numberOfLines={5}
                      ellipsizeMode="tail"
                      size={Ratio(12)}
                      family={Constants.regular}
                      color={Colors.primaryLightText}>
                      {_.find(Constants.services, { value: training.service })?.label}
                    </Text>
                  </View>
                  <View style={styles.progress}>
                    <InfoDetail
                      containerStyle={{ width: '90%', paddingRight: 0 }}
                      text={_.find(Constants.status, { value: training.status })?.title}
                      style={{
                        backgroundColor: _.find(Constants.status, { value: training.status })
                          ?.color,
                      }}
                      textColor={_.find(Constants.status, { value: training.status })?.textColor}
                    />
                  </View>
                </View>
              </View>
            </View>
            {training.status !== 'completed' && !training.customerView && (
              <View style={{ width: '25%', marginTop: 20 }}>
                <PrimaryButton
                  text={training.status === 'not_started' ? 'Start Training' : 'Complete Training'}
                  onPress={() => {
                    if (training.status === 'not_started') {
                      updateStatus('started');
                    } else {
                      updateStatus('completed');
                    }
                  }}
                  width="100%"
                  activity={state.edit}
                  backgroundColor={Colors.primaryDarkColor}
                />
              </View>
            )}
            <View style={styles.mediaContent}>
              <View style={styles.mediaHeader}>
                <Text size={Ratio(16)} family={Constants.medium} color={Colors.primaryDarkText}>
                  Pictures & Videos
                </Text>
                <TouchableOpacity onPress={() => gotoMedia()} activeOpacity={0.7} style={{cursor:"pointer"}}>
                  <Text size={Ratio(12)} color={Colors.secondaryDarkText}>
                    See All
                  </Text>
                </TouchableOpacity>
              </View>
              {media.length > 0 ? (
                <View style={styles.imageWrapper}>
                  <FlatList
                    data={media}
                    keyExtractor={(item: any) => item.name}
                    contentContainerStyle={styles.mediaWrapper}
                    renderItem={({ item, index }) => {
                      if (item.type == 'image') {
                        return <TouchableOpacity
                        style={[
                          styles.imageContent,
                          index === media.length - 1 && { marginBottom: Ratio(100) },
                        ]}
                        onPress={() =>  showMedia(item, index)}
                        >
                        <Image
                          src={item.url}
                          url
                          height={'100%'}
                          width={'100%'}
                          resize="cover"
                          radius={10}
                        />
                      </TouchableOpacity>
                      } else if (item.type == 'video') {
                        return <TouchableOpacity
                        style={[
                          styles.imageContent,
                          index === media.length - 1 && { marginBottom: Ratio(100) },
                        ]}
                        onPress={() =>  showMedia(item, index)}
                        >
                        <VideoPlayer
                          src={item.url}
                          url
                          height={'100%'}
                          width={'100%'}
                          resize="cover"
                  style={styles.videoPlayer}
                        />
                      </TouchableOpacity>
                      } else {
                        return <TouchableOpacity
                        style={[
                          styles.imageContent,
                          index === media.length - 1 && { marginBottom: Ratio(100) },
                        ]}
                        onPress={() =>  showMedia(item, index)}
                        >
                        <Image
                          src={item.type === "video" ? item.thumbnail : item.url}
                          url
                          height={'100%'}
                          width={'100%'}
                          resize="cover"
                          radius={30}
                        />
                      </TouchableOpacity>
                    }}}
                    scrollEnabled={false}
                  />
                </View>
              ) : (
                <View style={styles.emptyContainer}>
                  <Text
                    color={Colors.secondaryLightText}
                    size={14}>{`Photos and Videos not found`}</Text>
                </View>
              )}
            </View>
          </View>
        </View>
      </ScrollView>
      {state.mediaView && (
        // <MediaView
        //   index={state.mediaIndex}
        //   ref={sliderRef}
        //   isOpen={state.mediaView}
        //   onBackClick={closeModel}
        //   data={state.selectedMedia}
        //   onClose={() => setState({ mediaView: false })}
        //   sliderData={media}
        //   loader={state.loading}
        //   onDelete={(id) => getConfirmation(id)}
        //   // styles= {styles.mediaViewContainer}
        // />
        
          <MediaView
            isOpen={state.mediaView}
            data={state.selectedMedia}
            sliderData={state.selectedMedia}
            onBackClick={() => setState({ mediaView: !state.mediaView })}
            onClose={() => setState({ mediaView: !state.mediaView })}
          />       
      )}
      {state.editOption && (
        <View style={styles.filterContainer}>
          <EditPop 
          onEditClient={onEditClient}
          onEditDog={onEditDog}
          onEditTraining={onEditTraining}
          />
        </View>
      )}
    </Container>
  );
};

const styles = StyleSheet.create({
  dogProfileScreen: {
    height: '100%',
  },
  dogDetailsContainer: {
    width: '100%',
    height: '100%',
    // justifyContent: 'space-between',
  },
  headerWrapper: {
    padding: Ratio(20),
    marginBottom: 110,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent:"space-between"
  },
  headTextWrapper: {
    paddingLeft: Ratio(10),
  },
  textWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dogProfileContent: {
    height: '90%',
    width: '100%',
    backgroundColor: Colors.buttonTextColor,
    alignItems: 'center',
    borderTopRightRadius: 58,
    borderTopLeftRadius: 58,
  },
  imageContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dogProfileImage: {
    marginTop: Ratio(-100) ,
    width: Ratio(200),
    height: Ratio(200),
  },
  nameDetailsContent: {
    width: '100%',
    paddingTop: Ratio(120),
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 20px',
  },
  dogDetails: {
    paddingTop: Ratio(16),
    padding: '0px 20px',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  infoStyle: {
    padding: '3px 12px',
  },
  userContainer: {
    width: "50%",
    paddingTop: Ratio(20),
  },
  customerDetails: {
    padding: '0px 10px',
    paddingBottom: Ratio(10),
  },
  trainingDetails: {
    width: '100%',
    justifyContent: 'flex-start',
    padding: '0px 20px',
  },
  processContent: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: Ratio(5),
  },
  progress: {
    width: '30%',
    paddingLeft: Ratio(10),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  mediaContent: {
    width: '100%',
    padding: '0px 20px',
    paddingTop: Ratio(20),
  },
  mediaHeader: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: "0px 20px"
  },
  imageWrapper: {
    padding: Ratio(20),
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  imageContent: {
    width: "49%",
    height: 300,
    paddingBottom: Ratio(15),
  },
  mediaWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  emptyContainer: {
    width: '100%',
    paddingTop: Ratio(20),
    paddingBottom: Ratio(100),
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: Ratio(130),
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  videoPlayer:{
    height:"100%",
    width:"100%",
    borderRadius: "20px",
    objectFit: "cover"
  },
  headerBack:{
    display:"flex",
    flexDirection:"row"
  },
  filterContainer: {
    position: 'absolute',
    bottom: 0,
    top: Ratio(120),
    left: 0,
    right: 0,
    width: '100%',
  },
});

export default DogProfile;

    function Test({Ratio}) {
      return (<View style={styles.headTextWrapper}>
              <Text top={0} size={Ratio(24)} color={Colors.primaryDarkText} family={Constants.light} weight="300">
                {'Profile'}
              </Text>
            </View>);
    }
  