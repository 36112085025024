// import arrowLeft from 'assets/icons/ArrowLeft.svg';
import Eye from 'assets/icons/eye.svg';
import HiddenEye from 'assets/icons/hidden_eye.svg';
// import BG from "assets/images/bg.jpeg";
import Logo from 'assets/images/logo.png';
import Lock from 'assets/icons/Lock_fill.svg';
import UnLock from 'assets/icons/lock_2.svg';
import LockFill from 'assets/icons/Lock_fill.svg';
import Notification from 'assets/icons/notification_line.svg';
import KeyFill from 'assets/icons/Key_orange.svg';
import MessageDark from 'assets/icons/message.svg';
import UserGray from "assets/icons/User.svg";
import Trainer from 'assets/icons/Trainer.svg';
import petFeet from 'assets/icons/Paw.svg';
import HomeBlack from 'assets/icons/home_black.svg';
import Home from 'assets/icons/home.svg';
import MessageBlack from 'assets/icons/message_primary.svg';
import MessageWhite from 'assets/icons/message_white.svg';
import CricleBlack from 'assets/icons/add_circle.svg';
import Cricle from 'assets/icons/add.svg';
import CricleWhite from 'assets/icons/add_circle_white.svg';
import CalendarPrimary from 'assets/icons/Calendar.svg';
import CalendarBlack from 'assets/icons/Calendar_black.svg';
import CalendarWhite from 'assets/icons/Calendar_white.svg';
import TuneBlue from 'assets/icons/tune_blue.svg';
import Tune from 'assets/icons/tune.svg';
import Search from 'assets/icons/search.svg';
import MessageUnClick from 'assets/icons/message_unclick.svg';
import HomeLight from 'assets/icons/home_light.svg';
import ArrowUp from 'assets/icons/Up.svg';
import ArrowDown from 'assets/icons/Down.svg';
import ArrowBack from 'assets/icons/Back.svg';
// import Logout from 'assets/images/exit.png';
import Close from 'assets/icons/close.svg';
import PhonePrimary from 'assets/icons/phone_orange.svg';
import MessageOrange from 'assets/icons/message_red.svg';
import Tick from 'assets/icons/tick.svg';
import EyeHide from 'assets/icons/Eye_Hide.svg';
import UserWhite from 'assets/icons/User_White.svg';
import Plus from 'assets/icons/add.svg';
import Edit from 'assets/icons/edit.svg';
import EditWhite from 'assets/icons/edit_white.svg';
import DogPrimary from 'assets/icons/dog_orange.svg';
import GroupUserPrimary from 'assets/icons/group_user_orange.svg';
import TrainingPrimary from 'assets/icons/training.svg';
import WhistlePrimary from 'assets/icons/whistle.svg';
import BG from 'assets/images/dog_bg.jpeg';
import ExportWhite from 'assets/icons/Export.svg';
import AlbumWhite from 'assets/icons/Album.svg';
import SingleUserPrimary from 'assets/icons/Avatar.svg';
import PdfIcon from 'assets/icons/pdf_icon.svg';
import SendMessage from 'assets/icons/message_send.svg';
import Play from 'assets/icons/PlayVideo.svg';
import Pause from 'assets/icons/Pause.svg';
import MuteVideo from 'assets/icons/MuteVideo.svg';
import UnMuteVideo from 'assets/icons/UnmuteVideo.svg';
import FullScreen from 'assets/icons/FullScreen.svg';
import NormalScreen from 'assets/icons/NormalScreen.svg';
import Replay from 'assets/icons/Replay.svg';
import Send from 'assets/icons/SendMessage.svg';
import VideoPlaceholder from 'assets/icons/video_icon.svg';
import PhotoPlaceholder from 'assets/icons/photo_icon.svg';
import DocPlaceholder from 'assets/icons/doc_icon.svg';
import PhoneWhite from 'assets/icons/phone_white.svg';
import AttachMedia from 'assets/icons/AttachMedia.svg';
import LoudSpeaker from 'assets/icons/outPeaker.svg';
import EarSpeaker from 'assets/icons/Hands.svg';
import UnmuteCall from 'assets/icons/UnmuteCall.svg';
import MuteCall from 'assets/icons/MuteCall.svg';
import BulbPrimary from 'assets/icons/Bulb.svg';
import NotificationOrange from 'assets/icons/NotificationOrange.svg';
import Logout from 'assets/icons/Logout.svg';
import WistleWhite from 'assets/icons/whistleWhite.svg';
import GroupUserWhite from 'assets/icons/group_user_white.svg';
import DarkSpinner from 'assets/spinners/darkSpinner.gif';
import LightSpinner from 'assets/spinners/lightSpinner.gif';
// import Notify from 'assets/icons/notify_alery.svg';
// import MessageBlackAlert from 'assets/icons/msg_black_alert.svg';
// import MessageWhiteAlert from 'assets/icons/msg_white_alert.svg';
// import IncomingArrow from 'assets/icons/arrow_Incoming.svg';
// import OutgoingArrow from 'assets/icons/arrow_Outgoing.svg';
// import MissedCallArrow from 'assets/icons/arrow_Missed.svg';
import Delete from 'assets/icons/delete.svg';
import Download from 'assets/images/Download.png';
import alert from 'assets/icons/alert.svg';
import delete_account from 'assets/icons/delete_account.svg';
import organization from 'assets/icons/organisation.svg';
import branch from 'assets/icons/branch.svg';
import admin from 'assets/icons/Avatar.svg';
import dashboard_active from 'assets/icons/Dashboard_Active.svg'
import dashboard_inactive from 'assets/icons/Dashboard_Inactive.svg'
import org_white from 'assets/icons/Org_white.svg'
import branch_white from 'assets/icons/Branch_white.svg'
import circle_close from 'assets/icons/circle_close.svg'
import checkbox_checked from 'assets/icons/checkbox_checked.svg'
import checkbox_unchecked from 'assets/icons/checkbox_unchecked.svg'
import radiobox_active from 'assets/icons/radiobox_active.svg'
import radiobox_inactive from 'assets/icons/radiobox_inactive.svg'
import payment_active from 'assets/icons/payment_active.svg'
import payment_inactive from 'assets/icons/payment_inactive.svg'
import warning_circle from 'assets/icons/warning_circle.svg'
import check_circle from 'assets/icons/checkcircle.svg';
import un_check_circle from 'assets/icons/un_checkcircle.svg';
import close_white from 'assets/icons/close_white.svg';
import task from 'assets/icons/task.svg'
import ArrowForward from 'assets/icons/Foward.svg';
import services from 'assets/icons/services.svg'


const Assets = {
  // arrowLeft,
  BG,
  Eye,
  HiddenEye,
  Logo,
  Lock,
  UnLock,
  KeyFill,
  MessageDark,
  LockFill,
  Notification,
  UserGray,
  Trainer,
  petFeet,
  HomeBlack,
  Home,
  MessageBlack,
  CricleBlack,
  Cricle,
  MessageWhite,
  CalendarPrimary,
  TuneBlue,
  Tune,
  Search,
  HomeLight,
  MessageUnClick,
  CricleWhite,
  ArrowUp,
  ArrowDown,
  ArrowBack,
  Logout,
  Close,
  CalendarBlack,
  CalendarWhite,
  PhonePrimary,
  MessageOrange,
  Tick,
  EyeHide,
  UserWhite,
  Plus,
  Edit,
  EditWhite,
  DogPrimary,
  GroupUserPrimary,
  TrainingPrimary,
  WhistlePrimary,
  ExportWhite,
  AlbumWhite,
  SingleUserPrimary,
  PdfIcon,
  SendMessage,
  Play,
  Pause,
  MuteVideo,
  UnMuteVideo,
  FullScreen,
  NormalScreen,
  Replay,
  Send,
  VideoPlaceholder,
  PhotoPlaceholder,
  DocPlaceholder,
  PhoneWhite,
  AttachMedia,
  LoudSpeaker,
  EarSpeaker,
  UnmuteCall,
  MuteCall,
  BulbPrimary,
  NotificationOrange,
  WistleWhite,
  GroupUserWhite,
  DarkSpinner,
  LightSpinner,
  // Notify,
  // MessageBlackAlert,
  // MessageWhiteAlert,
  // IncomingArrow,
  // OutgoingArrow,
  // MissedCallArrow,
  Delete,
  Download,
  alert,
  delete_account,
  organization,
  branch,
  admin,
  dashboard_active,
  dashboard_inactive,
  org_white,
  branch_white,
  circle_close,
  checkbox_checked,
  checkbox_unchecked,
  radiobox_active,
  radiobox_inactive,
  payment_active,
  payment_inactive,
  warning_circle,
  check_circle,
  un_check_circle,
  close_white,
  task,
  ArrowForward,
  services,
};

export default Assets;
