import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'utils/functions.utils';
import { Functions, Models, Component, Assets } from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import './plan_card.component.scss';

interface IPlanCard {
  text?: String;
  buttonText?: String;
  plan?: String;
  amount?: any;
  duration?: String;
  selected?: boolean;
  onClick?: any;
  active?: boolean;
  buttonOnClick?: any;
  activity?: boolean;
}

const PlanCard = (props: IPlanCard) => {
  // Redux

  // State

  //Hooks
  useEffect(() => {}, []);

  // Network req

  //Logic

  return (
    <div className="plancard_container">
      <div
        className="plancard_wrapper"
        style={{ borderColor: `${props.selected ? '#10213f' : '#c4cacc'}` }}
        onClick={props.onClick}
        >
        <div className="plancard_header">
          <div className="plancard_header_wrapper">
          <div className="plancard_header_text">{props.plan}</div>
          {props.active && <div className='plancard_plan_status'>Active</div>}
          </div>
          <div className="plancard_header_checkbox">
            <img
              src={
                props.selected
                  ? Assets.checkbox_checked
                  : Assets.checkbox_unchecked
              }
              alt="checkbox"
            />
          </div>
        </div>
        <div className="plancard_amount_container">
          <div className="plancard_amount">${props.amount}</div>
          <div className="plancard_duration">{props.duration}</div>
        </div>
        <div className="plancard_footer">
          {/* <div className="plancard_footer_text">
            Includes 10 days free trial
          </div> */}
          {props.buttonText && (
            <div className="plancard_footer_button">
              <Component.Button
                text={`${props.buttonText}`}
                className={`${props.active ?"cancel_button":"active_button"}`}
                onClick={props.buttonOnClick}
                activity={props.activity}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
