import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeContainer from 'common_components/ui/stripe_container/stripeContainer';
import moment from 'moment';
import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { getAmount, useSetState } from 'utils/functions.utils';
import {
  Functions,
  Models,
  Container,
  PlanCard,
  Assets,
} from 'utils/imports.utils';
import './payment.screen.scss';

const Payment = (props) => {
  const stripe: any = useStripe();
  
  // Redux

  // State
  const [state, setState] = useSetState({
    product_list: [],
    id: '',
    invoice_list: [],
    paymentForm: false,
    data: {},
    plan: ''
  });
  const user = useSelector((state: any) => state.user.user);

  //Hooks
  useEffect(() => {
    getProducts();
    setState({ id: user.plan });
  }, []);

  // Network req
  const getProducts = async () => {
    try {
      setState({ loading: true });
      const res: any = await Models.subscription.getProductList();
      await getPriceList(res.data);
      await getBillingHistory();
      setState({ loading: false });
    } catch (error) {
      console.log('error', JSON.stringify(error));
    }
  };

  const getPriceList = async (data?: any) => {
    try {
      const res: any = await Models.subscription.getPriceAmount();
      data.map((item: any) => {
        res.data.map((price: any) => {
          if (item.id === price.product) {
            item.price = price.unit_amount;
            item.recurring = price.recurring;
          }
        });
        return item;
      });
      // console.log('getPriceList', data);
      setState({ product_list: data });
    } catch (error) {
      console.log('error', error);
    }
  };

  const getBillingHistory = async () => {
    try {
      const res: any = await Models.subscription.getInvoices();
      // console.log('res- invoices', res);
      setState({ invoice_list: res.data });
    } catch (error) {
      console.log('error', error);
    }
  };

  const upgradePlan = async (price) => {
    try {
      setState({ activity: true });
      let body = {
        subscription_id: user.subscription.subscription_id,
        price,
      };
      const response: any = await Models.subscription.upgradeSubscription(body);
      Functions.Success('Plan upgraded');
      if (response) {
        localStorage.setItem('subscribed', 'true');
      }
      setState({ activity: false });
      window.location.reload();
    } catch (error) {
      Functions.Failure('Failed to upgrade subscription');
      setState({ activity: false });
      console.log('error', error);
    }
  };

  const cancelSubscription = async () => {
    try {
      setState({ activity: true });
      const res: any = await Models.subscription.cancelSubscription({
        subscription_id: user.subscription.subscription_id,
      });
      if (res) {
        localStorage.setItem('subscribed', 'false');
        props.history.push('/Subscription');
      }
      setState({ activity: false });
      window.location.reload();
    } catch (error) {
      setState({ activity: false });
      Functions.Failure('Failed to cancel subscription');
      console.log('error', error);
    }
  };

  const handleUpgradeSubscription = async (active?, product?) => {
    if (user.plan) {
      if (active) {
        await cancelSubscription();
      } else {
        await upgradePlan(product.default_price);
      }
    } else {
      await addSubscription(product.default_price);
    }
  };
  const addSubscription = async (price) => {
    try {
      setState({ activity: true });
      const response: any = await Models.subscription.createSubscription({
        price,
      });
      setState({ activity: false });
      if (
        response.data.latest_invoice?.payment_intent?.status ===
        'requires_action'
      ) {
        const { paymentIntent, error } = await stripe.confirmCardPayment(
          response.data.latest_invoice.payment_intent
            .client_secret,
        );
        if (error) {
          Functions.Failure('Payment failed');
        }
        if (paymentIntent && paymentIntent?.status === 'succeeded') {
          localStorage.setItem('subscribed', 'true');
          window.location.reload();
        }
      } else {
        localStorage.setItem('subscribed', 'true');
        window.location.reload();
      }
    } catch (error) {
      setState({ activity: false });
      Functions.Failure('Failed to add subscription');
      console.log('error', error);
    }
  };
  
  return (
      <Container style={{ width: '100%', height: '100%' }} screen>
        <div className="payment_screen">
          <div className="payment_screen_header">Payment</div>
          <div className="payment_container">
            <div className="payment_plancard_container">
              {state.product_list.length > 0 &&
                state.product_list.map((product) => (
                  <PlanCard
                    amount={product.price / 100}
                    duration={`Per ${product.recurring.interval} `}
                    plan={product.name}
                    selected={state.id == product.id && true}
                    buttonText={
                      user.plan === product.id
                        ? 'Cancel Subscription'
                        : 'Upgrade Subscription'
                    }
                    active={user.plan === product.id && true}
                    onClick={() => setState({ id: product.id })}
                    buttonOnClick={() =>
                      handleUpgradeSubscription(
                        user.plan === product.id && true,
                        product,
                      )
                    }
                    activity={state.id === product.id && state.activity}
                  />
                ))}
            </div>
            {/* <div className="payment_method_container">
            <div className="payment_method_title">Payment Method</div>
            <div className="payment_method_wrapper">
              <div className="add_payment_card">+ Add Payment Method</div>
            </div>
          </div> */}
            <div className="billing_history_container">
              <div className="billing_history_title">Billing History</div>
              <div className="billing_history_wrapper">
                <div className="billing_history_table_header">
                  <div className="billing_plan">Plan</div>
                  <div className="billing_date">Billing Date</div>
                  <div className="billing_amount">Amount</div>
                  <div className="billing_download">Download</div>
                </div>
                {state.invoice_list.map((data) => (
                  <div className="billing_history_table_body">
                    <div className="billing_plan">{data.product.name}</div>
                    <div className="billing_date">
                      {moment.unix(data.created).format('DD MMM YYYY')}
                    </div>
                    <div className="billing_amount">
                      USD ${getAmount(data.total)}
                    </div>
                    <div className="billing_download">
                      <a
                        href={data.invoice_pdf}
                        target="_blank"
                        className="underline">
                        Invoice{' '}
                        {moment.unix(data.created).format('DD MMM YYYY')}
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Container>
  );
};

export default Payment;
