import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { Ratio, useSetState } from 'utils/functions.utils';
import {
  Assets,
  Colors,
  Functions,
  Models,
  NavButton,
  Text,
  View,
  TouchableOpacity,
  StyleSheet,
} from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';

interface ITaskCard {
  text?: String;
  title: String;
  onClick: any;
  completed?: boolean;
}

const TaskCard = (props: ITaskCard) => {
  // Redux
  const testState = useSelector((state: any) => state.test);

  // State
  const [state, setState] = useSetState({
    showContent: false,
  });

  //Hooks
  useEffect(() => {}, []);

  // Network req

  //Logic

  return (
    <View style={styles.taskContainer}>
      <View style={styles.taskWrapper}>
        <TouchableOpacity
          style={styles.taskHeaderWrapper}
          onPress={() => {
            // setState({ showContent: !state.showContent });
            props.onClick()
          }}>
          <View style={styles.statusContainer}>
            <NavButton
              icon={
                props.completed ? Assets.check_circle : Assets.un_check_circle
              }
              svg
              disabled
              iconHeight={Ratio(24)}
              iconWidth={Ratio(24)}
              style={{
                width: Ratio(24),
                height: Ratio(24),
                backgroundColor: 'transparent',
                borderRadius: 16,
              }}
            />
          </View>
          <View style={styles.taskTextContainer}>
            <Text color="#000000" weight="500" size={Ratio(18)}>
              {props.title}
            </Text>
          </View>
          <View style={styles.buttonContainer}>
            <NavButton
              icon={state.showContent ? Assets.ArrowUp : Assets.ArrowForward}
              svg
              iconHeight={Ratio(25)}
              iconWidth={Ratio(25)}
              style={{
                width: Ratio(40),
                height: Ratio(40),
                // backgroundColor: 'transparent',
                borderRadius: 16,
                // transform: [{ rotate: '45deg' }],
              }}
              onClick={() => {
                setState({ showContent: !state.showContent });
              }}
            />
          </View>
        </TouchableOpacity>
        {state.showContent && (
          <View style={styles.taskContent}>
            <Text color="#000000" size={Ratio(14)} weight="400">
              {props.text}
            </Text>
            <TouchableOpacity
              style={styles.guideMeContainer}
              onPress={() => props.onClick()}>
              <Text
                textDecoration="underline"
                color={Colors.secondaryDarkText}
                size={Ratio(16)}>
                Guide Me
              </Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  taskContainer: {
    padding: '10px 10px',
    borderRadius: '25px',
  },
  taskWrapper: {
    width: '100%',
    backgroundColor: Colors.buttonTextColor,
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    cursor:"pointer"
  },
  taskHeaderWrapper: {
    padding: '10px 20px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  statusContainer: {
    width: '10%',
  },
  taskTextContainer: {
    width: '70%',
  },
  buttonContainer: {
    width: '10%',
  },
  guideMeContainer: {
    padding: '10px 0px',
  },
  taskContent: {
    padding: '10px 20px',
  },
});

export default TaskCard;
