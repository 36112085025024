import instance from '../utils/axios.utils';

const user = {
  viewUser: (id: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'user/view/'+id;
      instance()
        .post(url)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },

  editUser: (data: object) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'user/edit';
      instance()
        .post(url, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },
  deleteUser: (id: object) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'user/delete_user/'+id;
      instance()
        .delete(url)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if (error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },
  deleteAccount:(id:any) =>{
    let promise = new Promise((resolve, reject) => {
      let url = 'user/delete_account';
      instance()
        .post(url)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if (error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },
  payment:(body : any) =>{
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/payment';
      instance()
        .post(url , body)
        .then(res => {
          resolve(res.data);
          console.log("res", res)
          console.log("body" , body)
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if (error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },
};

export default user;
