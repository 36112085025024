import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SubscriptionForm from 'screens/subscription_form/subscription_form.screen';
import '../payment_form/payment_form.ui.scss';

const StripePaymentContainer = (props: any) => {
  const stripePromise = loadStripe(
    'pk_live_51MAyyFFwVX3w8EcFh7HH9wZfoZGQOaeivJub03mwHZJtUVWykC5wwNAbi3NhtjgS98IiKm6LugP2E6isrpmost3g00vkmXUnvm',
  );

  return (
    <Elements stripe={stripePromise}>
      <SubscriptionForm {...props} />
    </Elements>
  );
};
export default StripePaymentContainer;
