import React from 'react';
import { View, StyleSheet, TouchableOpacity, Ratio, Colors, Text, LinearGradient } from 'utils/imports.utils';


interface filterProps {
  onEditClick ?: any,
  onEditClient?: any,
  onEditDog? : any,
  onEditTraining?: any,
}

const EditPop = (props: filterProps) => {
  const { onEditClient, onEditDog , onEditTraining } = props;
  return (
    <View style={styles.filterContainer}>
      <LinearGradient
        colors={["rgba(248, 249, 251, 0.8)", "rgba(248, 249, 251, 0.8)"]}
        style={styles.overlay}>
        <View style={styles.overlayContainer}>
          <View style={styles.buttonWrap}>
            <TouchableOpacity style={styles.button} activeOpacity={0.9} onPress={onEditClient}>
              <Text color={Colors.primaryDarkText}>Edit Client</Text>
            </TouchableOpacity>
          </View>
          <View style={styles.buttonWrap}>
            <TouchableOpacity style={styles.button} activeOpacity={0.9} onPress={onEditDog}>
              <Text color={Colors.primaryDarkText}>Edit Dog</Text>
            </TouchableOpacity>
          </View>
          <View style={styles.buttonWrap}>
            <TouchableOpacity style={styles.button} activeOpacity={0.9} onPress={onEditTraining}>
              <Text color={Colors.primaryDarkText}>Edit Training</Text>
            </TouchableOpacity>
          </View>
        </View>
      </LinearGradient>
    </View>
  )
}

const styles = StyleSheet.create({
    filterContainer: {
        height: Ratio("100%", true),
        width: "100%"
      },
      buttonWrap: {
        width: "100%",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginBottom: Ratio(10),
      },
      button: {
        padding: Ratio(20),
        backgroundColor: Colors.buttonTextColor,
        width: "200px",
        borderRadius: 24,
        webkitBoxShadow: "0px 0px 10px -1px rgba(241,103,34,0.6)",
        mozBoxShadow: "0px 0px 10px -1px rgba(241,103,34,0.6)",
        boxShadow: "0px 0px 10px -1px rgba(241,103,34,0.6)"
      },
      overlayContainer: {
        // paddingTop: Ratio(10),
        // paddingBottom: Ratio(10),
        // paddingLeft: Ratio()
        padding: '5px 20px',
      },
      overlay: {
        width: "100%",
        height: "100%"
      }
})

export default EditPop;