import React from 'react'
import { StyleSheet, NavButton, Assets, Colors } from 'utils/imports.utils';

function Container(props) {
  let style = props.style || props.styles;
  const { floatingButton, onFloationButtonClick, floatingButtonStyle = {} } = props;
  if(Array.isArray(props.style)){
    let arrStyle = {};
    props.style.forEach(value => {
      arrStyle = {...arrStyle, ...value}
    })
    style = arrStyle;
  }
  return (
    <div style={{...styles.viewContainer, ...style}}>
      {props.children}
      {
        floatingButton &&
        <div style={{ ...styles.floatingButton, ...floatingButtonStyle }}>
          <NavButton svg onClick={onFloationButtonClick} icon={Assets.Plus} background={Colors.secondaryDarkText} />
        </div>
      }
    </div>
  )
}

const styles = StyleSheet.create({
  viewContainer: {
    display: 'flex', 
    flexDirection: 'column',
    marginTop: 50
  },
  floatingButton: {
    // position: 'absolute',
    // top: 50,
    // right: 50,
    borderRadius: "50%",
    overflow: "hidden"
  }
})

export default Container
