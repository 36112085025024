import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Ratio, useSetState } from 'utils/functions.utils';
import { Assets, Image, Text, StyleSheet, View } from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';

interface IServiceCard {
  title?: String;
}

const ServiceCard = (props: IServiceCard) => {
  // Redux
  const testState = useSelector((state: any) => state.test);

  // State
  const [state, setState] = useSetState({});

  //Hooks
  useEffect(() => {}, []);

  // Network req

  //Logic

  return (
    <View style={styles.serviceCardContainer}>
      <View style={styles.serviceCardWrapper}>
        <View style={styles.imageContainer}>
          <Image
            src={Assets.services}
            svg
            width={Ratio(55)}
            height={Ratio(55)}
            resize="cover"
            radius={23}
          />
        </View>
        <View style={styles.titleContainer}>
          <Text color="black" size={20}>
            {props.title}
          </Text>
        </View>
      </View>
      <View style={styles.arrowButtonContainer}>
        <Image
          src={Assets.ArrowForward}
          svg
          width={Ratio(30)}
          height={Ratio(30)}
          styles={{ transform: [{ rotate: '180deg' }] }}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  serviceCardContainer: {
    backgroundColor: 'white',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    padding: 10,
    alignItems: 'center',
    borderRadius: 10,
  },
  serviceCardWrapper: {
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
  },
  imageContainer: {
    paddingRight: 14,
  },
  titleContainer: {},
  arrowButtonContainer: {},
});

export default ServiceCard;
