import {
  NOTIFICATION_DETAIL,
  NOTIFICATION_LIST
} from 'utils/types.utils';
import { storeAction } from 'interfaces/common.interface';

const initialState = {
  notificationList: [],
  notification: {}
};

const NotificationReducer = (state = initialState, action: storeAction) => {
  switch (action.type) {
    case NOTIFICATION_LIST:
      let notificationList: any = [...action.payload]
      state.notificationList = notificationList;
      return state;
    case NOTIFICATION_DETAIL:
      let notification: any = { ...action.payload }
      state.notification = notification;
      return state;
    default:
      return state;
  }
};

export default NotificationReducer;
