import React from 'react'
import { View, StyleSheet, TouchableOpacity, Ratio, Assets, Container, Image, Text, Input, PrimaryButton, Colors, Validation, Functions, Constants, Models } from 'utils/imports.utils';

const ForgetPassword = (props: any) => {
  const [state, setState] = Functions.useSetState({ email: "" });

  const forgetPassword = async () => {
    try {
      const req = { email: state.email.trim() };
      await Validation.forgetPassSchema.validate(req);
      const otp: any = await Models.auth.sendOtp(req);
      Functions.Success("Success", "OTP has been Sent to the registered email");
      props.history.push('/OTP' , { email: state.email.trim() })
      setState({ email: "" });
    } catch (err) {
      // if (err.errors && err.errors.length > 0) {
      //   Functions.Failure("Failed", err.errors[0]);
      // } else if (err) {
      //   Functions.Failure("Failed", err);
      // } else {
      //   Functions.Failure("Failed to forget password");
      // }
    }
  }

  const backToLogin = async () => {
    setState({ email: "" });
    window.location.href = "/login"
  }

  return (
    // <Container screen styles={{ height: "100%" }}>
        <View style={styles.forgetPasswordScreen}>
          <View style={styles.forgetPasswordWrapper}>
            <View style={styles.iconWrapper}>
              <Image src={Assets.LockFill} svg width={40} height={40} />
            </View>
            <View style={styles.headerWrapper}>
              <Text size={Ratio(24)} color={Colors.primaryDarkColor} styles={styles.headText}>Forget Password</Text>
              <Text size={Ratio(14)} textAlign={"center"} color={Colors.primaryLightText} styles={styles.descriptionText}>Please enter a registered email id to recieve verification code</Text>
            </View>
            <View style={styles.inputWrapper}>
              <View style={styles.inputContent}>
                <Input
                  name="email"
                  placeholder="Email"
                  styles={styles.input}
                  onChange={(email: string) => setState({ email })}
                />
              </View>
              <View style={styles.buttonWrapper}>
                <PrimaryButton 
                width="200px" 
                backgroundColor={Colors.primaryDarkColor}
                text="Continue" 
                onPress={() => forgetPassword()} />
              </View>
              <View style={styles.textWrapper}>
                <TouchableOpacity style={styles.loginBtn} activeOpacity={0.7} onPress={() => backToLogin()}>
                  <Text size={Ratio(12)} weight="600" color={Colors.primaryDarkColor} family={Constants.medium}>Back to Login</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
    // </Container>
  )
}

const styles = StyleSheet.create({
  forgetPasswordScreen: {
    width: "100vw",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
  backgroundColor: Colors.buttonTextColor,

  },
  forgetPasswordWrapper: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  iconWrapper: {
    width: Ratio(100),
    height: Ratio(100),
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.secondaryLightColor,
    borderRadius: 28,
    // paddingBottom: Ratio(20)
    marginBottom: Ratio(24)
  },
  headerWrapper: {
    width: "60%",
    alignItems: "center",
    paddingBottom: Ratio(32)
  },
  headText: {
    paddingBottom: Ratio(8)
  },
  inputWrapper: {
    width: "30%",
  },
  inputContent: {
    width: "100%",
    paddingBottom: Ratio(16)
  },
  buttonWrapper: {
    width: "100%",
    paddingBottom: Ratio(20)
  },
  textWrapper: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  loginBtn: {
    cursor:"pointer"
  },
})

export default ForgetPassword;