import {
  testDispatch,
  signupDispatch,
  loginDispatch
} from '../utils/redux.utils';
import instance from '../utils/axios.utils';

const admin = {
  createUser: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'admin/add_user';
      instance()
        .post(url, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },

  editUser: (data: object) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'admin/edit_user/';
      instance()
        .post(url, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },
  userList: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'admin/get_users';
      instance()
        .post(url, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },
  checkIfUserExist:(body : any) =>{
    let promise = new Promise((resolve, reject) => {
      let url = 'admin/check_user_exist';
      instance()
        .post(url , body)
        .then(res => {
          resolve(res.data);
          console.log("res", res)
          console.log("body" , body)
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if (error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  }
};

export default admin;
