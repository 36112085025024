import React, { useEffect, useRef } from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  FlatList,
  ActivityIndicator,
  Container,
  Alert,
  Functions,
  Text,
  Constants,
  Colors,
  Ratio,
  Assets,
  Image,
  Models,
  // FloatingButton,
  // FloatingActionButton,
  VideoPlayer,
  MediaView,
  Modal,
  NavButton,
  FileInput,
} from 'utils/imports.utils';
import { uploadFile } from 'utils/s3.utils';
// import DocumentPicker from 'react-native-document-picker';
// import { uploadFile } from 'utils/s3.utils';
import { useSelector } from 'react-redux';
import { updateMediaList } from 'utils/redux.utils';
// import ImagePicker from 'react-native-image-crop-picker';
// import { createThumbnail } from 'react-native-create-thumbnail';
// import * as Progress from 'react-native-progress';
// import FileViewer from 'react-native-file-viewer';
// import RNFS from 'react-native-fs';

interface paginationProps {
  page: number;
  limit: number;
  search: string;
  dogId: string;
  type?: string;
}

const Media = (props: any) => {
  const sliderRef: any = useRef(null);
  const mediaList = useSelector((state: any) => state.media.mediaList);
  const training = useSelector((state: any) => state.training.training);
  const authUser = useSelector((state: any) => state.user.user);
  const [state, setState] = Functions.useSetState({
    loading: true,
    page: 1,
    limit: 20,
    search: '',
    type: 'image',
    visible: false,
    loadMoreLoader: false,
    selectedMedia: {},
    progress: 0,
    upload: false,
    mediaIndex: 0,
  });

  useEffect(() => {
    getMediaList({
      dogId: training.pet._id,
      page: state.page,
      limit: state.limit,
      search: state.search,
      type: state.type,
    });
  }, []);

  const getMediaList = async ({ page, limit, search, dogId, type }: paginationProps) => {
    try {
      let body = {
        page,
        limit,
        search,
        pet: dogId,
        type,
      };
      const media: any = await Models.media.mediaList(body);
      if (page !== 1) {
        updateMediaList([...mediaList, ...media.data.docs]);
      } else {
        updateMediaList(media.data.docs);
      }
      setState({ loading: false, hasMore: media.data.hasNextPage, loadMoreLoader: false });
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get media list');
      }
      setState({ loading: false });
    }
  };

  const goBack = () => {
    updateMediaList([]);
    props.navigation.goBack();
  };

  const onChangeFilter = (type: string) => {
    updateMediaList([]);
    setState({ type: type, loadMoreLoader: true });
    getMediaList({
      dogId: training.pet._id,
      page: 1,
      limit: state.limit,
      search: state.search,
      type: type,
    });
  };

  const loader = () => {
    return (
      <View>
        {state.loadMoreLoader && (
          <ActivityIndicator
            size="small"
            color={Colors.primaryDarkColor}
            style={{ marginLeft: 6 }}
          />
        )}
      </View>
    );
  };

  const progress = (count: number) => {
    setState({ progress: count });
  };

  // const runPromise = async (proms: any[]) => {
  //   let d = 0;
  //   progress(0);
  //   let data: any = proms.map(async (file: any) => {
  //     // file.raw = Platform.OS === "android" ? true : false;
  //     const upload = await uploadFile(file);
  //     d++;
  //     progress( (d * 100) / proms.length );
  //     return upload;
  //   });
  //   data = Promise.all(data);
  //   return data;
  // };

  // const uploadDocs = async () => {
  //   try {
  //     let accept: any = [];
  //     if (state.type === 'docs') {
  //       accept = [
  //         DocumentPicker.types.plainText,
  //         DocumentPicker.types.pdf,
  //         DocumentPicker.types.csv,
  //         DocumentPicker.types.doc,
  //         DocumentPicker.types.docx,
  //         DocumentPicker.types.ppt,
  //         DocumentPicker.types.pptx,
  //         DocumentPicker.types.xls,
  //         DocumentPicker.types.xlsx,
  //       ];
  //     } else if (state.type === 'image') {
  //       accept = [DocumentPicker.types.images];
  //     } else {
  //       accept = [DocumentPicker.types.allFiles];
  //     }
  //     const res: any = await DocumentPicker.pickMultiple({
  //       type: accept,
  //     });
  //     setState({ upload: true });
  //     let files: any = await runPromise(res);
  //     let mediaBody = await Promise.all(
  //       files.map(async (data: any) => {
  //         let mediaObj: any = {
  //           type: await Functions.checkURL(data.url),
  //           pet: training.pet._id,
  //           url: data.url,
  //           name: data.name,
  //         };
  //         if (mediaObj.type === 'video') {
  //           const thumbnail = await createThumbnail({
  //             url: data.url,
  //             timeStamp: 10000,
  //           });

  //           const thumb: any = await uploadFile({
  //             type: thumbnail.mime,
  //             uri:
  //               Platform.OS === 'android'
  //                 ? thumbnail.path.replace('file://', '')
  //                 : 'file://' + thumbnail.path,
  //             raw: Platform.OS === 'android' ? true : false,
  //             name: thumbnail.path.split('/').pop(),
  //             size: thumbnail.size,
  //           });
  //           mediaObj.thumbnail = thumb.url;
  //         }
  //         return mediaObj;
  //       }),
  //     );
  //     const media = await Models.media.addMedia(mediaBody);
  //     await getMediaList({
  //       dogId: training.pet._id,
  //       page: 1,
  //       limit: state.page * state.limit,
  //       search: state.search,
  //       type: state.type,
  //     });
  //     setState({ upload: false, progress: 0 });
  //   } catch (err) {
  //     if (DocumentPicker.isCancel(err)) {
  //       console.log('picker cancelled');
  //     } else {
  //       console.log('err', err);
  //       throw err;
  //     }
  //     setState({ upload: false, progress: 0 });
  //   }
  // };

  // const uploadImages = async () => {
  //   try {
  //     const images: any = await ImagePicker.openPicker({ multiple: true });
  //     let promiseToRun = [];
  //     for (let i = 0; i < images.length; i++) {
  //       let img = {
  //         name: Platform.OS === 'android' ? images[i].path.split('/').pop() : images[i].filename,
  //         type: images[i].mime,
  //         uri:
  //           Platform.OS === 'android' ? images[i].path : images[i].sourceURL,
  //         size: images[i].size,
  //         raw: Platform.OS === 'android' ? true : false,
  //       };
  //       promiseToRun.push(img);
  //     }
  //     // let files = await Promise.all(promiseToRun);
  //     setState({ upload: true });
  //     let files: any = await runPromise(promiseToRun);
  //     let mediaBody = await Promise.all(
  //       files.map(async (data: any) => {
  //         let mediaObj: any = {
  //           type: await Functions.checkURL(data.url),
  //           pet: training.pet._id,
  //           url: data.url,
  //           name: data.name,
  //         };
  //         if (mediaObj.type === 'video') {
  //           const thumbnail = await createThumbnail({
  //             url: data.url,
  //             timeStamp: 10000,
  //           });
  //           const thumb: any = await uploadFile({
  //             type: thumbnail.mime,
  //             uri:
  //               Platform.OS === 'android'
  //                 ? thumbnail.path.replace('file://', '')
  //                 : 'file://' + thumbnail.path,
  //             raw: Platform.OS === 'android' ? true : false,
  //             name: thumbnail.path.split('/').pop(),
  //             size: thumbnail.size,
  //           });
  //           mediaObj.thumbnail = thumb.url;
  //         }
  //         return mediaObj;
  //       }),
  //     );
  //     const media = await Models.media.addMedia(mediaBody);
  //     getMediaList({
  //       dogId: training.pet._id,
  //       page: 1,
  //       limit: state.page * state.limit,
  //       search: state.search,
  //       type: state.type,
  //     });
  //     // console.log("media", media);
  //     setState({ upload: false, progress: 0 });
  //   } catch (err) {
  //     console.log('err', err);
  //     setState({ upload: false, progress: 0 });
  //   }
  // };

  // const openPicker = (name: string) => {
  //   if (name === 'gallery') {
  //     uploadImages();
  //     // upload();
  //   } else {
  //     uploadDocs();
  //   }
  // };

  // const openFile = async (doc: any) => {
  //   const localFile = `${RNFS.DocumentDirectoryPath}/offLeashTraining.${doc.url.split(".").pop()}`;
  //   const options = {
  //     fromUrl: doc.url,
  //     toFile: localFile
  //   };
  //   RNFS.downloadFile(options).promise
  //     .then(() =>
  //       FileViewer.open(localFile, { showOpenWithDialog: true })
  //         .then(() => {
  //           // success
  //         })
  //         .catch(error => {
  //           Functions.Warning("Failed", "No app associated with this file type");
  //         })
  //     )
  //     .catch(error => {
  //       Functions.Warning("Failed", "Failed to download file");
  //     })
  // };

  const showMedia = (media: any, index: number) => {
    try {
      setState({ mediaView: true, selectedMedia: media, mediaIndex: index });
      // console.log('sliderRef', sliderRef);
      // sliderRef?.current.slideTo(index);
    } catch (err) {
      console.log('err', err);
    }
  };

  const closeModel = () => {
    setState({ mediaView: false, selectedMedia: {} });
  };

  const loadMore = () => {
    if (state.hasMore) {
      getMediaList({
        dogId: training.pet._id,
        page: state.page + 1,
        limit: state.limit,
        search: state.search,
        type: state.type,
      });
    }
  }

  // const actions = [
  //   {
  //     text: 'Gallery',
  //     name: 'gallery',
  //     render: (props: any) => (
  //       <FloatingActionButton {...props} text="Gallery" icon={Assets.AlbumWhite} />
  //     ),
  //   },
  //   {
  //     text: 'Documents',
  //     name: 'document',
  //     position: 1,
  //     render: (props: any) => (
  //       <FloatingActionButton {...props} text="Document" icon={Assets.ExportWhite} />
  //     ),
  //   },
  // ];

  const deleteMedia = async (id: any) => {
    try {
      setState({ loading: true });
      await Models.media.deleteMedia(id);
      await getMediaList({
        dogId: training.pet._id,
        page: state.page,
        limit: state.limit,
        search: state.search,
        type: state.type,
      });
      setState({ mediaView: false, loading: false });
    } catch (err) {
      console.log('Error', err);
    }
  };

  const getConfirmation = (id: any) => {
    Alert.alert('Delete Media?', 'Are you sure want to delete media', [
      {
        text: 'Yes',
        onPress: async () => deleteMedia(id),
      },
      { text: 'No' },
    ]);
  };
  const uploadMedia = async (event) => {
    try {
      const image: any =event.target.files[0];
      setState({ profileUpload: true });
      // console.log("image", image)
      const file: any = await uploadFile(image);
      // console.log("file"  , file)
      setState({ profile: file.url, profileUpload: false });
       const imageType = image.type.split('/')
      // console.log("imageType",imageType[0])
      let mediaObj: any = {
        type: imageType[0],
        pet: training.pet._id,
        url: file.url,
        name: image.name,
      };
      console.log("mediaObj" , mediaObj)
      const media = await Models.media.addMedia(mediaObj);
      mediaList.push(mediaObj)
console.log("medialist inside function" , mediaList)

      getMediaList({
        dogId: training.pet._id,
        page: 1,
        limit: state.page * state.limit,
        search: state.search,
        // type: state.type,
      });

    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <Container screen loading={state.loading}>
      {state.upload && (
        <View style={styles.uploadPrgress}>
          {/* <Progress.Circle
            progress={state.progress}
            showsText
            thickness={Ratio(10)}
            color="#F16722"
            textStyle={{
              fontSize: Ratio(28),
              fontWeight: "600",
              fontFamily: Constants.medium
            }}
            size={Ratio(120)}
          /> */}
        </View>
      )}
      <View style={styles.mediaScreenContainer}>
        <View style={styles.headerWrapper}>
          <View style={styles.headingText}>
          <View style={{ justifyContent: 'center', alignItems: 'flex-start', height: '100%' }}>
            <TouchableOpacity
              activeOpacity={0.7}
              onPress={goBack}
              style={{ justifyContent: 'center', alignItems: 'center' }}>
              <Image svg src={Assets.ArrowBack} width={25} height={25} />
            </TouchableOpacity>
          </View>
          <View style={styles.headTextWrapper}>
            <Text
              size={Ratio(24)}
              color={Colors.primaryDarkText}
              family={Constants.light}
              weight="300">
              {'Media'}
            </Text>
          </View>
          </View>
          <TouchableOpacity style={styles.headerAddButton}>
            <FileInput
              src={Assets.Plus}
              style={styles.addButton}
              iconStyle={styles.addButtonIcon}
              id="add_media"
              onChange={uploadMedia}
            />
          </TouchableOpacity>
        </View>
        <View
          style={{
            padding: '0px 20px',
            paddingBottom: Ratio(0),
            height: Ratio('8%', true),
          }}>
          <View style={styles.tabContainer}>
            <TouchableOpacity
              activeOpacity={0.7}
              style={[styles.filterBtn, state.type === 'image' && styles.selectedFilter]}
              onPress={() => onChangeFilter('image')}>
              <Text
                color={state.type === 'image' ? Colors.buttonTextColor : Colors.primaryLightText}>
                Photos
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              activeOpacity={0.7}
              style={[styles.filterBtn, state.type === 'video' && styles.selectedFilter]}
              onPress={() => onChangeFilter('video')}>
              <Text
                color={state.type === 'video' ? Colors.buttonTextColor : Colors.primaryLightText}>
                Videos
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              activeOpacity={0.7}
              style={[styles.filterBtn, state.type === 'docs' && styles.selectedFilter]}
              onPress={() => onChangeFilter('docs')}>
              <Text
                color={state.type === 'docs' ? Colors.buttonTextColor : Colors.primaryLightText}>
                Docs
              </Text>
            </TouchableOpacity>
          </View>
        </View>
        {!state.loadMoreLoader ? (
          mediaList.length > 0 ? (
            <View style={styles.mediaList}>
              <FlatList
                data={mediaList}
                showsVerticalScrollIndicator={false}
                keyExtractor={(item: any) => item.name}
                // style={styles.mediaWrapper}
                contentContainerStyle={styles.mediaWrapper}
                renderItem={({ item, index }) => {
                  if(item.type == 'image'){
                    return <TouchableOpacity
                      activeOpacity={0.7}
                      onPress={() => showMedia(item, index)}>
                      <View
                        style={[
                          styles.mediaContent,
                          index === mediaList.length - 1 && {
                            marginBottom: Ratio(50),
                          },
                        ]}>
                        <Image
                          src={item.url}
                          url
                          width="200px"
                          height="180px"
                          // width="100%"
                          // height="100%"
                          resize="cover"
                          radius={10}
                          style={styles.image}
                        />
                      </View>
                    </TouchableOpacity>
                  }else if(item.type == 'video'){
                    return <TouchableOpacity
                      activeOpacity={0.7}
                      onPress={() => showMedia(item, index)}>
                      <View
                        style={[
                          styles.mediaContent,
                          index === mediaList.length - 1 && {
                            marginBottom: Ratio(50),
                          },
                        ]}>
                        <VideoPlayer
                          src={item.url}
                          url
                          // width={Ratio(105)}
                          // height={Ratio(105)}
                          width="200px"
                          height="180px"
                          resize="cover"
                          // radius={20}

                        />
                      </View>
                    </TouchableOpacity>
                  }else{
                    return <TouchableOpacity
                      activeOpacity={0.7}
                      // onPress={() => openFile(item)}
                      style={styles.documentWrapper}>
                      <View style={[styles.pdfContainer]}>
                        <View
                          style={{ height: '70%', justifyContent: 'center', alignItems: 'center' }}>
                          <Image
                            svg
                            src={Assets.PdfIcon}
                            width={Ratio(60)}
                            height={Ratio(60)}
                            resize="contain"
                          />
                        </View>
                        <View style={styles.pdfName}>
                          <Text
                            size={Ratio(12)}
                            color={Colors.primaryLightColor}
                            ellipsizeMode="tail"
                            numberOfLines={2}>
                            {item.name}
                          </Text>
                        </View>
                      </View>
                      <TouchableOpacity
                        onPress={() => getConfirmation(item._id)}
                        style={styles.deleteButtonWrapper}>
                        <Image
                          svg
                          src={Assets.Delete}
                          width={Ratio(15)}
                          height={Ratio(15)}
                          resize="contain"
                        />
                      </TouchableOpacity>
                    </TouchableOpacity>}
                }}
            
                onEndReached={loadMore}
                scrollEnabled={true}
                ListFooterComponent={loader()}
              />
            </View>
          ) : (
            <View style={styles.emptyContainer}>
              <Text color={Colors.secondaryLightText} size={14}>{`No ${
                state.type.includes('s') ? state.type : state.type + 's'
              } available`}</Text>
            </View>
          )
        ) : (
          <View style={styles.mediaLoader}>{loader()}</View>
        )}
      </View>
      {/* <View style={[styles.floatingAction, state.visible && styles.floatingActionButton]}>
        <FloatingButton
          actions={actions}
          onPress={openPicker}
          stateChange={() => setState({ visible: !state.visible })}
        />
      </View> */}
      {state.mediaView && (
        <MediaView
          index={state.mediaIndex}
          ref={sliderRef}
          isOpen={state.mediaView}
          onBackClick={closeModel}
          data={state.selectedMedia}
          onClose={() => setState({ mediaView: false })}
          sliderData={mediaList}
          loader={state.loading}
          onDelete={(id) => getConfirmation(id)}
        />
      )}
    </Container>
  );
};

const styles = StyleSheet.create({
  mediaScreenContainer: {
    width: '100%',
    height: '100%',
  },
  headerWrapper: {
    padding: Ratio(20),
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems:"center",
    zIndex: 2,
    height: Ratio('10%', true),
  },
  headTextWrapper: {
    paddingLeft: Ratio(20),
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  textWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  tabContainer: {
    backgroundColor: Colors.buttonTextColor,
    borderRadius: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '50%',
    height: 50
  },
  filterBtn: {
    padding: '10px 0px',
    width: Ratio(112),
    justifyContent: 'center',
    alignItems: 'center',
    cursor:"pointer",
  },
  selectedFilter: {
    backgroundColor: Colors.secondaryDarkColor,
    borderRadius: 10,
  },
  mediaList: {
    height: 200,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  mediaContent: {
    // width: Ratio(100),
    // height: Ratio(100),
    width: "100%",
    height: 200,
    // justifyContent: "space-evenly"
    // paddingBottom: Ratio(20),
    // paddingLeft: Ratio(10),
    margin: Ratio(6),
    // marginLeft: Ratio(10),
    // marginBottom: Ratio(10)
  },
  pdfContent: {
    // margin: Ratio(5),
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // textAlign: "center",
    // width:Ratio(105),
    // height: Ratio(105),
    backgroundColor: Colors.buttonTextColor,
    borderRadius: 10,
  },
  mediaWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    // justifyContent: "space-evenly",
    flexWrap: 'wrap',
    width: '100%',
  },
  floatingActionButton: {
    position: 'absolute',
    width: '100%',
    height: Ratio('100%', true),
    bottom: 0,
    right: 0,
  },
  floatingAction: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: Ratio(85),
    height: Ratio(85),
  },
  emptyContainer: {
    width: '100%',
    paddingTop: Ratio(20),
    justifyContent: 'center',
    alignItems: 'center',
  },
  mediaLoader: {
    width: '100%',
    height: '70%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pdfContainer: {
    margin: Ratio(5),
    width: '100%',
    height: Ratio(150),
    backgroundColor: Colors.buttonTextColor,
    borderRadius: 20,
    marginBottom: Ratio(20),
  },
  pdfName: {
    height: '40%',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: Ratio(10),
    backgroundColor: Colors.secondaryBackground,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
  uploadPrgress: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.8)',
    zIndex: 2,
    marginTop: Constants.statusBarHeight,
  },
  deleteButtonWrapper: {
    position: 'absolute',
    height: 30,
    width: 30,
    backgroundColor: Colors.secondaryBackground,
    borderRadius: 50,
    right: 5,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  documentWrapper:{
    position: "relative",
    width: "31%"
  },
  headingText:{
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
  },
  addButton:{
    height:"40px",
    width:"40px",
    backgroundColor:Colors.secondaryDarkColor,
    borderRadius:"50%",
    // padding:"8px",
    cursor:"pointer",
  },
  headerAddButton:{
    cursor:"pointer"
  },
  addButtonIcon:{
    cursor:"pointer"
  },
  image:{
    objectFit:"cover",
  }
});

export default Media;
