import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  View,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
  Modal,
  Container,
  Text,
  NavButton,
  Assets,
  Colors,
  Ratio,
  Functions,
  Constants,
  Image,
  MessageView,
  Input,
  FileInput,
  // BottomSheet,
  // FloatingActionButton,
  // FloatingButton,
  Models,
  PrimaryButton,
  Select,
  // MediaView,
  // Select,
  // PrimaryButton,
} from 'utils/imports.utils';
import {
  getChatList,
  getMessageList,
  getTrainingList,
  getPetList,
  getCaller,
  getChat,
} from 'utils/redux.utils';
// import { uploadFile } from 'utils/s3.utils';
// import DocumentPicker from 'react-native-document-picker';
// import ImagePicker from 'react-native-image-crop-picker';
// import { createThumbnail } from 'react-native-create-thumbnail';
// import * as Progress from 'react-native-progress';
// import { CommonActions } from '@react-navigation/native';
import connectSocket from 'utils/socket.utils';
// import AsyncStorage from '@react-native-async-storage/async-storage';
// import { windowWidth, windowHeight, compressImage, compressVideo } from 'utils/functions.utils';
import { uploadFile } from 'utils/s3.utils';


let socket: any;

interface pagination {
  limit: number;
  page: number;
  search: string;
  user_type?: string;
  user_id?: string;
}

interface userProps {
  call_allowed?: boolean;
  call_limited?: boolean;
  limited_hours?: object;
  phone?: string;
  profile?: string;
  user_type: string;
  username: string;
  _id: string;
}

interface memberProps {
  user?: userProps;
  created_at?: string;
}

const Message = (props: any) => {
  const chat = useSelector((state: any) => state.chat.chat);
  const user = useSelector((state: any) => state.user.user);
  const messages = useSelector((state: any) => state.chat.messageList);
  const petList = useSelector((state: any) => state.pet.petList);
  const customer = chat.members.filter((member: any) => member.user.user_type === "customer")[0];

  let customerPets: any = [];

  if (customer) {
    const pets = petList.filter((pet: any) => pet.customer === customer.user._id);
    customerPets = [...customerPets, ...pets];
  }

  const sheetRef: any = useRef(null);
  const fileRef: any = useRef(null);

  // console.log('customerPets', customerPets);
  // console.log('customer', customer.user._id);
  // console.log('chat', chat);
  // console.log('user', user);
  // console.log('messages', messages);

  const [state, setState] = Functions.useSetState({
    loading: true,
    text: '',
    receiver: '',
    page: 1,
    limit: 500,
    loadMoreLoader: false,
    isMedia: false,
    mediaType: '',
    petModel: false,
    petSearch: '',
    dog: '',
    height: 35,
    fileNumber: 1,
    uploadLength: 0,
    compressing: false, 
    id: '',
    showSeen: false,
    petListCount: 0,
    petList:[]
  });
  useEffect(() => {
    socket = connectSocket;
      socket.emit('join-chat', { user: user._id });
      socket.on('user-connected', (data: any) => {
        console.log('user connected...');
      });
      socket.on('receive-message', (msg: any) => {
        if (msg.messages.docs[0].conversation === chat._id) {
          // readConversationMessages();
          getMessageList([...msg.messages.docs]);
          setState({ hasMore: msg.messages.hasNextPage });
        }
      });
      socket.on('read_messages', (msg: any) => {
        getMessages({ page: 1, limit: state.limit });
      });
      getCustomerPetList()
  }, []);
  useEffect(() => {
      // get socket from global
      // get messages from conversation
      getMessages({ page: state.page, limit: state.limit });
      readConversationMessages();

      // set receiver_id if the conversation is private
      if (chat.type === 'private') {
        let receiver = chat.members.find((member: any) => member.user._id !== user._id);
        setState({ receiver: receiver.user._id });
      }

      trainingList({
        limit: 20,
        page: 1,
        search: state.petSearch,
      });

    // receive messages from server
    // socket.on('receive-message', (msg: any) => {
    //   // console.log('msg', msg);
    //   if (msg.messages.docs[0].conversation === chat._id) {
    //     readConversationMessages();
    //     // getMessageList([...msg.messages.docs]);
    //     setState({ hasMore: msg.messages.hasNextPage });
    //   }
    // });

  }, []);

  const readConversationMessages = () => {
    socket.emit('read_messages', { conversation: chat._id, user: user._id }, () => {
      console.log('message readed');
    });
  };

  const backAction = () => {
    getPetList([]);
    getMessageList([]);
    getChat({});
    if (props.navigation?.state?.params?.call) {
      props.navigation.navigate('Call');
    } else {
      props.navigation.goBack();
    }
    return true;
  };

  const trainingList = async ({ limit, page, search, user_id }: pagination) => {
    try {
      let req: any = {
        limit,
        page,
        search,
      };
      if (user.user_type === 'customer') {
        req.type = 'customer';
        req.customer = user._id;
      } else if (user.user_type === 'trainer') {
        req.trainer = user._id;
        req.type = 'trainer';
      }
      const petDate: any = await Models.training.trainingList(req);
      const dog = petDate.data.docs.map((pet: any) => pet.pet);
      getPetList(dog);
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get pet list');
      }
      setState({ loading: false });
    }
  };

  const getCustomerPetList = async () =>{
    try {
      let req : any ={ 
        limit: 50,
        page: 1,
        type:"customer"
      };
      if (user.user_type === 'trainer') {
        req.customer = customer.user._id;
      } else if (user.user_type === 'customer') {
        req.customer = user._id;
      }
       
      const res : any = await Models.pet.petList(req);
      setState({petList: res.data.docs})
    } catch (error) {
      console.log('error', error);
    }
  }


  const getMessages = ({ page, limit }: any) => {
    setState({ loadMoreLoader: true });
    socket.emit('join-room', { user: user._id, conversation: chat._id, page, limit });
    socket.on('conversation-message', (data: any) => {
      // console.log('data', data);
      if (page === 1) {
        getMessageList([...data.docs]);
      } else {
        getMessageList([...messages, ...data.docs]);
      }
      setState({ loading: false, page, limit, hasMore: data.hasNextPage, loadMoreLoader: false });
    });
  };
  
  const sendMessage = () => {
    if (state.text.length > 0) {
      setState({ text: '', page: 1 });
      let req = {
        conversation: chat._id,
        text: state.text,
        user: user._id,
        receiver: state.receiver,
        type: 'text',
        limit: state.limit,
        page: 1,
        chat_type: chat.type,
      };
      console.log('req', req);
      socket.emit('send_message', [req], async (data: any) => { });
    }
  };

  const sendMedia = (msg?: any) => {
    console.log('msg sendddd', msg)
    // if (msg.length > 0) {
      let req :any= {
        conversation: chat._id,
        text: state.text,
        user: user._id,
        receiver: state.receiver,
        chat_type: chat.type,
        pet: null,
        type:msg.type,
      };
      req.media = msg
      socket.emit('send_message', [req], async (data: any) => {
        setState({ text: '', page: 1 });
      });
  };

  const loader = () => {
    return (
      <View>
        {state.loadMoreLoader && (
          <ActivityIndicator
            size="small"
            color={Colors.primaryDarkColor}
            style={{ marginLeft: 6 }}
          />
        )}
      </View>
    );
  };

  const loadMore = () => {
    console.log('messages.length', messages.length);
    console.log('state.totalDocs', state.totalDocs);
    if (messages.length < state.totalDocs) {
      console.log('test');
      getMessages({ page: state.page + 1, limit: state.limit });
    }
  };

  const goBack = () => {
    // getChatList([]);
    console.log('go back');
    getMessageList([]);
    props.navigation.goBack();
    // props.navigation.push("Chat");
    // CommonActions.reset({
    //   index: 0,
    //   routes: [{ name: 'Chat' }]
    // })
  };

  const progress = (count: number) => {
    console.log(count)
    setState({ progress: count });
  };


  // const runPromise = async (proms: any[]) => {
  //   let d = 0;
  //   progress(0);
  //   let data: any = [];
  //   for (let file of proms) {
  //     d++
  //     setState({ fileNumber: d, uploadLength: proms.length});
  //     file.raw = Platform.OS === 'android' ? true : false;
  //     const upload = await uploadFile(file, progress);
  //     data.push(upload);
  //   }
  //   return data;
  // };

  // const uploadDocs = async () => {
  //   try {
  //     let accept: any = [];
  //     if (state.type === 'docs') {
  //       accept = [
  //         DocumentPicker.types.plainText,
  //         DocumentPicker.types.pdf,
  //         DocumentPicker.types.csv,
  //         DocumentPicker.types.doc,
  //         DocumentPicker.types.docx,
  //         DocumentPicker.types.ppt,
  //         DocumentPicker.types.pptx,
  //         DocumentPicker.types.xls,
  //         DocumentPicker.types.xlsx,
  //       ];
  //     } else if (state.type === 'image') {
  //       accept = [DocumentPicker.types.images];
  //     } else {
  //       accept = [DocumentPicker.types.allFiles];
  //     }
  //     const res: any = await DocumentPicker.pickMultiple({
  //       type: accept,
  //     });
  //     sheetRef?.current.close();
  //     setState({ upload: true, petModel: false });
  //     let messageArray: any = [];
  //     let files: any = await runPromise(res);
  //     let mediaBody = await Promise.all(
  //       files.map(async (data: any) => {
  //         let mediaObj: any = {
  //           type: await Functions.checkURL(data.url),
  //           pet: state.dog,
  //           url: data.url,
  //           name: data.name,
  //         };
  //         if (mediaObj.type === 'video') {
  //           try {
  //             const thumbnail = await createThumbnail({
  //               url: data.uri,
  //               timeStamp: 1000,
  //             })
  //             const thumb: any = await uploadFile({
  //               type: thumbnail.mime,
  //               uri:
  //                 Platform.OS === 'android'
  //                   ? thumbnail.path.replace('file://', '')
  //                   : 'file://' + thumbnail.path,
  //               raw: Platform.OS === 'android' ? true : false,
  //               name: thumbnail.path.split('/').pop(),
  //               size: thumbnail.size,
  //             });
  //             mediaObj.thumbnail = thumb.url;
  //           } catch (err) {
  //             console.log("err", err)
  //           }
  //         }
  //         let message: any = { ...mediaObj };
  //         (message.conversation = chat._id),
  //           (message.text = state.text),
  //           (message.user = user._id),
  //           (message.receiver = state.receiver),
  //           (message.chat_type = chat.type);
  //         message.media = mediaObj;
  //         message.limit = 20;
  //         message.page = 1;
  //         messageArray.push(message);
  //         return mediaObj;
  //       }),
  //     );
  //     const media = await Models.media.addMedia(mediaBody);
  //     sendMedia(messageArray);
  //     setState({ upload: false, progress: 0 });
  //   } catch (err: any) {
  //     if (typeof err.message === 'string') {
  //       Functions.Failure(err.message)
  //     }
  //     if (typeof err?.response?.message === 'string') {
  //       Functions.Failure(err?.response?.message)
  //     }
  //     console.log('err, ', err);
  //     setState({ upload: false, progress: 0, petModel: false });
  //   }
  // };

  // const uploadImages = async () => {
  //   try {
  //     checkPermission();
  //     const images: any = await ImagePicker.openPicker({ multiple: true, maxFiles:10 });
  //     let promiseToRun = [];
  //     sheetRef?.current.close();
  //     setState({ upload: true, petModel: false, compressing: true, uploadLength: images.length});
  //     for (let i = 0; i < images.length; i++) {
  //       let img = {
  //         name: Platform.OS === 'android' ? images[i].path.split('/').pop() : images[i].filename,
  //         type: images[i].mime,
  //         uri: Platform.OS === 'android' ? images[i].mime.includes("video") ? await compressVideo(images[i].path,progress) : await compressImage(images[i].path)
  //           : images[i].mime.includes("video") ? await compressVideo(images[i].sourceURL, progress) : await compressImage(images[i].sourceURL),
  //         size: images[i].size,
  //         raw: Platform.OS === 'android' ? true : false,
  //       };
  //       setState({ fileNumber: i + 1,  });
  //       promiseToRun.push(img);
  //     }

  //     setState({compressing: false});
  //     let files: any = await runPromise(promiseToRun);
  //     let messageArray: any = [];
  //     console.log(files)
  //     let mediaBody = await Promise.all(
  //       files.map(async (data: any) => {
  //         let mediaObj: any = {
  //           type: await Functions.checkURL(data.url),
  //           pet: findPet(customerPets),
  //           customer: customer?.user?._id,
  //           url: data.url,
  //           name: data.name,
  //         };
  //         if (mediaObj.type === 'video') {
  //           const thumbnail = await createThumbnail({
  //             url: data.url,
  //             timeStamp: 1000,
  //           });
  //           const thumb: any = await uploadFile({
  //             type: thumbnail.mime,
  //             uri:
  //               Platform.OS === 'android'
  //                 ? thumbnail.path
  //                 : 'file://' + thumbnail.path,
  //             raw: Platform.OS === 'android' ? true : false,
  //             name: thumbnail.path.split('/').pop(),
  //             size: thumbnail.size,
  //           });
  //           mediaObj.thumbnail = thumb.url;
  //         }
  //         let message: any = { ...mediaObj };
  //         (message.conversation = chat._id),
  //           (message.text = state.text),
  //           (message.user = user._id),
  //           (message.receiver = state.receiver),
  //           (message.chat_type = chat.type);
  //         message.media = mediaObj;
  //         message.limit = 20;
  //         message.page = 1;
  //         messageArray.push(message);
  //         return mediaObj;
  //       }),
  //     );
  //     const media = await Models.media.addMedia(mediaBody);
  //     sendMedia(messageArray);
  //     setState({ upload: false, progress: 0 });
  //   } catch (err: any) {
  //     if (typeof err.message === 'string') {
  //       Functions.Failure(err.message)
  //     }
  //     if (typeof err?.response?.message === 'string') {
  //       Functions.Failure(err?.response?.message)
  //     }
  //     console.log('err', err);
  //     setState({ upload: false, progress: 0, petModel: false });
  //   }
  // };

  const findPet = (pets: any) => {
    if (pets.length > 0) {
      return pets[0]
    }
    return null;
  }

  // const openMedia = (type: string) => {
  //   // sheetRef?.current.close();
  //   setTimeout(() => {
  //     setState({ mediaType: type, petModel: false });
  //     openDocs()
  //   }, 100);
  // };

  // const openDocs = async () => {
  //   try {
  //     // if (state.dog) {
  //       if (state.mediaType === 'docs') {
  //         uploadDocs();
  //       } else {
  //         uploadImages();
  //         // sheetRef?.current.close();
  //       }
  //     // } else {
  //     //   Functions.Warning('Warning', 'Select Dog');
  //     // }
  //   } catch (err) {
  //     console.log('err', err);
  //   }
  // };

  const closePetModel = () => {
    setState({ petModel: false, dog: '' });
  };

  const handleCall = async () => {
    const caller = await chat.members.find((member: any) => member.user._id !== user._id);
    if (
      (caller.user?.call_limited && Functions.isCallAvailable(caller.user.limited_hours)) ||
      caller.user.call_allowed
    ) {
      const callerDetails: any = await Models.user.viewUser(caller.user._id);
      getCaller(callerDetails.data);
      props.navigation.navigate('Call');
    } else {
      showMessage(caller.user.username);
    }
  };

  const showMessage = (name: string) => {
    Functions.Info('Sorry', `${name} is not available to take calls right now.`);
  };

  const isUserChat = (members: Array<memberProps>, host: memberProps) => {
    let memberExist = false;
    memberExist = members.some((member: memberProps) => member?.user?._id === user._id);
    if (host.user?._id === user._id) {
      memberExist = true;
    }

    if (host.user === user._id) {
      memberExist = true;
    }
    // console.log(host.user, user._id, chat.members, chat)
    return memberExist;
  }

  const getMessageType = (item: any) => {
    if (user.user_type === "admin") {
      if (isUserChat(chat.members, chat.host)) {
        return item.user === user._id
      } else {
        return ((item.user === chat.host[0]?.user) ? (item.user === chat.host[0]?.user) : (item.user === chat.host?.user) )
      }
    } else {
      return item.user === user._id
    }
  };
  const uploadMedia = async (event) => {
    setState({ isMedia: true });
    try {
      const image: any = event.target.files[0];
      setState({ profileUpload: true });
      // console.log('image', image);
      const file: any = await uploadFile(image);
      // console.log("file" , file)
      const imageType = image.type.split('/')[0]
      // console.log(imageType[0])

      let mediaObj: any = {
        type: imageType,
        pet: state.dog ? state.dog : findPet(customerPets),
        customer: customer?.user?._id,
        url: file.url,
        name: image.name,
      };
      // if (mediaObj.type === 'video') {
        
      // } 
      let message: any = { ...mediaObj };
          (message.conversation = chat._id),
            (message.text = state.text),
            (message.user = user._id),
            (message.receiver = state.receiver),
            // (message.chat_type = chat.type);
          message.media = mediaObj;
          message.limit = 20;
          message.page = 1;
          messages.unshift(message);
        
          const media = await Models.media.addMedia(mediaObj);
          // console.log("media" , media);
          // console.log("mediaobj " , mediaObj);
          // messages.push(image);
          sendMedia(mediaObj);
    } catch (err) {
      console.log('err', err);
      // if (err.message) {
      
    }
    setState({petModel:false})
  };
  const handleLongClick = (item: any) => {
    console.log("handellongclick")
    if (item.user === user._id) {
      setState({ showDelete: true, id: item._id });
    }
  };
  const unSend = (item) => {
    console.log('first')
    let req = {
      messages: item,

    };
    socket.emit('unsend_message', req, async (data: any) => {
      console.log('data', data);
    });
    let messageData =messages
    let index = _.findIndex(messages, {_id:item});
    messageData.splice(index , 1)
    setState({value:true})
  };
  const isSeen = (item:any) =>{
    if(props.route?.params?.members) {
      const userData:any = _.find(props.route.params.members ,function(data:any){return data.user._id !== user._id } );
     const index = _.findIndex(item.read , {user:userData.user._id} ) 
      if(index !== -1){
        return true;
      }
    }
    return false;
  }
  return (
    <Container screen loading={false} styles={styles.messageMainContainer}>
      {/* <KeyboardAvoidingView
          style={styles.keyboardView}
          keyboardVerticalOffset={-150}
          behavior="height"> */}
      {state.upload && (
        <View style={styles.uploadPrgress}>
          {/* <Progress.Circle
            progress={state.progress}
            showsText
            thickness={10}
            color="#F16722"
            textStyle={{
              fontSize: Ratio(28),
              fontWeight: '600',
              fontFamily: Constants.medium,
            }}
            size={150}
          /> */}
          <Text
            color={Colors.secondaryDarkColor}
            size={16}
            weight="bold"
            top={20}>
            {`${state.compressing ? "Compressing " : "Uploading "} ${state.fileNumber} of ${state.uploadLength}`}
          </Text>
        </View>
      )}
      <View style={styles.messageContainer}>
        <View style={styles.headerWrapper}>
          <View style={styles.profileContent}>
            <View style={{ paddingRight: Ratio(10) }}>
              <NavButton
                icon={Assets.ArrowBack}
                svg
                onClick={() => goBack()}
                iconHeight={Ratio(25)}
                iconWidth={Ratio(25)}
                disabled={false}
              />
            </View>
            {chat.type === 'private' &&
              chat.members.find((member: any) => member.user._id !== user._id).user.profile ? (
              <Image
                url
                src={chat.members.find((member: any) => member.user._id !== user._id).user.profile}
                width={Ratio(45)}
                height={Ratio(45)}
                resize="contain"
                radius={10}
              />
            ) : chat.type === 'group' && chat?.profile ? (
              <Image
                url
                src={chat?.profile}
                width={Ratio(45)}
                height={Ratio(45)}
                resize="contain"
                radius={10}
              />
            ) : (
              <NavButton
                icon={Assets.SingleUserPrimary}
                svg
                disabled
                iconHeight={Ratio(40)}
                iconWidth={Ratio(40)}
                style={{
                  width: Ratio(66),
                  height: Ratio(66),
                  backgroundColor: Colors.secondaryLightColor,
                  borderRadius: 20,
                }}
              />
            )}
            <Text
              size={Ratio(16)}
              styles={{ paddingLeft: Ratio(8) }}
              color={Colors.primaryDarkText}
              family={Constants.regular}
              weight="400">
              {chat.type === 'private'
                ? chat.members.find((member: any) => member.user._id !== user._id)?.user?.username
                : chat.name}
            </Text>
          </View>
          <View style={styles.headTextWrapper}>
            {/* <NavButton
              icon={Assets.PhonePrimary}
              svg
              iconHeight={Ratio(25)}
              iconWidth={Ratio(25)}
              onClick={() => handleCall()}
              disabled={
                !(chat.members.find((member: any) => member.user._id !== user._id)?.user
                  ?.call_limited ?
                  Functions.isCallAvailable(
                    chat.members.find((member: any) => member.user._id !== user._id)?.user
                      ?.limited_hours,
                  ) : true ||
                  !chat.members.find((member: any) => member.user._id !== user._id)?.user?.call_allowed)
              }
            // onDisableClick={() => showMessage(chat.members.find((member: any) => member.user._id !== user._id)?.user?.username)}
            /> */}
          </View>
        </View>
        {state.loading ?
          <View style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
            <ActivityIndicator size="small" color={Colors.primaryDarkColor} />
          </View>
          :
          <View style={[styles.messageListContainer, { flex: isUserChat(chat.members, chat.host) ? 0.85 : 1, height: "90vh", overflow: "scroll"}]}>
            <FlatList
              data={messages}
              extraData={messages}
              inverted
              showsVerticalScrollIndicator={false}
              keyExtractor={(item: any) => item.name}
              renderItem={({ item, index }) => (
                <TouchableOpacity activeOpacity={1} style={{ marginBottom: Ratio(20) }}>
                  <MessageView
                    text={item.text}
                    type={item.type}
                    data={item}
                    isReceived={getMessageType(item)}
                    isSeen={isSeen(item) && index == 0 && user._id == item.user ? true : false}
                    unSendButton={item.user == user._id}
                    deleteClick={() => {
                      if (item.user == user._id) {
                        unSend(item._id);
                      }
                    }}
                  />
                </TouchableOpacity>
              )}
              scrollEnabled={true}
              ListFooterComponent={loader()}
            />
          </View>
        }
        {isUserChat(chat.members, chat.host) &&
          <View style={styles.keyboardView}>
            <View style={styles.inputContainer}>
              <View style={styles.input}>
                <Input
                  onChange={(text: string) => setState({ text: text })}
                  value={state.text}
                  name="text"
                  styles={{
                    width: "100%",
                    color: Colors.primaryDarkColor,
                    height: Math.min(100, Math.max(50, state.height)),
                    paddingTop: 12,
                    paddingBottom: 10,
                    paddingLeft: 10,
                    paddingRight: 10,
                    backgroundColor: "white",
                    borderRadius: 10,
                    fontSize: 20,
                    lineHeight: 28
                  }}
                />
              </View>
              <View
                style={{
                  width: '20%',
                  height: '100%',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  flexDirection: 'row',
                  // backgroundColor: "blue"
                }}>
                  {customerPets.length > 1 ?
                <NavButton
                  icon={Assets.AttachMedia}
                  svg
                  style={{ width: Ratio(50), height: Ratio(50) , backgroundColor:"white" }}
                  iconHeight={Ratio(25)}
                  iconWidth={Ratio(25)}
                  onClick={()=> setState({petModel:true})}
                  /> :
                <FileInput style={styles.fileInput} src={Assets.AttachMedia} iconStyle={styles.addImageIcon} id="add_user" onChange={uploadMedia}/>
                  }
                <NavButton
                  icon={Assets.Send}
                  svg
                  style={{
                    width: Ratio(50),
                    height: Ratio(50),
                    backgroundColor: Colors.primaryDarkColor,
                  }}
                  iconHeight={Ratio(25)}
                  iconWidth={Ratio(25)}
                  onClick={() => sendMessage()}
                />
              </View>
            </View>
          </View>
        }
      </View>
      {/* <BottomSheet
        ref={sheetRef}
        height={100}
        closeDuration={100}
        containerStyle={styles.bottomSheetContainer}
        wrapperStyle={styles.wrapperStyle}>
        <View style={styles.mediaBtnContent}>
          <TouchableOpacity
            activeOpacity={0.7}
            onPress={() => {
              openMedia('image');
              // setState({ petModel: true });
            }}>
            <View style={styles.imageContainer}>
              <Image svg src={Assets.AlbumWhite} width={Ratio(20)} height={Ratio(20)} />
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            activeOpacity={0.7}
            onPress={() => {
              openMedia('docs');
              // setState({ petModel: true });
            }}>
            <View style={styles.imageContainer}>
              <Image svg src={Assets.ExportWhite} width={Ratio(20)} height={Ratio(20)} />
            </View>
          </TouchableOpacity>
        </View>
      </BottomSheet> */}
      <Modal
        open={state.petModel}
        showCloseIcon={false}
        onClose={() => setState({ petModel: false })}
        classNames={{
          modal:"selectDogModal",
          overlay:"selectDogModalOverlay"
        }}
        >
        <View style={styles.petModelContainer}>
          <View style={styles.petModel}>
            <View>
              <View style={styles.modelHeader}>
                <Text color={Colors.primaryDarkColor}>Select Dog for media</Text>
                <NavButton icon={Assets.Close} svg onClick={() => closePetModel()} />
              </View>
              <Select
                labelValue="name"
                keyValue="_id"
                data={state.petList}
                search
                selected={state.dog}
                onOpenSelect={() => {
                  trainingList({
                    page: state.page,
                    limit: state.limit,
                    search: '',
                  });
                }}
                onSelect={(value: string) => setState({ dog: value })}
                onSearch={(text: any) => {
                  if (!_.isObject(text)) {
                    trainingList({
                      page: state.page,
                      limit: state.limit,
                      search: text,
                    });
                  }
                }}
              />
              <View style={styles.btnContent}>
                <View style={{ width: '40%' }}>
                <PrimaryButton text="Done" width="100%" backgroundColor={Colors.primaryDarkColor} onPress={() => {fileRef.current.click()}}/>
                <input type={"file"} ref={fileRef} onChange={uploadMedia} style={{display:"none"}}/>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </Container>
  );
};

const styles = StyleSheet.create({
  messageMainContainer: {
    height: '100%',
    paddingTop: 16,
    alignItems: "flex-end",
    marginTop: 0
    // paddingBottom: 0
  },
  messageContainer: {
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    paddingBottom: 20
    // backgroundColor:Colors.background
  },
  headerWrapper: {
    width: '100%',
    padding: Ratio(20),
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '10%',
    // backgroundColor:Colors.buttonTextColor

  },
  headTextWrapper: {},
  profileContent: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  placeholderProfile: {
    width: Ratio(45),
    height: Ratio(45),
    backgroundColor: Colors.secondaryLightColor,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
  },
  messageListContainer: {
    // height: Platform.OS === 'android' ? '80%' : '85%',
    // flex: 0.85,
    paddingHorizontal: Ratio(20),
    paddingVertical: Ratio(10),
    flexDirection: "column-reverse",
    // backgroundColor: "red"
  },
  inputContainer: {
    // position: "absolute",
    // bottom: 0,
    height: '100%',
    width: '100%',
    paddingHorizontal: Ratio(20),
    paddingVertical: Ratio(10),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    // backgroundColor: "red"
  },
  input: {
    width: '80%',
    // height: "100%"
  },
  keyboardView: {
    // height: '10%',
    width: '100%',
    alignItems: 'flex-end',
    flex: 0.15
  },
  bottomSheetContainer: {
    position: 'absolute',
    bottom: Ratio(100),
    left: Ratio(20),
  },
  wrapperStyle: {
    width: '100%',
    padding: 20,
    paddingBottom: Ratio(100),
  },
  imageContainer: {
    width: Ratio(50),
    height: Ratio(50),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: Ratio(50) / 2,
    elevation: 20,
    backgroundColor: Colors.secondaryDarkColor,
  },
  mediaBtnContent: {
    backgroundColor: Colors.buttonTextColor,
    height: '100%',
    borderRadius: 15,
    flexDirection: 'row',
    alignItems: 'center',
    padding: Ratio(20),
    justifyContent: 'space-evenly',
  },
  petModel: {
    backgroundColor: Colors.buttonTextColor,
    borderRadius: 20,
    width: '100%',
    height:"100%",
    maxHeight: 500,
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: Ratio(20),
  },
  modelHeader: {
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  btnContent: {
    justifyContent: 'center',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    padding:"20px 0px"
  },
  uploadPrgress: {
    position: 'absolute',
    // width: windowWidth,
    // height: windowHeight,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.6)',
    zIndex: 2,
    // marginTop: Constants.statusBarHeight,
  },
  petModelContainer: {
    width: "100%",
    height:"100%",
  },
  uploadStatus: {
    color: "white"
  },
  fileInput:{
    backgroundColor: "white",
    padding: "12px",
    borderRadius:"8px",
    // boxShadow: "0px 0px 20px -15px rgba(0, 0, 0, 0.75)",
  }
});

export default Message;
