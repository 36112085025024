import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import {
  View, 
  StyleSheet, 
  TouchableOpacity,
  Text,
  Container,
  Colors,
  Modal,
  NavButton,
  Assets,
  VideoPlayer,
  Image,
  Ratio,
  Constants,
  // Swiper,
} from 'utils/imports.utils';
import { useSelector } from 'react-redux';
import { useSetState } from 'utils/functions.utils';

interface viewProps {
  isOpen?: any;
  src?: string;
  type?: string;
  onClose?: any;
  data?: any;
  onBackClick?: any;
  sliderData?: any;
  index?: number;
  onDelete?: any;
  loader?: any;
  from?: string;
}

const MediaView = forwardRef((props: viewProps, ref: any) => {
  const sliderRef: any = useRef();
  const authUser = useSelector((state: any) => state.user.user);
  const [state, setState] = useSetState({
    mediaId: "",
  });
  const { isOpen, type, src, onClose, data, onBackClick, sliderData, index, onDelete, loader , from  } = props;

  useEffect(() => {
    setState({ mediaId: data._id });
  },[])
  // const [state, setState] = Functions.useSetState({ index: 0 });
  // console.log('index', index);
  // console.log('data', data);
  // console.log('sliderRef', sliderRef.current);
  // useImperativeHandle(ref, () => ({
  //   slideTo(index: number) {
  //     console.log("imperactive index", index);
  //     setState({ index: index });
  //     // sliderRef?.current?.scrollToIndex(2);
  //   },
  // }));

  const renderImage = ({ item }: any) => {
    return (
      <View>
        <Image src={item.url} url width="100%" height="100%" />
      </View>
    );
  };

  const renderVideo = () => {
    return (
      <View>
        <VideoPlayer src={data.url} url />
      </View>
    );
  };

  return (
    // <Container screen>
      <Modal
        open={isOpen}
        onClose={onClose}
        styles={{
          modalContainer: {
            backgroundColor: 'transparent',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap:"30px",
          },
          modal: {borderRadius:"16px", maxHeight:"500px", maxWidth:"600px" },
          closeIcon:{display:"none"},
        }}>
        <View style={styles.headerContent}>
          <View style={styles.headerWrapper}>
            <NavButton icon={Assets.ArrowBack} svg onClick={onBackClick} />
            <Text
              size={Ratio(24)}
              color={Colors.primaryDarkText}
              family={Constants.light}
              left={Ratio(10)}
              weight="300">
              {'Photos & Videos'}
            </Text>
          </View>
        {from !== 'chat' &&
            (authUser?.user_type === 'trainer' || authUser?.user_type === 'admin') && (
               <NavButton
                 icon={Assets.Delete}
                 style={styles.navButton}
                 svg
                 onClick={() => onDelete(state.mediaId)}
                />
           )}
        </View>

        <View style={styles.mediaContent}>
          {/* { data.type === "video" ?
              <View>
                <VideoPlayer src={data.url} url />
              </View>
            : <View>
                <Image src={data.url} url width="100%" height="100%" />
              </View>} */}
          {/* <Swiper
            ref={sliderRef}
            // index={index}
            // onChangeIndex={(value: any) => { 
            //   const index: any = value?.index;
            //   const id = sliderData[index]?._id;
            //   setState({ mediaId: id });
            // }}
            > */}
          {sliderData.map((item: any, index: number) => (
            <TouchableOpacity
              activeOpacity={1}
              style={{
                height: '100%',
                // width: Ratio('100%'),
                justifyContent: 'center',
              }}>
              {item.type === 'video'? (
                <VideoPlayer src={item.url} url height="300px" width="500px"/>
              ) : (
                <View
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Image
                    src={item.url}
                    url
                    width={330}
                    height={330}
                    resize={'cover'}
                    radius={20}
                  />
                </View>
              )}
            </TouchableOpacity>
          ))}
          {/* </Swiper> */}
        </View>
      </Modal>
    // </Container>
  );
});

const styles = StyleSheet.create({
  headerContent: {
    position: 'absolute',
    paddingLeft: Ratio(20),
    paddingRight: Ratio(20),
    top: Ratio(20),
    width: '100%',
    height: '8%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: "center",
    zIndex: 1,
  },
  mediaContent: {
    width: '100%',
    height: '100%',
    paddingTop:"50px"
    // justifyContent: 'center',
    // backgroundColor: "red"
  },
  headerWrapper:{
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical:"30px",
  },
  navButton:{
    backgroundColor: Colors.secondaryBackground
  }
});

export default MediaView;
