import React, { useEffect } from 'react';
import { getTrainerList, getCustomerList, getPetDetails } from 'utils/redux.utils';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Assets,
  Constants,
  Image,
  NavButton,
  Ratio,
  Text,
  Colors,
  Input,
  Functions,
  PrimaryButton,
  Container,
  Models,
  Select,
  Validation,
  FileInput,
} from 'utils/imports.utils';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { uploadFile } from 'utils/s3.utils';

interface paginationProps {
  page: number;
  limit: number;
  search: string;
  userId?: string;
  userType?: string;
}

const AddDog = (props: any) => {
  const user = useSelector((state: any) => state.user.user);
  const trainers = useSelector((state: any) => state.user.trainerList);
  const customers = useSelector((state: any) => state.user.customerList);
  const acuity = useSelector((state: any) => state.acuity.acuity);
  const acuityUser = useSelector((state: any) => state.user.acuityUser);
  const [state, setState] = Functions.useSetState({
    name: '',
    category: '',
    gender: '',
    age: '',
    age_type: '',
    customer: '',
    value: '',
    profile: '',
    loading: true,
    page: 1,
    limit: 20,
    customerSearch: '',
    trainerSearch: '',
    adding: false,
    edit: false,
    profileUpload: false,
  });

  useEffect(() => {
    // props.updateRouteParams(props.location.state);
    if (props.route.params.view) {
      getPetDetails(props.route.params.id);
    }
    if (props.route.params?.appoinment) {
      setState({ customer: acuityUser._id });
      getUsers({
        page: state.page,
        limit: state.limit,
        search: state.search,
        userType: 'customer',
        userId: acuityUser._id,
      });
    }
    getUsers({
      page: state.page,
      limit: state.limit,
      search: state.search,
      userType: 'trainer',
    });
  }, []);

  useEffect(()=>{
    if(props.route.params?.clientData){
      setState({customer : props.route.params.clientData?.id})
      getUsers({
        page: state.page,
        limit: state.limit,
        search: '',
        userType: 'customer',
        userId:'',
      });
    }
  },[props.route.params])
  
  const backAction = () => {
    props.navigation.goBack();
    return true;
  };

  const getPetDetails = async (id: string) => {
    try {
      const dog: any = await Models.pet.getPetById(id);
      setState({
        name: dog.data.name,
        age: dog.data?.age?.toString(),
        age_type: dog.data?.age_type,
        gender: dog.data?.gender,
        category: dog.data?.category,
        customer: dog.data?.customer?._id,
        profile: dog.data?.profile ? dog.data.profile : '',
      });
      getUsers({
        page: state.page,
        limit: state.limit,
        search: state.customerSearch,
        userId: dog.data?.customer?._id,
        userType: 'customer',
      });
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get dog details');
      }
    }
  };

  const getUsers = async ({ limit, page, search, userId, userType }: paginationProps) => {
    try {
      let req: any = { page, limit, search, user_type: userType };
      if (userType === 'customer') {
        req.user_id =
          props.route.params.view || props.route.params?.appoinment
            ? userId
              ? userId
              : state.customer
            : '';
      }
      const user: any = await Models.admin.userList(req);
      const usersData = user.data.docs.map((list: any) => {
        return {
          label: list.username,
          value: list._id,
        };
      });
      if (userType === 'trainer') {
        if (page !== 1) {
          getTrainerList([...trainers, ...usersData]);
        } else {
          getTrainerList([...usersData]);
        }
        setState({ loading: false, page, limit, trainerSearch: search });
      } else {
        if (page !== 1) {
          getCustomerList([...customers, ...usersData]);
        } else {
          getCustomerList([...usersData]);
        }
        setState({ loading: false, page, limit, customerSearch: search });
      }
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure(`Failed to get ${userType} list`);
      }
      setState({ loading: false });
    }
  };

  const onClose = () => {
    if (props.route.params.from =="dog_profile"){
      props.navigation.goBack();
      } else{
        props.navigation.navigate("DogList")
      }
      return true
  };

  const addDog = async () => {
    try {
      setState({ adding: true });
      let dog = {
        name: state.name,
        category: state.category,
        gender: state.gender,
        age: state.age,
        age_type: state.age_type,
        customer: state.customer,
        profile: state.profile,
      };
      await Validation.addPet.validate(dog);
      const added: any = await Models.pet.createPet(dog);
      user.created_pet = true
      Functions.Success('Success', 'Dog added');
      setState({ adding: false });
      onClose();
    } catch (err) {
      Functions.Failure(err)
      setState({ adding: false });
    }
  };

  const editDog = async () => {
    try {
      setState({ adding: true });
      let dog: any = {
        name: state.name,
        category: state.category,
        gender: state.gender,
        age: state.age,
        age_type: state.age_type,
        customer: state.customer,
        profile: state.profile,
      };
      await Validation.addPet.validate(dog);
      dog.id = props.route.params.id;
      const edit: any = await Models.pet.editPet(dog);
      Functions.Success('Success', 'Dog updated');
      setState({ adding: false });
      onClose();
    } catch (err) {
      console.log('err', err);
      Functions.Failure(err)
      setState({ adding: false });
    }
  };

  const uploadProfile = async (event) => {
    try {
      const image: any = event.target.files[0];
      setState({ profileUpload: true });
      const file: any = await uploadFile(image);
      setState({ profile: file.url, profileUpload: false });
    } catch (err) {
      console.log('err', err);
      Functions.Failure(err)
    }
  };

  const saveDogAddTraining = async () => {
    try {
      setState({ activity: true });
      let dog = {
        name: state.name,
        category: state.category,
        gender: state.gender,
        age: state.age,
        age_type: state.age_type,
        customer: state.customer,
        profile: state.profile,
      };
      await Validation.addPet.validate(dog);
      const added: any = await Models.pet.createPet(dog);
      Functions.Success('Success', 'Dog added');
      props.navigation.navigate('AddTraining', {
        dogData: { name: added?.data?.name, id: added?.data?._id },
        add: true,
      });
      setState({ activity: false });
    } catch (err) {
      if(err){
      Functions.Failure(err)
      }else{
        Functions.Failure('Failed to add dog');
      }
      
      setState({ activity: false });
    }
  };

  const editDogAddTraining = async () => {
    try {
      setState({ activity: true });
      let dog: any = {
        name: state.name,
        category: state.category,
        gender: state.gender,
        age: state.age,
        age_type: state.age_type,
        customer: state.customer,
        profile: state.profile,
      };
      await Validation.addPet.validate(dog);
      dog.id = props.route.params.id;
      const added: any = await Models.pet.editPet(dog);
      props.navigation.navigate('AddTraining', {
        dogData: { name: added?.data?.name, id: added?.data?._id },
        add: true,
      });
      Functions.Success('Success', 'Dog updated');
      setState({ activity: false });
      // onClose();
    } catch (err) {
      if(err){
      Functions.Failure(err)
      }else{
        Functions.Failure('Failed to update dog details');
      }
      
      setState({ activity: false });
    }
  };

  const gotoAddClient = () => {
    props.navigation.navigate('AddUser', {
      buttonText: 'Add Client',
      type: 'customer',
      add: true,
    });
  };

  return (
    <Container screen loading={state.loading} styles={{ height: '100%' }}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={styles.addPetContainer}>
          <View style={styles.headerWrapper}>
            <View style={styles.textWrapper}>
              <View style={styles.imageContainer}>
                <TouchableOpacity
                  activeOpacity={0.7}
                  style={{
                    width: Ratio(40),
                    height: Ratio(40),
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onPress={onClose}>
                  <Image svg src={Assets.ArrowBack} width={20} height={20} />
                </TouchableOpacity>
              </View>
              <View style={styles.headTextWrapper}>
                <Text
                  top={0}
                  size={24}
                  color={Colors.primaryDarkText}
                  family={Constants.light}
                  weight="300">
                  {props.route.params.add ? 'Add Dog' : 'Edit'}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.addPetFormContainer}>
            <View style={styles.formBody}>
              <View style={styles.bodyContainer}>
                <View style={styles.addPetIconContainer}>
                  {!state.profile && !state.profileUpload ? (
                    <View style={styles.profileContainer}>
                      <NavButton
                        icon={Assets.DogPrimary}
                        disabled
                        width="100px"
                        height="100px"
                        style={styles.navIcon}
                        iconHeight={Ratio(80)}
                        iconWidth={Ratio(80)}
                        svg
                      />
                    </View>
                  ) : state.profile && !state.profileUpload ? (
                    <View style={styles.profileImage}>
                      <Image
                        src={state.profile}
                        height="100%"
                        width="100%"
                        url
                        resize="cover"
                        radius={30}
                      />
                    </View>
                  ) : (
                    <View style={styles.profileLoading}>
                      {/* <Progress.Circle
                        size={30}
                        borderWidth={3}
                        indeterminate
                        color={Colors.buttonTextColor}
                      /> */}
                    </View>
                  )}
                  <TouchableOpacity
                    style={styles.uploadIcon}
                    activeOpacity={0.7}>
                      <FileInput style={styles.fileInput} src={Assets.EditWhite} iconStyle={styles.addImageIcon} id="add_dog" onChange={uploadProfile}/>
                  </TouchableOpacity>
                </View>
                <View style={{ width: '100%', padding: '10px 0px' }}>
                  <Input
                    name="name"
                    label="Full Name"
                    onChange={(name: string) => setState({ name })}
                    placeholder="Full Name"
                    value={state.name}
                    />
                </View>
                <View style={{ width: '100%', padding: '10px 0px', zIndex: 100 }}>
                  <Select
                    data={Constants.breeds}
                    keyValue={'value'}
                    label="Breeds"
                    labelValue="label"
                    selected={state.category}
                    onSelect={(value: any) => setState({ category: value })}
                  />
                </View>
                <View style={{ width: '100%', padding: '10px 0px', zIndex: 90 }}>
                  <Select
                    data={Constants.genderList}
                    keyValue={'value'}
                    label="Gender"
                    labelValue="label"
                    selected={state.gender}
                    onSelect={(value: any) => setState({ gender: value })}
                  />
                </View>
                <View style={{ width: '100%', padding: '10px 0px', zIndex: 80 }}>
                  <Select
                    data={Constants.Age()}
                    keyValue="value"
                    keyType="type"
                    label="Age"
                    labelValue="label"
                    selected={state.age}
                    onSelect={(value: any, type: any) => {
                      setState({ age: value, age_type: type });
                    }}
                  />
                </View>
                <View style={{ width: '100%', padding: '10px 0px', zIndex: 60 }}>
                  <Select
                    key="value"
                    data={customers}
                    keyValue={'value'}
                    label="Client"
                    labelValue="label"
                    selected={state.customer}
                    search
                    onOpenSelect={(value: any) => {
                      if (value) {
                        getUsers({
                          page: state.page,
                          limit: state.limit,
                          search: '',
                          userType: 'customer',
                          userId: props.route.params?.appoinment ? acuityUser._id : '',
                        });
                      }
                    }}
                    onSearch={(text: any) => {
                      if (!_.isObject(text)) {
                        getUsers({
                          page: state.page,
                          limit: state.limit,
                          search: text,
                          userType: 'customer',
                          userId: props.route.params?.appoinment ? acuityUser._id : '',
                        });
                      }
                    }}
                    onSelect={(value: any) => {
                      setState({ customer: value });
                    }}
                    optionText="Add Client"
                    optionClick={() => gotoAddClient()}
                    emptyMessage="Client list is empty"
                  />
                </View>
              </View>
            </View>
            <View style={styles.addPetButtonContainer}>
              <PrimaryButton
                text={props.route.params.add ? 'Add Dog' : 'Done'}
                onPress={props.route.params.add ? addDog : editDog}
                width={'200px'}
                activeOpacity={0.7}
                activity={state.adding}
                backgroundColor={Colors.primaryDarkColor}
              />
              <PrimaryButton
                text="Save & Add Training"
                width={'200px'}
                activeOpacity={0.7}
                backgroundColor={Colors.primaryDarkColor}
                onPress={props.route.params.add ? saveDogAddTraining : editDogAddTraining}
                activity={state.activity}
              />
            </View>
          </View>
        </View>
      </ScrollView>
    </Container>
  );
};

const styles = StyleSheet.create({
  addPetContainer: {
    padding: Ratio(20),
    marginBottom: Ratio(50),
    width: '100%',
  },
  headerWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  imageContainer: {},
  headTextWrapper: {
    paddingLeft: Ratio(10),
  },
  textWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  addPetFormContainer: {
    position: 'relative',
    width: '100%',
    marginTop: Ratio(50),
    paddingBottom: Ratio(100),
    alignItems: 'center',
  },
  addPetIconContainer: {
    position: 'absolute',
    width: '100%',
    zIndex: 1,
    alignItems: 'center',
    top: -45,
  },
  profileContainer: {
    borderRadius: 30,
    // width: Ratio(100),
    // height: Ratio(100)
  },
  profileImage: {
    // backgroundColor: "red",
    width: Ratio(100),
    height: Ratio(100),
  },
  uploadIcon: {
    backgroundColor: Colors.primaryDarkColor,
    width: Ratio(30),
    height: Ratio(30),
    borderRadius: Ratio(20),
    justifyContent: 'center',
    alignItems: 'center',
    bottom: -15,
    position: 'absolute',
  },
  navIcon: {
    width: Ratio(100),
    height: Ratio(100),
    borderRadius: 30,
    backgroundColor: Colors.secondaryLightColor,
  },
  formBody: {
    // paddingVertical: Ratio(100),
    // paddingHorizontal: Ratio(10),
    width: '50%',
    backgroundColor: Colors.buttonTextColor,
    borderRadius: 32,
    marginTop: Ratio(10),
    shadowColor: '#DDE5EE',
    shadowOffset: { width: 4, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    // zIndex: -11000,
    padding: 20
  },
  bodyContainer: {
    paddingTop: Ratio(50),
    paddingBottom: Ratio(40),
    padding: '0px 15px',
    // marginBottom: Ratio(30),
    alignItems: 'center',
    width: '100%',
  },
  inputWrapper: {
    width: '100%',
    // zIndex: 100,
    padding: '10px 0px',
  },
  addPetButtonContainer: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    // height: "10%",
    width: '100%',
    display:"flex",
    flexDirection:"row",
    gap:"20px",
    // bottom: Ratio(3, true),
    bottom: 0,
    zIndex: 1000,
  },
  btnContent: {
    width: '80%',
  },
  addImageIcon: {
    width: 20,
    height: 20,
  },
  profileLoading: {
    width: Ratio(100),
    height: Ratio(100),
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.primaryDarkColor,
    opacity: 0.4,
  },
});

export default AddDog;
