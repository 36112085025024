import { CHAT_DETAIL, CHAT_LIST, MESSAGE_LIST } from 'utils/types.utils';
import { storeAction } from 'interfaces/common.interface';

const initialState = {
  chatList: [],
  messageList: [],
  chat: {}
};

const ChatReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CHAT_DETAIL:
      let newChat: any = {...action.payload};
      state.chat = newChat;
      return state;
    case MESSAGE_LIST:
      let messageList: any = [...action.payload];
      state.messageList = messageList;
      return state;
    case CHAT_LIST:
      let chatList: any = [...action.payload];
      state.chatList = chatList;
      return state;
    default:
      return state;
  }
};

export default ChatReducer;
