import {
  GET_USER_DETAILS,
  REMOVE_USER,
  GET_USER_LIST,
  GET_CUSTOMER_LIST,
  GET_TRAINER_LIST,
  CALLER,
  ACUITY_USER,
  REMOVE_USER_LIST,
} from 'utils/types.utils';
import { storeAction } from 'interfaces/common.interface';

const initialState = {
  user: {},
  userList: [],
  trainerList: [],
  customerList: [],
  caller: {},
  acuityUser: {}
};

const UserReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_USER_DETAILS:
      state.user = { ...state.user, ...action.payload }
      return state;
    case GET_USER_LIST:
      let userList = action.payload;
      state.userList = userList;
      return state;
    case GET_CUSTOMER_LIST:
      let customerList = action.payload;
      state.customerList = customerList;
      return state;
    case GET_TRAINER_LIST:
      let trainerList = action.payload;
      state.trainerList = trainerList;
      return state;
    case CALLER:
      state.caller = action.payload;
      return state;
    case ACUITY_USER:
      state.acuityUser = action.payload;
      return state;
    case REMOVE_USER:
      state.user = {};
      return state;
    case REMOVE_USER_LIST:
      state.userList = [];
      return state;
    default:
      return state;
  }
};

export default UserReducer;
