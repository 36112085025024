import store from 'store/store';
import {
  GET_USER_DETAILS,
  GET_USER_LIST,
  TEST_ACTION,
  USER_LOGIN,
  USER_SIGNUP,
  REMOVE_USER,
  GET_PET_DETAILS,
  GET_PET_LIST,
  REMOVE_PET_DETAILS,
  REMOVE_USER_LIST,
  GET_TRAINING_DETAILS,
  GET_TRAINING_LIST,
  REMOVE_TRAINING_DETAILS,
  GET_CUSTOMER_LIST,
  GET_TRAINER_LIST,
  MEDIA_LIST,
  REMOVE_MEDIA,
  CHAT_LIST,
  CHAT_DETAIL,
  MESSAGE_LIST,
  CALLER,
  NOTIFICATION_LIST,
  NOTIFICATION_DETAIL,
  ACUITY_LIST,
  ACUITY_DETAIL,
  ACUITY_USER,
  GET_ACUITY_TRAINING_LIST,
  CHAT_ALERT,
  CALL_LIST,
  CHANGE_FILTER,
  GET_ORG_LIST,
  GET_BRANCH_LIST,

} from './types.utils';

export const testDispatch = (payload: object) =>
  store.dispatch({
    type: TEST_ACTION,
    payload: payload,
  });

export const signupDispatch = (payload: object) =>
  store.dispatch({
    type: USER_SIGNUP,
    payload: payload,
  });

export const loginDispatch = (payload: object) =>
  store.dispatch({
    type: USER_LOGIN,
    payload: payload,
  });

export const getUserDetails = (payload: object) => {
  store.dispatch({
    type: GET_USER_DETAILS,
    payload: payload,
  });
};

export const getUserList = (payload: any[]) => {
  store.dispatch({
    type: GET_USER_LIST,
    payload: payload,
  });
};

export const removeUser = () => {
  store.dispatch({
    type: REMOVE_USER,
    payload: {},
  });
};

export const getPetDetails = (payload: object) => {
  store.dispatch({
    type: GET_PET_DETAILS,
    payload: payload
  })
}

export const getPetList = (payload: any[]) => {
  store.dispatch({
    type: GET_PET_LIST,
    payload: payload
  })
}

export const removePetDetails = () => {
  store.dispatch({
    type: REMOVE_PET_DETAILS,
    payload: {}
  })
}

export const removeUserList = () => {
  store.dispatch({
    type: REMOVE_USER_LIST,
    payload: []
  })
}

export const getTrainingDetails = (payload: object) => {
  store.dispatch({
    type: GET_TRAINING_DETAILS,
    payload: payload
  })
}

export const getTrainingList = (payload: any[]) => {
  store.dispatch({
    type: GET_TRAINING_LIST,
    payload: payload
  })
}

export const removeTrainingDetails = () => {
  store.dispatch({
    type: REMOVE_TRAINING_DETAILS,
    payload: {}
  })
}

export const getTrainerList = (payload: any[]) => {
  store.dispatch({
    type: GET_TRAINER_LIST,
    payload: payload,
  });
};

export const getCustomerList = (payload: any[]) => {
  store.dispatch({
    type: GET_CUSTOMER_LIST,
    payload: payload,
  });
};

export const updateMediaList = (payload: any[]) => {
  store.dispatch({
    type: MEDIA_LIST,
    payload: payload,
  });
};

export const removeMedia = () => {
  store.dispatch({
    type: REMOVE_MEDIA,
    payload: [],
  });
};

export const getChatList = (payload: any[]) => {
  store.dispatch({
    type: CHAT_LIST,
    payload: payload,
  });
}

export const getChat = (payload: any) => {
  store.dispatch({
    type: CHAT_DETAIL,
    payload: payload,
  });
}

export const getMessageList = (payload: any[]) => {
  store.dispatch({
    type: MESSAGE_LIST,
    payload: payload,
  });
}

export const getCaller = (payload: any) => {
  store.dispatch({
    type: CALLER,
    payload: payload,
  });
}

export const getNotificationList = (payload: any[]) => {
  store.dispatch({
    type: NOTIFICATION_LIST,
    payload: payload,
  });
}

export const getNotificationDetails = (payload: any) => {
  store.dispatch({
    type: NOTIFICATION_DETAIL,
    payload: payload,
  });
}

export const getAcuityList = (payload: any[]) => {
  store.dispatch({
    type: ACUITY_LIST,
    payload: payload,
  });
}

export const getAcuityDetails = (payload: any) => {
  store.dispatch({
    type: ACUITY_DETAIL,
    payload: payload,
  });
}

export const getAcuityUser = (payload: any) => {
  store.dispatch({
    type: ACUITY_USER,
    payload: payload,
  });
}

export const getAcuityTrainingList = (payload: any) => {
  store.dispatch({
    type: GET_ACUITY_TRAINING_LIST,
    payload: payload
  })
}

export const getChatAlert = (payload: any) => {
  store.dispatch({
    type: CHAT_ALERT,
    payload: payload
  })
}

export const getCallList = (payload: any) => {
  store.dispatch({
    type: CALL_LIST,
    payload: payload
  })
}

export const changeFilter = (payload: any) => {
  store.dispatch({
    type: CHANGE_FILTER,
    payload: payload
  })
}
export const getOrganisationList = (payload: any[]) => {
  store.dispatch({
    type: GET_ORG_LIST,
    payload: payload
  })
}

export const getBranchList = (payload: any[]) => {
  store.dispatch({
    type: GET_BRANCH_LIST,
    payload: payload
  })
}