import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'utils/functions.utils';
import { Functions, Models, Assets, Component } from 'utils/imports.utils';
import './subscription.screen.scss';
import { getUserDetails } from 'utils/redux.utils';

const Subscription = (props: any) => {

  useEffect(()=>{
    getUser()
  },[])

  // Redux
  const user = useSelector((state: any) => state.user.user);
  let user_type = localStorage.getItem('user_type')
  // State
  const [state, setState] = useSetState({user:{}});

  //Hooks
  useEffect(() => {}, []);

  // Network req
  //Logic
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_type");
    localStorage.removeItem("subscribed");
    window.location.href = "/login";
  }
  
  const getUser = async () => {
    try {
      const user: any = await Models.auth.getUserByToken();
      getUserDetails(user.data);
      localStorage.setItem("subscribed", user.data.org_details.subscribed.toString())
      setState({user})
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get user details');
      }
    }

  };


  const handelNavigate = async () => {
    if(state.user.data.customer_id){
      window.location.href = "/payment"
    }else{
      window.location.href = "/checkoutPayment"
    }
  }
  return (
    <div className="subscription_screen">
      <div className="subscription_wrapper">
        <div className="success_image">
          <img src={Assets.warning_circle} />
        </div>
        <div className="subscription_text1">Your subscription has ended.</div>
        <div className="subscription_text1">Subscribe to continue using PetLoop</div>
        {user_type !=='org_admin' && <div className="subscription_text1">Contact your admin</div>}
        {/* <div className="subscription_text2">
          You have subscribed to Basic Plan
        </div> */}
        {user_type =='org_admin' ? <div className="button_container">
          <Component.Button text="Add Subscriptions" onClick={()=> handelNavigate()} />
        </div>: <div className="button_container">
          <Component.Button text="Logout" onClick={logout} />
        </div>}
        {/* <div className="receipt_download_text">Download Receipt</div> */}
      </div>
    </div>
  );
};

export default Subscription;
