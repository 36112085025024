import React from 'react'
import { StyleSheet } from 'utils/imports.utils';

function View(props) {
  let style = props.style;
  if(Array.isArray(props.style)){
    let arrStyle = {};
    props.style.forEach(value => {
      arrStyle = {...arrStyle, ...value}
    })
    style = arrStyle;
  }
  return (
    <div onClick={props.onPress} style={{...styles.viewContainer, ...style}} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave} onSelect={props.onSelect} onMouseDown={props.onMouseDown}>
      {props.children}
    </div>
  )
}

const styles = StyleSheet.create({
  viewContainer: {
    display: 'flex', 
    flexDirection: 'column'
  }
})

export default View
