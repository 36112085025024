import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getPetList } from 'utils/redux.utils';
import {
  Assets,
  Colors,
  Container,
  Image,
  NavButton,
  Ratio,
  Text,
  Functions,
  Models,
  PetCard,
  Constants,
  View,
  StyleSheet,
  TouchableOpacity,
  FlatList,
  Input,
  FilterModal,
  LinearGradient,
  useDebounceEffect
} from 'utils/imports.utils';
import _ from 'lodash';

interface pagination {
  limit: number;
  page: number;
  search: string;
  user_type?: string;
  user_id?: string;
}

const DogList = (props: any) => {
  const user = useSelector((state: any) => state.user);
  const pets = useSelector((state: any) => state.pet.petList);

  const [state, setState] = Functions.useSetState({
    loading: true,
    page: 1,
    limit: 100,
    search: '',
    hasMore: false,
    searchVisible: false,
    loadMoreLoader: false,
    openFilter: false,
    head: 'All Dogs',
    filterValue: 'all',
    addPet: false,
  });

  useEffect(() => {
    getDashboardDetails();
  }, []);

  useDebounceEffect(() => petList({
      limit: state.limit, page: 1, search: state.search
    }), [state.search], 500)

  const getDashboardDetails = async () => {
    try {
      const data: any = await Models.dashboard.manageCardDetails({});
      setState({ ...data.data });
      petList({ limit: state.limit, page: state.page, search: state.search });
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get pet list');
      }
      setState({ loading: false });
    }
  };

  const petList = async ({ limit, page, search, user_id, user_type }: pagination) => {
    try {
      setState({ loadMoreLoader: true });
      let req: any = {
        limit,
        page,
        search: search.toString(),
      };
      const petsDate: any = await Models.pet.petList(req);
      if (page !== 1) {
        getPetList([...pets, ...petsDate.data.docs]);
      } else {
        getPetList(petsDate.data.docs);
      }
      setState({
        loading: false,
        hasMore: petsDate.data.hasNextPage,
        page,
        limit,
        loadMoreLoader: false,
      });
    } catch (err) {
      console.log('err', err);
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get pet list');
      }
      setState({ loading: false });
    }
  };

  const goBack = () => {
    getPetList([]);
    props.navigation.navigate("Manage")

  };

  const loadMore = () => {
    if (state.hasMore) {
      petList({ limit: state.limit, page: state.page + 1, search: state.search });
    }
  };

  const closeAction = () => {
    setState({ openFilter: !state.openFilter });
  };

  const closeSearch = () => {
    setState({ searchVisible: !state.searchVisible, search: '' });
    petList({ limit: state.limit, page: 1, search: '' });
  };

  const gotoAddDog = () => {
    // setState({ search: "" })
    props.navigation.navigate('AddDog', { add: true });
  };


  return (
    <Container
      screen
      loading={state.loading}
      styles={{ height: '100%' }}
      // floatingButton
      // floatingButtonStyle={{ right: 75 }}
      // onFloationButtonClick={() => gotoAddDog()}
      >
      <View style={styles.dogListContainer}>
        <View style={styles.headerWrapper}>
          <View style={styles.headerContent}>
            {!state.searchVisible && !state.openFilter && (
              <View>
                <NavButton
                  icon={Assets.ArrowBack}
                  style={{ backgroundColor: Colors.background }}
                  svg
                  onClick={goBack}
                  iconHeight={Ratio(25)}
                  iconWidth={Ratio(25)}
                />
              </View>
            )}
            {!state.searchVisible && !state.openFilter && (
              <View style={styles.headTextContainer}>
                <View style={{ height: '70%' }}>
                  <Text size={Ratio(24)} color={Colors.primaryDarkColor} family={Constants.regular}>
                    {state.head}
                  </Text>
                </View>
                <View style={{ height: '30%' }}>
                  <Text size={Ratio(12)} color={Colors.secondaryDarkColor}>{`${
                    state?.pets_count ? state?.pets_count : 0
                  } Dogs`}</Text>
                </View>
              </View>
            )}
          </View>
          {!state.searchVisible && !state.openFilter ? (
            <View style={styles.headerContent}>
              <View style={styles.navButtonContainer}>
                <NavButton icon={Assets.Plus} style={styles.addButton} onClick={() => gotoAddDog()}/>
                <NavButton
                  icon={Assets.Search}
                  onClick={() => setState({ searchVisible: !state.searchVisible })}
                  svg
                  iconHeight={Ratio(25)}
                  iconWidth={Ratio(25)}
                />
              </View>
            </View>
          ) : (
            <View style={styles.headerContent}>
              {state.searchVisible ? (
                <View style={styles.navButtonContainerSearch}>
                <NavButton icon={Assets.Plus} style={styles.addButton} onClick={() => gotoAddDog()} height={40} width={45}/>

                  <View style={styles.searchContainer}>
                    <Input
                      onChange={(search: any) => {
                        if (!search.target) {
                          setState({ search });
                          petList({ limit: state.limit, page: 1, search: search });
                        }
                      }}
                      name={"search"}
                      value={state.search}
                      iconPosition="start"
                      source={Assets.Search}
                      iconHeight={20}
                      iconWidth={20}
                      styles={styles.input}
                      placeholder="Search"
                    />
                  </View>
                  <NavButton
                    icon={Assets.Close}
                    onClick={closeSearch}
                    svg
                    iconHeight={Ratio(25)}
                    iconWidth={Ratio(25)}
                  />
                </View>
              ) : (
                <View style={styles.navButtonContainer}>
                  <NavButton
                    icon={Assets.Close}
                    onClick={closeAction}
                    svg
                    iconHeight={Ratio(25)}
                    iconWidth={Ratio(25)}
                  />
                </View>
              )}
            </View>
          )}
        </View>
        {pets.length > 0 ? (
          <View style={styles.dogListWrapper}>
            <FlatList
              data={pets}
              scrollEnabled={true}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              keyExtractor={(item: any) => item.name}
              renderItem={({ item, index }) => (
                <TouchableOpacity
                  activeOpacity={0.7}
                  style={[
                    styles.petDetail,
                    { paddingBottom: index === pets.length - 1 ? Ratio(150) : Ratio(10) },
                  ]}
                  onPress={() => {
                    setState({ search: '' });
                    props.navigation.navigate('AddDog', { view: true, id: item._id });
                  }}>
                  <PetCard
                    name={item.name}
                    category={_.find(Constants.breeds, { value: item.category })?.label}
                    gender={item.gender}
                    age={item.age}
                    age_type={item.age_type}
                    username={item.customer?.username}
                    user_type={
                      item.customer?.user_type.charAt(0).toUpperCase() +
                      item.customer?.user_type.slice(1)
                    }
                    profile={item.profile ? item?.profile : null}
                  />
                </TouchableOpacity>
              )}
              onEndReached={loadMore}
              onEndReachedThreshold={0.5}
              hasMoreItems={state.hasMore}
            />
          </View>
        ) : (
          <View style={styles.emptyContainer}>
            <Text color={Colors.secondaryLightText} size={14}>
              No dogs available
            </Text>
          </View>
        )}
      </View>
      {!state.openFilter && (
        <LinearGradient
          colors={['rgba(248, 249, 251, 0)', 'rgba(248, 249, 251, 0.8)', 'rgba(248, 249, 251, 1)']}
          style={styles.overlay}
        />
      )}
      {state.openFilter && (
        <View style={styles.filterContainer}>
          <FilterModal onFilterClick={(value: string, head: string) => console.log(value, head)} />
        </View>
      )}
    </Container>
  );
};

const styles = StyleSheet.create({
  dogListContainer: {
    height: '100%',
    paddingLeft: Ratio(20),
    paddingRight: Ratio(20),
  },
  headerWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '8%',
  },
  headerContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    elevation: 10,
  },
  headTextContainer: {
    justifyContent: 'center',
    height: '100%',
    paddingLeft: Ratio(10),
  },
  navButtonContainer: {
    paddingLeft: Ratio(20),
    display:"flex",
    flexDirection: 'row',
  },
  navButtonContainerSearch: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    // paddingLeft: Ratio(20),
  },
  dogListWrapper: {
    marginTop: Ratio(10),
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  petDetail: {
    marginBottom: Ratio(10),
    width: "49%"
  },
  searchContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '80%',
  },
  input: {
    width: '100%',
  },
  emptyContainer: {
    width: '100%',
    paddingTop: Ratio(20),
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: Ratio(150),
    width: '100%',
  },
  filterContainer: {
    position: 'absolute',
    bottom: 0,
    top: Ratio(100),
    left: 0,
    right: 0,
    width: '100%',
  },
  addButton:{
    borderRadius:"50%",
    backgroundColor: Colors.secondaryDarkColor,
    marginRight:"20px",
  },
});

export default DogList;
