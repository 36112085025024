import socketIOClient from "socket.io-client";
import { getBaseURL } from "./functions.utils";

// const endpoint = process.env.REACT_APP_NODE_ENV === 'development' ? 'http://192.168.29.245:8001' : process.env.REACT_APP_NODE_ENV === 'stage' ? 'https://stage.baeroad.com' : 'http://172.20.10.2:8001';
const endpoint = getBaseURL();
// const endpoint = "wss://training.augmo.io"
// const endpoint = "http://192.168.1.20:8001"

const connectSocket = socketIOClient(endpoint, { transports: ['websocket'], timeout: 2000 })
connectSocket.on('connect', () => {
  console.log(`socket connected..`); // eslint-disable-line
});

export default connectSocket;
