import React, { useEffect } from 'react';

import {
  View,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Assets,
  Constants,
  Image,
  NavButton,
  Ratio,
  Text,
  Colors,
  Input,
  Functions,
  PrimaryButton,
  Container,
  Select,
  Models,
  Validation,
  FileInput,
} from 'utils/imports.utils';
import { uploadFile } from 'utils/s3.utils';
import { useSelector } from 'react-redux';
import { getBranchList, getOrganisationList } from 'utils/redux.utils';

const AddAdmin = (props: any) => {
  const user = useSelector((state: any) => state.user.user);
  const organizations = useSelector((state: any) => state.org.orgList);
  const branch = useSelector((state: any) => state.branch.branchList);


  const [state, setState] = Functions.useSetState({
    username: '',
    email: '',
    phone: '',
    profile: '',
    organization: null,
    branch:"",
    activity: false,
    isOpen: true,
    loading: false,
    edit: false,
    profileUpload: false,
    page: 1,
    limit: 100,
    search: '',
  });

  useEffect(() => {


    getOrganisation({
      page: state.page,
      limit: state.limit,
      filter: state.filter
    });

    getBranchData({
      page: state.page,
      limit: state.limit,
      filter: state.filter
    });

    if (props.route.params.view) {
      getUserDetails(props.route.params.userId);
    } else {
      setState({ loading: false });
    }

    if (props.route.params.orgData) {
      setState({ organization: props.route.params?.orgData.id });
    }
    if(props.route.params.branchData){
      setState({branch:props.route.params?.branchData.branchId  , organization:props.route.params?.orgData})
      
    } 
    // return () => {
    //   props.navigation.addListener('focus', () =>console.log("event listener removed"))
      
    // }
  }, []);

  useEffect(()=>{ 
    if (props.route.params.view) {
      getUserDetails(props.route.params.userId);
    } else {
      setState({ loading: false });
    }

    if (props.route.params.orgData) {
      setState({ organization: props.route.params?.orgData.id });
    }
    if(props.route.params.branchData){
      setState({branch:props.route.params?.branchData.branchId  , organization:props.route.params?.orgData})
      
    } 

  }, [props.route.params])

  const backAction = () => {
    props.navigation.goBack();
    return true;
  };

  const getOrganisation = async ({ page, limit, filter }: any) => {
    try {
      setState({ loadMoreLoader: true });
      let req: any = { page, limit };
      if (filter) {
        req.status = filter;
      }
      const org: any = await Models.org.orgList(req);
      if (page !== 1) {
        getOrganisationList([...organizations, ...org.data.docs]);
      } else {
        getOrganisationList(org.data.docs);
      }
      setState({
        loading: false,
        hasMore: org.data.hasNextPage,
        page,
        limit,
        loadMoreLoader: false,
        filter,
      });
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get organization');
      }
      setState({ loading: false });
    }
  };

  const getBranchData = async ({ page, limit, filter }: any) => {
    try {
      setState({ loadMoreLoader: true });
      let req: any = { page, limit };
      if (filter) {
        req.status = filter;
      }
      const branch: any = await Models.branch.branchList(req);
      if (page !== 1) {
        getBranchList([...branch, ...branch.data.docs]);
      } else {
        getBranchList(branch.data.docs);
      }
      setState({
        loading: false,
        hasMore: branch.data.hasNextPage,
        page,
        limit,
        loadMoreLoader: false,
        filter,
      });
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get pet list');
      }
      setState({ loading: false });
    }
  };

  const getUserDetails = async (id: string) => {
    try {
      const user: any = await Models.user.viewUser(id);
      setState({
        email: user.data.email,
        phone: user.data?.phone,
        username: user.data.username,
        loading: false,
        organization: user.data?.org,
        profile: user.data?.profile ? user.data?.profile : '',
        branch: user.data?.branch

      });
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get user details');
      }
      setState({ loading: false });
    }
  };
  // console.log('getUserDetails(props.route.params.userId)', getUserDetails(props.route.params.userId));

  const addUser = async () => {
    try {
      setState({ activity: true });
      let req: any = {
        username: state.username,
        email: state.email,
        phone: state.phone,
        profile: state.profile,
        org: state.organization || user.org,
        role: props.route.params.type,
        user_type: props.route.params.type,
        branch:state.branch
      };
      await Validation.newUserSchema.validate(req);
      req.user_type = props.route.params.type;
      const response: any = await Models.admin.createUser(req);
      Functions.Success('Success', 'User added');
      setState({ username: '', email: '', phone: '', activity: false });
      // onClose();
    } catch (err) {
      console.log('err', err);
      setState({ activity: false });
    }
  };
// {console.log('props.route.params', props.route.params)}
  const saveUser = async () => {
    console.log("save user")
    try {
      setState({ activity: true });
      let req: any = {
        username: state.username,
        email: state.email,
        phone: state.phone,
        profile: state.profile,
        org: state.organization || user.org,
        role: props.route.params.type,
        user_type: props.route.params.type,
      };
      if(state.branch !== "" ){
        req.branch = state.branch
      }
      if(user.role === "admin"){
        req.branch = user.branch
      }
      if (props.route.params.type === "org_admin"){
        await Validation.newOrgAdminSchema.validate(req);
      } else if (props.route.params.type === "admin"){
        await Validation.newBranchAdminSchema.validate(req);
      }
      req.user_type = props.route.params.type;
      const response: any = await Models.admin.createUser(req);
      Functions.Success('Success', 'User added');
      setState({ username: '', email: '', phone: '', activity: false , edit: true});
      if(isBranchAdmin()){
        user.created_branch_admin == true
        props.navigation.navigate("AdminList", { type: "admin", title: "Branch Admin"} )
      }
      else{
        user.created_org_admin == true
       props.navigation.navigate("AdminList", { type: "org_admin", title: "Org Admin" })
      }
    } catch (err) {
      console.log("err" , err)
      Functions.Failure(err)
      setState({ activity: false });
    }
  };



  const editUser = async () => {
    try {
      setState({ activity: true });
      let req: any = {
        username: state.username,
        email: state.email,
        phone: state.phone,
        profile: state.profile,
        org: state.organization,
        role: props.route.params.type,
        user_type: props.route.params.type,
        branch: state.branch
      };
      req.id = props.route.params.userId;
      if (props.route.params.type === "org_admin"){
        Validation.newOrgAdminSchema.validate(req);
      } else if (props.route.params.type === "admin"){
        Validation.newBranchAdminSchema.validate(req);
      }
      await Models.admin.editUser(req);
      Functions.Success('Success', 'User updated');
      setState({ activity: false, edit: false });
      if(isBranchAdmin()){
        props.navigation.navigate("AdminList", { type: "admin", title: "Branch Admin"} )
      }
      else{
       props.navigation.navigate("AdminList", { type: "org_admin", title: "Org Admin" })
      }
      // onClose();
    } catch (err) {
      Functions.Failure(err)
      console.log('err', err);
      setState({ activity: false });
    }
  };


  const uploadProfile = async (event) => {
    try {
      const image: any =event.target.files[0];
      setState({ profileUpload: true });
      const file: any = await uploadFile(image);
      setState({ profile: file.url, profileUpload: false });
    } catch (err) {
      console.log('err', err);
    }
  };

  const onClose = () => {
    if (state.edit) {
      setState({ edit: false });
    } else if(isBranchAdmin()){
      setState({ edit: false });
        props.navigation.navigate("AdminList", { type: "admin", title: "Branch Admin"} )
    }
    else{
      setState({ edit: false });
      props.navigation.navigate("AdminList", { type: "org_admin", title: "Org Admin" })
    }
  };
  const isBranchAdmin=() => {
    if (props.route.params.type=="admin"){
      return true
    }else{
      return false
    }
  }
// console.log('isBranchAdmin', isBranchAdmin());
// console.log('state.branch', state.branch);
  // console.log('state.organization', state.organization, props);
  return (
    <Container styles={styles.addAdminScreen} loading={state.loading}>
      <ScrollView style={styles.addOrgAdminContainer}>
        <View style={styles.headerWrapper}>
          <View style={styles.textWrapper}>
            <View style={styles.imageContainer}>
              <TouchableOpacity
                style={{
                  width: Ratio(40),
                  height: Ratio(40),
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                activeOpacity={0.7}
                onPress={onClose}>
                <Image svg src={Assets.ArrowBack} width={20} height={20} />
              </TouchableOpacity>
            </View>
            <View style={styles.headTextWrapper}>
              <Text
                size={Ratio(24)}
                color={Colors.primaryDarkText}
                family={Constants.light}
                weight="300">
                {props.route.params.add
                  ? props.route.params.buttonText
                  : 'Edit'}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.addUserFormContainer}>
          <View style={styles.addPetIconContainer}>
            {!state.profile && !state.profileUpload ? (
              <View style={styles.profileContainer}>
                <NavButton
                  icon={Assets.SingleUserPrimary}
                  disabled
                  style={styles.navIcon}
                  iconHeight={Ratio(50)}
                  iconWidth={Ratio(50)}
                  svg
                />
              </View>
            ) : state.profile && !state.profileUpload ? (
              <View style={styles.profileImage}>
                <Image
                  src={state.profile}
                  height="100%"
                  width="100%"
                  url
                  resize="cover"
                  radius={30}
                />
              </View>
            ) : (
              <View style={styles.profileLoading}>
                <View
                  size={30}
                  borderWidth={3}
                  indeterminate
                  color={Colors.buttonTextColor}
                />
              </View>
            )}
            <TouchableOpacity
              style={styles.uploadIcon}
              activeOpacity={0.7}
              onPress={uploadProfile}>
           <FileInput style={styles.fileInput} src={Assets.EditWhite} iconStyle={styles.addImageIcon} id="add_user" onChange={uploadProfile}/>

            </TouchableOpacity>
          </View>
          <View style={styles.formContainer}>
          <View style={styles.formBody}>
            <View style={styles.bodyContainer}>
            <Input
                label="Full Name"
                name="username"
                onChange={(username: string) => setState({ username })}
                placeholder="Full name"
                value={state.username}
                />
              <Input
                label="Email"
                name="email"
                onChange={(email: string) => setState({ email })}
                placeholder="Email"
                value={state.email} />

              <Input
              type={"number"}
                label="Phone"
                onChange={(phone: string) => setState({ phone })}
                placeholder="Phone"
                name="phone"
                value={state.phone} />
              {user.role === 'super_admin' && (
                <Select
                  data={organizations}
                  onSelect={(data: any) => setState({ organization: data })}
                  keyValue={'_id'}
                  label={'Organization'}
                  labelValue={'name'}
                  selected={state.organization}
                  labelStyle={{ paddingLeft: Ratio(0) }}
                />
              )}
              {isBranchAdmin() &&
                (user.role === 'super_admin' || user.role === 'org_admin') && (
                  <Select
                    data={branch}
                    onSelect={(data: any) => setState({ branch: data })}
                    keyValue={'_id'}
                    label={'Branch'}
                    labelValue={'name'}
                    selected={state.branch}
                    labelStyle={{ paddingLeft: Ratio(0) }}
                  />
                )}
                {/* {console.log('state.branch', branch)} */}
            </View>
          </View>
          </View>
          <View style={styles.addPetButtonContainer}>
             <PrimaryButton
                text={"Save"}
                onPress={props.route.params.add ? saveUser : editUser}
                width={'200px'}
                activeOpacity={0.7}
                backgroundColor={Colors.primaryDarkColor}
                activity={state.activity}
              />
            {/* {!isBranchAdmin() &&
              <PrimaryButton
                text="Save & Add Branch"
                width={Ratio('40%')}
                size={13}
                onPress={props.route.params.add ? saveUserAddBranch : editUser}
              />
              } */}
          </View>
        </View>
      </ScrollView>
    </Container>
  );
};

const styles = StyleSheet.create({
  addAdminScreen: {
    paddingTop: Ratio(20),
    height: '100%',
  },
  addOrgAdminContainer: {
    padding: Ratio(20),
    width: '100%',
    height: '100%',
    // justifyContent: 'space-between',
  },
  headerWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '10%',
    justifyContent: 'space-between',
  },
  imageContainer: {},
  headTextWrapper: {
    paddingLeft: Ratio(10),
  },
  textWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  addUserFormContainer: {
    position: 'relative',
    width: '100%',
    height: '90%',
    marginTop: Ratio(60),
  },
  addPetIconContainer: {
    position: 'absolute',
    width: '100%',
    zIndex: 1,
    alignItems: 'center',
    top: -55,
  },
  navIcon: {
    width: Ratio(100),
    height: Ratio(100),
    backgroundColor: Colors.secondaryLightColor,
    borderRadius: 30,
  },
  formBody: {
    width: '50%',
    // height: Platform.OS === 'android' ? Ratio('60%', true) : Ratio('50%', true),
    backgroundColor: Colors.buttonTextColor,
    borderRadius: 32,
    shadowColor: '#DDE5EE',
    shadowOffset: { width: 4, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    padding: 20,
    // backgroundColor: Colors.answerColor
  },
  bodyContainer: {
    paddingTop: Ratio(50),
    paddingHorizontal: Ratio(15),
    width: '100%',

  },
  inputWrapper: {
    width: '100%',
    zIndex: 100,
    paddingBottom: Ratio(10),
  },
  addPetButtonContainer: {
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    // bottom: Platform.OS === 'android' ? Ratio(-10, true) : Ratio(-10, true),
    display: 'flex',
    flexDirection: 'row',
    paddingTop:"30px",
    paddingBottom: 150
  },
  btnContent: {
    width: '80%',
  },
  profileContainer: {},
  profileImage: {
    width: Ratio(100),
    height: Ratio(100),
  },
  uploadIcon: {
    backgroundColor: Colors.primaryDarkColor,
    width: Ratio(30),
    height: Ratio(30),
    borderRadius: Ratio(20),
    justifyContent: 'center',
    alignItems: 'center',
    bottom: Ratio(17),
    marginTop:"-20px"
  },
  profileLoading: {
    width: Ratio(100),
    height: Ratio(100),
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.primaryDarkColor,
    opacity: 0.4,
  },
  formContainer:{
    width:"100%",
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
  }
});

export default AddAdmin;
