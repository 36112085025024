import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  StyleSheet,
  View,
  ScrollView,
  Colors,
  Assets,
  Ratio,
  Text,
  Constants,
  HomeCard,
  Functions,
  Models,
  ActivityIndicator,
  Select,
  PrimaryButton,
  TaskCard,
  NavButton,
  TouchableOpacity
} from 'utils/imports.utils';
import connectSocket from 'utils/socket.utils';
import _ from 'lodash';
import { getBranchList, getOrganisationList , getUserDetails } from 'utils/redux.utils';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

let socket: any;
interface pagination {
  limit: number;
  page: number;
  search: string;
}

const Dashboard = (props: any) => {
  const user = useSelector((state: any) => state.user.user);
  const pets = useSelector((state: any) => state.training.trainingList);
  const organizations = useSelector((state: any) => state.org.orgList);


  const [state, setState] = Functions.useSetState({
    loading: true,
    page: 1,
    limit: 100,
    search: '',
    hasMore: false,
    loadMoreLoader: false,
    notify: 0,
    current: 0,
    upcoming: 0,
    trainers_count:0,
    completed:0,
    organizations:{name:"All"},
    organizationsData:[],
    orgCount: 0,
    branchCount: 0 ,
    branch: [],
    selectedBranch: '' ,
    pets_count: 0,
    clients_count: 0,
    organization:'',
    openModal: false,
    incomplete: 0,
  });

  useEffect(() => {
    connectSocketServer();
    // props.navigation.addListener('focus', () => {
      // getUser();
      getNotify(user._id);
    // });
    getOrganisation({
      page: state.page,
      limit: state.limit,
      filter: state.filter
    });
    if(user.org){
      setState({organization:user.org})
    }
    getDashboardDetails();
    incompleteTask()
    getBranchData({
      page: state.page,
      limit: state.limit,
      org: user?.data?.org,
    });
  }, []);

  useEffect(()=>{
    getUser(user._id)
    incompleteTask();
  },[user.created_org_admin , user.created_branch , user.created_branch_admin])

  const connectSocketServer = () => {
    socket = connectSocket;
    // console.log('socket', socket);
    socket.emit('join-chat', { user: user._id });
    socket.on('user-connected', (data: any) => {
      console.log('user connected...');
    });
  };
  
  const getUser = async (id:any) => {
    try {
      const user: any = await Models.user.viewUser(id);
      getUserDetails(user.data)
      setState({user: user.data})
      // console.log('user', user);
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get user details');
      }
      setState({ loading: false });
    }
  };

  const getOrganisation = async ({ page, limit, filter }: any) => {
    try {
      setState({ loadMoreLoader: true });
      let req: any = { page, limit };
      if (filter) {
        req.status = filter;
      }
      const org: any = await Models.org.orgList(req);
      const orgData = org.data.docs;
      orgData.unshift({name: "All" , _id:"all"})
      // setState({organizationsData:orgData})
      if (page !== 1) {
        getOrganisationList([...organizations, ...org.data.docs]);
        setState({organizationsData:[state.organizationsData, ...orgData]})
      } else {
        getOrganisationList([...org.data.docs]);
        setState({organizationsData:[...orgData]})
      }
      setState({
        loading: false,
        hasMore: org.data.hasNextPage,
        page,
        limit,
        loadMoreLoader: false,
        filter,
        organization:"all",
        orgCount: org.data.totalDocs
      });
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get organization');
      }
      setState({ loading: false });
    }
  };

  const getNotify = async (user: string) => {
    try {
      const notification: any = await Models.notification.hasNotification({ user_id: user });
      setState({ notify: notification.data.count });
    } catch (err) {
      console.log(err);
    }
  };
  const getDashboardDetails = async (org?:any , branch?:any) => {
    try {
      let req: any = {  };
      if(org !== "all"){
        req.org = org
      }
      if(user.role == "org_admin"){
        req.org = user.org
      }
      if(branch){
        req.branch = branch
      }
      console.log('req', req);
      const dashboard: any = await Models.dashboard.adminDashboard(req);
     setState({
       orgCount: dashboard.data.org_count,
       branchCount: dashboard.data.branch_count,
       upcoming: dashboard.data.upcoming,
       current: dashboard.data.current,
       completed: dashboard.data.completed,
       trainers_count: dashboard.data.trainers_count,
       pets_count: dashboard.data.pets_count,
       clients_count: dashboard.data.clients_count,
     });
    } catch (err) {
      console.log('err', err);
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get dashboard details');
      }
      setState({ loading: false });
    }
  };



  const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }: any) => {
    const paddingToBottom = 20;
    return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingToBottom;
  };

  const getBranchData = async ({ page, limit, org}: any) => {
    try {
      setState({ loadMoreLoader: true });
      let req: any = { page, limit };
      if (org !== "all") {
        req.org = org;
      }
      const branch: any = await Models.branch.branchList(req);
      if (page !== 1) {
        getBranchList([...branch, ...branch.data.docs]);
      } else {
        getBranchList(branch.data.docs);
      }
      setState({
        loading: false,
        hasMore: branch.data.hasNextPage,
        page,
        limit,
        loadMoreLoader: false,
        branch: branch.data.docs,
      });
    } catch (err) {
      console.log('err', err);
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get branch list');
      }
      setState({ loading: false });
    }
  };
  
  const taskData = [
    {
      title: 'Create Org Admin',
      text: 'Congratulation, now you can view the list of the Org Admin, Click below to view.',
      onClick: () => {
        props.navigation.navigate('AddAdmin', {
          buttonText: 'Add Admin',
          type: 'org_admin',
          add: true,
          title: 'Org Admin',
          view: false,
        });
        setState({openModal:false})
      },
      completed: user.created_org_admin,
    },
    {
      title: 'Create Branch',
      text: 'Congratulation, now you can view the list of the Branch, Click below to view.',
      onClick: () => {
        props.navigation.navigate('AddBranch', { add: true });
        setState({openModal:false})
      },
      completed: user.created_branch,
    },
    {
      title: 'Create Branch Admin',
      text: 'Congratulation, now you can view the list of the Branch Admin, Click below to view.',
      onClick: () => {
        props.navigation.navigate('AddAdmin', {
          buttonText: 'Add Admin',
          type: 'admin',
          add: true,
          title: 'Branch Admin',
          view: false,
        });
        setState({openModal:false})
      },
      completed: user.created_branch_admin,
    },
  ];

  const getTaskData = () =>{
    return taskData
  }

  const incompleteTask = () =>{
    let incompleteArray: any = []
    getTaskData()?.map((item)=>{
      if(!item.completed){
        incompleteArray.push(item)
      }
    })
    setState({incomplete: incompleteArray.length })
  }

  return (
    <Container style={styles.homeScreen} screen loading={state.loading}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        scrollEventThrottle={200}>
            <View>
            <Text size={Ratio(24)} color={Colors.primaryDarkColor} family={Constants.regular}>Dashboard</Text>
            </View>
            <View style={styles.dropdownContainer}>
          {user.role == "super_admin" &&<View style={styles.orgDropdown}>
          <Select
            data={state.organizationsData}
            onSelect={(data: any) => {
              setState({ organization: data });
              getBranchData({
                page: state.page,
                limit: state.limit,
                org: data,
              });
              getDashboardDetails(data , state.selectedBranch)
            }}
            keyValue={'_id'}
            label={'Organization'}
            labelValue={'name'}
            selected={state.organization}
            labelStyle={{ paddingLeft: Ratio(0) }}
          />
        </View>}
          {(state.organization !== "all" || user.role == "org_admin") &&
          <View style={styles.branchDropdown}>
          <Select
            data={state.branch}
            onSelect={(data: any) => {
              setState({ selectedBranch: data });
              getDashboardDetails(state.organization , data)
            }}
            keyValue={'_id'}
            label="Branch"
            labelValue={"name"}
            selected={state.selectedBranch}
            labelStyle={{ paddingLeft: Ratio(0) }}
             />
        </View>
          }
        </View>
        <View style={styles.homeCardWrapper}>
        {user.role == "super_admin" &&<View style={styles.homeCardContainer}>
            <HomeCard title="Organization" icon={Assets.org_white} count={state.orgCount} />
          </View>}
          <View style={styles.homeCardContainer}>
            <HomeCard title="Branch" icon={Assets.branch_white} count={state.branchCount} />
          </View>
          <View style={styles.homeCardContainer}>
            <HomeCard title="Upcoming Trainings" icon={Assets.UserWhite} count={state.upcoming} />
          </View>
          <View style={styles.homeCardContainer}>
            <HomeCard title="Current Trainings" icon={Assets.UserWhite} count={state.current} />
          </View>
          <View style={styles.homeCardContainer}>
            <HomeCard title="Previous Trainings" icon={Assets.UserWhite} count={state.completed} />
          </View>
          <View style={styles.homeCardContainer}>
            <HomeCard title="Trainers" icon={Assets.petFeet} count={state.trainers_count} />
          </View>
          <View style={styles.homeCardContainer}>
            <HomeCard title="Pets" icon={Assets.petFeet} count={state.pets_count} />
          </View>
          <View style={styles.homeCardContainer}>
            <HomeCard title="Clients" icon={Assets.UserWhite} count={state.clients_count} />
          </View>
        </View>
       </ScrollView>
       {state.incomplete > 0 && user.role == "org_agmin" &&
        <TouchableOpacity style={styles.taskButtonContainer}
        onPress={()=>setState({openModal:true})}
        >
        <PrimaryButton
          text={"Quick Start"}
          icon={Assets.task}
          width={'140px'}
          style={{marginTop: '0px'}}
          onPress={() => {
            setState({ openModal: true });
          }}
        />
        <View style={styles.countContainer}>
        <Text
            size={Ratio(16)}
            weight={'600'}
            color={Colors.buttonTextColor}
            >
          {state.incomplete}
        </Text>
        </View>
      </TouchableOpacity>
      }
      <Modal
        open={state.openModal}
        onClose={() => {}}
        onOverlayClick={() => setState({openModal: false})}
        styles={styles.modal}
        showCloseIcon={false}
        closeOnOverlayClick
        classNames={{
          overlay: 'customOverlay',
          modal: 'customModal',
        }}
        >
        <View style={styles.bottomSheetWrapper}>
          <View style={styles.bottomSheetHeader}>
            <View style={styles.headerClose}>
              <NavButton
                icon={Assets.close_white}
                onClick={() => {
                  setState({ openModal: false });
                }}
                svg
                iconHeight={24}
                iconWidth={24}
                style={{ backgroundColor: 'transparent' }}
              />
            </View>
            <View style={styles.headerText}>
              <Text size={Ratio(24)} color={'white'}>
                Quick Start
              </Text>
            </View>
          </View>
          <ScrollView style={styles.bottomSheetBody}>
          {taskData.map(item => (
              <TaskCard
                title={item.title}
                text={item.text}
                completed={item.completed}
                onClick={item.onClick}
              />
            ))}
          </ScrollView>
        </View>
      </Modal>
    </Container>
  );
};

const styles = StyleSheet.create({
  marginLeft: {
    marginLeft: Ratio(15),
  },
  homeScreen: {
    height: '100%',
    // backgroundColor: Colors.inputBackground,
    paddingBottom: 0,
    // paddingTop: Constants.statusBarHeight,
  },
  homeCardWrapper: {
    width: '100%',
    // height: Ratio(150),
    flexDirection: 'row',
    // justifyContent: 'space-between',
    paddingRight: Ratio(20),
    paddingLeft: Ratio(20),
    paddingTop: Ratio(20),
    gap: "10px",
    flexWrap:"wrap"
  },
  orgDropdown:{
    paddingTop:"25px",
    paddingBottom:"25px",
    width:"49%"
  },
  branchDropdown:{
    paddingTop:"25px",
    paddingBottom:"25px",
    width:"49%"
  },
  dropdownContainer:{
    width:"100%",
    flexDirection:"row",
    padding: Ratio(10),
    backgroundColor:"white",
    margin:Ratio(10),
    borderRadius: Ratio(15),
    justifyContent:"space-between",
    alignItems:"center",
  },
  taskButtonContainer: {
    position: 'fixed',
    bottom: 100,
    right: 80,
    display:"flex",
    flexDirection:"row",
    backgroundColor: Colors.primaryDarkColor,
    justifyContent: "space-between",
    padding:"0px 10px",
    alignItems:"center",
    borderRadius: "20px",
    cursor:"pointer",
  },
  taskButton: {
    backgroundColor: Colors.primaryDarkColor,
    borderRadius: 15,
    height: 50,
    width: 50,
  },
  bottomSheetContainer: {
    height: 500,
    width: '100%',
  },
  bottomSheetHeader: {
    height: "100px",
    width: '100%',
    padding: " 10px 15px 15px 15px",
    backgroundColor: Colors.primaryDarkColor,
    borderTopEndRadius: '30px',
    borderTopStartRadius: '30px',
  },
  bottomSheetWrapper: {
    height: '100%',
    width: '100%',
  },
  bottomSheetBody: {
    height:"450px",
    width: '100%',
    padding:"20px",
    backgroundColor: Colors.background,
    overflowY: 'scroll',

  },
  headerClose: {
    width: '100%',
    alignItems: 'flex-end',
  },
  headerText: {
    width: '100%',
    alignItems: 'center',
  },
  modal: {
    width: '400px',
    backgroundColor: 'red',
  },
  countContainer:{
    paddingLeft: '10px',
    borderLeft: '1px solid',
    borderLeftColor:Colors.secondaryDarkText,
    paddingRight:"10px"
  }
});

export default Dashboard;