import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  getTrainingList,
  getUserDetails,
  getPetDetails,
  getTrainingDetails,
} from 'utils/redux.utils';
import {
  StyleSheet,
  View,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
  Container,
  Colors,
  Assets,
  Ratio,
  Text,
  Constants,
  Image,
  NavButton,
  HomeCard,
  PetCard,
  Functions,
  Models,
  ScrollView,
  TaskCard,
  PrimaryButton,
} from 'utils/imports.utils';
import connectSocket from 'utils/socket.utils';
import _ from 'lodash';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

let socket: any;

interface pagination {
  limit: number;
  page: number;
  search: string;
  user_type?: string;
  user_id?: string;
}

const Home = (props: any) => {
  const user = useSelector((state: any) => state.user.user);
  const pets = useSelector((state: any) => state.training.trainingList);

  const [state, setState] = Functions.useSetState({
    loading: true,
    page: 1,
    limit: 20,
    search: '',
    hasMore: false,
    loadMoreLoader: false,
    totalDogs: 0,
    notify: false,
    incomplete: 0,
  });

  useEffect(() => {
    connectSocketServer();
      getNotify(user._id);
      trainingList({
        limit: state.limit,
        page: state.page,
        search: state.search,
        user_id: user._id,
      });
      incompleteTask();
  }, []);

  useEffect(()=>{
    getUser(user._id)
    incompleteTask();
  },[user.created_pet , user.created_customer , user.created_training])

  const connectSocketServer = () => {
    socket = connectSocket;
    // console.log('socket', socket);
    // global.WebSocket = socket;
    socket.emit('join-chat', { user: user._id });
    socket.on('user-connected', (data: any) => {
      console.log('user connected...');
    });
  };

  const getUser = async (id:any) => {
    try {
      const user: any = await Models.user.viewUser(id);
      getUserDetails(user.data)
      setState({user: user.data})
      // console.log('user', user);
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get user details');
      }
      setState({ loading: false });
    }
  };

  const trainingList = async ({ limit, page, search, user_id }: pagination) => {
    try {
      setState({ loadMoreLoader: true });
      let req: any = {
        limit,
        page,
        search,
        trainer: user_id ? user_id : user._id,
      };
      const petDate: any = await Models.training.trainingList(req);
      if (page !== 1) {
        getTrainingList([...pets, ...petDate.data.docs]);
      } else {
        getTrainingList(petDate.data.docs);
      }
      setState({
        loading: false,
        hasMore: petDate.data.hasNextPage,
        page,
        limit,
        loadMoreLoader: false,
        totalDogs: petDate.data.totalDocs,
      });
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get pet list');
      }
      setState({ loading: false });
    }
  };

  const getNotify = async (user: string) => {
    try {
      const notification: any = await Models.notification.hasNotification({ user_id: user });
      setState({ notify: notification.data.count });
    } catch (err) {
      console.log(err);
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user_type');
    window.location.href = "/login";
  };

  const loadMore = () => {
    if (state.hasMore) {
      trainingList({ limit: state.limit, page: state.page + 1, search: state.search });
    }
  };

  const loader = () => {
    return (
      <View>
        {state.loadMoreLoader && (
          <ActivityIndicator
            size="small"
            color={Colors.primaryDarkColor}
            style={{ marginLeft: 6 }}
          />
        )}
      </View>
    );
  };

  const gotoProfile = (item: any) => {
    getTrainingDetails({ ...item, ...{ trainerView: true } });
    props.navigation.navigate('DogProfile', { id: item.pet._id });
  };

  const gotoNotification = () => {
    props.navigation.navigate('Notification');
  };

  const returnDate = (item: any, type?: any) => {
    if (item.training_type == 'range') {
      if (type == 'start_date') {
        return item.start_date;
      } else {
        return item.end_date;
      }
    } else if (item.training_type == 'multiple days') {
      if (type == 'start_date') {
        return item.dates[0];
      } else {
        return item.dates[item.dates.length - 1];
      }
    } else if (item.training_type == 'single day') {
      return item.date;
    } else {
      if (item.start_date && type == 'start_date') {
        return item.start_date;
      } else {
        return item.end_date;
      }
    }
  };

  const taskData = [
    {
      title: 'Create Client',
      text: 'Congratulation, now you can view the list of the Client, Click below to view.',
      onClick: () => {
        props.navigation.navigate('AddUser', {
          buttonText: 'Add Client',
          type: 'customer',
          add: true,
          title: 'Clients',
        });
        setState({openModal:false})
      },
      completed: user.created_customer,
    },
    {
      title: 'Create Dog',
      text: 'Congratulation, now you can view the list of the Dog, Click below to view.',
      onClick: () => {
        props.navigation.navigate('AddDog', { add: true });
        setState({openModal:false})
      },
      completed: user.created_pet,
    },
    {
      title: 'Create Training',
      text: 'Congratulation, now you can view the list of the Training, Click below to view.',
      onClick: () => {
        props.navigation.navigate('AddTraining', { add: true });
        setState({openModal:false})
      },
      completed: user.created_training,
    },
  ];

  const getTaskData = () =>{
    return taskData
  }

  const incompleteTask = () =>{
    let incompleteArray: any = []
    getTaskData()?.map((item)=>{
      if(!item.completed){
        incompleteArray.push(item)
      }
    })
    setState({incomplete: incompleteArray.length })
  }

  return (
    <Container style={styles.homeScreen} screen loading={state.loading}>
      <View style={styles.textContainer}>
        <Text
          weight="500"
          size={Ratio(18)}
          family={Constants.bold}
          color={Colors.primaryDarkColor}>
          {user.username}
        </Text>
        <Text
          weight="500"
          size={Ratio(14)}
          family={Constants.bold}
          color={Colors.secondaryLightText}>{`${state.totalDogs} ${
          state.totalDogs > 1 ? 'Dogs' : 'Dog'
        } in training`}</Text>
      </View>
      <View style={styles.sessonWrapper}>
        <View style={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
          <Text
            size={Ratio(24)}
            color={Colors.primaryDarkColor}
            family={Constants.light}
            weight="300">
            Assigned Dogs
          </Text>
        </View>
        <TouchableOpacity
          activeOpacity={0.7}
          onPress={() => props.navigation.navigate('Trainings')}
          style={{ alignItems: 'center', justifyContent: 'center' , cursor:"pointer" }}>
          <Text color={Colors.secondaryDarkColor} size={Ratio(12)} family={Constants.regular}>
            See all
          </Text>
        </TouchableOpacity>
      </View>
      {pets.length > 0 ? (
        <View style={styles.petList}>
          <FlatList
            data={pets}
            scrollEnabled={true}
            showsVerticalScrollIndicator={false}
            keyExtractor={(item: any) => item.name}
            ListFooterComponent={loader()}
            renderItem={({ item, index }) => (
              <TouchableOpacity
                activeOpacity={0.7}
                style={[styles.petDetail, index === pets.length - 1 && { marginBottom: Ratio(40) }]}
                onPress={() => gotoProfile(item)}>
                <PetCard

                  name={item.pet.name}
                  category={_.find(Constants.breeds, { value: item.pet.category })?.label}
                  gender={item.pet.gender}
                  age_type={item.pet.age_type}
                  age={item.pet.age}
                  single_date={item.date}
                  start_date={returnDate(item , "start_date")}
                  end_date={returnDate(item, "end_date")}
                  username={item.customer?.username}
                  user_type={
                    item?.customer.user_type.charAt(0).toUpperCase() +
                    item?.customer.user_type.slice(1)
                  }
                  profile={item.pet.profile ? item.pet.profile : null}
                  user_profile={item.customer?.profile}
                  date
                  type={item.training_type}
                />
              </TouchableOpacity>
            )}
            onEndReached={() => loadMore()}
            onEndReachedThreshold={0.05}
          />
        </View>
      ) : (
        <View style={styles.emptyContainer}>
          <Text color={Colors.secondaryLightText} size={14}>
            No dogs available
          </Text>
        </View>
      )}
      {/* </ScrollView> */}
      {state.incomplete > 0 && 
        <TouchableOpacity style={styles.taskButtonContainer}
        onPress={()=>setState({openModal:true})}
        >
        <PrimaryButton
          text={"Quick Start"}
          icon={Assets.task}
          width={'140px'}
          style={{marginTop: '0px'}}
          onPress={() => {
            setState({ openModal: true });
          }}
        />
        <View style={styles.countContainer}>
        <Text
            size={Ratio(16)}
            weight={'600'}
            color={Colors.buttonTextColor}
            >
          {state.incomplete}
        </Text>
        </View>
      </TouchableOpacity>
      }
      <Modal
        open={state.openModal}
        onClose={() => {}}
        onOverlayClick={() => setState({openModal: false})}
        styles={styles.modal}
        showCloseIcon={false}
        closeOnOverlayClick
        classNames={{
          overlay: 'customOverlay',
          modal: 'customModal',
        }}
        >
        <View style={styles.bottomSheetWrapper}>
          <View style={styles.bottomSheetHeader}>
            <View style={styles.headerClose}>
              <NavButton
                icon={Assets.close_white}
                onClick={() => {
                  setState({ openModal: false });
                }}
                svg
                iconHeight={24}
                iconWidth={24}
                style={{ backgroundColor: 'transparent' }}
              />
            </View>
            <View style={styles.headerText}>
              <Text size={Ratio(24)} color={'white'}>
                Quick Start
              </Text>
            </View>
          </View>
          <ScrollView style={styles.bottomSheetBody}>
          {taskData.map(item => (
              <TaskCard
                title={item.title}
                text={item.text}
                completed={item.completed}
                onClick={item.onClick}
              />
            ))}
          </ScrollView>
        </View>
      </Modal>
    </Container>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: Ratio(25),
    height: '18%',
  },
  headerWrapper: {},
  userProfile: {
    backgroundColor: Colors.secondaryLightColor,
    width: Ratio(55),
    height: Ratio(55),
  },
  textContainer: {
    paddingTop: Ratio(15),
    paddingLeft: Ratio(5),
  },
  homeScreen: {
    height: '100%',
    backgroundColor: Colors.inputBackground,
    // paddingTop: Constants.statusBarHeight,
    paddingRight: Ratio(20),
    paddingLeft: Ratio(20),
    paddingBottom: 0,
    marginTop: 50
  },
  homeCardWrapper: {
    width: '100%',
    height: Ratio(150),
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  petList: {
    // height: '74%',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  petDetail: {
    width: "49%",
    marginBottom: Ratio(15),
  },
  sessonWrapper: {
    width: '100%',
    height: '10%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: Ratio(5),
    paddingBottom: Ratio(10),
  },
  emptyContainer: {
    width: '100%',
    paddingTop: Ratio(20),
    justifyContent: 'center',
    alignItems: 'center',
  },
  taskButtonContainer: {
    position: 'fixed',
    bottom: 100,
    right: 80,
    display:"flex",
    flexDirection:"row",
    backgroundColor: Colors.primaryDarkColor,
    justifyContent: "space-between",
    padding:"0px 10px",
    alignItems:"center",
    borderRadius: "20px",
    cursor:"pointer",
  },
  taskButton: {
    backgroundColor: Colors.primaryDarkColor,
    borderRadius: 15,
    height: 50,
    width: 50,
  },
  bottomSheetContainer: {
    height: 500,
    width: '100%',
  },
  bottomSheetHeader: {
    height: "100px",
    width: '100%',
    padding: " 10px 15px 15px 15px",
    backgroundColor: Colors.primaryDarkColor,
    borderTopEndRadius: '30px',
    borderTopStartRadius: '30px',
  },
  bottomSheetWrapper: {
    height: '100%',
    width: '100%',
  },
  bottomSheetBody: {
    height:"450px",
    width: '100%',
    padding:"20px",
    backgroundColor: Colors.background,
    overflowY: 'scroll',

  },
  headerClose: {
    width: '100%',
    alignItems: 'flex-end',
  },
  headerText: {
    width: '100%',
    alignItems: 'center',
  },
  modal: {
    width: '400px',
    backgroundColor: 'red',
  },
  countContainer:{
    paddingLeft: '10px',
    borderLeft: '1px solid',
    borderLeftColor:Colors.secondaryDarkText,
    paddingRight:"10px"
  }
});

export default Home;
