import instance from '../utils/axios.utils';

const services = {
  createServices: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'services/add_services';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error?.response?.data?.message === 'Service already exists') {
            console.log(error.response);
            reject(error.response);
          } else if (error.response) {
            reject(error.response.data.message);
          } else if (error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },

  editServices: (data: object) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'services/edit_services';
      instance()
        .put(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if (error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },
  servicesList: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'services/services_list';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if (error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },
  viewServices: (id: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'services/view_services/' + id;
      instance()
        .get(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if (error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },
  deleteServices: (id: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'services/delete_services/' + id;
      instance()
        .delete(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if (error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },
};

export default services;
