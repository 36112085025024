import React, { useEffect } from 'react';
import {
  getTrainerList,
  getCustomerList,
  getPetDetails,
  getPetList,
  getAcuityUser,
  getAcuityDetails,
} from 'utils/redux.utils';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Alert,
  Assets,
  Constants,
  Image,
  NavButton,
  Ratio,
  Text,
  Colors,
  // Datepicker,
  Functions,
  PrimaryButton,
  Container,
  Models,
  Select,
  Validation,
} from 'utils/imports.utils';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import Datepicker from 'react-multi-date-picker';
import { bold, thin } from 'utils/constant.utils';

interface paginationProps {
  page: number;
  limit: number;
  search: string;
  user_type?: string;
  customerId?: string;
  userId?: string;
  petId?: string;
}

const AddDog = (props: any) => {
  const trainers = useSelector((state: any) => state.user.trainerList);
  const pets = useSelector((state: any) => state.pet.petList);
  const user = useSelector((state: any) => state.user.user);
  const acuityUser = useSelector((state: any) => state.user.acuityUser);
  const acuity = useSelector((state: any) => state.acuity.acuity);
  const customers = useSelector((state: any) => state.user.customerList);

  const [state, setState] = Functions.useSetState({
    pet: '',
    trainer: '',
    service: '',
    start_date: '',
    end_date: '',
    customer: '',
    loading: true,
    page: 1,
    limit: 20,
    trainerSearch: '',
    petSearch: '',
    adding: false,
    edit: false,
    customerSearch: '',
    rangeValue: '',
    date: '',
    multipleDates: [],
    servicesData:[]
  });

  useEffect(() => {
    // props.updateRouteParams(props.location.state);
    if (props?.route?.params?.view) {
      getPetDetails(props?.route?.params.id);
    }
    if (props?.route?.params?.appoinment) {
      // addCustomer();
      console.log('accuity', acuityUser);
      setState({
        start_date: moment(acuity.datetime).format(),
        customer: acuityUser?._id,
     });
     createPet();
    }
    if (props?.route?.params?.add) {
      getUsers({
        page: state.page,
        limit: state.limit,
        search: state.search,
        user_type: 'customer',
      });
      getUsers({
        page: state.page,
        limit: state.limit,
        search: state.search,
        user_type: 'trainer',
      });
      getPets({
        page: state.page,
        limit: state.limit,
        search: state.search,
      });
    }
    getServicesList();
  }, []);

  useEffect(()=>{
    if(props.route.params.dogData){
      setState({pet: props.route.params.dogData?.id})
      setCustomer(props.route.params.dogData?.id);

      getPets({
        page: state.page,
        limit: state.limit,
        search: state.search,
        user_type: 'trainer',
      });
    }
  },[props.route.params])

  const backAction = () => {
    getAcuityDetails({});
    getAcuityUser({});
    props.navigation.goBack();
    return true;
  };

  const createPet = async () => {
    try {
      let name;
      acuity.forms.filter((form: any) => {
        const index = _.findIndex(form.values, {
          name: "What is your dog's name?",
        });
          if (index !== -1) {
          name = form.values[index].value;
        }
      });
      if (name) {
        let pet: any = {
          name,
          customer: acuityUser._id,
          // service: acuity.type.replace(/ /g, "_").toLowerCase()
        };
        const dog: any = await Models.pet.createPet(pet);
        setState({ pet: dog.data._id });
        getUsers({
          page: state.page,
          limit: state.limit,
          search: state.search,
          user_type: 'customer',
          userId: dog.data.customer,
        });
        getUsers({
          page: state.page,
          limit: state.limit,
          search: state.search,
          user_type: 'trainer',
        });
        getPets({
          page: state.page,
          limit: state.limit,
          search: state.search,
          customerId: acuityUser._id,
          user_type: 'customer',
        });
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const getPetDetails = async (id: string) => {
    try {
      const dog: any = await Models.training.getTrainingById(id);
      let multipleDatesArray : any =[]
      dog.data.dates.map((item:any)=>{
        multipleDatesArray.push(moment(new Date(item)).format("DD-MM-YYYY"))
      })
      setState({
        pet: dog.data.pet._id,
        service: dog.data.service,
        trainer: dog.data.trainer._id,
        start_date: dog.data.start_date || dog.data.date,
        end_date: dog.data.end_date,
        customer: dog.data.customer._id,
        rangeValue: dog.data.training_type,
        multipleDates:multipleDatesArray,
        // date :dog.data.start_date,
      });
      getUsers({
        page: state.page,
        limit: state.limit,
        search: state.search,
        user_type: 'customer',
        userId: dog.data.customer._id,
      });
      getUsers({
        page: state.page,
        limit: state.limit,
        search: state.search,
        user_type: 'trainer',
        userId: dog.data.trainer._id,
      });
      getPets({
        page: state.page,
        limit: state.limit,
        search: state.search,
        petId: dog.data.pet._id,
      });
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get training details');
      }
    }
  };

  const getUsers = async ({
    limit,
    page,
    search,
    user_type,
    userId,
  }: paginationProps) => {    
    try {
      let req: any = { page, limit, search, user_type: user_type };
      if (props?.route?.params?.view || props?.route?.params?.appoinment) {
        if (user_type === 'trainer') {
          req.user_id = userId;
        } else {
          req.user_id = userId;
        }
      }
      const user: any = await Models.admin.userList(req);
      if (user_type == 'trainer') {
        if (page !== 1) {
          getTrainerList([...trainers, ...user.data.docs]);
        } else {
          getTrainerList([...user.data.docs]);
        }
        setState({ page, limit, trainerSearch: search, loading: false });
      } else {
        if (page !== 1) {
          getCustomerList([...customers, ...user.data.docs]);
        } else {
          getCustomerList([...user.data.docs]);
        }
        setState({ page, limit, customerSearch: search, loading: false });
      }
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure(`Failed to get ${user_type} list`);
      }
      setState({ loading: false });
    }
  };

  const getPets = async ({
    page,
    limit,
    search,
    petId,
    user_type,
    customerId,
  }: paginationProps) => {
    try {
      let req: any = {
        page,
        limit,
        search,
      };
      if (props?.route?.params?.appoinment) {
        req.type = 'customer';
        req.customer = acuityUser._id;
        req.pet_id = state.pet || petId;
      }
      if (props?.route?.params?.view) {
        req.pet_id = state.pet || petId;
      }
      const petsData: any = await Models.pet.petList(req);
      let petsLabel = petsData.data.docs.map((pet: any) => {
        return {
          label: pet.name,
          value: pet._id,
          customer: props?.route?.params?.appoinment
          ? acuityUser._id
          : pet.customer._id,
          };
      });
      if (page !== 1) {
        getPetList([...pets, ...petsLabel]);
      } else {
        getPetList(petsLabel);
      }
      setState({ page, limit, petSearch: search, loading: false });
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get pet list');
      }
      setState({ loading: false });
    }
  };

  const onClose = () => {
    if (state.edit) {
      setState({ edit: false });
    } else if (props.route.params?.dogData){
      props.navigation.navigate('Trainings' , {from:"add_training"});
    }else if(props.route.params.from == "dog_profile"){
      props.navigation.goBack()
    }
    else {
      props.navigation.navigate('Trainings' ,{from:"add_training"} );
    }
  };
  const addTraining = async () => {
    try {
      setState({ adding: true });
      let training: any = {
        pet: state.pet,
        service: state.service,
        trainer: state.trainer,
        customer: state.customer,
        status: 'not_started',
        training_type: state.rangeValue,
      };
      if(state.rangeValue == "single day"){
        training.date = state.date
      }
      if(state.rangeValue == "range"){
        training.start_date = state.start_date;
        training.end_date = state.end_date;
      }
      if(state.rangeValue == "multiple days"){
        training.dates = state.multipleDates.sort()
      }
      if (props.route.params?.appoinment) {
        training.acuity_id = acuity.id;
      }
      await Validation.addTraining.validate(training);
      const added: any = await Models.training.createTraining(training);
      user.created_training = true
      Functions.Success('Success', 'Training added');
      setState({ adding: false });
      onClose();
    } catch (err) {
      console.log('err', err);
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to add training ');
      }
      setState({ adding: false });
    }
  };

  const editTraining = async () => {
    try {
      setState({ adding: true });
      let training: any = {
        pet: state.pet,
        service: state.service,
        trainer: state.trainer,
        customer: state.customer,
        status: state.status,
        training_type: state.rangeValue,
      };
      if(state.rangeValue == "single day"){
        training.date = state.date
      }
      if(state.rangeValue == "range"){
        training.start_date = state.start_date;
        training.end_date = state.end_date;
      }
      if(state.rangeValue == "multiple days"){
        training.dates = state.multipleDates.sort()
      }
      await Validation.addTraining.validate(training);
      training.id = props.route.params.id;
      const edit: any = await Models.training.editTraining(training);
      Functions.Success('Success', 'Training updated');
      setState({ adding: false });
      onClose();
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to update training details');
      }
      setState({ adding: false });
    }
  };

  const deleteTraining = async () => {
    try {
      console.log('In delete');
      const edit: any = await Models.training.deleteTraining(
        props?.route?.params?.id,
      );
      Functions.Success('Success', 'Training deleted');
      onClose();
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to delete training details');
      }
      setState({ adding: false });
    }
  };

  const onConfirm = (date: any) => {
    setState({
      start_date: moment(date.selection.startDate).format(),
      end_date: moment(date.selection.endDate).format(),
    });
    };

  const setCustomer = async (id: string) => {
    let response: any = await Models.pet.getPetById(id);
    setState({ customer: response?.data?.customer?._id, pet: id });
  };

  const addDog = () => {
    props.navigation.navigate('AddDog', {
      view: false,
      add: true,
      appoinment: true,
    });
    };

  const getConfirmation = () => {
    Alert.alert('Want to delete training?', '', [
      {
        text: 'Yes',
        onPress: () => deleteTraining(),
      },
      { text: 'No', onPress: () => console.log('OK Pressed') },
    ]);
  };

  const getServicesList = async () => {
    try {
      let req: any = { page: 1, limit: 100, search: '' };
      const services: any = await Models.services.servicesList(req);
      let servicesData:any = []
      services.data.docs.map((item:any)=>{
        servicesData.push({label: item.name , value: item.name})
      })

      setState({servicesData:servicesData})
    } catch (error) {
      Functions.Failure(error)
      console.log('error', error);
    }
  };

  const trainingRange = [
    { value: 'single day', label: 'Single Day' },
    { value: 'multiple days', label: 'Multiple Days' },
    { value: 'range', label: 'Range' },
  ];
  const format = 'DD/MM/YYYY';
  return (
    <Container screen loading={state.loading}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={styles.addPetContainer}>
          <View style={styles.headerWrapper}>
            <View style={styles.textWrapper}>
              <View style={styles.imageContainer}>
                <TouchableOpacity
                  style={{
                    width: Ratio(40),
                    height: Ratio(40),
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  activeOpacity={0.7}
                  onPress={onClose}>
                  <Image svg src={Assets.ArrowBack} width={20} height={20} />
                </TouchableOpacity>
              </View>
              <View style={styles.headTextWrapper}>
                <Text
                  top={0}
                  size={24}
                  color={Colors.primaryDarkText}
                  family={Constants.light}
                  weight="300">
                  {state.edit
                    ? 'Edit'
                    : props?.route?.params?.view
                    ? 'Details'
                    : 'Add Training'}
                </Text>
              </View>
            </View>
            <View>
              <NavButton
                icon={Assets.Delete}
                style={{ backgroundColor: Colors.secondaryBackground }}
                svg
                onClick={() => getConfirmation()}
                iconHeight={Ratio(25)}
                iconWidth={Ratio(25)}
              />
            </View>
          </View>
          <View style={styles.addPetFormContainer}>
            <View style={styles.formBody}>
              <View style={styles.bodyContainer}>
                {props?.route?.params?.appoinment && (
                  <View style={styles.inputWrapper}>
                    <Select
                      data={customers}
                      keyValue={'_id'}
                      label="Customer"
                      labelValue="username"
                      selected={state.customer}
                      search
                      onOpenSelect={() => {
                        getUsers({
                          page: state.page,
                          limit: state.limit,
                          search: '',
                          user_type: 'customer',
                          userId: state.customer,
                        });
                      }}
                      onSelect={(value: any) => setState({ customer: value })}
                      // disabled={state.edit ? !state.edit : props?.route?.params.view}
                      onSearch={(text: any) => {
                        if (!_.isObject(text)) {
                          getUsers({
                            page: state.page,
                            limit: state.limit,
                            search: text,
                            user_type: 'customer',
                            userId: state.customer,
                          });
                        }
                      }}
                      emptyMessage="Customer list is empty"
                    />
                  </View>
                )}
                <View style={styles.inputWrapper}>
                  <Select
                    data={pets}
                    keyValue={'value'}
                    label="Dog"
                    labelValue="label"
                    selected={state.pet}
                    search
                    onOpenSelect={() => {
                      getPets({
                        page: state.page,
                        limit: state.limit,
                        search: '',
                      });
                    }}
                    onSelect={(value: any) => setCustomer(value)}
                    // disabled={state.edit ? !state.edit : props?.route?.params.view}
                    disabled={user.user_type === 'admin' ? false : true}
                    onSearch={(text: any) => {
                      if (!_.isObject(text)) {
                        getPets({
                          page: state.page,
                          limit: state.limit,
                          search: text,
                        });
                      }
                    }}
                    optionText={
                      props?.route?.params?.appoinment ? 'Add Dog' : false
                    }
                    emptyMessage="Dog list is empty"
                    optionClick={() => addDog()}
                  />
                </View>
                <View style={styles.inputWrapper}>
                  <Select
                    data={trainers}
                    keyValue={'_id'}
                    label="Trainer"
                    labelValue="username"
                    selected={state.trainer}
                    search
                    onOpenSelect={() => {
                      getUsers({
                        page: state.page,
                        limit: state.limit,
                        search: '',
                        user_type: 'trainer',
                        userId: state.trainer,
                      });
                    }}
                    onSelect={(value: any) => setState({ trainer: value })}
                    // disabled={state.edit ? !state.edit : props?.route?.params.view}
                    disabled={user.user_type === 'admin' ? false : true}
                    onSearch={(text: any) => {
                      if (!_.isObject(text)) {
                        getUsers({
                          page: state.page,
                          limit: state.limit,
                          search: text,
                          user_type: 'trainer',
                          userId: state.trainer,
                        });
                      }
                    }}
                    emptyMessage="Trainer list is empty"
                  />
                </View>
                <View style={styles.inputWrapper}>
                  <Select
                    data={state.servicesData}
                    keyValue={'value'}
                    label="Service"
                    labelValue="label"
                    selected={state.service}
                    onSelect={(value: any) => setState({ service: value })}
                    // disabled={state.edit ? !state.edit : props?.route?.params.view}
                    disabled={user.user_type === 'admin' ? false : true}
                  />
                </View>
                <View style={styles.inputWrapper}>
                  <Select
                    data={trainingRange}
                    keyValue={'value'}
                    label="Training Range"
                    labelValue="label"
                    selected={state.rangeValue}
                    onSelect={(value: any) => setState({ rangeValue: value })}
                  />
                </View>
                {state.rangeValue == 'single day' && (
                  <View style={styles.singleDatePicker}>
                    <Text
                      size={14}
                      color={Colors.primaryLightText}
                      styles={{ width: '100%' , paddingLeft:"14px"}}>
                      Choose Date
                    </Text>
                    <input
                      type="date"
                      value={state.date}
                      onChange={(data) => setState({ date: data.target.value })}
                      style={styles.datePickerWrapper}
                    />
                  </View>
                )}
                {state.rangeValue == 'range' && (
                  <View style={[styles.dateWrapper, { zIndex: 700 }]}>
                    <View style={styles.datePickerContainer}>
                      <Text
                        size={14}
                        color={Colors.primaryLightText}
                        styles={{ width: '100%' }}>
                        Start Date
                      </Text>
                      <input
                        type="date"
                        value={state.start_date}
                        onChange={(data) =>
                          setState({ start_date: data.target.value })
                        }
                        style={styles.datePickerWrapper}
                      />
                    </View>
                    <View style={styles.datePickerContainer}>
                      <Text
                        size={14}
                        color={Colors.primaryLightText}
                        styles={{ width: '100%' }}>
                        End Date
                      </Text>
                      <input
                        type="date"
                        value={state.end_date}
                        onChange={(data) =>
                          setState({ end_date: data.target.value })
                        }
                        style={styles.datePickerWrapper}
                      />
                    </View>
                  </View>
                )}
                {state.rangeValue == 'multiple days' && (
                  <View style={styles.multipleDatePicker}>
                    <Text size={14}
                        color={Colors.primaryLightText}
                        styles={{ width: '100%' , paddingLeft: "14px"}}>Select Training Dates</Text>
                    <Datepicker
                      value={state.multipleDates}
                      format={"DD/MM/YYYY"}
                      style={{
                        width: '100%',
                        height: 50,
                        backgroundColor: Colors.inputBackground,
                        border: 0,
                        borderRadius: 10,
                        paddingLeft: 14,
                        paddingRight: 14,
                      }}
                      className="calender"
                      containerStyle={{ width: '100%' }}
                      multiple={true}
                      range={false}
                      onChange={(dates: any) => { 
                        let listedDates :any = [] ;
                        dates.sort().map((date:any)=>(listedDates.push(date.format())))
                        setState({multipleDates:listedDates})
                       }
                      }
                    />
                    {/* <DatePicker label={"Select dates"}/> */}
                  </View>
                )}
              </View>
             
            </View>
            <View style={styles.addPetButtonContainer}>
              <PrimaryButton
                text={props?.route?.params?.add ? 'Add Training' : 'Done'}
                onPress={props?.route?.params?.add ? addTraining : editTraining}
                // style={{ width: "50%", position: "absolute", top: Ratio(50), zIndex: 1 }}
                width={200}
                backgroundColor={Colors.primaryDarkColor}
                activeOpacity={0.7}
                activity={state.adding}
                // disabled={user.user_type === 'admin' ? false : true}
              />
            </View>
          </View>
          {/* </View> */}
        </View>
      </ScrollView>
    </Container>
  );
};

const styles = StyleSheet.create({
  addPetContainer: {
    padding: Ratio(20),
    marginBottom: Ratio(50),
    width: '100%',
    paddingBottom: "50px",
  },
  headerWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  imageContainer: {},
  headTextWrapper: {
    paddingLeft: Ratio(10),
  },
  textWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  addPetFormContainer: {
    position: 'relative',
    paddingTop: Ratio(50),
    paddingBottom: Ratio(100),
    width: '100%',
    alignItems: 'center',
  },
  addPetIconContainer: {
    position: 'absolute',
    width: '100%',
    zIndex: 1,
    alignItems: 'center',
    top: -30,
  },
  navIcon: {
    width: Ratio(80),
    height: Ratio(80),
    backgroundColor: Colors.secondaryLightColor,
  },
  formBody: {
    // paddingVertical: Ratio(100),
    // paddingHorizontal: Ratio(10),
    backgroundColor: Colors.buttonTextColor,
    borderRadius: 32,
    marginTop: Ratio(10),
    shadowColor: '#DDE5EE',
    shadowOffset: { width: 4, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    // zIndex: -11000,
    width: '50%',
  },
  bodyContainer: {
    paddingTop: Ratio(20),
    paddingBottom: Ratio(40),
    padding: '0px 15px',
    // marginBottom: Ratio(30),
    alignItems: 'center',
    width: '100%',
  },
  dateWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0px',
    width: '100%',
    justifyContent: 'space-between',
  },
  inputWrapper: {
    width: '100%',
    // zIndex: 100,
    padding: '10px 0px',
  },
  dropDowngtgtt: {
    width: '100%',
    // zIndex: 100,
    padding: '10px 0px',
  },
  // addPetButtonContainer: {
  //   position: "absolute",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   // height: "10%",
  //   width: "100%",
  //   bottom: Ratio(5, true),
  // },
  addPetButtonContainer: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    // height: "10%",
    width: '100%',
    // bottom: Ratio(3, true),
    bottom: 0,
    zIndex: 1000,
  },
  btnContent: {
    width: '80%',
  },
  trainingDateText: {
    paddingTop: 5,
    paddingLeft: 5,
    paddingBottom: 20,
  },
  datePickerContainer: {
    width: '48%',
  },
  datePickerWrapper: {
    border: 0,
    backgroundColor: Colors.inputBackground,
    padding: '15px',
    borderRadius: '12px',
    fontSize: '14px',
    fontFamily: Constants.regular,
  },
  multipleDatePicker:{
    width:"100%",
    marginTop: "20px",
    marginBottom:"20px",
  },
  singleDatePicker:{
    width:"100%",
    marginTop: "20px",
    marginBottom:"20px",
  }
});

export default AddDog;
