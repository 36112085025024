import React from 'react';
import moment from 'moment';
import { View, StyleSheet, Ratio, Colors, Image, Text, Container, InfoDetail, Assets, Constants, NavButton } from 'utils/imports.utils';

interface petlist {
  pet?: any;
  name: string;
  age: string;
  age_type?: string;
  gender: string;
  start_date: string;
  end_date: string;
  username: string;
  user_type: string;
  category: string;
  profile?: string;
  service?: string;
  status?: any;
  statusColor?: any;
  statusTextColor?: any;
  user_profile?: any;
  onChatClick?: any;
  onCallClick?: any;
  callDisable?: boolean,
  onDisableClick?: any;
  single_date:any;
  type: string;
}

const PetCard = (props: petlist) => {
  const {
    pet,
    name,
    age,
    age_type,
    gender,
    start_date,
    end_date,
    user_type,
    username,
    category,
    profile,
    service,
    status,
    statusColor,
    statusTextColor,
    user_profile,
    onChatClick,
    onCallClick,
    callDisable,
    onDisableClick,
    single_date,
    type,
  } = props;
  return (
    <Container styles={styles.petCardScreen}>
      <View style={styles.petCardContainer}>
        {profile ? 
        <View style={[styles.imageContainer]}>
          <Image src={profile} url  width="100%" height="100%" resize="cover" radius={30} />
        </View>
        : <View style={[styles.imageContainer]}>
          <Image src={Assets.DogPrimary} style={{ backgroundColor: Colors.secondaryLightColor, borderRadius: 30 }}  width="100%" height="100%" resize="cover" radius={30} />
        </View>}
        <View style={styles.textContainer}>
          <View style={styles.petInfo}>
            <Text size={20} family={Constants.regular} color={Colors.primaryDarkColor}  weight="600">{name}</Text>
            <Text size={12} family={Constants.regular} color={Colors.primaryLightText}>{category}</Text>
          </View>
          <View style={styles.infoContainer}>
            <View style={styles.infoCard}>
              <InfoDetail text={gender} style={{ backgroundColor: Colors.secondaryBackground }} />
            </View>
            <View style={styles.infoCard}>
              <InfoDetail text={`${age} ${age_type ? age_type : ''}`} style={{ backgroundColor: Colors.secondaryBackground }} />
            </View>
            <View style={styles.dateContainer}>
              <View style={styles.dateText}>
                {single_date || start_date || end_date ? (
                  type == 'single day' ? (
                    <View style={styles.dateContainer}>
                      <View>
                        <InfoDetail
                          text={moment(single_date).format('MMM D')}
                          icon={Assets.CalendarPrimary}
                        />
                      </View>
                    </View>
                  ) : (
                    <View style={styles.dateContainer}>
                      <View>
                        <InfoDetail
                          text={`${moment(start_date).format('MMM D')} - ${moment(end_date).format(
                            'MMM D',
                          )}`}
                          icon={Assets.CalendarPrimary}
                        />
                      </View>
                    </View>
                  )
                ) : (
                  <></>
                )}
              </View>
            </View>
          </View>
          <View style={styles.trainerContainer}>
            <View style={styles.trainerDetails}>
              {user_profile ?
                <View style={styles.trainerImage}>
                  <Image src={user_profile} url width="100%" height="100%" radius={10} resize="cover" />
                </View>
              : <View style={styles.trainerImage}>
                <Image src={Assets.SingleUserPrimary} style={styles.defaultProfile} width="70%" height="70%" radius={10} resize="cover" />
              </View>}
              <View style={styles.trainerNameDetail}>
                <Text size={12} weight="500" family={Constants.medium} color={Colors.primaryDarkColor}>{username}</Text>
                <Text size={12} weight="400" styles={{ paddingTop: Ratio(0)}} color={Colors.primaryLightText} family={Constants.regular}>{user_type}</Text>
              </View>
            </View>
            <View style={styles.trainerContact}>
              <View style={styles.chat}>
                <NavButton icon={Assets.PhonePrimary} style={{ backgroundColor: Colors.secondaryLightColor }} svg onClick={callDisable ? onDisableClick : onCallClick} />
              </View>
              <View style={styles.call}>
                <NavButton icon={Assets.MessageOrange} style={{ backgroundColor: Colors.secondaryLightColor }} svg onClick={() => onChatClick()} />
              </View>
            </View>
          </View>
          <View style={styles.trainingDetails}>
            <Text size={12} color={Colors.primaryDarkColor}>Training</Text>
            <View style={styles.trainingContent}>
              <Text size={Ratio(10)} color={Colors.primaryLightText}>{service}</Text>
              <View style={styles.statusContainer}>
                <InfoDetail text={status} style={{ backgroundColor: statusColor }} textColor={statusTextColor || Colors.buttonTextColor}  />
              </View>
            </View>
          </View>
        </View>
      </View>
    </Container>
  )
}

const styles = StyleSheet.create({
  petCardScreen: {
    backgroundColor: Colors.buttonTextColor,
    borderRadius: Ratio(40)
  },
  petCardContainer: {
    width: "100%",
  },
  imageContainer: {
    paddingLeft: Ratio(15),
    paddingRight: Ratio(15),
    paddingTop: Ratio(15),
    paddingBottom: Ratio(10),
    height: Ratio(300),
    width: "100%",
    borderRadius: Ratio(30)
  },
  textContainer: {
    paddingLeft: Ratio(20),
    paddingRight: Ratio(15),
    paddingBottom: Ratio(30),
    paddingTop: Ratio(5)
  },
  petInfo: {

  },
  infoContainer: {
    flexDirection: "row",
    paddingTop: Ratio(15),
  },
  infoCard: {
    maxWidth: Ratio(150)
  },
  dateContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  calendar: {
    width: Ratio(14),
    height: Ratio(14)
  },
  dateText: {
    paddingLeft: Ratio(5)
  },
  trainerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: Ratio(20),
  },
  trainerDetails: {
    flexDirection: "row",
    alignItems: "center",
    width: "60%"
  },
  trainerImage: {
    width: Ratio(45),
    height: Ratio(45)
  },
  trainerNameDetail: {
    paddingLeft: Ratio(10)
  },
  trainerContact: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "40%"
  },
  chat: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "50%"
  },
  call: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "50%"
  },
  trainingDetails: {
    paddingTop: Ratio(20)
  },
  trainingContent: {
    flexDirection: "row",
    alignItems: "center"
  },
  statusContainer: {
    maxWidth: Ratio(200),
    paddingLeft: Ratio(10),
    justifyContent: "center",
    alignItems: "center"
  },
  defaultProfile: {
    backgroundColor: Colors.secondaryLightColor,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10
  }
})

export default PetCard;