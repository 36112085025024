import React from 'react'
import FlatList from 'flatlist-react';

function Component(props) {
  const { data, renderItem, emptyRender, onEndReached, hasMoreItems, style } = props;
  return (
    <FlatList
    style={style}
    list={data}
    renderItem={(item, index)=>renderItem({item, index})}
    renderWhenEmpty={emptyRender}
    loadMoreItems={onEndReached}
    hasMoreItems={hasMoreItems || true}
    paginationLoadingIndicator={<div/>}
    />
  )
}

export default Component