import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  FlatList,
  ActivityIndicator,
  Text,
  Container,
  Assets,
  Functions,
  NavButton,
  Colors,
  Constants,
  Ratio,
  Input,
  // FilterModel,
  UserCard,
  Models,
} from 'utils/imports.utils';
// import LinearGradient from 'react-native-linear-gradient';
import _ from 'lodash';
import { getOrganisationList } from 'utils/redux.utils';
interface paginationProps {
  page?: number;
  limit?: number;
  search?: string;
  filter?: string;
}

const Organization = (props: any) => {
  const organizations = useSelector((state: any) => state.org.orgList);
  const user = useSelector((state: any) => state.user.user);
// console.log('organizations', organizations);
  const [state, setState] = Functions.useSetState({
    searchVisible: false,
    page: 1,
    limit: 100,
    search: '',
    loading: false,
    organizationList: [],
    totalDocs:''
  });

  useEffect(() => {

      getOrganisation({
        page: state.page,
        limit: state.limit,
        filter: state.filter
      });
      // props.navigation.addListener('beforeRemove', backAction);
// console.log("qwerty")

  }, []);

  const backAction = () => {
    // getOrganisation([]);
    props.navigation.goBack();
    // props.navigation.navigate('Manage');
    return true;
  };

  const getOrganisation = async ({ page = state.page, limit = state.limit, filter = state.filter, search }: paginationProps) => {

    try {
      setState({ loadMoreLoader: true, search: search });
      let req: any = { page, limit, search };
      if (filter) {
        req.status = filter;
      }
      const org: any = await Models.org.orgList(req);
      console.log("org",org)
      setState({totalDocs:org.data.totalDocs})
      if (page !== 1) {
        getOrganisationList([...organizations, ...org.data.docs]);
      } else {
        getOrganisationList(org.data.docs);
      }
      setState({
        loading: false,
        hasMore: org.data.hasNextPage,
        page,
        limit,
        loadMoreLoader: false,
        filter,
        organizationList: org.data.docs,
      });
    } catch (err) {
      console.log('err', err);
      setState({ loading: false });
    }
  };
  const goBack = () => {
    props.navigation.navigate("Manage");
  };

  const getSearch = () => {
    setState({ searchVisible: !state.searchVisible });
    getOrganisation({ limit: state.limit, page: 1, filter: state.filter });
  };

  const loadMore = () => {
    if (state.hasMore) {
        getOrganisation({ limit: state.limit, page: state.page + 1 });
    }
  };

  const closeAction = () => {
    setState({ openFilter: !state.openFilter, filter: '' });
  };

  const closeSearch = () => {
    setState({ searchVisible: !state.searchVisible, search: '' });
    console.log('search,', state.filter);
  };

  const gotoAddOrganization = () => {
    props.navigation.navigate('AddOrganization', { add: true });
  };
  const viewDetails = (item: any) => {
    props.navigation.navigate('AddOrganization', {
      view: true,
      buttonText: 'Details',
      orgId: item._id,
    });
  };


  const loader = () => {
    return (
      <View>
        {state.loadMoreLoader && (
          <ActivityIndicator
            size="small"
            color={Colors.primaryDarkColor}
            style={{ marginLeft: 6 }}
          />
        )}
      </View>
    );
  };

  return (
    <Container
      screen
      styles={styles.organizationsContainer}
      loading={state.loading}>
      <View style={styles.organizationsWrapper}>
        <View style={styles.headerWrapper}>
          <View style={styles.headerContent}>
            {!state.searchVisible && !state.openFilter && (
              <View>
                <NavButton
                  icon={Assets.ArrowBack}
                  style={{ backgroundColor: Colors.background }}
                  svg
                  onClick={() => goBack()}
                  iconHeight={Ratio(25)}
                  iconWidth={Ratio(25)}
                />
              </View>
            )}
            {!state.searchVisible && !state.openFilter && (
              <View style={styles.headTextContainer}>
                <View style={{ height: '70%' }}>
                  <Text
                    size={Ratio(24)}
                    color={Colors.primaryDarkColor}
                    family={Constants.regular}>
                    Organization
                  </Text>
                </View>
                <View style={{ height: '30%', paddingBottom: '30px' }}>
                  <Text
                    size={Ratio(12)}
                    color={
                      Colors.secondaryDarkColor
                    }>{`${state.totalDocs} Organizations`}</Text>
                </View>
              </View>
            )}
          </View>
          {!state.searchVisible && !state.openFilter ? (
            <View style={styles.headerContent}>
              <NavButton
                icon={Assets.Plus}
                style={styles.addButton}
                onClick={() => gotoAddOrganization()}
              />
              <View style={styles.navButtonContainer}>
                <NavButton
                  icon={Assets.Search}
                  onClick={closeSearch}
                  svg
                  iconHeight={Ratio(25)}
                  iconWidth={Ratio(25)}
                />
              </View>
              {/* <View style={styles.navButtonContainer}>
                <NavButton
                  icon={Assets.TuneBlue}
                  onClick={() => setState({ openFilter: !state.openFilter })}
                  svg
                  iconHeight={Ratio(25)}
                  iconWidth={Ratio(25)}
                />
              </View> */}
            </View>
          ) : (
            <View style={styles.headerContent}>
              {state.searchVisible ? (
                <View style={styles.navButtonContainerSearch}>
                  <View style={styles.searchContainer}>
                    <Input
                      onChange={(search: any) => {
                        if (!search.target) {
                          setState({ search });
                          getOrganisation({
                            limit: state.limit,
                            page: 1,
                            search: search,
                          });
                        }
                      }}
                      value={state.search}
                      iconPosition="start"
                      source={Assets.Search}
                      iconHeight={20}
                      iconWidth={20}
                      // inputWidth="100%"
                      styles={styles.input}
                      placeholder="Search"
                      name="search"
                    />
                  </View>
                  <NavButton
                    icon={Assets.Close}
                    onClick={getSearch}
                    svg
                    iconHeight={Ratio(25)}
                    iconWidth={Ratio(25)}
                  />
                </View>
              ) : (
                <View style={styles.navButtonContainer}>
                  <NavButton
                    icon={Assets.Close}
                    onClick={closeAction}
                    svg
                    iconHeight={Ratio(25)}
                    iconWidth={Ratio(25)}
                  />
                </View>
              )}
            </View>
          )}
        </View>
        {organizations.length > 0 ? (
          <View style={styles.organizationListWrapper}>
            <FlatList
              data={organizations}
              scrollEnabled={true}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              keyExtractor={(item: any) => item.name}
              renderItem={({ item, index }) => (
                <View
                  activeOpacity={0.7}
                  style={[
                    styles.petDetail,
                    {
                      paddingBottom: index === 10 - 1 ? Ratio(150) : Ratio(10),
                    },
                  ]}
                  >
                  <UserCard
                    user={item._id}
                    username={item.name}
                    user_type={item.name}
                    hasCall={false}
                    hasChat={false}
                    onClick={()=> viewDetails(item)}
                  />
                </View>
              )}
              onEndReached={loadMore}
              onEndReachedThreshold={0.5}
              hasMoreItems={state.hasMore}
              // ListFooterComponent={loader()}
            />
          </View>
        ) : (
          <View style={styles.emptyContainer}>
            <Text color={Colors.secondaryLightText} size={14}>
              Organizations not found
            </Text>
          </View>
        )}
      </View>
    </Container>
  );
};

const styles = StyleSheet.create({
  organizationsContainer: {
    height: '100%',
  },
  organizationsWrapper: {
    width: '100%',
    height: '100%',
    paddingHorizontal: Ratio(20),
  },
  headerWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '8%',
  },
  headerContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    elevation: 10,
  },
  headTextContainer: {
    justifyContent: 'center',
    height: '100%',
    paddingLeft: Ratio(10),
  },
  navButtonContainer: {
    paddingLeft: Ratio(20),
  },
  navButtonContainerSearch: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    // paddingLeft: Ratio(20),
  },
  searchContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '80%',
  },
  input: {
    width: '100%',
  },
  organizationListWrapper: {
    marginTop: Ratio(10),
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
    flexWrap:"wrap",
  },
  petDetail: {
    marginBottom: Ratio(10),
    width:"49%"
  },
  emptyContainer: {
    width: '100%',
    paddingTop: Ratio(20),
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: Ratio(150),
    width: '100%',
  },
  filterContainer: {
    position: 'absolute',
    bottom: 0,
    top: Ratio(100),
    left: 0,
    right: 0,
    width: '100%',
  },
  addButton:{
    borderRadius:"50%",
    backgroundColor: Colors.secondaryDarkColor,
    marginRight:"20px",
  },
});

export default Organization;
