import React from 'react';

interface IImage {
  width?: any;
  height?: any;
  borderRadius?: string;
  src?: string;
  backgroundSize?: string;
  style?: any;
  styles?: any;
  objectFit?: string;
  url?: boolean;
  resize?: string;
  radius?: number;
  svg?: boolean;
  alt?: string;
}

const Image = (props: IImage) => {
  const { width, height, borderRadius, src, backgroundSize, objectFit, radius, alt } =
    props;
  let style = props.style || props.styles;
  if(Array.isArray(props.style)){
    let arrStyle = {};
    props.style.forEach(value => {
      arrStyle = {...arrStyle, ...value}
    })
    style = arrStyle;
  }
  return (
    <img
      alt={alt || "image"}
      className="image_component"
      src={src}
      style={{
        ...{
          width: width || '100%',
          height: height || '100%',
          borderRadius: radius || borderRadius,
          backgroundSize: backgroundSize || 'cover',
          objectFit: objectFit || 'cover',
        },
        ...style,
      }}
    />
  );
};

export default Image;
