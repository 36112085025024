import { testDispatch, signupDispatch, loginDispatch } from '../utils/redux.utils';
import instance from '../utils/axios.utils';

const org = {
  createOrg: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'org/add_org';
      instance()
        .post(url, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error?.response?.data?.message === 'Email already exists') {
            console.log(error.response);
            reject(error.response);
          } else if (error.response) {
            reject(error.response.data.message);
          } else if (error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },

  editOrg: (data: object) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'org/edit_org';
      instance()
        .put(url, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if (error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },
  orgList: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'org/org_list';
      instance()
        .post(url, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if (error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },
  viewOrg: (id: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'org/view_org/' + id;
      instance()
        .get(url)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if (error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },
  checkOrgExist: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'org/check_org_exist';
      instance()
        .post(url,body)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if (error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },
};

export default org;
