import React from 'react';
import {
  Text,
  Colors,
  Constants,
  Assets,
  Ratio,
  Container,
  InfoDetail,
  PrimaryButton,
  View, StyleSheet
} from 'utils/imports.utils';
import moment from 'moment';

interface AcuityProps {
  start_date?: string;
  end_date?: string;
  name?: string;
  training_type?: string;
  scheduled?: boolean;
  handleSchedule?: any;
}

const Acuity = (props: AcuityProps) => {

  const { start_date, end_date, scheduled, name, training_type, handleSchedule } = props;
  
  return (
    <View style={styles.containerStyle}>
      <View style={styles.acuityContainer}>
        <View style={styles.dateContainer}>
          <View style={styles.dateContent}>
            <InfoDetail
              text={(start_date && end_date) ? `${moment(start_date).format('MMM D')} - ${moment(end_date).format('MMM D')}` : `${moment(start_date).format('MMM D')}`}
              icon={Assets.CalendarPrimary}
              style={styles.borderRadiusTop}
            />
          </View>
        </View>
        <View style={styles.acuityDetails}>
          <View style={styles.nameDetails}>
            <Text
              color={Colors.primaryDarkText}
              size={18}
              weight="500"
              ellipsizeMode="tail"
              numberOfLines={1}
              styles={{ width: '90%' , textOverflow: "ellipsis" , overflow:"hidden", whiteSpace:"noWrap"}}
              family={Constants.medium}>
              {name}
            </Text>
            <Text
              color={Colors.primaryLightText}
              ellipsizeMode="tail"
              numberOfLines={1}
              styles={{ width: '90%' }}
              size={12}
              weight="400"
              family={Constants.medium}>
              {training_type}
            </Text>
          </View>
          <View style={[styles.buttonContainer]}>
            <View style={[styles.buttonContent]}>
              <PrimaryButton
                onPress={handleSchedule}
                text={scheduled ? 'Scheduled' : 'Schedule'}
                backgroundColor={scheduled ? Colors.secondaryBackground : Colors.primaryDarkText}
                textColor={scheduled ? Colors.primaryDarkText : null}
                width={"100%"}
                height={Ratio(40)}
                disabled={scheduled}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  containerStyle: {
    backgroundColor: Colors.buttonTextColor,
    borderRadius: 25,
    padding: "0px 15px 15px",
  },
  acuityContainer: {
    width: '100%',
    // paddingHorizontal: Ratio(10)
  },
  dateContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
  dateContent: {
    marginRight: Ratio(20),
  },
  borderRadiusTop: {
    borderTopRightRadius: Ratio(0),
    borderTopLeftRadius: Ratio(0),
  },
  acuityDetails: {
    flexDirection: 'row',
  },
  nameDetails: {
    padding: "5px 20px",
    width: '55%',
  },
  buttonContainer: {
    justifyContent: 'center',
    alignItems: 'flex-end',
    width: '45%',
    // paddingRight: Ratio(10),
    // paddingTop: Ratio(15),
    // backgroundColor: "blue"
  },
  buttonContent: {
    width: Ratio(110),
    height: Ratio(40),
    justifyContent: 'center',
    // paddingTop: Ratio(25),
    // backgroundColor: "red"
  },
});

export default Acuity;
