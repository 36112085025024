import {
  GET_TRAINING_DETAILS,
  GET_TRAINING_LIST,
  REMOVE_TRAINING_DETAILS,
  GET_ACUITY_TRAINING_LIST,
  CHANGE_FILTER
} from 'utils/types.utils';
import { storeAction } from 'interfaces/common.interface';

const initialState = {
  trainingList: [],
  acuityTrainingList: [],
  training: {},
  filter: ""
};

const PetReducer = (state = initialState, action: storeAction) => {
  switch (action.type) {
    case GET_TRAINING_LIST:
      let trainingList: any = [...action.payload]
      state.trainingList = trainingList;
      return state;
    case GET_TRAINING_DETAILS:
      let training: any = { ...action.payload }
      state.training = training;
      return state;
    case REMOVE_TRAINING_DETAILS:
      state.training = {};
      return state;
    case GET_ACUITY_TRAINING_LIST:
      let acuityTrainingList: any = [...action.payload]
      state.acuityTrainingList = acuityTrainingList;
      return state;
    case CHANGE_FILTER:
      state.filter = action.payload;
      return state;
    default:
      return state;
  }
};

export default PetReducer;
