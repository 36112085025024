import instance from '../utils/axios.utils';
import { getCallList } from '../utils/redux.utils';

const call = {
  addCall: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'call/add_call';
      instance()
        .post(url, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if (error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },

  veiwCall: (id: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = `call/view_call/${id}`;
      instance()
        .get(url)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if (error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },

  editCall: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = `call/edit_call`;
      instance()
        .put(url, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if (error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },

  deleteCall: (id: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = `call/delete_call/${id}`;
      instance()
        .post(url)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if (error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },

  callList: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = `call/call_list`;
      instance()
        .post(url, data)
        .then(res => {
          resolve(res.data);
          getCallList(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if (error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },
};

export default call;
