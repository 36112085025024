import {
  GET_ORG_DETAILS,
  GET_ORG_LIST,
  REMOVE_ORG_DETAILS,
} from 'utils/types.utils';
import { storeAction } from 'interfaces/common.interface';

const initialState = {
  orgList: [],
  organization: {},
};

const orgReducer = (state = initialState, action: storeAction) => {
  switch (action.type) {
    case GET_ORG_LIST:
      let orgList: any = [...action.payload]
      state.orgList = orgList;
      return state;
    case GET_ORG_DETAILS:
      let training: any = { ...action.payload }
      state.organization = training;
      return state;
    case REMOVE_ORG_DETAILS:
      state.organization = {};
      return state;
    default:
      return state;
  }
};

export default orgReducer;
