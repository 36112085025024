import React from 'react'
import { View, StyleSheet, Ratio, Assets, Container, Image, Text, PrimaryButton, Colors } from 'utils/imports.utils';

const ResetSuccess = (props: any) => {

  const goToLogin = () => {
    // props.navigation.navigate("Login");
    window.location.href = "/Login"
  }

  return (
      <View style={styles.otpVerifyScreen}>
        <View style={styles.otpWrapperWrapper}>
          <View style={styles.iconWrapper}>
            <Image src={Assets.Tick} svg width={36} height={36} />
          </View>
          <View style={styles.headerWrapper}>
            <Text size={Ratio(24)} color={Colors.primaryDarkColor} styles={styles.headText}>Reset Successfully</Text>
          </View>
          <View style={styles.inputWrapper}>
            <View style={styles.buttonWrapper}>
              <PrimaryButton 
              width={200} 
              text="Login" 
              onPress={() => goToLogin()} 
              backgroundColor={Colors.primaryDarkColor}
              />
            </View>
          </View>
        </View>
      </View>
  )
}

const styles = StyleSheet.create({
  otpVerifyScreen: {
    width: "100%",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center"
  },
  otpWrapperWrapper: {
    width: "30%",
    justifyContent: "center",
    alignItems: "center"
  },
  iconWrapper: {
    width: Ratio(90),
    height: Ratio(90),
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.secondaryLightColor,
    borderRadius: 28,
    // paddingBottom: Ratio(20)
    marginBottom: Ratio(24)
  },
  headerWrapper: {
    width: "70%",
    alignItems: "center",
    paddingBottom: Ratio(32)
  },
  headText: {
    paddingBottom: Ratio(8)
  },
  inputWrapper: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    // height: Ratio(60)
  },
  inputContent: {
    width: "100%",
    // height: "100%",
    paddingBottom: Ratio(16)
  },
  input: {
    width: "80%"
  },
  buttonWrapper: {
    width: "70%",
    paddingBottom: Ratio(20)
  },
  textWrapper: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center"
  }
})

export default ResetSuccess;