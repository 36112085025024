import React from 'react';
import { ActivityIndicator, StyleSheet, View, Assets, Colors, Ratio } from 'utils/imports.utils';

interface LoadingProps {
  color?: any;
}

const Loading = (props: LoadingProps) => {
  const { color } = props;
  return (
    <View style={css.loadingWrap} >
      <ActivityIndicator
        style={css.indicator}
        size="small"
        color={Colors.primaryDarkColor}>
      </ActivityIndicator>
    </View>
  );
};

const css = StyleSheet.create({
  loadingWrap: {
    width: Ratio('100%'),
    height: Ratio('100%', true),
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 100,
    height: 100,
    resizeMode: "contain",
    marginBottom: 30
  },
  indicator: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0
  }
});

export default Loading;
