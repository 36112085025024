import { Form, Formik } from 'formik';
import auth from 'models/auth.model';
import React, { useEffect } from 'react';
import { isPropertySignature } from 'typescript';
import { useSetState } from 'utils/functions.utils';
import {
  Assets,
  Functions,
  Models,
  Validation,
  Component,
  Input,
  Colors,
} from 'utils/imports.utils';
import { getUserDetails } from 'utils/redux.utils';
import './login.screen.scss';
import _ from 'lodash';

interface LoginProps {
  name?: string;
  email?: string;
  password?: string;
  id?: string;
  org?: string;
  branch?: string
}

function Login(props: any) {
  const [state, setState] = useSetState({
    activity: false,
    secure: true,
    email: "",
    password: "",
    existingUserArray: [],
  });
  useEffect(() => {
    getExistingUser()
  }, []);

  const login = async () => {

    try {
      setState({ activity: true });
      await Validation.loginSchema.validate({
        password: state.password,
        email: state.email.trim(),
      });
      const loggedIn: any = await Models.auth.userLogin({
        email: state.email,
        password: state.password,
      });

      getUserDetails(loggedIn.data);
      if (loggedIn.status == 'success') {
        const role =
          loggedIn.data.user_type.charAt(0).toUpperCase() + loggedIn.data.user_type.slice(1);
        await localStorage.setItem("token", loggedIn.token);
        if(loggedIn.data.org_details?.subscribed){
          localStorage.setItem('subscribed', loggedIn.data.org_details.subscribed.toString());
        }
        if (loggedIn?.data?.user_type) {
          {console.log('loggedIn?.data?.user_type', loggedIn?.data?.user_type)}
          await localStorage.setItem("user_type", loggedIn.data.user_type);
        }
        await localStorage.setItem("role", loggedIn.role);
        const callToken = await localStorage.getItem("callToken");
        const pushToken = await localStorage.getItem("pushToken");
        let array :any = [];
        let loggedInData = await localStorage.getItem('loggedInData');
        if (loggedInData !== null) {
          array = JSON.parse(loggedInData);
        }
        let user = {
          name: loggedIn.data.username,
          email: state.email,
          password: state.password,
          org: loggedIn?.data?.org_details?.name,
          branch: loggedIn?.data?.branch_details?.name,
          id: loggedIn.data._id,
        };

        let index = _.findIndex(array, { id : loggedIn.data._id });
        if (index !== -1) {
          array[index] = user;
        } else {
          array.push(user);
        }
        await localStorage.setItem('loggedInData', JSON.stringify(array));

        Functions.Success('Success', `${role} Login success`);
        setState({ email: '', password: '', secure: true, activity: false });
        if (!loggedIn.data.org_details?.subscribed) {
          window.location.href=('/Subscription');
        }
        if (loggedIn.data.role === 'org_admin' && loggedIn.data.org_details?.subscribed) {
        window.location.href=('/Dashboard');
        }else if(loggedIn.data.role === 'super_admin'){
          window.location.href=('/Dashboard')
        } 
        else if(loggedIn.data.user_type == 'trainer'){
        window.location.href=('/trainer_home');
        }else if(loggedIn.data.role == "admin"){
        window.location.href=('/home');
        }
        else if(loggedIn.data.user_type == 'customer'){
        window.location.href=('/customer_home');
       }
      } else {
        console.log("error")
        Functions.Failure('Failed to login');
      }
    } catch (err) {
      Functions.Failure(err);
      console.log('err', err);
      setState({ activity: false });
    }
  };

  const signIn = async (item: any) => {
    try {
      console.log("first")
      setState({ loading: true , conditionEmail: item.email});
      await Validation.loginSchema.validate({
        password: item.password,
        email: item.email,
      });
      const loggedIn: any = await Models.auth.userLogin({
        email: item.email,
        password: item.password,
      });
      getUserDetails(loggedIn.data);
      if (loggedIn.status == 'success') {
        const role =
          loggedIn.data.user_type.charAt(0).toUpperCase() + loggedIn.data.user_type.slice(1);
        await localStorage.setItem('token', loggedIn.token);
        if(loggedIn.data.org_details?.subscribed){
          localStorage.setItem('subscribed', loggedIn.data.org_details.subscribed.toString());
        }
        if (loggedIn?.data?.user_type) {
          await localStorage.setItem('user_type', loggedIn.data.user_type);
        }
        await localStorage.setItem('role', loggedIn.role);
        const callToken = await localStorage.getItem('callToken');
        const pushToken = await localStorage.getItem('pushToken');
        
        Functions.Success('Success', `${role} Login success`);
        setState({ email: '', password: '', secure: true, loading: false });
        if (!loggedIn.data.org_details?.subscribed) {
          window.location.href=('/Subscription');
        }
        if (loggedIn.data.role === 'org_admin' && loggedIn.data.org_details.subscribed) {
          window.location.href=('/Dashboard');
          }else if(loggedIn.data.role === 'super_admin'){
            window.location.href=('/Dashboard')
          } 
          else if(loggedIn.data.user_type == 'trainer'){
          window.location.href=('/trainer_home');
          }else if(loggedIn.data.role == "admin"){
          window.location.href=('/home');
          }
          else if(loggedIn.data.user_type == 'customer'){
          window.location.href=('/customer_home');
         }
      } else {
        Functions.Failure('Failed to login');
      }
    } catch (err) {
      Functions.Failure(err);
      console.log('err', err);
      setState({ activity: false });
    }
  };

  const getExistingUser = async () => {
    let loggedInData: any = await localStorage.getItem('loggedInData');
    setState({ existingUserArray: JSON.parse(loggedInData) });
  };


  const removeExistingUser = async (email: string , password: string) =>{
    let newUser:any = [ ]
    let loggedInData: any = await localStorage.getItem('loggedInData');
    JSON.parse(loggedInData).forEach( async (data:LoginProps) => {
      if(data.email !== email){
        newUser.push(data)
      }
    })
   await localStorage.setItem('loggedInData' , JSON.stringify(newUser))
    setState({existingUserArray:newUser})

  }

  return (
    <div className="login_screen">
      <div className="login_container">
        <div className="login_wrapper">
          <div className="login_logo_wrapper">
            <img src={Assets.Logo} className="login_logo" alt="login_logo" />
          </div>
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={Validation.loginSchema}
            onSubmit={(values: any, { setSubmitting }) => {
              login();
            }}>
            {({ isSubmitting }) => (
              <Form className="login_form">
                <div className="form_control">
                  <Input
                    label="Email"
                    onChange={(value) =>setState({ email: value })}
                    name="email"
                  />
                </div>
                <div className="form_control">
                  <Input
                    label="Password"
                    onChange={(value) =>setState({ password: value })}
                    name="password"
                    type={state.secure ? 'password' : 'text'}
                    onIconClick={() => setState({ secure: !state.secure })}
                    icon={state.secure ? Assets.EyeHide : Assets.Eye}
                    iconPosition="end"
                  />
                </div>
                <div className='forget_password'>
                  <div className='forget_password_button' onClick={()=>{props.history.push('/ForgetPassword')}}>
                    Forgot Password
                  </div>
                </div>
                <div className="button_control">
                  <Component.Button
                    text="Login"
                    type="submit"
                    activity={state.activity}
                    onClick={login}
                  />
                </div>
              </Form>
            )}
          </Formik>
          <div className='signin_container'>
          <div className='signin_button' onClick={()=>{props.history.push('/signin')}}>
          Create trial for your Training Company
           </div>
          </div>
        </div>
        {state.existingUserArray?.length > 0 &&
        <div className='user_container'>
        <div className='user_container_title'>Signed In Accounts</div>
          <div className='user_wrapper'>
        {state.existingUserArray?.map((item:any)=> (
          <div className='user_card_container'>
            <div className='user_card_wrapper' onClick={()=> {signIn(item)}}>
            <div className='user_initial_container' >
              <div className='user_initial'>{item.name.charAt(0)}</div>
            </div>
            <div className='user_details'>
              <div className='user_name'>{item.name}</div>
              <div className='user_email'>{item.email}</div>
              {(item.org || item.branch) && 
              <div className='user_org_branch'>
              <div className='user_org'>{item.org}</div>
              {item.branch && 
              <div className='user_branch'> - {item.branch}</div>
              }
            </div>
              }
            </div>
            </div>
            <div className='user_card_close' onClick={()=>{removeExistingUser(item.email, item.password)}}>
                <img src={Assets.circle_close} className='close_button' onClick={()=>{removeExistingUser(item.email, item.password)}}/>
            </div>
          </div>
          ))}
        </div>
      </div>
        }
      </div>
    </div>
  );
}

export default Login;
