export const TEST_ACTION = "TEST_ACTION";
export const CONVERSATION = "CONVERSATION";
export const GET_USER = "GET_USER";
export const USER_SIGNUP = 'USER_SIGNUP';
export const USER_LOGIN = 'USER_LOGIN';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_USER_LIST = 'GET_USER_LIST';
export const REMOVE_USER = 'REMOVE_USER';
export const GET_PET_LIST = "GET_PET_LIST";
export const GET_PET_DETAILS = "GET_PET_DETAILS";
export const REMOVE_PET_DETAILS = "REMOVE_PET_DETAILS";
export const REMOVE_USER_LIST = "REMOVE_USER_LIST";
export const GET_TRAINING_LIST = "GET_TRAINING_LIST";
export const GET_TRAINING_DETAILS = "GET_TRAINING_DETAILS";
export const REMOVE_TRAINING_DETAILS = "REMOVE_TRAINING_DETAILS";
export const GET_CUSTOMER_LIST = "GET_CUSTOMER_LIST";
export const GET_TRAINER_LIST = "GET_TRAINER_LIST";
export const MEDIA_LIST = "MEDIA_LIST";
export const REMOVE_MEDIA = "REMOVE_MEDIA";
export const CHAT_LIST = "CHAT_LIST";
export const MESSAGE_LIST = "MESSAGE_LIST";
export const CHAT_DETAIL = "CHAT_DETAIL";
export const CALLER = "CALLER";
export const NOTIFICATION_LIST = "MESSAGE_LIST";
export const NOTIFICATION_DETAIL = "CHAT_DETAIL";
export const ACUITY_LIST = "ACUITY_LIST";
export const ACUITY_DETAIL = "ACUITY_DETAIL";
export const ACUITY_USER = "ACUITY_USER";
export const GET_ACUITY_TRAINING_LIST = "GET_ACUITY_TRAINING_LIST";
export const CHAT_ALERT = "CHAT_ALERT";
export const CHANGE_FILTER = "CHANGE_FILTER";
export const CALL_LIST = "CALL_LIST";
export const GET_ORG_DETAILS = "GET_ORG_DETAILS";
export const GET_ORG_LIST = "GET_ORG_LIST";
export const REMOVE_ORG_DETAILS = "REMOVE_ORG_DETAILS";
export const GET_BRANCH_DETAILS = "GET_BRANCH_DETAILS";
export const GET_BRANCH_LIST = "GET_BRANCH_LIST";
export const REMOVE_BRANCH_DETAILS = "REMOVE_BRANCH_DETAILS";
export const RESET = 'RESET';
