import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Ratio, useSetState } from 'utils/functions.utils';
import {
  Assets,
  Colors,
  Constants,
  Container,
  Functions,
  Input,
  Models,
  NavButton,
  Text,
  ServiceCard,
  View,
  StyleSheet,
  TouchableOpacity,
  FlatList,
  ActivityIndicator,
} from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';

interface paginationProps {
  page: number;
  limit: number;
  search: string;
  filter?: string;
}

const Services = (props: any) => {
  // Redux
  const testState = useSelector((state: any) => state.test);
  const user = useSelector((state: any) => state.user.user);


  // State
  const [state, setState] = useSetState({
    loading: false,
    page: 1,
    limit: 20,
    searchVisible: false,
    totalDocs: 0,
    search: '',
    serviceList: [],
  });

  //Hooks
  useEffect(() => {
    getServicesList({
      page: state.page,
      limit: state.limit,
      search: state.search,
    });
  }, []);

  // Network req

  const getServicesList = async ({
    page = state.page,
    limit = state.limit,
    search,
  }: paginationProps) => {
    try {
      let req: any = { page, limit, search };
      const services: any = await Models.services.servicesList(req);
      setState({
        totalDocs: services.data.totalDocs,
        serviceList: services.data.docs,
      });
    } catch (error) {
      Functions.Failure(error)
      console.log('error', error);
    }
  };

  //Logic
  const testLogic = () => {};

  const gotoAddService = () => {
    props.navigation.navigate('AddServices', { add: true });
  };

  const getSearch = () => {
    setState({ searchVisible: !state.searchVisible });
  };

  const loader = () => {
    return (
      <View>
        {state.loadMoreLoader && (
          <ActivityIndicator
            size="small"
            color={Colors.primaryDarkColor}
            style={{ marginLeft: 6 }}
          />
        )}
      </View>
    );
  };

  const loadMore = () => {
    if (state.hasMore) {
      getServicesList({
        limit: state.limit,
        page: state.page + 1,
        search: state.search,
      });
    }
  };

  const viewDetails = (id: string) => {
    props.navigation.navigate('AddServices', {
      view: true,
      serviceId: id,
    });
  };

  return (
    <Container
      screen
      styles={styles.serviceContainer}
      loading={state.loading}
      onFloationButtonClick={gotoAddService}>
      <View style={styles.servicesWrapper}>
        <View style={styles.headerWrapper}>
          <View style={styles.headerContent}>
            {!state.searchVisible && (
              <View>
                <NavButton
                  icon={Assets.ArrowBack}
                  style={{ backgroundColor: Colors.background }}
                  svg
                  onClick={() => props.navigation.navigate('Manage')}
                  iconHeight={Ratio(25)}
                  iconWidth={Ratio(25)}
                />
              </View>
            )}
            {!state.searchVisible && (
              <View style={styles.headTextContainer}>
                <View style={{ height: '70%' }}>
                  <Text
                    size={Ratio(24)}
                    color={Colors.primaryDarkColor}
                    family={Constants.regular}>
                    Services
                  </Text>
                </View>
                <View style={{ height: '30%' }}>
                  <Text
                    size={Ratio(12)}
                    color={
                      Colors.secondaryDarkColor
                    }>{`${state.totalDocs} Services`}</Text>
                </View>
              </View>
            )}
          </View>
          {!state.searchVisible && !state.openFilter ? (
            <View style={styles.headerContent}>
              <NavButton
                icon={Assets.Plus}
                style={styles.addButton}
                onClick={() => gotoAddService()}
              />

              <View style={styles.navButtonContainer}>
                <NavButton
                  icon={Assets.Search}
                  onClick={() =>
                    setState({
                      searchVisible: !state.searchVisible,
                      search: '',
                    })
                  }
                  svg
                  iconHeight={Ratio(25)}
                  iconWidth={Ratio(25)}
                />
              </View>
            </View>
          ) : (
            <View style={styles.headerContent}>
              {state.searchVisible && (
                <View style={styles.navButtonContainerSearch}>
                  <View style={styles.searchContainer}>
                    <Input
                      onChange={(search: any) => {
                        if (!search.target) {
                          setState({ search });
                          getServicesList({ limit: state.limit, page: 1, search: search });
                        }
                      }}
                      name="search"
                      value={state.search}
                      iconPosition="start"
                      source={Assets.Search}
                      iconHeight={20}
                      iconWidth={20}
                      // inputWidth="100%"
                      styles={styles.input}
                      placeholder="Search"
                    />
                  </View>
                  <NavButton
                    icon={Assets.Close}
                    onClick={getSearch}
                    svg
                    iconHeight={Ratio(25)}
                    iconWidth={Ratio(25)}
                  />
                </View>
              )}
            </View>
          )}
        </View>
        <View>
          {state.totalDocs > 0 ? (
            <View style={styles.serviceCardContainer}>
              <FlatList
                data={state.serviceList}
                scrollEnabled={true}
                showsVerticalScrollIndicator={false}
                keyExtractor={(item: any) => item.name}
                ListFooterComponent={loader()}
                renderItem={({ item, index }) => (
                  <TouchableOpacity
                    activeOpacity={1}
                    onPress={() => {
                      viewDetails(item._id);
                    }}
                    style={[
                      styles.serviceCard,
                      {
                        marginBottom:
                          index === state.serviceList.length - 1
                            ? Ratio(150)
                            : Ratio(10),
                      },
                    ]}>
                    <ServiceCard title={item.name} />
                  </TouchableOpacity>
                )}
                onEndReached={() => loadMore()}
                onEndReachedThreshold={0.5}
              />
            </View>
          ) : (
            <View style={styles.emptyContainer}>
              <Text color={Colors.secondaryLightText} size={14}>
                No Service found
              </Text>
            </View>
          )}
        </View>
      </View>
    </Container>
  );
};

const styles = StyleSheet.create({
  serviceContainer: {
    height: '100%',
  },
  servicesWrapper: {
    width: '100%',
    height: '100%',
    paddingHorizontal: Ratio(20),
  },
  headerWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '8%',
  },
  headerContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    elevation: 10,
  },
  headTextContainer: {
    justifyContent: 'center',
    height: '100%',
    paddingLeft: Ratio(10),
  },
  navButtonContainer: {
    paddingLeft: Ratio(20),
  },
  navButtonContainerSearch: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  searchContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '80%',
  },
  input: {
    width: '100%',
  },
  emptyContainer: {
    width: '100%',
    paddingTop: Ratio(20),
    justifyContent: 'center',
    alignItems: 'center',
  },
  serviceCard: {
    width: '47%',
    marginBottom: Ratio(10),
  },
  serviceCardContainer: {
    width: '100%',
    marginTop: Ratio(20),
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'Wrap',
  },
  addButton: {
    borderRadius: '50%',
    backgroundColor: Colors.secondaryDarkColor,
    marginRight: '20px',
  },
});

export default Services;
