import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'utils/functions.utils';
import { Functions, Models, Assets, Component } from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import './payment_success.screen.scss';

interface IPaymentSuccess {
  text?: String;
}

const PaymentSuccess = (props: IPaymentSuccess) => {
  // Redux

  // State
  const [state, setState] = useSetState({});

  //Hooks
  useEffect(() => {}, []);

  // Network req
  const testReq = async () => {
    try {
      const res: any = await Models.test.testRequest('body');
      console.log(res);
      //dispatch
      testDispatch({});
    } catch (error) {
      Functions.Failure(error);
    }
  };

  //Logic
  const testLogic = () => {};

  return (
    <div className="payment_success_screen">
      <div className="payment_success_wrapper">
        <div className="success_image">
          <img src={Assets.Tick} />
        </div>
        <div className="payment_success_text1">Thank You For Your Purchase</div>
        <div className="payment_success_text2">
          You have subscribed to Basic Plan
        </div>
        <div className="button_container">
          <Component.Button text="Go to Payment" />
        </div>
        {/* <div className="receipt_download_text">Download Receipt</div> */}
      </div>
    </div>
  );
};

export default PaymentSuccess;
