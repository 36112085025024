import React from 'react';
import { View, TouchableOpacity, StyleSheet, ActivityIndicator, Ratio, Colors, Constants, Text, Image, Assets, LinearGradient } from 'utils/imports.utils';

interface PrimaryButtonProps {
  text?: string;
  color?: string;
  backgroundColor?: any;
  activity?: boolean;
  onPress?: any;
  width?: string | number;
  height?: string | number;
  style?: object;
  disabled?: boolean;
  icon?: any;
  activeOpacity?: number;
  textColor?: any;
}

const PrimaryButton = (props: PrimaryButtonProps) => {
  const {
    text,
    color,
    backgroundColor,
    activity,
    onPress,
    width,
    height,
    style,
    disabled,
    icon,
    activeOpacity,
    textColor
  } = props;
  return (
    <TouchableOpacity onPress={onPress} activeOpacity={activeOpacity || 0.7} disabled={disabled}>
      {backgroundColor ? (
        <View
          style={[
            css.container,
            style,
            {
              width: width,
              opacity: disabled ? 0.5 : 1,
              height: height,
              backgroundColor: backgroundColor,
              color: color,
            },
          ]}>
          <View style={css.icon}>
          {icon ? (
              <View style={{ marginRight: 10 }}>
                <Image src={icon} width={Ratio(20)} height={Ratio(20)}/>
              </View>
            ) : null}
            {!activity ? (
              <Text size={Ratio(16)} family={Constants.medium} color={textColor || Colors.buttonTextColor}>
                {text}
              </Text>
            ) : (
              // <ActivityIndicator size="small" color={Colors.buttonTextColor} />
          <img src={Assets.LightSpinner} alt="activity_button_spinner" style={{height: "20px" , width:"20px"}}/>

            )}
          </View>
        </View>
      ) : (
        <LinearGradient
          start={{ x: 0.2, y: 0.2 }}
          end={{ x: 1, y: 1 }}
          colors={[Colors.primaryDarkColor, Colors.primaryDarkColor]}
          style={[
            css.container,
            style,
            {
              width: width,
              opacity: disabled ? 0.5 : 1,
              height: height,
              backgroundColor: backgroundColor,
              color: color,
              icon: icon,
            },
          ]}>
          <View style={css.icon}>
            {icon ? (
              <View style={{ marginRight: 10 }}>
                <Image src={icon} width={Ratio(20)} height={Ratio(20)} />
              </View>
            ) : null}
            {!activity ? (
              <Text size={Ratio(16)} family={Constants.medium} color={textColor || Colors.buttonTextColor}>
                {text}
              </Text>
            ) : (
              <ActivityIndicator size="small" color={Colors.buttonTextColor} />
            )}
          </View>
        </LinearGradient>
      )}
    </TouchableOpacity>
  );
};

PrimaryButton.defaultProps = {
  width: '90%',
  height: Ratio(50),
};

const css = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    // height: Ratio(40),
    marginTop: Ratio(10),
    alignSelf: 'center',
    borderRadius: 20,
    cursor:"pointer",
  },
  icon: {
    justifyContent: 'center',
    alignItems: 'center',
    // width: Ratio('90%'),
    height: Ratio(50),
    flexDirection: 'row',
  },
  text: {
    fontSize: Ratio(25),
    color: Colors.buttonTextColor,
    fontWeight: '600',
    fontFamily: Constants.medium,
  },
});

export default PrimaryButton;
