import { Elements } from '@stripe/react-stripe-js';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Payment from './payment.screen';
import { loadStripe } from '@stripe/stripe-js';

const StripePayment = (props: any) => {
  // Redux
  const stripePromise = loadStripe(
    'pk_live_51MAyyFFwVX3w8EcFh7HH9wZfoZGQOaeivJub03mwHZJtUVWykC5wwNAbi3NhtjgS98IiKm6LugP2E6isrpmost3g00vkmXUnvm',
  );

  return (
    <Elements stripe={stripePromise}>
      <Payment {...props} />
    </Elements>
  );
};

export default StripePayment;
