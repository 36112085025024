import {
  testDispatch,
  signupDispatch,
  loginDispatch
} from '../utils/redux.utils';
import instance from '../utils/axios.utils';
import { getUserDetails } from 'utils/redux.utils';

const user = {
  userRequest: (id: string) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'user/public_profile_details';
      let body = {
        id: id,
      };
      instance()
        .post(url, body)
        .then(res => {
          testDispatch(res.data.data);
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },

  userSignup: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/user_signup';
      instance()
        .post(url, data)
        .then(res => {
          signupDispatch(res.data);
          // getUserDetails(res.token);
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },
  userLogin: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/user_login';
      let body = {
        email: data.email,
        password: data.password
      }
      instance()
        .post(url, body)
        .then(res => {
          loginDispatch(res.data.data);
          // getUserDetails(res.token);
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },

  sendOtp: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/send_otp';
      instance()
        .post(url, data)
        .then(res => {
          // getUserDetails(res.token);
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },

  verifyOtp: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/verify_otp';
      instance()
        .post(url, data)
        .then(res => {
          // getUserDetails(res.token);
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },

  resetPassword: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/reset_password';
      instance()
        .post(url, data)
        .then(res => {
          // getUserDetails(res.token);
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },

  changePassword: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/change_password';
      instance()
        .post(url, data)
        .then(res => {
          // getUserDetails(res.token);
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },

  getUserByToken: () => {
    let promise = new Promise((resolve, reject) => {
      let url = 'user/user_by_token';
      instance()
        .post(url)
        .then(res => {
          getUserDetails(res.data.data);
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },

  updateDevice: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/update_device_details';
      instance()
        .post(url, data)
        .then(res => {
          loginDispatch(res.data.data);
          // getUserDetails(res.token);
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },
};

export default user;
