import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  View,
  FlatList,
  ActivityIndicator,
  TouchableOpacity,
  StyleSheet,
  Text,
  Ratio,
  Colors,
  Image,
  Constants,
  Assets,
  NavButton,
  Functions,
  Conversation,
  Input,
  Models,
  Loading,
} from 'utils/imports.utils';
import { getChat, getChatAlert, getChatList } from 'utils/redux.utils';
import connectSocket from 'utils/socket.utils';
import _ from 'lodash';
let socket: any;

interface paginationProps {
  page: number;
  limit: number;
  search: string;
  loader?: boolean;
}

const Chat = (props: any) => {
  const user = useSelector((state: any) => state.user.user);
  // console.log('user', user);
  const conversations = useSelector((state: any) => state.chat.chatList);
  // console.log('conversations', conversations);
  const [state, setState] = Functions.useSetState({
    loading: true,
    page: 1,
    limit: 20,
    search: '',
    totalConversation: 0,
  });

  useEffect(() => {
    socket = connectSocket;
    // console.log('socket', socket);
    // const unsubscribe = props.navigation.addListener('focus', () => {
      connectSocketServer();
      // console.log('focusss');
      conversationList({ page: state.page, limit: state.limit, search: '' });
      getUnreadMessage();
    // });

    // receive conversation
    socket.on('receive-message', (msg: any) => {
      conversationList({ page: state.page, limit: state.limit * state.page, search: '' });
      getUnreadMessage();
    });
  }, []);

  const connectSocketServer = () => {
    socket = connectSocket;
    console.log('socket', socket);
    // global.WebSocket = socket;
    socket.emit('join-chat', { user: user._id });
    socket.on('user-connected', (data: any) => {
      console.log('user connected...');
    });
  };

  const conversationList = async ({ page, limit, search }: paginationProps) => {
    try {
      setState({ loading: true });
      console.log('page', page, 'limit', limit, 'search', search, 'user', user._id);
      socket.emit('get-conversations', { user: user._id, page: page, limit, search, user_type: user.user_type, org: user.org, branch: user.branch });
      socket.once('conversation-lists', (data: any) => {
        // console.log('data', data);
        if (page !== 1) {
          getChatList([...conversations, ...data.conv.docs]);
        } else {
          getChatList(data.conv.docs);
        }
        setState({
          page: page,
          limit,
          search,
          loading: false,
          totalConversation: data.conv.totalDocs,
          hasMore: data.conv.hasNextPage,
          loadMoreLoader: false,
        });
      });
    } catch (err) {
      console.log('err', err);
    }
  };

  const getUnreadMessage = async () => {
    try {
      const hasUnreadMessage: any = await Models.chat.hasUnreadMessage({ user: user._id });
      getChatAlert({ ...{ hasUnreadMessage: hasUnreadMessage.data } });
    } catch (err) {
      console.log('err', err);
    }
  };

  const goBack = () => {
    props.navigation.goBack();
  };

  const loadMore = () => {
    if (state.hasMore) {
      conversationList({
        page: state.page + 1,
        limit: state.limit,
        search: state.search,
      });
    }
  };

  const openMessages = (item: any) => {
    props.navigation.navigate('Message');
    getChat(item);
    setState({ searchConversation: false });
    // getChatList([]);
  };

  const loader = () => {
    return (
      <View>
        {state.loadMoreLoader && (
          <ActivityIndicator
            size="small"
            color={Colors.primaryDarkColor}
            style={{ marginLeft: 6 }}
          />
        )}
      </View>
    );
  };
  return (
    <Container screen styles={{ height: '100%' }}>
      <View style={styles.chatContainer}>
        <View style={styles.headerWrapper}>
          <View style={styles.headTextWrapper}>
            <Text size={Ratio(24)} color={Colors.primaryDarkText} family={Constants.regular}>
              Chat
            </Text>
            <View style={styles.headerButtonWrapper}>
              {/* <NavButton
                icon={Assets.PhonePrimary}
                svg
                iconHeight={Ratio(25)}
                iconWidth={Ratio(25)}
                onClick={() => props.navigation.navigate("CallLog")}
              /> */}
              <NavButton
                icon={state.searchConversation ? Assets.Close : Assets.Search}
                style={{ marginBottom: 10 }}
                svg
                iconHeight={Ratio(30)}
                iconWidth={Ratio(30)}
                onClick={() => setState({ searchConversation: !state.searchConversation })}
              />
            </View>
          </View>
          {state.searchConversation && (
            <View style={styles.searchContainer}>
              <Input
                onChange={(search: any) => {
                  if (!search.target) {
                    setState({ search });
                    conversationList({ limit: state.limit, page: 1, search: search });
                  }
                }}
                value={state.search}
                iconPosition="start"
                source={Assets.Search}
                iconHeight={20}
                iconWidth={20}
                name="search"
                // inputWidth="100%"
                styles={styles.input}
                placeholder="Search"
              />
            </View>
          )}
        </View>
        {conversations.length > 0 ? (
          <View style={styles.conversationContainer}>
            <FlatList
              data={conversations}
              style={{ flexWrap: "wrap", flexDirection: "row" }}
              showsVerticalScrollIndicator={false}
              keyExtractor={(item: any) => item._id}
              renderItem={({ item, index }) => (
                <TouchableOpacity
                  activeOpacity={0.7}
                  style={{
                    marginBottom: index === conversations.length - 1 ? Ratio(100) : Ratio(10),
                    width: "49%"
                  }}
                  onPress={() => openMessages(item)}>
                  <Conversation
                    username={
                      item.type === 'private'
                        ? item.members.find((member: any) => member?.user?._id !== user._id)?.user
                            .username
                        : item.name
                    }
                    user_type={
                      item.type === 'private'
                        ? item.members.find((member: any) => member?.user?._id !== user._id)?.user
                            .user_type
                        : 'group'
                    }
                    user_id={
                      item.type === 'private'
                        ? item.members.find((member: any) => member?.user?._id !== user._id)?.user
                            ._id
                        : 'group'
                    }
                    user={user}
                    message_type={item?.last_message ? item.last_message.type : null}
                    last_message={item?.last_message ? item?.last_message : null}
                    last_update={item?.modified_at}
                    profile={
                      item.type === 'private'
                        ? item.members.find((member: any) => member?.user?._id !== user._id)?.user
                            .profile
                          ? item.members.find((member: any) => member?.user?._id !== user._id)?.user
                              .profile
                          : null
                        : item.profile
                    }
                    unreadCount={item?.unreadCount}
                    members={item?.members}
                  />
                </TouchableOpacity>
              )}
              onEndReached={loadMore}
              scrollEnabled={true}
              onEndReachedThreshold={0.5}
              // ListFooterComponent={loader()}
            />
          </View>
        ) : (
          <View style={styles.emptyContainer}>
            {  state.loading ?
            <View style={styles.loaderContainer}>
              <Loading />
            </View> : conversations.length === 0 &&
            <Text color={Colors.secondaryLightText} size={14}>
              No conversations available
            </Text>
            }
          </View>
        )}
      </View>
    </Container>
  );
};

const styles = StyleSheet.create({
  chatContainer: {
    width: '100%',
    height: '100%',
  },
  headerWrapper: {
    padding: "20px 0px",
    paddingBottom: Ratio(10),
    // flexDirection: 'row',
    alignItems: 'center',
    // height: '10%',
  },
  headTextWrapper: {
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  conversationContainer: {
    padding: "20px 0px",
    // height: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    // backgroundColor: "red"
  },
  searchContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    width: '100%',
  },
  emptyContainer: {
    width: '100%',
    paddingTop: Ratio(20),
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerButtonWrapper:{
    // width: "27%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  loaderContainer:{
    width: "100%",
    height: "90%",
    justifyContent: "center",
    alignItems: "center"
  }
});

export default Chat;
