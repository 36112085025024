import React from 'react';
import { View, StyleSheet, Assets, Colors, Constants, Image, Ratio, Text, NavButton, TouchableOpacity } from 'utils/imports.utils';

interface userProps {
  user: any;
  username: string,
  user_type: string,
  hasChat?: boolean,
  hasCall?: boolean,
  imageWidth?: any;
  imageHeight?: any;
  imageRadius?: any;
  containerStyle?: any;
  profile?: any;
  iconHeight?: any;
  iconWidth?: any;
  imageStyle?: any;
  iconStyle?: any;
  onChatClick?: any;
  onCallClick?: any;
  callDisable?: boolean;
  onDisableClick?: any;
  onClick?: any;
}

const UserCard = (props: userProps) => {
  const {
    username,
    user_type,
    hasChat,
    hasCall,
    user,
    imageWidth,
    imageHeight,
    imageRadius,
    iconHeight,
    iconWidth,
    containerStyle,
    profile,
    iconStyle,
    imageStyle,
    onChatClick,
    onCallClick,
    callDisable,
    onDisableClick,
    onClick,
  } = props;
  return (
    <View styles={styles.userCard}>
      <View style={[styles.userCardContainer, containerStyle]}>
        <TouchableOpacity style={styles.userCardDetails} onPress={onClick}>
          {profile ?
          <View style={styles.imageWrapper}>
            <Image
              src={profile}
              url
              width={imageWidth || Ratio(65)}
              height={imageHeight || Ratio(65)}
              resize="cover"
              styles={imageStyle}
              radius={imageRadius ? imageRadius : 23}
            />
          </View>
          : <View style={styles.imageWrapper}>
            <Image
              src={Assets.SingleUserPrimary}
              style={[styles.imageDefaultWrapper, iconStyle]}
              svg
              width={iconHeight || Ratio(40)}
              height={iconWidth || Ratio(40)}
              objectFit="contain"
            />
          </View>}
          <View style={styles.nameDetails}>
            <Text styles={{ width: "100%"}} numberOfLines={1} ellipsizeMode="tail" color={Colors.primaryDarkText} size={Ratio(16)} family={Constants.regular}>{username}</Text>
            <Text styles={{ width: "100%", textTransform: 'capitalize'}} numberOfLines={1} ellipsizeMode="tail" color={Colors.primaryLightText} size={Ratio(12)} family={Constants.regular}>{user_type}</Text>
          </View>
        </TouchableOpacity>
        <View style={styles.actionContainer}>
          <View style={styles.actionBtn}>
            {hasChat && 
            <NavButton
              icon={Assets.MessageOrange}
              iconHeight={Ratio(20)}
              iconWidth={Ratio(20)}
              svg
              style={styles.buttonStyle}
              onClick={onChatClick}
            />}
          </View>
          <View style={styles.actionBtn}>
            {hasCall && 
            <NavButton
              icon={Assets.PhonePrimary}
              iconHeight={Ratio(20)}
              iconWidth={Ratio(20)}
              svg
              style={styles.buttonStyle}
              onClick={callDisable ? onDisableClick : onCallClick}
            />}
          </View>
        </View>
      </View>
    </View>
  )
}

UserCard.defaultProps ={
  hasChat: true,
  hasCall: true,
}

const styles = StyleSheet.create({
  userCard: {
    width: "100%",
  },
  userCardContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.buttonTextColor,
    borderRadius: 28,
    paddingRight: "20px",
  },
  userCardDetails: {
    width: "80%",
    flexDirection: "row",
    alignItems: "center",
    padding: "20px 0px 20px 20px",
    cursor:"pointer",
    borderRadius: 28,
    textOverflow: "ellipsis",
    overflow:"hidden",
    whiteSpace:"noWrap"
  },
  imageDefaultWrapper: {
    // padding: Ratio(10),
    backgroundColor: Colors.secondaryLightColor,
    borderRadius: 23,
    width: Ratio(65),
    height: Ratio(65)
  },
  imageWrapper: {
    padding: Ratio(10)
  },
  userProfile: {
    // backgroundColor: Colors.secondaryLightColor,
  },
  nameDetails: {
    width:"100%"
  },
  actionContainer: {
    width: "20%",
    flexDirection: "row",
    justifyContent: "flex-end",
    gap:"20px"
  },
  actionBtn: {
  },
  buttonStyle: {
    backgroundColor: Colors.secondaryLightColor
  }
})

export default UserCard;
