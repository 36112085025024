import React from 'react';
import OtpInputs from 'react-otp-input';
import { Colors, Container, Ratio, StyleSheet } from 'utils/imports.utils';

interface otpInput {
  onChange: any,
  numberOfInputs?: number,
  style?: any,
  inputStyles?: any,
  inputContainerStyles?: any,
  focusStyles?: any
}

const OtpInput = (props: otpInput) => {
  const { onChange, numberOfInputs, style, inputStyles, inputContainerStyles, focusStyles } = props;
  return (
    <Container styles={styles.otpContainer}>
      <OtpInputs
        onChange={onChange}
        numInputs={numberOfInputs || 6}
        separator={<span>-</span>}
        inputStyle={{...styles.inputStyles, ...inputStyles}}
        focusStyle={{ ...styles.focusStyles, ...focusStyles}}
        containerStyle={styles.inputContainerStyles}
      />
    </Container>
  );
}

const styles = StyleSheet.create({
  otpContainer: {
    width: "100%",
    // display: "flex",
    // flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  otpContent: {
    flexDirection: "row",
    width: "90%",
    justifyContent: 'space-between',
    alignItems: "center",
    fontSize: Ratio(16),
  },
  inputContainerStyles: {
    width: Ratio(50),
    height: Ratio(50),
    borderRadius: 16,
    marginRight: 250
  },
  inputStyles: {
    borderWidth: Ratio(1),
    borderColor: Colors.otpOutLine,
    borderRadius: 16,
    backgroundColor: Colors.background,
    color: Colors.primaryDarkText,
    width: "50px",
    height: "100%",
    textAlign: "center",
  },
  focusStyles: {
    // backgroundColor: "red"
    borderWidth: Ratio(1),
    borderRadius: 16,
    borderColor: Colors.focusOutLine,
    // backgroundColor: Colors.primaryDarkColor,
  }
})

export default OtpInput;