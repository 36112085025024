import React, { useEffect } from 'react';

import {
  View,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Assets,
  Constants,
  Image,
  NavButton,
  Ratio,
  Text,
  Colors,
  Input,
  Functions,
  PrimaryButton,
  Container,
  Models,
  FileInput,
} from 'utils/imports.utils';
import { uploadFile } from 'utils/s3.utils';
import { useSelector } from 'react-redux';

const AddOrganization = (props: any) => {
  const org = useSelector((state: any) => state.org);
// console.log('org', org);

const [state, setState] = Functions.useSetState({
  name: '',
  domain: '',
  profile: '',
  activity: false,
  isOpen: true,
  loading: true,
  edit: false,
  profileUpload: false,
  loader: false,
  });
  
  useEffect(() => {
    if (props.route.params.view) {
      getOrgDetails(props.route.params.orgId);
    } else {
      setState({ loading: false });
    }
    
  }, []);
  // console.log('req.params.org_id', props.route.params.orgId);

  const getOrgDetails = async (id: string) => {
    try {
      const org: any = await Models.org.viewOrg(id);
      // console.log(org)
      setState({
        name: org.data.name,
        domain: org.data?.domain,
        loading: false,
        profile: org.data?.profile ? org.data?.profile : '',
      });
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get org details');
      }
      setState({ loading: false });
    }
  };

  const addOrg = async () => {
    try {
      setState({ loader: true });
      let req: any = {        
        name: state.name,
        domain: state.domain,
      };
      // console.log('req', req);
      // await Validation.newUserSchema.validate(req);
      const response: any = await Models.org.createOrg(req);
      Functions.Success('Success', response.data.message)
      setState({name: '', domain: '' , loader: false })
      onClose();
    } catch (err) {
      Functions.Failure(err)
      setState({ loader: false });
      console.log("error message" , err);
    }
  };

  const saveOrgAddAdmin = async () => {
    try {
      setState({ activity: true });
      let req: any = {        
        name: state.name,
        domain: state.domain,
      };
      // await Validation.newUserSchema.validate(req);
      const response: any = await Models.org.createOrg(req);
      Functions.Success('Success', response.data.message)
      setState({name: '', domain: '' , activity: false })
      props.navigation.navigate('AddAdmin' , {orgData: {name:response.data.name , id:response.data._id} , add: true , buttonText:"Add Admin" ,type:"org_admin" , close: true} )
      // onClose();
    } catch (err) {
      Functions.Failure(err)
      setState({ activity: false });
      console.log("error message" , err);
    }
  };

  const editOrg = async () => {
    try {
      setState({ loader: true });
      let req: any = {
        name: state.name,
        domain: state.domain,
        // profile: state.profile,
      };
      // await Validation.newUserSchema.validate(req);
      req.org_id = props.route.params.orgId;
      await Models.org.editOrg(req);
      Functions.Success('Success', 'Organization updated');
      setState({ loader: false, edit: false });
      onClose();
    } catch (err) {
      Functions.Failure(err)
     console.log('err', err);
      setState({ loader: false });
    }
  };


  const backAction = () => {
    props.navigation.goBack();
    return true;
  };

  const uploadProfile = async (event) => {
    try {
      const image: any =event.target.files[0];
      setState({ profileUpload: true });
      const file: any = await uploadFile(image);
      setState({ profile: file.url, profileUpload: false });
    } catch (err) {
      console.log('err', err);
    }
  };

  const onClose = () => {
    if (state.edit) {
      setState({ edit: false });
    } else {
      props.navigation.goBack();
    }
  };

  return (
    <Container styles={styles.addOrganizationScreen} screen loading={state.loading}>
        <ScrollView
          style={styles.addOrganizationContainer}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={styles.addOrgWrapper}
          contentInset={{ bottom: 30 }}>
          <View style={styles.headerWrapper}>
            <View style={styles.textWrapper}>
              <View style={styles.imageContainer}>
                <TouchableOpacity
                  style={{
                    width: Ratio(40),
                    height: Ratio(40),
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  activeOpacity={0.7}
                  onPress={onClose}>
                  <Image svg src={Assets.ArrowBack} width={20} height={20} />
                </TouchableOpacity>
              </View>
              <View style={styles.headTextWrapper}>
                <Text
                  // top={Platform.OS === 'android' ? 5 : 0}
                  size={Ratio(24)}
                  color={Colors.primaryDarkText}
                  family={Constants.light}
                  weight="300">
                  {props.route.params.add ? "Add organization" : 'Edit organization'}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.addUserFormContainer}>
            <View style={styles.addPetIconContainer}>
              {!state.profile && !state.profileUpload ? (
                <View style={styles.profileContainer}>
                  <NavButton
                    icon={Assets.SingleUserPrimary}
                    disabled
                    style={styles.navIcon}
                    iconHeight={Ratio(50)}
                    iconWidth={Ratio(50)}
                    svg
                  />
                </View>
              ) : state.profile && !state.profileUpload ? (
                <View style={styles.profileImage}>
                  <Image
                    src={state.profile}
                    height="100%"
                    width="100%"
                    url
                    resize="cover"
                    radius={30}
                  />
                </View>
              ) : (
                <View style={styles.profileLoading}>
                  <View
                  size={30}
                  borderWidth={3}
                  indeterminate
                  color={Colors.buttonTextColor}
                />
                </View>
              )}
             
            </View>
            <View style={styles.formContainer}>
            <View style={styles.formBody}>
              <View style={styles.bodyContainer}>
                <Input
                  label="Organization Name"
                  name="organization name"
                  onChange={(name: string) => setState({ name })}
                  placeholder="Organization name"
                  value={state.name} />
                <Input
                  label="Domain"
                  name="domain"
                  onChange={(domain: string) => setState({ domain })}
                  placeholder="Domain"
                  value={state.domain}
                  />
              </View>
            </View>
            </View>
            <View style={styles.addPetButtonContainer}>
              <PrimaryButton
                text={"Save"}
                onPress={props.route.params.add ? addOrg : editOrg}
                width={'200px'}
                activeOpacity={0.7}
                backgroundColor={Colors.primaryDarkColor}
                activity={state.loader}
              />
              {props.route.params.add &&
              
              <PrimaryButton
                text={"Save & Add Admin"}
                onPress={()=> saveOrgAddAdmin()}
                width={'200px'}
                activeOpacity={0.7}
                backgroundColor={Colors.primaryDarkColor}
                activity={state.activity}
              />
              }
            </View>
          </View>
        </ScrollView>
    </Container>
  );
};

const styles = StyleSheet.create({
  addOrganizationScreen: {
    paddingTop: Ratio(20),
    height: '100%',
  },
  addOrganizationContainer: {
    padding: Ratio(20),
    width: '100%',
    height: '100%',
    // justifyContent: 'space-between',
  },
  addOrgWrapper:{
    justifyContent:"space-between",
    width: '100%',
    height:"100%"
  },
  headerWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '10%',
    justifyContent: 'space-between',
  },
  imageContainer: {},
  headTextWrapper: {
    paddingLeft: Ratio(10),
  },
  textWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  addUserFormContainer: {
    position: 'relative',
    width: '100%',
    height: '90%',
    marginTop: Ratio(60),
  },
  addPetIconContainer: {
    position: 'absolute',
    width: '100%',
    zIndex: 1,
    alignItems: 'center',
    top: -55,
  },
  navIcon: {
    width: Ratio(100),
    height: Ratio(100),
    backgroundColor: Colors.secondaryLightColor,
    borderRadius: 30,
  },
  formBody: {
    width: '50%',
    // height: Platform.OS === 'android' ? Ratio('60%', true) : Ratio('50%', true),
    backgroundColor: Colors.buttonTextColor,
    borderRadius: 32,
    shadowColor: '#DDE5EE',
    shadowOffset: { width: 4, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    padding: 20,
  },
  bodyContainer: {
    paddingTop: Ratio(50),
    paddingHorizontal: Ratio(15),
    width: '100%',
  },
  inputWrapper: {
    width: '100%',
    zIndex: 100,
    paddingBottom: Ratio(10),
  },
  addPetButtonContainer: {
    justifyContent: 'center',
    gap:"30px",
    alignItems: 'center',
    width: '100%',
    // bottom: Platform.OS === 'android' ? Ratio(-10, true) : Ratio(-10, true),
    display: 'flex',
    flexDirection: 'row',
    paddingTop:"30px"
  },
  btnContent: {
    width: '80%',
  },
  profileContainer: {},
  profileImage: {
    width: Ratio(100),
    height: Ratio(100),
  },
  uploadIcon: {
    backgroundColor: Colors.primaryDarkColor,
    width: Ratio(30),
    height: Ratio(30),
    borderRadius: Ratio(20),
    justifyContent: 'center',
    alignItems: 'center',
    bottom: Ratio(17),
    marginTop:"-20px"
  },
  profileLoading: {
    width: Ratio(100),
    height: Ratio(100),
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.primaryDarkColor,
    opacity: 0.4,
  },
  formContainer:{
    width:"100%",
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
  }
});

export default AddOrganization;
