import React from 'react';
import './nav_button.ui.scss';

interface INavButton {
  icon?: any;
  background?: string;
  onClick?: any;
  borderRadius?: any;
  disabled?: boolean;
  onDisabledClick?: any;
  width?: any;
  height?: any;
  iconWidth?: any;
  iconHeight?: any;
  style?: any;
  svg?: boolean;
  badge?: boolean;
  badgeCount?: number;
  disableShadow?: boolean;
}

const NavButton = (props: INavButton) => {
  const {
    icon,
    background,
    onClick,
    borderRadius,
    disabled,
    onDisabledClick,
    width,
    height,
    iconHeight,
    iconWidth,
    style,
    disableShadow
  } = props;
  return (
    <div
      className="nav_btn_container"
      onClick={onClick}
      style={{...{
        boxShadow: disableShadow ? "0px" : "",
        width: width || '40px',
        height: height || '40px',
        backgroundColor: background || 'white',
        borderRadius: borderRadius || '10px',
        pointerEvents: (disabled && !onDisabledClick) ? "none" : "auto"
      }, ...style}}>
      <div className="nav_btn_wrapper">
        <img
          src={icon}
          style={{
            width: iconWidth || "20px",
            height: iconHeight || "20px"
          }}
          className="nav_btn_icon"
          alt="nav_btn_icon"
        />
      </div>
    </div>
  );
};

export default NavButton;
