import { USER_SIGNUP, USER_LOGIN, GET_USER_DETAILS } from 'utils/types.utils';
import { storeAction } from 'interfaces/common.interface';

const initialState = {};

const AuthReducer = (state = initialState, action: storeAction) => {
  switch (action.type) {
    case USER_SIGNUP:
      let newState = { ...state };
      newState = { ...newState, ...action.payload };
      return newState;
    case USER_LOGIN:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default AuthReducer;
