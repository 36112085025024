import React from 'react';
import { Colors, View } from 'utils/imports.utils';

interface infoDetails {
  icon?: any,
  text?: string,
  style?: any,
  containerStyle?: any,
  textColor?: any,
  textStyle?: any;
}

const InfoDetail = (props: infoDetails) => {
  const { icon, text, style, containerStyle, textColor, textStyle } = props;
  return (
    <View style={{ ...styles.infoDetailContainer, ...containerStyle } }>
      <View style={{ ...styles.infoDetail, ...style}}>
          {icon ?
          <View style={styles.imageContainer}>
              <img src={icon} style={{ objectFit: "cover"}} width={12} height={12} />
          </View>
          : null}
        <View>
          <View style={{...styles.text, ...textStyle}} color={ textColor ? textColor : Colors.primaryDarkColor }>{text}</View>
        </View>
      </View>
    </View>
  )
}

const styles = {
  infoDetailContainer: {
    width: "100%",
  },
  infoDetail: {
    backgroundColor: Colors.secondaryLightColor,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius:10,
    paddingTop: 2,
    paddingBottom: 2,
  },
  imageContainer: {
    paddingLeft: 10,
    justifyContent: "center",
    alignItems: "center"
  },
  text: {
    paddingLeft: 8,
    paddingRight: 10,
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: 10,
    fontWeight: 400,
    display: "flex"
  }
}

export default InfoDetail;