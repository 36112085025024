import React from 'react';
import "./home_card.scss";

interface homeCardProps {
  icon: any,
  title: string,
  count: string,
  onPress?: any
}

const HomeCard = (props: homeCardProps) => {
  const { icon, title, count, onPress } = props;
  return (
    <div onClick={onPress} className="home_card_container">
      <div className="home_card_icon_wrap">
        <img src={icon} className="home_card_icon"/>
      </div>
      <div className="home_card_title">{title}</div>
      <div className="home_card_count">{count}</div>
    </div>
  )
}


export default HomeCard;