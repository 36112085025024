import axios from "axios";
import { Functions } from "./imports.utils";

export const instance = () => {
  const data = axios.create({
    baseURL: Functions.getBaseURL()+"/api/v1/",
  })
  data.interceptors.request.use(async function (config) {
      const accessToken = await localStorage.getItem("token");
      config.headers['authorization'] = accessToken ? accessToken : '';
      return config;
  });
  return data
}

export default instance