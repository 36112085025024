import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Alert,
  Text,
  Container,
  Assets,
  Functions,
  NavButton,
  Colors,
  Constants,
  Ratio,
  Input,
  FilterModal,
  UserCard,
  Models,
  ActivityIndicator,
  FlatList,
  LinearGradient,
} from 'utils/imports.utils';
// import LinearGradient from 'react-native-linear-gradient';
import _ from 'lodash';
import { getBranchList } from 'utils/redux.utils';
interface paginationProps {
  page?: number;
  limit?: number;
  filter?: string;
  search?: string;
}

const Branch = (props: any) => {
  const branch = useSelector((state: any) => state.branch.branchList);
  const filter = useSelector((state: any) => {});
  const user = useSelector((state: any) => {});

  const [state, setState] = Functions.useSetState({
    searchVisible: false,
    page: 1,
    limit: 100,
    search: '',
    loading: false,
    filter: filter,
  });

  useEffect(() => {
      getBranch({
        page: state.page,
        limit: state.limit,
        filter: state.filter
    
      // props.navigation.addListener('beforeRemove', backAction);
    });

  }, []);

  useEffect(() => {
    getBranch({
      page: state.page,
      limit: state.limit,
      filter: state.filter,
      search: state.search
    });
  }, [state.search]);

  const backAction = () => {
    // getTrainingList([]);
    props.navigation.goBack();
    // props.navigation.navigate('Manage');
    return true;
  };

  const goBack = () => {
    // if (user && user.user_type === 'trainer') {
    // props.navigation.goBack();
    // } else {
      props.navigation.navigate('Manage');
    // }
  };

  const getBranch = async ({ page = state.page, limit = state.limit, filter = state.filter, search }: paginationProps) => {
    try {
      setState({ loadMoreLoader: true });
      let req: any = { page, limit, search };
      if (filter) {
        req.status = filter;
      }
      const branch: any = await Models.branch.branchList(req);
      console.log(branch)
      if (page !== 1) {
        getBranchList([...branch, ...branch.data.docs]);
      } else {
        getBranchList(branch.data.docs);
      }
      setState({
        loading: false,
        hasMore: branch.data.hasNextPage,
        page,
        limit,
        loadMoreLoader: false,
        filter,
      });
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get branches');
      }
      setState({ loading: false });
    }
  };

  const getSearch = () => {
    setState({ searchVisible: !state.searchVisible });
    // getTrainings({ limit: state.limit, page: 1, search: '', filter: state.filter });
  };

  const loadMore = () => {
    if (state.hasMore) {
      //   getTrainings({ limit: state.limit, page: state.page + 1, search: state.search });
    }
  };

  const closeAction = () => {
    setState({ openFilter: !state.openFilter, filter: '' });
  };

  const closeSearch = () => {
    setState({ searchVisible: !state.searchVisible, search: '' });
    console.log('search,', state.filter);
  };

  const gotoAddBranch = () => {
    props.navigation.navigate('AddBranch', { add: true });
  };

  const viewDetails = (item: any) => {
    props.navigation.navigate('AddBranch', {
      view: true,
      buttonText: 'Details',
      branchId: item._id,
      orgId: item.org,
    });
  };


  const filterChange = (value: string) => {
    setState({ openFilter: !state.openFilter });
    // getTrainings({ limit: state.limit, page: 1, search: '', filter: value });
  };

  const loader = () => {
    return (
      <View>
        {state.loadMoreLoader && (
          <ActivityIndicator
            size="small"
            color={Colors.primaryDarkColor}
            style={{ marginLeft: 6 }}
          />
        )}
      </View>
    );
  };



  return (
    <Container
      screen
      styles={styles.branchsContainer}
      loading={state.loading}>
      <View style={styles.branchsWrapper}>
        <View style={styles.headerWrapper}>
          <View style={styles.headerContent}>
            {!state.searchVisible && !state.openFilter && (
              <View>
                <NavButton
                  icon={Assets.ArrowBack}
                  style={{ backgroundColor: Colors.background }}
                  svg
                  onClick={() => goBack()}
                  iconHeight={Ratio(25)}
                  iconWidth={Ratio(25)}
                />
              </View>
            )}
            {!state.searchVisible && !state.openFilter && (
              <View style={styles.headTextContainer}>
                <View style={{ height: '70%' }}>
                  <Text
                    size={Ratio(24)}
                    color={Colors.primaryDarkColor}
                    family={Constants.regular}>
                    Branch
                  </Text>
                </View>
                <View style={{ height: '30%' }}>
                  <Text
                    size={Ratio(12)}
                    color={
                      Colors.secondaryDarkColor
                    }>{`${branch.length} Branch`}</Text>
                </View>
              </View>
            )}
          </View>
          {!state.searchVisible && !state.openFilter ? (
            <View style={styles.headerContent}>
              <NavButton
                icon={Assets.Plus}
                style={styles.addButton}
                onClick={() => gotoAddBranch()}
              />

              <View style={styles.navButtonContainer}>
                <NavButton
                  icon={Assets.Search}
                  onClick={closeSearch}
                  svg
                  iconHeight={Ratio(25)}
                  iconWidth={Ratio(25)}
                />
              </View>
              {/* <View style={styles.navButtonContainer}>
                <NavButton
                  icon={Assets.TuneBlue}
                  onClick={() => setState({ openFilter: !state.openFilter })}
                  svg
                  iconHeight={Ratio(25)}
                  iconWidth={Ratio(25)}
                />
              </View> */}
            </View>
          ) : (
            <View style={styles.headerContent}>
              {state.searchVisible ? (
                <View style={styles.navButtonContainerSearch}>
                  <View style={styles.searchContainer}>
                    <Input
                      onChange={(search: any) => {
                        if (!search.target) {
                          setState({ search });
                          getBranch({
                            limit: state.limit,
                            page: 1,
                            search: search,
                          });
                        }
                      }}
                      value={state.search}
                      iconPosition="start"
                      source={Assets.Search}
                      iconHeight={20}
                      iconWidth={20}
                      // inputWidth="100%"
                      styles={styles.input}
                      placeholder="Search"
                      name="search"
                    />
                  </View>
                  <NavButton
                    icon={Assets.Close}
                    onClick={getSearch}
                    svg
                    iconHeight={Ratio(25)}
                    iconWidth={Ratio(25)}
                  />
                </View>
              ) : (
                <View style={styles.navButtonContainer}>
                  <NavButton
                    icon={Assets.Close}
                    onClick={closeAction}
                    svg
                    iconHeight={Ratio(25)}
                    iconWidth={Ratio(25)}
                  />
                </View>
              )}
            </View>
          )}
        </View>
        {branch.length > 0 ? (
          <View style={styles.branchListWrapper}>
            <FlatList
              data={branch}
              scrollEnabled={true}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              keyExtractor={(item: any) => item.name}
              renderItem={({ item, index }) => (
                <View
                  activeOpacity={0.7}
                  style={[
                    styles.petDetail,
                    {
                      paddingBottom: index === 10 - 1 ? Ratio(150) : Ratio(10),
                    },
                  ]}
                  >
                  <UserCard
                    user={item._id}
                    username={item.name}
                    user_type={'branch'}
                    hasCall={false}
                    hasChat={false}
                    onClick={()=> viewDetails(item)}
                  />
                </View>
              )}
              onEndReached={loadMore}
              onEndReachedThreshold={0.5}
              ListFooterComponent={loader()}
              hasMoreItems={state.hasMore}
            />
          </View>
        ) : (
          <View style={styles.emptyContainer}>
            <Text color={Colors.secondaryLightText} size={14}>
             No Branches found
            </Text>
          </View>
        )}
      </View>
      {!state.openFilter && (
        <LinearGradient
          colors={[
            'rgba(248, 249, 251, 0)',
            'rgba(248, 249, 251, 0.8)',
            'rgba(248, 249, 251, 1)',
          ]}
          style={styles.overlay}
        />
      )}
      {state.openFilter && (
        <View style={styles.filterContainer}>
          <FilterModal
            onFilterClick={(value: string, head: string) => filterChange(value)}
          />
        </View>
      )}
    </Container>
  );
};

const styles = StyleSheet.create({
  branchsContainer: {
    height: '100%',
  },
  branchsWrapper: {
    width: '100%',
    height: '100%',
    paddingHorizontal: Ratio(20),
  },
  headerWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '8%',
  },
  headerContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    elevation: 10,
  },
  headTextContainer: {
    justifyContent: 'center',
    height: '100%',
    paddingLeft: Ratio(10),
  },
  navButtonContainer: {
    paddingLeft: Ratio(20),
  },
  navButtonContainerSearch: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    // paddingLeft: Ratio(20),
  },
  searchContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '80%',
  },
  input: {
    width: '100%',
  },
  branchListWrapper: {
    marginTop: Ratio(10),
    display:"flex",
    flexDirection:"row",
    width:"100%",
    justifyContent:"space-between",
    flexWrap:"wrap"
  },
  petDetail: {
    width:"49%",
    marginBottom: Ratio(10),
  },
  emptyContainer: {
    width: '100%',
    paddingTop: Ratio(20),
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: Ratio(150),
    width: '100%',
  },
  filterContainer: {
    position: 'absolute',
    bottom: 0,
    top: Ratio(100),
    left: 0,
    right: 0,
    width: '100%',
  },
  addButton:{
    borderRadius:"50%",
    backgroundColor: Colors.secondaryDarkColor,
    marginRight:"20px",
  },
});

export default Branch;
