import React from 'react';
// import { ErrorMessage, Field } from 'formik';
import './input.ui.scss';
import { Colors, Functions, ValidationError } from 'utils/imports.utils';

// iconPosition "icon_end" "icon_start"

interface IInput {
  placeholder?: string;
  onChange: any;
  label?: any;
  inputClass?: string;
  icon?: string;
  source?: string;
  iconPosition?: string;
  value?: string;
  type?: string;
  disabled?: boolean;
  name: string;
  onIconClick?: any;
  iconHeight?: string | number;
  iconWidth?: string | number;
  styles?: any;
}

const Input = (props: IInput) => {
  const [state, setState] = Functions.useSetState({
    focus: false
  })
  const {
    placeholder,
    onChange,
    label,
    inputClass,
    value,
    type,
    iconPosition,
    disabled,
    name,
    onIconClick,
    source,
    iconHeight,
    iconWidth,
    styles,
  } = props;

  const icon = props.icon || props.source;

  return (
    <div className="input_component">
      <div className="input_container">
        {label && (
          <div className="input_label_container">
            <div className={`head5 input_label ${state.focus && "input_label_focus"}`}>{label}</div>
          </div>
        )}
        <div className="input_wrapper">
          <div className="input">
            {(icon) && iconPosition === "start" && (
              <div className="icon_container_start" onClick={onIconClick && onIconClick}>
                <img src={icon} alt="input_icon" height={iconHeight} width={iconWidth}className="input_icon" />
              </div>
            )}
            <input
              name={name}
              onChange={(event) => onChange(event.target.value)}
              type={type || "text"}
              className={`field__input p4 ${disabled && 'input_disabled'} `}
              autoComplete="off"
              disabled={disabled}
              onFocus={() => setState({ focus: !state.focus })}
              onBlur={() => setState({ focus: !state.focus })}
              value={value}
              style={{...{
                backgroundColor: Colors.background,
                paddingLeft: icon && iconPosition === "start" ? "55px" : "1em",
                paddingRight: icon && iconPosition === "end" ? "55px" : "1em"
              }, ...styles}}
              placeholder={placeholder}
            />
            {icon && iconPosition === "end" && (
              <div className="icon_container_end" onClick={onIconClick && onIconClick}>
                <img src={icon} alt="input_icon" className="input_icon" />
              </div>
            )}
          </div>
          {/* <ErrorMessage name={name} component={ValidationError} /> */}
        </div>
      </div>
    </div>
  );
};

export default Input;
