import Button from 'common_components/ui/button/button.ui';
import NavButton from 'common_components/ui/nav_button/nav_button.ui';
import Image from 'common_components/ui/image/image.ui';
import Input from 'common_components/ui/input/input.ui';

const Components = {
  Button,
  NavButton,
  Image,
  Input
}

export default Components;