import * as yup from 'yup';

// patterns
const NumberPattern = /^\d{10}$/;
const PassWordPattern = /^(?=.*[0-9])(?=.*[!@#$%^&*?])[a-zA-Z0-9!@#$%^&*?]{8,30}$/;
const letters = /^[A-Za-z _]*[A-Za-z][A-Za-z _]{2,}$/;
const RegExNum = /^[0-9]*$/;
const phonePattern = /^[1-9]{1}[0-9]{9}$/;
const Mail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;

export const loginSchema = yup.object().shape({
  password: yup.string().required("Password is required"),
  email: yup.string().required("Email is required").matches(Mail, "Enter valid email"),
});

export const signUpSchema = yup.object().shape({
  password: yup.string().required("Password is required").matches(PassWordPattern, "Password should contain 8 to 30 charecters, atleast one number and one special character"),
  email: yup.string().required("Email is required").matches(Mail, "Enter valid email"),
  username: yup.string().required("Name is required"),
});

export const forgetPassSchema = yup.object().shape({
  email: yup.string().required("Email is required").matches(Mail, "Enter valid email"),
})

export const otpSchema = yup.object().shape({
  otp: yup.string().required("otp is required").min(6, 'OTP must be exactly 6 digits').max(6, 'OTP must be exactly 6 digits')
})

export const resetSchema = yup.object().shape({
  password: yup.string().required("Password is required").matches(PassWordPattern, "Password should contain 8 charecters, atleast one number and one special character"),
  confirmPassword: yup.string().required("Confirm password is required").oneOf([yup.ref('password'), null], 'Password and Confirm password must match')
})

export const changePasswordConfirmSchema = yup.object().shape({
  password: yup.string().required("New Password is required").matches(PassWordPattern, "New Password should contain 8 charecters, atleast one number and one special character"),
  confirmPassword: yup.string().required("Confirm password is required").oneOf([yup.ref('password'), null], 'New Password and Confirm password must match')
})

export const changePasswordSchema = yup.object().shape({
  password: yup.string().required("New Password is required").matches(PassWordPattern, "New Password should contain 8 charecters, atleast one number and one special character"),
  currentPassword: yup.string().required("Current Password is required"),
})

export const newUserSchema = yup.object().shape({
  phone: yup.string().required("Phone is required").matches(phonePattern, "Enter Valid phone"),
  email: yup.string().required("Email is required").matches(Mail, "Enter valid email"),
  username: yup.string().required("Name is required"),
})

export const newOrgAdminSchema = yup.object().shape({
  phone: yup.string().required("Phone is required").matches(phonePattern, "Enter Valid phone"),
  email: yup.string().required("Email is required").matches(Mail, "Enter valid email"),
  username: yup.string().required("Name is required"),
  org: yup.string().required("Org is required"),
})

export const newBranchAdminSchema = yup.object().shape({
  phone: yup.string().required("Phone is required").matches(phonePattern, "Enter Valid phone"),
  email: yup.string().required("Email is required").matches(Mail, "Enter valid email"),
  username: yup.string().required("Name is required"),
  org: yup.string().required("Org is required"),
  branch: yup.string().required("Branch is required"),
})

export const editUserSchema = yup.object().shape({
  profile: yup.string().optional(),
  username: yup.string().required("Name is required"),
})

export const addPet = yup.object().shape({
  customer: yup.string().required("Owner is required"),
  trainer: yup.string().optional(),
  age: yup.string().required("Age is required"),
  gender: yup.string().required("Gender is required"),
  category: yup.string().required("Breed is required"),
  name: yup.string().required("Name is required"),
})

export const addTraining = yup.object().shape({
  start_date: yup.string().optional(),
  end_date: yup.string().optional(),
  service: yup.string().required("Service is required"),
  trainer: yup.string().optional(),
  pet: yup.string().required("Dog is required"),
})

export const editTraining = yup.object().shape({
  start_date: yup.string().optional(),
  end_date: yup.string().optional(),
  service: yup.string().optional(),
  trainer: yup.string().optional(),
  pet: yup.string().optional(),
  status: yup.string().optional()
})

export const trialSubscription = yup.object().shape({
  username: yup.string().required("Username is Required"),
  email: yup.string().required("Email is Required"),
  org_name: yup.string().required("Org Name is Required"),
  password: yup.string().required("Password is Required"),
});