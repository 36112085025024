import React from 'react';
import { transform } from 'typescript';
import { Text, Colors, Container,  StyleSheet, View 
, Ratio, Constants, Assets, Image } from 'utils/imports.utils';

interface manageCardProps {
  icon: string,
  title: string,
  description: string;
  iconWidth?: any;
  iconHeight?: any;
}

const ManageCard = (props: manageCardProps) => {
  const { icon, title, description, iconWidth, iconHeight } = props;
  return (
    <Container style={styles.manageCardContainer}>
      <View style={styles.cardContainer}>
        <View style={styles.cardContents}>
          <View style={styles.imageContainer}>
            <Image src={icon} width={iconWidth || Ratio(25)} height={iconHeight || Ratio(25)} svg objectFit='contain'/>
          </View>
          <View style={styles.textContainer}>
            <Text size={Ratio(24)} family={Constants.bold} weight="700" color={Colors.primaryDarkColor} styles={styles.textStyle}>{title}</Text>
            <Text size={Ratio(12)} family={Constants.regular} weight="400" color={Colors.primaryLightText}>{description}</Text>
          </View>
        </View>
        <View style={styles.arrowContainer}>
          <Image src={Assets.ArrowBack} styles={{ transform: [{ rotate: "180deg"}]}} width={Ratio(25)} height={Ratio(25)} svg />
        </View>
      </View>
    </Container>
  )
}

const styles = StyleSheet.create({
  cardContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: Colors.buttonTextColor,
    borderRadius: Ratio(30),
    paddingTop: Ratio(45),
    paddingBottom: Ratio(45),
    paddingLeft: Ratio(25),
    paddingRight: Ratio(25),
    marginTop: Ratio(0),
  },
  cardContents: {
    flexDirection: "row",
    alignItems: "center",
  },
  imageContainer: {
    width: Ratio(60),
    height: Ratio(60),
    backgroundColor: Colors.secondaryLightColor,
    borderRadius: Ratio(12),
    justifyContent: "center",
    alignItems: "center"
  },
  textContainer: {
    paddingLeft: Ratio(16)
  },
  textStyle: {
    paddingBottom: Ratio(5)
  },
  arrowContainer: {
    transform: "rotateY(180deg)"
  },
  manageCardContainer:{
    marginTop:"0px",
  }
})

export default ManageCard;