import { combineReducers} from 'redux';
import testReducer from 'store/reducers/test.reducer';
import AuthReducer from './auth.reducer';
import UserReducer from './user.reducer';
import PetReducer from './pet.reducer';
import TrainingReducer from './training.reducer';
import MediaReducer from './media.reducer';
import ChatReducer from './chat.reducer';
import NotificationReducer from './notification.reducer';
import AcuityReducer from './acuity.reducer';
import CallReducer from './call.reducers';
import OrgReducer from './org.reducer';
import BranchReducer from './branch.reducer';

const combinedReducer =  combineReducers({
  test: testReducer,
  auth: AuthReducer,
  user: UserReducer,
  pet: PetReducer,
  training: TrainingReducer,
  media: MediaReducer,
  chat: ChatReducer,
  notification: NotificationReducer,
  acuity: AcuityReducer,
  call: CallReducer,
  org: OrgReducer,
  branch: BranchReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type == 'RESET') {
    return combinedReducer(action.type == 'RESET' ? undefined : state, action);
  } else {
    return combinedReducer(state, action);
  }
};

export default rootReducer;