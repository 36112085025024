import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  getTrainingList,
  getUserDetails,
  getPetDetails,
  getTrainingDetails,
  getCaller,
  getChat,
} from 'utils/redux.utils';
import {
  Container,
  Colors,
  Assets,
  Ratio,
  Text,
  Constants,
  Image,
  NavButton,
  HomeCard,
  CustomerPetCard,
  Functions,
  Models,
  StyleSheet,
  View,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
  TaskCard,
  ScrollView,
  PrimaryButton,
} from 'utils/imports.utils';
import _ from 'lodash';
import connectSocket from 'utils/socket.utils';
import { Modal } from 'react-responsive-modal';

let socket: any;

interface pagination {
  limit: number;
  page: number;
  search: string;
  user_type?: string;
  user_id?: string;
}

const Home = (props: any) => {
  const user = useSelector((state: any) => state.user.user);
  const pets = useSelector((state: any) => state.training.trainingList);

  const [state, setState] = Functions.useSetState({
    loading: true,
    page: 1,
    limit: 20,
    search: '',
    hasMore: false,
    loadMoreLoader: false,
    notify: 0,
    openModal: false,
    incomplete: 0
  });

  useEffect(() => {
    connectSocketServer();
      getNotify(user._id);
      trainingList({
        limit: state.limit,
        page: state.page,
        search: state.search,
        user_id: user._id,
      });
      incompleteTask();
  }, []);

  useEffect(()=>{
    getUser(user._id)
    incompleteTask();
  },[user.check_training_status])

  const connectSocketServer = () => {
    socket = connectSocket;
    socket.emit('join-chat', { user: user._id });
    socket.on('user-connected', (data: any) => {
      console.log('user connected...');
    });
  };

  const backAction = () => {
    getTrainingList([]);
    return true;
  };

  const getUser = async (id:any) => {
    try {
      const user: any = await Models.user.viewUser(id);
      getUserDetails(user.data)
      setState({user: user.data})
      // console.log('user', user);
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get user details');
      }
      setState({ loading: false });
    }
  };

  const trainingList = async ({ limit, page, search, user_id }: pagination) => {
    try {
      setState({ loadMoreLoader: true });
      let req: any = {
        limit,
        page,
        search,
        customer: user_id ? user_id : user._id,
      };
      const petDate: any = await Models.training.trainingList(req);
      if (page !== 1) {
        getTrainingList([...pets, ...petDate.data.docs]);
      } else {
        getTrainingList(petDate.data.docs);
      }
      setState({
        loading: false,
        hasMore: petDate.data.hasNextPage,
        page,
        limit,
        loadMoreLoader: false,
      });
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get pet list');
      }
      setState({ loading: false });
    }
  };

  const getNotify = async (user: string) => {
    try {
      const notification: any = await Models.notification.hasNotification({ user_id: user });
      setState({ notify: notification.data.count });
    } catch (err) {
      console.log(err);
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user_type');
    window.location.href = "/login";
  };

  const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }: any) => {
    const paddingToBottom = 20;
    return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingToBottom;
  };

  const loadMore = () => {
    if (state.hasMore) {
      trainingList({ limit: state.limit, page: state.page + 1, search: state.search });
    }
  };

  const loader = () => {
    return (
      <View>
        {state.loadMoreLoader && (
          <ActivityIndicator
            size="small"
            color={Colors.primaryDarkColor}
            style={{ marginLeft: 6 }}
          />
        )}
      </View>
    );
  };

  const gotoProfile = (item: any) => {
    user.check_training_status = true
    getTrainingDetails({ ...item, ...{ customerView: true } });
    props.navigation.navigate('DogProfile', { id: item.pet._id });
  };

  const gotoNotification = () => {
    props.navigation.navigate('Notification');
  };

  const onCallClick = async (id: string) => {
    const callerDetails: any = await Models.user.viewUser(id);
    getCaller(callerDetails.data);
    props.navigation.navigate('Call');
  };

  const onChatClick = async (receiver: string) => {
    try {
      let body = {
        from: user._id,
        to: receiver,
        type: 'private',
      };
      const conversation: any = await Models.chat.startConversation(body);
      // console.log('conversation', conversation);
      getChat(conversation.data);
      // socket = connectSocket;
      // console.log('socket', socket);
      // global.WebSocket = socket;

      socket.emit('join-chat', { user: user._id });
      socket.on('user-connected', (data: any) => {
        console.log('user connected...');
      });
      props.navigation.navigate('Message');
    } catch (err) {
      console.log('err');
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get conversation');
      }
    }
  };

  const showMessage = (name: string) => {
    Functions.Info('Sorry', `${name} is not available to take calls right now.`);
  }

  const returnDate = (item: any, type?: any) => {
    if (item.training_type == 'range') {
      if (type == 'start_date') {
        return item.start_date;
      } else {
        return item.end_date;
      }
    } else if (item.training_type == 'multiple days') {
      if (type == 'start_date') {
        return item.dates[0];
      } else {
        return item.dates[item.dates.length - 1];
      }
    } else if (item.training_type == 'single day') {
      return item.date;
    } else {
      if (item.start_date && type == 'start_date') {
        return item.start_date;
      } else {
        return item.end_date;
      }
    }
  };

  const taskData = [
    {
      title: 'Check Training Status',
      text: 'Congratulation, now you can view the status of Training, Click below to view.',
      onClick: () => {
        gotoProfile(pets[0])
        setState({openModal: false});

      },
      completed: user.check_training_status,
    }
  ];

  const getTaskData = () =>{
    return taskData
  }

  const incompleteTask = () =>{
    let incompleteArray: any = []
    getTaskData()?.map((item)=>{
      if(!item.completed){
        incompleteArray.push(item)
      }
    })
    setState({incomplete: incompleteArray.length })
  }

  return (
    <Container styles={styles.homeScreen} screen loading={state.loading}>
      {/* <ScrollView
        showsVerticalScrollIndicator={false}
        onMomentumScrollEnd={({ nativeEvent }) => {
          if (isCloseToBottom(nativeEvent)) {
            state.hasMore ? trainingList({ limit: state.limit, page: state.page + 1, search: state.search }) : null;
          }
        }}
        scrollEventThrottle={50}
      > */}
      <View style={styles.headerContainer}>
        <View style={styles.headerWrapper}>
          <View>
            <Text
            styles={{textTransform:"capitalize"}}
              weight="500"
              size={Ratio(18)}
              family={Constants.bold}
              color={Colors.primaryDarkColor}>
              {user.username}
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.sessonWrapper}>
        <View>
        <Text size={Ratio(24)} color={Colors.primaryDarkColor} family={Constants.regular}>
            My Dogs
          </Text>
        </View>
      </View>
      {pets.length > 0 ? (
        <View style={styles.petList}>
          <FlatList
            data={pets}
            scrollEnabled={true}
            showsVerticalScrollIndicator={false}
            keyExtractor={(item: any) => item.name}
            ListFooterComponent={loader()}
            renderItem={({ item, index }) => (
              <TouchableOpacity
                activeOpacity={0.9}
                style={[
                  styles.petDetail,
                  index === pets.length - 1 && { paddingBottom: Ratio(100) },
                ]}
                onPress={() => gotoProfile(item)}>
                <CustomerPetCard
                  name={item.pet.name}
                  category={item.pet.category}
                  gender={item.pet.gender}
                  age={item.pet.age}
                  single_date={item.date}
                  age_type={item.pet.age_type}
                  start_date={returnDate(item , "start_date")}
                  end_date={returnDate(item, "end_date")}
                  username={item.trainer?.username}
                  user_type={item.trainer?.user_type}
                  service={_.find(Constants.services, { value: item.service })?.label}
                  status={_.find(Constants.status, { value: item.status })?.title}
                  profile={item.pet.profile}
                  statusColor={_.find(Constants.status, { value: item.status })?.color}
                  statusTextColor={_.find(Constants.status, { value: item.status })?.textColor}
                  user_profile={item.trainer?.profile}
                  onCallClick={() => onCallClick(item.trainer?._id)}
                  onChatClick={() => onChatClick(item.trainer?._id)}
                  callDisable={
                    !(item.trainer?.call_limited
                      ? Functions.isCallAvailable(item.trainer?.limited_hours)
                      : true || !item.trainer?.call_allowed)
                  }
                  onDisableClick={() => showMessage(item.trainer?.username)}
                  type={item.training_type}
                />
              </TouchableOpacity>
            )}
            onEndReached={() => loadMore()}
            onEndReachedThreshold={0.5}
          />
        </View>
      ) : (
        <View style={styles.emptyContainer}>
          <Text color={Colors.secondaryLightText} size={14}>
            No dogs available
          </Text>
        </View>
      )}
      {/* </ScrollView> */}
      {state.incomplete > 0 && 
        <TouchableOpacity style={styles.taskButtonContainer}
        onPress={()=>setState({openModal:true})}
        >
        <PrimaryButton
          text={"Quick Start"}
          icon={Assets.task}
          width={'140px'}
          style={{marginTop: '0px'}}
          onPress={() => {
            setState({ openModal: true });
          }}
        />
        <View style={styles.countContainer}>
        <Text
            size={Ratio(16)}
            weight={'600'}
            color={Colors.buttonTextColor}
            >
          {state.incomplete}
        </Text>
        </View>
      </TouchableOpacity>
      }
      <Modal
        open={state.openModal}
        onClose={() => {}}
        onOverlayClick={() => setState({ openModal: false })}
        styles={styles.modal}
        showCloseIcon={false}
        closeOnOverlayClick
        classNames={{
          overlay: 'customOverlay',
          modal: 'customModal',
        }}>
        <View style={styles.bottomSheetWrapper}>
          <View style={styles.bottomSheetHeader}>
            <View style={styles.headerClose}>
              <NavButton
                icon={Assets.close_white}
                onClick={() => {
                  setState({ openModal: false });
                }}
                svg
                iconHeight={24}
                iconWidth={24}
                style={{ backgroundColor: 'transparent' }}
              />
            </View>
            <View style={styles.headerText}>
              <Text size={Ratio(24)} color={'white'}>
                Quick Start
              </Text>
            </View>
          </View>
          <ScrollView style={styles.bottomSheetBody}>
            {taskData.map((item) => (
              <TaskCard
                title={item.title}
                text={item.text}
                completed={item.completed}
                onClick={item.onClick}
              />
            ))}
          </ScrollView>
        </View>
      </Modal>
    </Container>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  marginLeft: {
    marginLeft: Ratio(15),
  },
  homeScreen: {
    height: '100%',
    backgroundColor: Colors.inputBackground,
    // paddingTop: Constants.statusBarHeight,
    paddingRight: Ratio(20),
    paddingLeft: Ratio(20),
    paddingBottom: 0,
  },
  homeCardWrapper: {
    width: '100%',
    height: Ratio(150),
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  userProfile: {
    backgroundColor: Colors.secondaryLightColor,
    width: Ratio(55),
    height: Ratio(55),
  },
  homeCardContainer: {
    width: '48%',
    height: '100%',
  },
  petList: {
    width: '100%',
    height: '89%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  petDetail: {
    width: "49%",
    marginBottom: Ratio(15),
  },
  sessonWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '10%',
    marginTop: Ratio(10),
  },
  emptyContainer: {
    width: '100%',
    paddingTop: Ratio(20),
    justifyContent: 'center',
    alignItems: 'center',
  },
  taskButtonContainer: {
    position: 'fixed',
    bottom: 100,
    right: 80,
    display:"flex",
    flexDirection:"row",
    backgroundColor: Colors.primaryDarkColor,
    justifyContent: "space-between",
    padding:"0px 10px",
    alignItems:"center",
    borderRadius: "20px",
    cursor:"pointer",
  },
  taskButton: {
    backgroundColor: Colors.primaryDarkColor,
    borderRadius: 15,
    height: 50,
    width: 50,
  },
  bottomSheetContainer: {
    height: 500,
    width: '100%',
  },
  bottomSheetHeader: {
    height: '100px',
    width: '100%',
    padding: ' 10px 15px 15px 15px',
    backgroundColor: Colors.primaryDarkColor,
    borderTopEndRadius: '30px',
    borderTopStartRadius: '30px',
  },
  bottomSheetWrapper: {
    height: '100%',
    width: '100%',
  },
  bottomSheetBody: {
    height: '450px',
    width: '100%',
    padding: '20px',
    backgroundColor: Colors.background,
    overflowY: 'scroll',
  },
  headerClose: {
    width: '100%',
    alignItems: 'flex-end',
  },
  headerText: {
    width: '100%',
    alignItems: 'center',
  },
  modal: {
    width: '400px',
  },
  countContainer:{
    paddingLeft: '10px',
    borderLeft: '1px solid',
    borderLeftColor:Colors.secondaryDarkText,
    paddingRight:"10px"
  }
});

export default Home;
