import {
  GET_PET_LIST,
  GET_PET_DETAILS,
  REMOVE_PET_DETAILS
} from 'utils/types.utils';
import { storeAction } from 'interfaces/common.interface';

const initialState = {
  petList: [],
  pet: {}
};

const PetReducer = (state = initialState, action: storeAction) => {
  switch (action.type) {
    case GET_PET_LIST:
      let petList: any = [...action.payload]
      state.petList = petList;
      return state;
    case GET_PET_DETAILS:
      let pet: any = { ...action.payload }
      state.pet = pet;
      return state;
    case REMOVE_PET_DETAILS:
      state.pet = {};
      return state;
    default:
      return state;
  }
};

export default PetReducer;
