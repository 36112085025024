import React, { useEffect, Fragment } from 'react';
import { useSetState } from 'utils/functions.utils';
import { useSelector } from 'react-redux';
import { reducers } from 'interfaces/common.interface';
import connectSocket from 'utils/socket.utils';
import Div100vh from 'react-div-100vh';
import { useLocation, NavLink, Link } from 'react-router-dom';
import './main.hoc.scss';
import uuid from 'react-uuid';
import {
  Assets,
  Component,
  Functions,
  Models,
  Tabs,
} from 'utils/imports.utils';
import { getUserDetails } from 'utils/redux.utils';
import EditProfile from 'screens/edit_profile/edit_profile.screen';

let socket: any;

export default function Main(props: any) {
  const [state, setState] = useSetState({ signout: false, loading: true, params: {} });
  const user = useSelector((store: any) => store.user.user);
  const location = useLocation();
  useEffect(() => {
    getUser();
    socket = connectSocket;
  }, []);
  
  useEffect(()=>{
    getUser()
  },[window.location.href])
  
  const subscribed = localStorage.getItem('subscribed')
  const Tabs = [
    {
      title: "Dashboard",
      route: "Dashboard",
      users: ["super_admin" , "org_admin"],
      activeIcon: Assets.dashboard_active,
      inActiveIcon: Assets.dashboard_inactive,
      subscribed: user.user_type =='customer'? true :subscribed == 'true' ? true : false
    },
    {
      title: "Home",
      route: user.user_type == "trainer" ? "trainer_home" : user.user_type == "admin" ? "home" : "customer_home" ,
      users: ["admin", "trainer" , "customer"],
      activeIcon: Assets.Home,
      inActiveIcon: Assets.HomeLight,
      subscribed: user.user_type =='customer'? true :subscribed == 'true' ? true : false
    },
    {
      title: "Schedule",
      route: "TrainingDetails",
      users: ["admin"],
      activeIcon: Assets.CalendarWhite,
      inActiveIcon: Assets.CalendarBlack,
      subscribed: user.user_type =='customer'? true :subscribed == 'true' ? true : false
    },
    {
      title: "Chat",
      route: "chat",
      users: [ "customer", "trainer" , "admin"],
      activeIcon: Assets.MessageWhite,
      inActiveIcon: Assets.MessageUnClick,
      subscribed: user.user_type =='customer'? true :subscribed == 'true' ? true : false
    },
    {
      title: "Manage",
      route: "manage",
      users: ["admin", "super_admin" , "org_admin" , "trainer"],
      activeIcon: Assets.CricleWhite,
      inActiveIcon: Assets.CricleBlack,
      subscribed: user.user_type =='customer'? true :subscribed == 'true' ? true : false
    },
    {
      title: "Payment",
      route: "Payment",
      users: (user.org_details?.always_subscribed)?[""]:(user.customer_id ? "org_admin": ""),
      activeIcon: Assets.payment_active,
      inActiveIcon: Assets.payment_inactive,
      subscribed: user.user_type =='customer'? false:true
    },
    {
      title: "Settings",
      route: "Settings",
      users: ["admin","org_admin", "customer", "trainer" , "super_admin"],
      activeIcon: Assets.Tune,
      inActiveIcon: Assets.TuneBlue,
      subscribed: user.user_type =='customer'? true :subscribed == 'true' ? true : false
    },
  ]

  const getUser = async () => {
    try {
      const user: any = await Models.auth.getUserByToken();
      getUserDetails(user.data);
      localStorage.setItem("subscribed", user.data.org_details.subscribed.toString())
      if(!user.data.org_details.subscribed && window.location.pathname !== "/payment"){
        window.location.href = "/subscription"
      }
      setState({ loading: false });
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get user details');
      }
    }
  };

  const showSuccess = (text: string) => {
    //show snack success
    console.log('text', text);
  };

  const throwError = (text: string) => {
    //show snack error
  };

  const setMainLoading = (loading: boolean) => {
    setState({ loading: loading });
  };

  const navigation = {
    navigate: (url: string, data: any) => {
      if(data){
        props.history.push(url, data);
        updateRouteParams(data)
      } else {
        props.history.push(url);
      }
    },
    goBack: () => props.history.goBack(),
  }

  const updateRouteParams = (params) => {
    setState({ params: params })
  }

  const route = {
    pathname: window.location.href.split('/').pop(),
    params: state.params,
    query: Functions.getURLParams(),
  }

  const renderChildren = () => {
    return React.Children.map(props.children, (child: any) => {
      if (child) {
        return React.cloneElement(child, {
          user,
          showSuccess,
          throwError,
          socket,
          setMainLoading,
          navigation,
          route,
          updateRouteParams
        });
      }
    });
  };

  const checkActive = (route) => {
    const location = useLocation();
    const url = location.pathname.split('/')[1];
    if (url.includes(route)) {
      return true;
    }
    return false;
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_type");
    localStorage.removeItem("subscribed");
    window.location.href = "/login";
  }

  if (state.signout) window.location.href = '/';
  if (state.loading) return <div>Loading</div>;

  const checkSubscription = () => {
    if(user.user_type == 'org_admin') {
      return user.org_details.subscribed
    } else return true
  }

  return (
    <Div100vh>
      <div className="main_hoc_container">
        <div className="main_hoc_wrapper">
          <div className="main_hoc_sidebar_container">
            <div className="main_hoc_sidebar_wrapper">
              <div className="main_hoc_sidebar_body">
                <div className="main_hoc_sidebar_logo_content">
                  <img src={Assets.Logo} className="hoc_logo" alt="hoc_logo" width={"250px"}/>
                </div>
                <div className="main_hoc_sidebar_tab_container">
                  <div className="main_hoc_sidebar_tab_content">
                    {Tabs.map((tab, index) => (
                      tab.users.includes(user.role || user.user_type) && checkSubscription() &&
                      <NavLink
                        className={`tab_content`}
                        key={uuid()}
                        to={`/${tab.route}`}>
                        <div className="tab_icon">
                          <img
                            src={
                              checkActive(tab.route)
                                ? tab.activeIcon
                                : tab.inActiveIcon
                            }
                          />
                        </div>
                        <div className="tab_title p3">{tab.title}</div>
                      </NavLink>
                    ))}
                  </div>
                </div>
              </div>
              <div className="logout_container">
                <div className="logout_wrapper" onClick={logout}>
                  <div>Logout</div>
                </div>
              </div>
            </div>
          </div>
          <div className="main_hoc_body">{renderChildren()}</div>
          <div className="main_hoc_header_container">
            <div className="main_hoc_header_wrapper">
              { user.role == "admin" || user.user_type == "trainer" || user.user_type == "customer" ?
                <Link to="Notification">
                <Component.NavButton icon={Assets.Notification} />
              </Link> : " "
              }
              <div className="hoc_profile_container">
                {!state.loading && user.profile ? (
                  <Link to="EditProfile">
                  <Component.Image src={user.profile} borderRadius={'20px'} width={60} height={60} />
                  </Link>
                ) : (
                  <Link to="EditProfile">
                  <Component.NavButton
                    width="60px"
                    height="60px"
                    icon={Assets.SingleUserPrimary}
                    iconHeight="30px"
                    iconWidth="30px"
                    disabled
                    borderRadius="20px"
                  />
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Div100vh>
  );
}
