import React from 'react';
import { useSelector } from 'react-redux';
import {
  View, StyleSheet, TouchableOpacity,
  Text,
  Colors,
  Ratio,
  Container,
  Constants,
  Image,
  MediaView,
  Functions,
  Assets,
  VideoPlayer,
  NavButton,
} from 'utils/imports.utils';
import moment from 'moment';
import _ from 'lodash';
import FileViewer from 'react-file-viewer';

interface messageProps {
  text?: any;
  media?: string;
  isReceived?: boolean;
  type: string;
  data: any;
  onLongPress?: any;
  isSeen?:boolean;
  deleteClick?: any;
  showDelete?: any;
  unSendButton?: any;
}

const Message = (props: messageProps) => {
  const { text, media, isReceived, type, data, onLongPress, isSeen , deleteClick , showDelete , unSendButton } = props;
  const [state, setState] = Functions.useSetState({
    open: false,
    delete: false
  });
  const messages = useSelector((state: any) => state.chat.messageList);

  const openDocument = (doc: any) => {
    const type = `${doc.url.split('.').pop()}`;
    <FileViewer
      fileType={type}
      filePath={doc.url}
      // errorComponent={CustomErrorComponent}
      // onError={this.onError}
      />
  };

  const isUrlFound = text => {
    let regex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    return regex.test(text);
  };

  const httpsFound = (text)=>{
    if(text.includes("https")){
      return text
    }
    else{
      return "https://"+text
    }
    
  }
  return (
    <Container
      styles={{ ...styles.messageComponent, ...(isReceived ? styles.endPosition : styles.startPosition)}}>
      <View>
        <View
          style={[
            styles.messageView,
            isReceived ? { backgroundColor: Colors.secondaryBackgroundColor } : { backgroundColor: Colors.buttonTextColor },
            (type === 'image' || type === 'video' || type === 'docs') && styles.messageViewMedia,
          ]} onMouseDown={() => setState({delete: !state.delete})
        }>
          {type === 'image' && (
            <TouchableOpacity activeOpacity={0.7} onPress={() => setState({ open: !state.open })}>
              <Image
                url
                src={data.media.url}
                width={Ratio(150)}
                height={Ratio(150)}
                radius={15}
                resize="cover"
              />
            </TouchableOpacity>
          )}
          {type === 'video' && (
            <TouchableOpacity activeOpacity={0.7} onPress={() => setState({ open: !state.open })}>
              <View style={{ width: Ratio(150), position: 'relative', height: Ratio(150) }}>
                <VideoPlayer
                  src={data.media.url}
                  url
                  width={'100%'}
                  height={'100%'}
                  resize="cover"
                  autoplay = {false}
                  controls={false}
                  style={styles.videoPlayer}
                />
                <View
                  style={{
                    position: 'absolute',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                  }}>
                  <Image
                    // styles={{ position: 'absolute', justifyContent: "center" }}
                    src={Assets.Play}
                    width={Ratio(30)}
                    height={Ratio(30)}
                    svg
                  />
                </View>
              </View>
            </TouchableOpacity>
          )}
          {(type === 'application' || type == "docs") && (
            <TouchableOpacity activeOpacity={0.7} onPress={() => openDocument(data.media)}>
              <View style={styles.docViewer}>
                <View style={{ width: '100%', height: '100%' }}>
                  <View style={styles.docImageView}>
                    <Image src={Assets.DocPlaceholder} width={Ratio(70)} height={Ratio(70)} svg />
                  </View>
                  <View style={{ padding: Ratio(10) }}>
                    <Text size={Ratio(12)} color={Colors.primaryDarkColor} ellipsizeMode="tail" numberOfLines={2}>
                      {data.media.name}
                    </Text>
                  </View>
                </View>
              </View>
            </TouchableOpacity>
          )}
          {type === 'text' && (
            <View style={styles.messageWrapper}>
              {text.split(' ').map((item: string) => {
                if (isUrlFound(item)) {
                  return (
                    <TouchableOpacity  onLongPress={onLongPress} activeOpacity={0.7}>
                      <a href={httpsFound(item)} target="_blank" style={{textDecoration:"none"}}>
                      <Text
                        color={Colors.primaryLinkColor}
                        family={Constants.regular}
                        size={Ratio(14)}
                        weight="400">
                        {item}
                      </Text>
                      </a>
                    </TouchableOpacity>
                  );
                } else {
                  return (
                    <TouchableOpacity onLongPress={onLongPress} activeOpacity={0.7}>
                      <Text
                        color={Colors.primaryDarkText}
                        family={Constants.regular}
                        size={Ratio(14)}
                        right={3}
                        weight="400">
                        {item}
                      </Text>
                    </TouchableOpacity>
                  );
                }
              })}
            </View>
          )}
        </View>
        <View style={[styles.timeStampContainerRight, {alignItems: isReceived?"flex-end":"flex-start"}]}>
          <Text color={Colors.primaryLightText} size={8}>
            {moment(data.created_at).format('MM-DD-YYYY, hh:mm a')}
          </Text>
          {isSeen && (
            <Text color={Colors.primaryLightText} size={12}>
              Seen
            </Text>
          )}
          {(state.delete && unSendButton) &&
           <TouchableOpacity
           style={[styles.deleteWrapper]}
           activeOpacity={0.9}
           onPress= {()=>{
            deleteClick()
            setState({delete:false})
           }}>
           <Text color={Colors.secondaryDarkColor}>Unsend</Text>
         </TouchableOpacity>
          }

        </View>
      </View>
      {/* {isReceived ? <View style={[styles.shapRight]} /> : <View style={[styles.shapLeft]} />} */}
      {state.open && (
        <MediaView
          isOpen={state.open}
          data={data.media}
          sliderData={[data.media]}
          onBackClick={() => setState({ open: !state.open })}
          onClose={() => setState({ open: !state.open })}
          from='chat'
        />
      )}
    </Container>
  );
};

const styles = StyleSheet.create({
  messageComponent: {
    width: '100%',
  },
  messageView: {
    maxWidth: Ratio('80%'),
    padding: "15px 25px",
    backgroundColor: Colors.buttonTextColor,
    borderRadius: 20,
  },
  messageViewMedia: {
    padding: "10px",
  },
  endPosition: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingRight: Ratio(15),
  },
  startPosition: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingLeft: Ratio(15),
  },
  triangleCorner: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderRightWidth: 25,
    borderTopWidth: 20,
    borderRightColor: 'transparent',
    borderTopColor: Colors.primaryDarkColor,
  },
  shapRight: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderRightWidth: 20,
    borderTopWidth: 20,
    borderRightColor: 'transparent',
    borderTopColor: Colors.secondaryBackgroundColor,
    
  },
  shapLeft: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 20,
    borderTopWidth: 20,
    borderLeftColor: 'transparent',
    borderTopColor: Colors.buttonTextColor,
  },
  timeStampContainerRight: {
    // alignItems: 'flex-start',
    paddingTop: Ratio(5),
    paddingLeft: Ratio(10),
  },
  docViewer: {
    width: Ratio(150),
    height: Ratio(150),
    borderRadius: 20,
  },
  docImageView: {
    height: '65%',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  videoPlayer:{
    height:"100%",
    width:"100%",
    borderRadius: "20px",
    objectFit: "cover"
  },
  messageWrapper:{
    display:"flex",
    flexDirection:"row",
    flexWrap:"wrap",
  },
  deleteWrapper:{
    cursor:"pointer",
    backgroundColor:Colors.secondaryBackground,
    borderRadius: 10,
    padding: "5px"
  }
});

export default Message;
