import StripeContainer from 'common_components/ui/stripe_container/stripeContainer';
import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'utils/functions.utils';
import {
  Functions,
  Models,
  Input,
  Assets,
  Component,
  SignUpComponent,
  PaymentForm,
} from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import './signin.screen.scss';

const SignUp = (props) => {
  // Redux

  // State
  const [state, setState] = useSetState({
    signup: true,
    product_id: ''
  });

  //Hooks
  useEffect(() => {}, []);

  // Network req

  //Logic
  const testLogic = () => {};

  return (
    <div className="signup_screen">
      {/* {state.signup ? (
        <SignUpComponent
        {...props}
          onButtonClick={(data:any) => {
            setState({ signup: false, product_id: data.id, data });
          }}
        />
      ) : (
        <StripeContainer {...props} data={state.data} onBackPress={() => setState({signup: true})} />
      )} */}
      <SignUpComponent />
    </div>
  );
};

export default SignUp;
