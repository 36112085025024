import React from 'react';
import { View, StyleSheet, Text, Colors, Container, NavButton, Assets, Ratio, Constants } from 'utils/imports.utils';

interface settingProps {
  icon?: any;
  title?: string;
  iconHeight?: any;
  iconWeight?: any;
}

const SettingsCard = (props: settingProps) => {
  const { icon, title, iconHeight, iconWeight } = props;
  return (
    <View>
      <View style={styles.settingCard}>
        <View style={styles.iconContainer}>
          <NavButton
            icon={icon}
            svg
            disabled
            iconHeight={iconHeight || Ratio(30)}
            iconWidth={iconWeight || Ratio(30)}
            style={{
              width: Ratio(50),
              height: Ratio(50),
              backgroundColor: Colors.secondaryLightColor,
              borderRadius: 16,
            }}
          />
          <View style={{ paddingLeft: Ratio(12) }}>
            <Text
              size={Ratio(16)}
              color={Colors.primaryDarkColor}
              family={Constants.regular}
              weight="400">
              {title}
            </Text>
          </View>
        </View>
        <View style={{transform: { rotateY: "180deg" }}}>
          <NavButton
            icon={Assets.ArrowBack}
            svg
            disabled
            iconHeight={Ratio(25)}
            iconWidth={Ratio(25)}
            style={{
              width: Ratio(50),
              height: Ratio(50),
              backgroundColor: "transparent",
              borderRadius: 16,
              transform: "rotateY(180deg)"
            }}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  settingCard: {
    width: '100%',
    backgroundColor: Colors.buttonTextColor,
    borderRadius: 24,
    padding:'40px 16px',
    flexDirection: "row",
    justifyContent: "space-between",
    cursor:"pointer",
  },
  iconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default SettingsCard;
