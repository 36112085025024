import {
  GET_BRANCH_DETAILS,
  GET_BRANCH_LIST,
  REMOVE_BRANCH_DETAILS,
} from 'utils/types.utils';
import { storeAction } from 'interfaces/common.interface';

const initialState = {
  branchList: [],
  branch: {},
};

const branchReducer = (state = initialState, action: storeAction) => {
  switch (action.type) {
    case GET_BRANCH_LIST:
      let branchList: any = [...action.payload]
      state.branchList = branchList;
      return state;
    case GET_BRANCH_DETAILS:
      let training: any = { ...action.payload }
      state.branch = training;
      return state;
    case REMOVE_BRANCH_DETAILS:
      state.branch = {};
      return state;
    default:
      return state;
  }
};

export default branchReducer;
