import instance from '../utils/axios.utils';

const notification = {
  nofificationList: (body: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'notification/find_many_notification';
      instance()
        .post(url, body)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },

  updateNotification: (body: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'notification/update_notification';
      instance()
        .post(url, body)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },

  updateManyNotification: () => {
    let promise = new Promise((resolve, reject) => {
      let url = 'notification/update_many_notification';
      instance()
        .post(url)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          console.log('error', error);
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },

  hasNotification: (body: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'notification/has_new_notification';
      instance()
        .post(url, body)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            reject(error.response.data.message);
          } else if(error.message) {
            console.log(error.message);
            reject(error.message);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
    return promise;
  },
};

export default notification;
