import React from 'react';
import moment from 'moment';
import {
  Colors,
  Constants,
  Container,
  Image,
  Ratio,
  Text,
  Assets,
  NavButton,
  Functions,
  View, StyleSheet, TouchableOpacity
} from 'utils/imports.utils';

interface conversationProps {
  profile?: string;
  username: string;
  user_type?: string;
  user_id?: string;
  last_message?: any;
  last_update?: string;
  onClick?: any;
  url?: any;
  unreadCount?: number;
  message_type?: string;
  notification?: boolean;
  seen?: boolean;
  notification_date?: string;
}

const Notify = (props: conversationProps) => {
  const [state, setState] = Functions.useSetState({
    toggle: false,
  });
  const {
    profile,
    last_message,
    last_update,
    user_id,
    user_type,
    username,
    url,
    unreadCount,
    message_type,
    notification,
    seen,
    onClick,
    notification_date,
  } = props;

  const toggleMessage = () => {
    // setState({ toggle: !state.toggle });
    onClick();
  };

  return (
    <TouchableOpacity
      activeOpacity={0.8}
      style={[styles.conversationContainer, notification && !seen && styles.unseenStyle]}
      onPress={() => toggleMessage()}>
      <View style={styles.profileContainer}>
        {profile ? (
          <View style={{ position: 'relative' }}>
            <Image
              src={profile}
              url
              width={Ratio(66)}
              resize="cover"
              radius={20}
              height={Ratio(66)}
            />
          </View>
        ) : (
          <View style={{ position: 'relative' }}>
            <NavButton
              icon={Assets.SingleUserPrimary}
              svg
              disabled
              iconHeight={Ratio(40)}
              iconWidth={Ratio(40)}
              style={{
                width: Ratio(66),
                height: Ratio(66),
                backgroundColor: Colors.secondaryLightColor,
                borderRadius: 20,
              }}
            />
          </View>
        )}
      </View>
      <View style={{width:"100%"}}>
      <View style={styles.timeDetails}>
        <View style={{width:"100%", flexDirection:"row" , display:"flex" , justifyContent:"flex-end"}}>
        <Text
            styles={{paddingRight:Ratio(8)}}
            color={Colors.primaryLightText}
            size={Ratio(9)}>
            {moment(notification_date).format('MMM DD')}
          </Text>
          <Text
            // styles={{ paddingBottom: !unreadCount ? Ratio(15) : Ratio(0) }}
            color={Colors.primaryLightText}
            size={Ratio(9)}>
            {moment(last_update).format('hh:mm a')}
          </Text>
        </View>
        </View>
      <View style={styles.chatContent}>
        <View style={styles.nameDetails}>
          <Text
            color={Colors.primaryDarkColor}
            styles={{ width: '100%', paddingBottom: unreadCount && Ratio(3)}}
            ellipsizeMode="tail"
            numberOfLines={1}
            size={Ratio(16)}
            family={Constants.medium}
            weight="600">
            {username}
          </Text>
          {state.toggle ? (
            <Text
              color={Colors.primaryLightText}
              styles={{ width: '100%', paddingBottom: Ratio(15) }}
              size={Ratio(12)}
              family={Constants.regular}
              weight="400">
              {last_message?.text}
            </Text>
          ) : (
            <Text
              color={Colors.primaryLightText}
              styles={{ width: '100%' }}
              ellipsizeMode="tail"
              numberOfLines={1}
              size={Ratio(12)}
              family={Constants.regular}
              weight="400">
              {last_message?.text}
            </Text>
          )}
        </View>
      </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  conversationContainer: {
    width: '100%',
    height:"100%",
    padding: Ratio(10),
    backgroundColor: Colors.buttonTextColor,
    borderRadius: 28,
    flexDirection: 'row',
  },
  profileContainer: {
    width: Ratio(66),
    height: Ratio(66),
  },
  chatContent: {
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingLeft: Ratio(10),
    // paddingTop: Ratio(12),
  },
  nameDetails: {
    width: '100%',
    justifyContent: 'center',
  },
  timeDetails: {
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection:"row",

  },
  countStyle: {
    backgroundColor: Colors.secondaryDarkColor,
    padding: '2px 5px',
    borderRadius: 4,
  },
  userDifferent: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  unseenStyle: {
    backgroundColor: Colors.buttonTextColor,
  },
});

export default Notify;
