import React, { useEffect } from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Assets,
  Constants,
  Image,
  NavButton,
  Ratio,
  Text,
  Colors,
  Input,
  Functions,
  PrimaryButton,
  Container,
  Select,
  Models,
  FileInput,
} from 'utils/imports.utils';
import { uploadFile } from 'utils/s3.utils';
import { useSelector } from 'react-redux';
import { getOrganisationList } from 'utils/redux.utils';

const AddBranch = (props: any) => {
  const organizations = useSelector((state: any) => state.org.orgList);
  const user = useSelector((state: any) => state.user.user);
  const [state, setState] = Functions.useSetState({
    name: '',
    profile: '',
    organization:"",
    activity: false,
    isOpen: true,
    loading: true,
    edit: false,
    profileUpload: false,
    page: 1,
    limit: 100,
    search: '',
    loader:false,
  });

  useEffect(() => {
    getOrganisation({
      page: state.page,
      limit: state.limit,
      filter: state.filter
    });
    if (props.route.params.view) {
      getBranchDetails(props.route.params.branchId);
    } else {
      setState({ loading: false });
    }
    if (props.route.params?.orgId) {
      setState({ organization: props.route.params.orgId });
    }

  }, []);

  const backAction = () => {
    props.navigation.goBack();
    return true;
  };

  const getOrganisation = async ({ page, limit, filter }: any) => {
    try {
      setState({ loadMoreLoader: true });
      let req: any = { page, limit };
      if (filter) {
        req.status = filter;
      }
      const org: any = await Models.org.orgList(req);
      if (page !== 1) {
        getOrganisationList([...organizations, ...org.data.docs]);
      } else {
        getOrganisationList(org.data.docs);
      }
      setState({
        loading: false,
        hasMore: org.data.hasNextPage,
        page,
        limit,
        loadMoreLoader: false,
        filter,
      });
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get organization');
      }
      setState({ loading: false });
    }
  };

  const getBranchDetails = async (id: string) => {
    try {
      const branch: any = await Models.branch.viewBranch(id);
      setState({
        name: branch.data.name,
        loading: false,
        profile: branch.data?.profile ? branch.data?.profile : '',
        api_key: branch.data?.zapier_api_key
      });
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get user details');
      }
      setState({ loading: false });
    }
  };

  const addBranch = async () => {
    console.log("first")
    try {
      setState({ loader: true });
      let req: any = {
        name: state.name,
        org: user.org,
      };
      // await Validation.newUserSchema.validate(req);
      const response: any = await Models.branch.createBranch(req);
      user.created_branch = true
      Functions.Success('Success', response.data.message)
      setState({ name: '', organization: '', loader: false })
      onClose();
    } catch (err) {
      Functions.Failure(err)
      console.log("error message", err);
      setState({ loader: false });
    }
  };

  const saveBranchAddAdmin = async () => {
    try {
      setState({ activity: true });
      let req: any = {
        name: state.name,
        org: user.org,
      };
      // await Validation.newUserSchema.validate(req);
      const response: any = await Models.branch.createBranch(req);
      Functions.Success('Success', response.data.message)
      props.navigation.navigate('AddAdmin' , {branchData: {branchName:response.data.name , branchId:response.data._id} , orgData:state.organization, type:"admin", add: true , buttonText:"Add Admin" } )
      setState({ name: '', organization: '', activity: false })
      // onClose();
    } catch (err) {
      console.log("error" , err)
      Functions.Failure(err)
      setState({ activity: false });
    }
  };  
  
  const editBranch = async () => {
    try {
      setState({ loader: true });
      let req: any = {
        name: state.name,
        org: state.organization,
        // profile: state.profile,
      };
      // await Validation.newUserSchema.validate(req);
      req.branch_id = props.route.params.branchId;
      await Models.branch.editBranch(req);
      Functions.Success('Success', 'branch updated');
      setState({ loader: false, edit: false });
      onClose();
    } catch (err) {
      Functions.Failure(err)
      console.log('err', err);
      setState({ loader: false });
    }
  };

  const uploadProfile = async (event) => {
    try {
      const image: any =event.target.files[0];
      setState({ profileUpload: true });
      const file: any = await uploadFile(image);
      setState({ profile: file.url, profileUpload: false });
    } catch (err) {
      console.log('err', err);
    }
  };

  const onClose = () => {
    if (state.edit) {
      setState({ edit: false });
    } else {
      props.navigation.goBack();
    }
  };
  return (
    <Container styles={styles.addBranchScreen} screen loading={state.loading}>
      <View style={styles.addBranchContainer}>
        <View style={styles.headerWrapper}>
          <View style={styles.textWrapper}>
            <View style={styles.imageContainer}>
              <TouchableOpacity
                style={{
                  width: Ratio(40),
                  height: Ratio(40),
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                activeOpacity={0.7}
                onPress={onClose}>
                <Image svg src={Assets.ArrowBack} width={20} height={20} />
              </TouchableOpacity>
            </View>
            <View style={styles.headTextWrapper}>
              <Text
                size={Ratio(24)}
                color={Colors.primaryDarkText}
                family={Constants.light}
                weight="300">
                {props.route.params.add ? 'Create branch' : 'Edit branch'}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.addBranchFormContainer}>
          <View style={styles.addPetIconContainer}>
            {!state.profile && !state.profileUpload ? (
              <View style={styles.profileContainer}>
                <NavButton
                  icon={Assets.SingleUserPrimary}
                  disabled
                  style={styles.navIcon}
                  iconHeight={Ratio(50)}
                  iconWidth={Ratio(50)}
                  svg
                />
              </View>
            ) : state.profile && !state.profileUpload ? (
              <View style={styles.profileImage}>
                <Image
                  src={state.profile}
                  height="100%"
                  width="100%"
                  url
                  resize="cover"
                  radius={30}
                />
              </View>
            ) : (
              <View style={styles.profileLoading}>
                <View
                  size={30}
                  borderWidth={3}
                  indeterminate
                  color={Colors.buttonTextColor}
                />
              </View>
            )}
            {/* <TouchableOpacity
              style={styles.uploadIcon}
              activeOpacity={0.7}
              onPress={uploadProfile}>
             <FileInput style={styles.fileInput} src={Assets.EditWhite} iconStyle={styles.addImageIcon} id="add_user" onChange={uploadProfile}/>
            </TouchableOpacity> */}
          </View>
          <View style={styles.formContainer}>
            <View style={styles.formBody}>
              <View style={styles.bodyContainer}>
                <Input
                  label="Branch Name"
                  name="branch name"
                  onChange={(name: string) => setState({ name })}
                  placeholder="Full name"
                  value={state.name}
                />
                {props.route.params.view && <Input
                  label="API Key"
                  name="api key"
                  onChange={(api_key: string) => setState({ api_key })}
                  placeholder="API key"
                  value={state.api_key}
                  disabled
                />}
                {/* <Select
                  data={organizations}
                  onSelect={(data: any) => setState({ organization: data })}
                  keyValue={'_id'}
                  label={'Organization'}
                  labelValue={'name'}
                  selected={state.organization}
                  labelStyle={{ paddingLeft: Ratio(0) }}
                /> */}
              </View>
            </View>
          </View>
          <View style={styles.addPetButtonContainer}>
            <PrimaryButton
              text={'Save'}
              onPress={props.route.params.add ? addBranch : editBranch}
              // onPress={()=>{}}
              width={'200px'}
              activeOpacity={0.7}
              backgroundColor={Colors.primaryDarkColor}
              activity={state.loader}
            />

            {props.route.params.add && (
              <PrimaryButton
                text={'Save & Add Admin'}
                onPress={ saveBranchAddAdmin}
                width={'200px'}
                activeOpacity={0.7}
                backgroundColor={Colors.primaryDarkColor}
                activity={state.activity}
              />
            )}
          </View>
        </View>
      </View>
    </Container>
  );
};

const styles = StyleSheet.create({
  addBranchScreen: {
    paddingTop: Ratio(20),
    height: '100%',
  },
  addBranchContainer: {
    padding: Ratio(20),
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
  },
  headerWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '10%',
    justifyContent: 'space-between',
  },
  imageContainer: {},
  headTextWrapper: {
    paddingLeft: Ratio(10),
  },
  textWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  addBranchFormContainer: {
    position: 'relative',
    width: '100%',
    height: '90%',
    marginTop: Ratio(60),
  },
  addPetIconContainer: {
    position: 'absolute',
    width: '100%',
    zIndex: 1,
    alignItems: 'center',
    top: -55,
  },
  navIcon: {
    width: Ratio(100),
    height: Ratio(100),
    backgroundColor: Colors.secondaryLightColor,
    borderRadius: 30,
  },
  formBody: {
    width: '50%',
    // height: Platform.OS === 'android' ? Ratio('60%', true) : Ratio('50%', true),
    backgroundColor: Colors.buttonTextColor,
    borderRadius: 32,
    shadowColor: '#DDE5EE',
    shadowOffset: { width: 4, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    padding: 20,
  },
  bodyContainer: {
    paddingTop: Ratio(50),
    paddingHorizontal: Ratio(15),
    width: '100%',
  },
  inputWrapper: {
    width: '100%',
    zIndex: 100,
    paddingBottom: Ratio(10),
  },
  addPetButtonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    // bottom: Platform.OS === 'android' ? Ratio(-10, true) : Ratio(-10, true),
    display: 'flex',
    flexDirection: 'row',
    gap:"30px",
    paddingTop:"30px",
  },
  btnContent: {
    width: '80%',
  },
  profileContainer: {},
  profileImage: {
    width: Ratio(100),
    height: Ratio(100),
  },
  uploadIcon: {
    backgroundColor: Colors.primaryDarkColor,
    width: Ratio(30),
    height: Ratio(30),
    borderRadius: Ratio(20),
    justifyContent: 'center',
    alignItems: 'center',
    bottom: Ratio(17),
    marginTop:"-20px"
  },
  profileLoading: {
    width: Ratio(100),
    height: Ratio(100),
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.primaryDarkColor,
    opacity: 0.4,
  },
  formContainer:{
    width:"100%",
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
  }
});

export default AddBranch;
