import React, { Profiler, useEffect } from 'react';
import {
  Text,
  Container,
  Constants,
  NavButton,
  Assets,
  Ratio,
  Colors,
  SettingsCard,
  Image,
  Functions,
  Models,
  View,
  StyleSheet,
  ScrollView,
  FlatList,
  TouchableOpacity,
  TaskCard,
} from 'utils/imports.utils';
import { useSelector } from 'react-redux';
import { getAcuityList, getCallList, getChatList, getNotificationList, getPetList, getUserDetails, getChat } from 'utils/redux.utils';
import connectSocket from 'utils/socket.utils';
import { Modal } from 'react-responsive-modal';

let socket: any;

const Settings = (props: any) => {
  const user = useSelector((state: any) => state.user.user);

  const [state, setState] = Functions.useSetState({
    loading: true,
    openModal: false,
  });

  useEffect(() => {
    getUser(user._id);
  }, []);

  const getUser = async (id: string) => {
    try {
      const userData: any = await Models.user.viewUser(id);
      setState({
        loading: false,
      });
      getUserDetails(userData.data);
    } catch (err) {
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get user details');
      }
      setState({ loading: false });
    }
  };

  const handleProfile = () => {
    props.navigation.navigate('EditProfile');
  };

  const handlePassword = () => {
    props.navigation.navigate('ChangePassword');
  };
  const handleAlertList = () => {
    props.navigation.navigate('AlertList');
  }

  const handleLogout = async () => {
    getUserDetails({});
    getNotificationList([]);
    getChatList([]);
    getAcuityList([]);
    getCallList([]);
    getPetList([]);
    await localStorage.removeItem('token');
    await localStorage.removeItem('user_type');
    window.location.href = '/login';
  };

  const handleCallAvailability = () => {
    props.navigation.navigate('CallAvailability');
  };
  const handleChatClick = async () => {
    try {
      let body = {
        from: user._id,
        to: "6197662829e4560df05466ca",
        type: 'private',
      };
      const conversation: any = await Models.chat.startConversation(body);
      // console.log('conversation', conversation);
      getChat(conversation.data);
      socket = connectSocket;
      // console.log('socket', socket);
      // global.WebSocket = socket;

      socket.emit('join-chat', { user: user._id });
      socket.on('user-connected', (data: any) => {
        console.log('user connected...');
      });
      props.navigation.navigate('Message');
    } catch (err) {
      console.log('err');
      if (err) {
        Functions.Failure(err);
      } else {
        Functions.Failure('Failed to get conversation');
      }
    }
  };
  const openTaskBottomSheet =async () => {
    setState({openModal:true})
  }

  let settingsList = [
    {
      icon: Assets.SingleUserPrimary,
      title: 'Profile',
      onClick: handleProfile,
      iconWidth: Ratio(25),
      iconHeight: Ratio(25),
    },
    {
      icon: Assets.PhonePrimary,
      title: 'Call Availability',
      onClick: handleCallAvailability,
    },
    {
      icon: Assets.KeyFill,
      title: 'Password',
      onClick: handlePassword,
    },
    {
      icon: Assets.SendMessage,
      title: 'Contact Admin',
      onClick: handleChatClick,
    },
    {
      icon: Assets.task,
      title: 'Help',
      onClick: openTaskBottomSheet,
    },
    {
      icon: Assets.Logout,
      title: 'Logout',
      onClick: handleLogout,
      iconWidth: Ratio(20),
      iconHeight: Ratio(20),
    },
  ];
  if (user.role == 'admin') {
    settingsList.splice(3, 0, {
      icon: Assets.alert,
      title: 'Alert List',
      onClick: handleAlertList,
      iconWidth: Ratio(20),
      iconHeight: Ratio(20),
    });
  }
  if (user.role === 'super_admin' || user.role === 'org_admin' || user.role === 'admin') {
    settingsList = settingsList.filter(list => list.title !== 'Call Availability')
    settingsList = settingsList.filter(list => list.title !== 'Contact Admin')
  }

  const taskData : any  = () => {
    if (user.role === 'org_admin') {
      return [
        {
          title: 'Create Org Admin',
          text: 'Congratulation, now you can view the list of the Org Admin, Click below to view.',
          onClick: () => {
            props.navigation.navigate('AddAdmin', {
              buttonText: 'Add Admin',
              type: 'org_admin',
              add: true,
              title: 'Org Admin',
              view: false,
            });
            setState({openModal:false})
          },
          completed: user.created_org_admin,
        },
        {
          title: 'Create Branch',
          text: 'Congratulation, now you can view the list of the Branch, Click below to view.',
          onClick: () => {
            props.navigation.navigate('AddBranch', { add: true });
            setState({openModal:false})
          },
          completed: user.created_branch,
        },
        {
          title: 'Create Branch Admin',
          text: 'Congratulation, now you can view the list of the Branch Admin, Click below to view.',
          onClick: () => {
            props.navigation.navigate('AddAdmin', {
              buttonText: 'Add Admin',
              type: 'admin',
              add: true,
              title: 'Branch Admin',
              view: false,
            });
            setState({openModal:false})
          },
          completed: user.created_branch_admin,
        },
      ];
    } else if (user.role === 'admin') {
      return [
        {
          title: 'Create Branch Admin',
          text: 'Congratulation, now you can view the list of the Branch Admin, Click below to view.',
          onClick: () => {
            props.navigation.navigate('AddAdmin', {
              buttonText: 'Add Admin',
              type: 'admin',
              add: true,
              title: 'Branch Admin',
              view: false,
            });
            setState({openModal:false})
          },
          completed: user.created_branch_admin,
        },
        {
          title: 'Create Trainer',
          text: 'Congratulation, now you can view the list of the Trainers, Click below to view.',
          onClick: () => {
            props.navigation.navigate('AddUser', {
              buttonText: 'Add Trainer',
              type: 'trainer',
              add: true,
              title: 'Trainers',
            });
            setState({openModal:false})
          },
          completed: user.created_trainer,
        },
        {
          title: 'Create Client',
          text: 'Congratulation, now you can view the list of the Client, Click below to view.',
          onClick: () => {
            props.navigation.navigate('AddUser', {
              buttonText: 'Add Client',
              type: 'customer',
              add: true,
              title: 'Clients',
            });
            setState({openModal:false})
          },
          completed: user.created_customer,
        },
        {
          title: 'Create Dog',
          text: 'Congratulation, now you can view the list of the Dog, Click below to view.',
          onClick: () => {
            props.navigation.navigate('AddDog', { add: true });
            setState({openModal:false})
          },
          completed: user.created_pet,
        },
        {
          title: 'Create Training',
          text: 'Congratulation, now you can view the list of the Training, Click below to view.',
          onClick: () => {
            props.navigation.navigate('AddTraining', { add: true });
            setState({openModal:false})
          },
          completed: user.created_training,
        },
      ];
    } else if (user.user_type == 'trainer') {
      return [
        {
          title: 'Create Client',
          text: 'Congratulation, now you can view the list of the Client, Click below to view.',
          onClick: () => {
            props.navigation.navigate('AddUser', {
              buttonText: 'Add Client',
              type: 'customer',
              add: true,
              title: 'Clients',
            });
            setState({openModal:false})
          },
          completed: user.created_customer,
        },
        {
          title: 'Create Dog',
          text: 'Congratulation, now you can view the list of the Dog, Click below to view.',
          onClick: () => {
            props.navigation.navigate('AddDog', { add: true });
            setState({openModal:false})
          },
          completed: user.created_pet,
        },
        {
          title: 'Create Training',
          text: 'Congratulation, now you can view the list of the Training, Click below to view.',
          onClick: () => {
            props.navigation.navigate('AddTraining', { add: true });
            setState({openModal:false})
          },
          completed: user.created_training,
        },
      ];
    } else if (user.user_type == 'customer') {
      return [
        {
          title: 'Check Training Status',
          text: 'Congratulation, now you can view the status of Training, Click below to view.',
          onClick: () => {
            setState({openModal:false})
          },
          completed: user.check_training_status,
        },
      ];
    }
  };

  return (
    <Container screen styles={{ height: '100%' }} loading={state.loading}>
      <View style={styles.settingScreen}>
        <View style={styles.headerWrapper}>
          <NavButton
            icon={Assets.ArrowBack}
            style={{
              backgroundColor: 'transparent',
              justifyContent: 'flex-start',
              flexDirection: 'row',
            }}
            svg
            iconHeight={Ratio(25)}
            iconWidth={Ratio(25)}
            onClick={() => props.navigation.goBack()}
          />
          <View style={styles.marginLeft}>
            <Text size={Ratio(24)} family={Constants.light} color={Colors.primaryDarkColor}>
              Settings
            </Text>
          </View>
        </View>
        <View style={styles.settingList}>
          <FlatList
            data={settingsList}
            showsVerticalScrollIndicator={false}
            keyExtractor={(item: any) => item.name}
            renderItem={({ item, index }) => (
              <TouchableOpacity
                onPress={() => item.onClick()}
                activeOpacity={0.9}
                style={{ width: "49%", marginBottom: index === settingsList.length - 1 ? Ratio(50) : Ratio(10) }}>
                <SettingsCard
                  icon={item.icon}
                  title={item.title}
                  iconHeight={item.iconHeight || null}
                  iconWeight={item.iconWidth || null}
                />
              </TouchableOpacity>
            )}
            scrollEnabled={true}
          />
        </View>
      </View>
      <Modal
        open={state.openModal}
        onClose={() => {}}
        onOverlayClick={() => setState({ openModal: false })}
        styles={styles.modal}
        showCloseIcon={false}
        closeOnOverlayClick
        classNames={{
          overlay: '',
          modal: 'setting_modal',
        }}>
        <View style={styles.bottomSheetWrapper}>
          <View style={styles.bottomSheetHeader}>
            <View style={styles.headerClose}>
              <NavButton
                icon={Assets.close_white}
                onClick={() => {
                  setState({ openModal: false });
                }}
                svg
                iconHeight={24}
                iconWidth={24}
                style={{ backgroundColor: 'transparent' }}
              />
            </View>
            <View style={styles.headerText}>
              <Text size={Ratio(24)} color={'white'}>
                Quick Start
              </Text>
            </View>
          </View>
          <ScrollView style={styles.bottomSheetBody}>
            {taskData()?.map((item) => (
              <TaskCard
                title={item.title}
                text={item.text}
                completed={item.completed}
                onClick={item.onClick}
              />
            ))}
          </ScrollView>
        </View>
      </Modal>
    </Container>
  );
};

const styles = StyleSheet.create({
  settingScreen: {
    width: '100%',
    height: '100%',
  },
  headerWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    height: '10%',
    padding: '0px 20px',
  },
  marginLeft: {
    marginLeft: Ratio(5),
  },
  settingList: {
    padding: '0px 20px',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    flexDirection: "row",
    // backgroundColor: "red"
  },
  profileContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px 20px',
    // backgroundColor: 'red',
  },
  profileContainerWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px 20px',
    paddingTop: Ratio(10),
    paddingBottom: Ratio(30),
  },
  profileDetailsContainer: {
    paddingLeft: Ratio(15),
  },
  bottomSheetContainer: {
    height: 500,
    width: '100%',
  },
  bottomSheetHeader: {
    height: '100px',
    width: '100%',
    padding: ' 10px 15px 15px 15px',
    backgroundColor: Colors.primaryDarkColor,
    borderTopEndRadius: '30px',
    borderTopStartRadius: '30px',
  },
  bottomSheetWrapper: {
    height: '100%',
    width: '100%',
  },
  bottomSheetBody: {
    height: '450px',
    width: '100%',
    padding: '20px',
    backgroundColor: Colors.background,
    overflowY: 'scroll',
  },
  headerClose: {
    width: '100%',
    alignItems: 'flex-end',
  },
  headerText: {
    width: '100%',
    alignItems: 'center',
  },
  modal: {
    width: '400px',
  },
});

export default Settings;
