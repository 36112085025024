import React, { useEffect, useRef } from 'react';
import {
  Assets,
  Constants,
  Image,
  Models,
  NavButton,
  Ratio,
  Text,
  Colors,
  Input,
  Functions,
  PrimaryButton,
  Validation,
  Container,
  Select,
  View,
  StyleSheet,
  TouchableOpacity,
} from 'utils/imports.utils';
import { useSelector } from 'react-redux';

const CallAvailability = (props: any) => {
  const user = useSelector((state: any) => state.user.user);
  const [state, setState] = Functions.useSetState({
    username: '',
    profile: '',
    activity: false,
    isOpen: true,
    loading: true,
    callDontAllow: false,
    callAllowed: true,
    callLimited: false,
    limitedHours: {},
    fromTime: '12:00 AM',
    toTime: '12:00 AM',
  });

  useEffect(() => {
    if (!user.call_limited) {
      setState({
        callAllowed: user.call_allowed,
        callDontAllow: user.call_allowed ? false : true,
        callLimited: false,
        loading: false,
      });
    } else {
      setState({
        callAllowed: false,
        callDontAllow: false,
        callLimited: true,
        fromTime: user.limited_hours.from,
        toTime: user.limited_hours.to,
        loading: false,
      });
    }
  }, []);

  const backAction = () => {
    props.navigation.goBack();
    return true;
  };

  const handleDontAllowCall = () => {
    setState({
      callDontAllow: true,
      callAllowed: false,
      callLimited: false,
    });
  };

  const handleAllowCall = () => {
    setState({
      callDontAllow: false,
      callAllowed: true,
      callLimited: false,
    });
  };

  const handleLimitAvailability = () => {
    setState({
      callDontAllow: false,
      callAllowed: false,
      callLimited: true,
    });
  };

  const callOptions = [
    {
      label: 'Dont allow calls',
      onClick: handleDontAllowCall,
      key: 'callDontAllow',
    },
    {
      label: 'Allow call hours (24/7)',
      onClick: handleAllowCall,
      key: 'callAllowed',
    },
    {
      label: 'Limit your availability',
      onClick: handleLimitAvailability,
      key: 'callLimited',
    },
  ];

  const updateCallAvailability = async () => {
    try {
      setState({ activity: true });
      let req = {};
      if (state.callLimited) {
        let from = Number(state.fromTime.split(' ')[0].replace(':', ''));
        let to = Number(state.toTime.split(' ')[0].replace(':', ''));
        if (from > to) {
          console.log('Warning');
          Functions.Warning('Warning', 'To Time should be greater than From Time');
          setState({ activity: false });
        } else {
          req = {
            call_allowed: false,
            call_limited: true,
            limited_hours: {
              from: state.fromTime,
              to: state.toTime,
            },
            id: user._id,
          };
          await Models.user.editUser(req);
          Functions.Success('Success', 'Your details updated');
          setState({ activity: false, edit: false });
          props.navigation.goBack();
        }
      } else {
        req = {
          call_allowed: state.callDontAllow ? false : state.callAllowed,
          call_limited: false,
          limited_hours: {},
          id: user._id,
        };
        await Models.user.editUser(req);
        Functions.Success('Success', 'Your details updated');
        setState({ activity: false, edit: false });
        props.navigation.goBack();
      }
    } catch (err) {
      // if (err.errors && err.errors.length > 0) {
      //   Functions.Failure('Failed', err.errors[0]);
      // } else if (err) {
      //   Functions.Failure(err);
      // } else {
      //   Functions.Failure('Failed to update user details');
      // }
      setState({ activity: false });
    }
  };

  const onClose = () => {
    if (state.edit) {
      setState({ edit: false });
    } else {
      props.navigation.goBack();
    }
  };

  return (
    <Container styles={styles.addUserScreen} screen loading={state.loading}>
      <View style={styles.addUserContainer}>
        <View style={styles.headerWrapper}>
          <View style={styles.textWrapper}>
            <View style={styles.imageContainer}>
              <TouchableOpacity
                style={{
                  width: Ratio(40),
                  height: Ratio(40),
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                activeOpacity={0.7}
                onPress={onClose}>
                <Image svg src={Assets.ArrowBack} width={20} height={20} />
              </TouchableOpacity>
            </View>
            <View style={styles.headTextWrapper}>
              <Text
                top={0}
                size={Ratio(24)}
                color={Colors.primaryDarkText}
                family={Constants.light}
                weight="300">
                {'Call Availability'}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.addUserFormContainer}>
          <View style={[styles.formBody, state.callLimited]}>
            <View style={styles.bodyContainer}>
              {callOptions.map((option, index) => (
                <TouchableOpacity
                  style={styles.optionContent}
                  onPress={option.onClick}
                  activeOpacity={0.6}>
                  <View style={styles.checkBoxContainer}>
                    {state[option.key] && <View style={styles.checked}></View>}
                  </View>
                  <View style={styles.optionTextContainer}>
                    <Text
                      color={state[option.key] ? Colors.primaryDarkColor : Colors.primaryLightText}>
                      {option.label}
                    </Text>
                  </View>
                </TouchableOpacity>
              ))}
              {state.callLimited && (
                <View style={styles.selectTimeContainer}>
                  <View style={styles.selectHours}>
                    <Select
                      data={Functions.getHours()}
                      labelValue={'label'}
                      keyValue={'label'}
                      onSelect={(value: string) => setState({ fromTime: value })}
                      selected={state.fromTime}
                      label="From :"
                      labelStyle={{
                        paddingLeft: Ratio(5),
                      }}
                    />
                  </View>
                  <View style={styles.selectHours}>
                    <Select
                      data={Functions.getHours()}
                      labelValue={'label'}
                      keyValue={'label'}
                      onSelect={(value: string) => setState({ toTime: value })}
                      selected={state.toTime}
                      label="To :"
                      labelStyle={{
                        paddingLeft: Ratio(5),
                      }}
                    />
                  </View>
                </View>
              )}
            </View>
          </View>
          <View style={styles.addPetButtonContainer}>
            <PrimaryButton
              text={'Save'}
              onPress={() => updateCallAvailability()}
              width={200}
              backgroundColor={Colors.primaryDarkColor}
              activeOpacity={0.7}
              activity={state.activity}
            />
          </View>
        </View>
      </View>
    </Container>
  );
};

const styles = StyleSheet.create({
  addUserScreen: {
    paddingTop: Ratio(20),
    height: '100%',
  },
  addUserContainer: {
    padding: Ratio(20),
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
  },
  headerWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '10%',
    justifyContent: 'space-between',
  },
  imageContainer: {},
  headTextWrapper: {
    paddingLeft: Ratio(10),
  },
  textWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  addUserFormContainer: {
    position: 'relative',
    width: '100%',
    height: '90%',
    marginTop: Ratio(20),
  },
  formBody: {
    width: '100%',
    // height: Ratio('30%', true),
    backgroundColor: Colors.buttonTextColor,
    borderRadius: 32,
    shadowColor: '#DDE5EE',
    shadowOffset: { width: 4, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
  bodyContainer: {
    padding: "20px 20px 0px",
    width: '100%',
  },
  addPetButtonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  btnContent: {
    width: '80%',
  },
  optionContent: {
    width: '85%',
    padding: '15px 0px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkBoxContainer: {
    width: Ratio(22),
    height: Ratio(22),
    borderRadius: Ratio(22) / 2,
    borderColor: Colors.primaryDarkColor,
    borderWidth: Ratio(1.5),
    alignItems: 'center',
    justifyContent: 'center',
  },
  checked: {
    width: Ratio(14),
    height: Ratio(14),
    borderRadius: Ratio(14) / 2,
    backgroundColor: Colors.secondaryDarkColor,
  },
  optionTextContainer: {
    paddingLeft: Ratio(10),
  },
  selectTimeContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: "0px 2px 30px",
  },
  selectHours: {
    width: '45%',
  },
});

export default CallAvailability;
