import React from 'react';
import { Colors, Constants, View, Image, Ratio, StyleSheet } from 'utils/imports.utils';

interface TextComponentProps {
  color?: string;
  iconPosition?: string;
  icon?: string;
  size?: number;
  weight?: string;
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
  family?: string;
  onPress?: any;
  styles?: object;
  children: any;
  source?: any;
  iconWidth?: number;
  iconHeight?: number;
  lineHeight?: number;
  opacity?: number;
  textAlign?: string;
  ellipsizeMode?: any;
  numberOfLines?: number;
  containerStyle?: any;
  textDecoration?: string;
}

const TextComponent = (props: TextComponentProps) => {
  const {
    color,
    size,
    weight,
    left,
    right,
    top,
    bottom,
    family,
    onPress,
    styles,
    children,
    iconPosition,
    source,
    lineHeight,
    opacity,
    textAlign,
    ellipsizeMode,
    numberOfLines,
    containerStyle,
    textDecoration,
  } = props;
  return (
    <View style={[css.container, containerStyle]}>
      {iconPosition == 'start' ? (
        <Image
          src={source}
          width={props.iconWidth}
          height={props.iconHeight}
        />
      ) : null}
      <div
        onClick={onPress}
        style={
          {
            ...css.text,
            ...styles,
            ...{
              color: color,
              fontSize: size,
              fontWeight: weight,
              marginLeft: left,
              marginRight: right,
              marginTop: top,
              marginBottom: bottom,
              fontFamily: family,
              lineHeight: lineHeight,
              opacity: opacity,
              textAlign: textAlign,
              textDecorationLine: textDecoration,  
            }
          }
        }>
        {children}
      </div>
      {iconPosition == 'end' ? (
        <Image
          src={source}
          width={props.iconWidth}
          height={props.iconHeight}
        />
      ) : null}
    </View>
  );
};

TextComponent.defaultProps = {
  size: Ratio(16),
  family: Constants.regular,
  iconWidth: Ratio(15),
  iconHeight: Ratio(15),
};

const css = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    color: Colors.primaryDarkColor,
    fontSize: Ratio(16),
    fontFamily: Constants.regular,
  },
});

export default TextComponent;
